import { apiEndpoints } from "../../../apis/apiEndpoints";
import { getData } from "../../../apis/apiMethods";
import CONFIG from "../../../helpers/config";

export const MerchantEndpoint = async ({ accountNumber, pin }) => {
  const { data } = await getData(
    `${CONFIG.BASE_URL2}${apiEndpoints.DEACTIVATE_WALLET_ACCOUNT}/${accountNumber}?pin=${pin}`
  );

  return data;
};
