import React from "react";

export default function Drawer({
  children,
  isOpen,
  setIsOpen,
}: {
  children: React.ReactNode;
  isOpen: boolean;
  setIsOpen: (newState: boolean) => void;
}) {
  return (
    <main
      className={`fixed overflow-hidden z-10 bg-[#0B1E4B] bg-opacity-25 inset-0 transform ease-in-out w-full" 
        ${
          isOpen
            ? " transition-opacity opacity-100 duration-500 translate-x-0  "
            : " opacity-0 translate-x-full  "
        }
      `}
    >
      <section
        className={
          " w-screen max-w-lg right-0 absolute bg-white h-full shadow-xl delay-300 duration-500 ease-in-out transition-all transform  overflow-x-hidden overflow-y-auto" +
          (isOpen ? " translate-x-0 " : " translate-x-full ")
        }
      >
        <article className="relative w-screen max-w-lg py-10 px-4 lg:px-10 flex flex-col ">
          {children}
        </article>
      </section>
      <section
        className=" w-screen h-full cursor-pointer "
        onClick={() => {
          setIsOpen(false);
        }}
      ></section>
    </main>
  );
}
