import moment from "moment";
import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData } from "../apis/apiMethods";
import CONFIG from "../helpers/config";

export const getSubAccountActivityLog = async ({
  queryKey,
}: {
  queryKey: any;
}) => {
  const { from, to, query, id } = queryKey[1];
  let queryParams = ``;
  if (query) {
    queryParams = `${
      queryParams ? `${queryParams}&` : `${queryParams}?`
    }query=${query}`;
  }
  if (from) {
    queryParams = `${
      queryParams ? `${queryParams}&` : `${queryParams}?`
    }from=${moment(from).format("YYYY-MM-DD")}`;
  }

  if (to) {
    queryParams = `${
      queryParams ? `${queryParams}&` : `${queryParams}?`
    }to=${moment(to).format("YYYY-MM-DD")}`;
  }

  const data = await getData(
    `${CONFIG.BASE_URL2}${apiEndpoints.SUBACCOUNTS}/${id}/logs${queryParams}`
  );
  return data;
};

export const useSubAccountActivityLog = (props: {
  query?: any;
  from?: number;
  to?: number;
  id: string;
}) => {
  const { from, to, query, id } = props;
  return useQuery(
    ["getSubAccountActivityLog", { from, to, query, id }],

    getSubAccountActivityLog,
    {
      refetchOnWindowFocus: false, // Refetch on window focus
      enabled: id ? true : false,
      retry: 1,
    }
  );
};
