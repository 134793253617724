import { useEffect, useState } from "react";
import { errorHandler } from "../../helpers/errorHandler";
import { toast } from "react-toastify";
import { useAellaServices } from "../../hooks/useAellaServices";
import CONFIG from "../../helpers/config";
import { apiEndpoints } from "../../apis/apiEndpoints";
import CardSkeletonLoader from "../../component/Skeleton/CardSkeletonLoader.component";
import { postData } from "../../apis/apiMethods";
import { useMerchantServices } from "../../hooks/useMerchantServices";
import ConnectServicesCard from "./ConnectServicesCard";
import { useQueryClient } from "react-query";
import ConnectServiceModal from "./Modal/ConnectServiceModal";
import { useAuth } from "../../context/auth";
import { getFromLocalStorage } from "../../helpers/localStorage";
import { LOCAL_STORAGE_KEYS } from "../../helpers/localStorageKeys";

const ConnectServices = () => {
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedData, setSelectedData] = useState<any>("");
  const [services, setServices] = useState([]);
  const [currentServices, setCurrentServices] = useState<any>([]);
  const { data: allServices, isLoading: allServicesLoading } =
    useAellaServices();
  const { merchantCntxtStatus } = useAuth();
  const { data: allMerchantServices, isLoading: allMerchantServicesLoading } =
    useMerchantServices({ status: merchantCntxtStatus });
  const queryClient = useQueryClient();
  const merchantID = getFromLocalStorage(LOCAL_STORAGE_KEYS.MERCHANTID);

  useEffect(() => {
    if (allMerchantServices?.length > 0) {
      const currentServices = allMerchantServices.map(
        (data: any) => data?.type?.name
      );

      setCurrentServices(currentServices);
    }
  }, [allMerchantServices]);

  useEffect(() => {
    if (allServices) {
      for (let resp of allServices) {
        if (resp.name === "Aella Care") {
          resp.logo_url_white =
            "https://static.aellaapp.com/files/icons/White+Mode/AellaCareW.png";
        } else if (resp.name === "Bank Account") {
          resp.logo_url_white =
            "https://static.aellaapp.com/files/icons/White+Mode/BankAccountW.png";
        } else if (resp.name === "Aella Pay") {
          resp.logo_url_white =
            "https://static.aellaapp.com/files/icons/White+Mode/AellaPayW.png";
        } else if (resp.name === "Agency") {
          resp.logo_url_white =
            "https://static.aellaapp.com/files/icons/White+Mode/AgencyW.png";
        } else if (resp.name === "Employee Loans") {
          resp.logo_url_white =
            "https://static.aellaapp.com/files/icons/White+Mode/EmployeeLoansW.png";
        } else if (resp.name === "Events") {
          resp.logo_url_white =
            "https://static.aellaapp.com/files/icons/White+Mode/EventsW.png";
        } else if (resp.name === "POS") {
          resp.logo_url_white =
            "https://static.aellaapp.com/files/icons/White+Mode/POSW.png";
        } else if (resp.name === "Transfer") {
          resp.logo_url_white =
            "https://static.aellaapp.com/files/icons/White+Mode/TransferW.png";
        }

        setServices(allServices);
      }
    }
  }, [allServices]);

  const onSubmit = async (id: string) => {
    setLoading(true);

    const reqBody = {
      type_id: id,
      merchant_id: merchantID,
    };

    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const res: any = await postData(
        `${CONFIG.BASE_URL2}${apiEndpoints.UPDATE_MERCHANT_SERVICES}`,
        reqBody
      );
      queryClient.invalidateQueries("getMerchantServices");
      setTimeout(() => {
        setShowModal(false);
      }, 1000);
    } catch (error) {
      setLoading(false);
      toast.error(errorHandler(error));
    }
    setLoading(false);
  };

  return (
    <>
      <div className="px-10 pt-6 lg:pt-[60px] pb-6">
        <div className="pb-7">
          <h3 className="text-[32px] lg:leading-[38px] font-[300] pb-2">
            Connect More Services
          </h3>
          <p className="text-aellaGray text-[16px] font-[100] lg:leading-[24px]">
            You can do more with Aella! Where would you like to start?
          </p>
        </div>
        {(allMerchantServicesLoading || allServicesLoading || !services) && (
          <>
            <div className="hidden lg:grid grid-cols-1 lg:grid-cols-3 gap-6">
              <CardSkeletonLoader />
              <CardSkeletonLoader />
              <CardSkeletonLoader />
              <CardSkeletonLoader />
              <CardSkeletonLoader />
              <CardSkeletonLoader />
            </div>
            <div className="md:hidden grid grid-cols-1 lg:grid-cols-3 gap-6">
              <CardSkeletonLoader width={300} height={210} />
              <CardSkeletonLoader width={300} height={210} />
              <CardSkeletonLoader width={300} height={210} />
              <CardSkeletonLoader width={300} height={210} />
              <CardSkeletonLoader width={300} height={210} />
              <CardSkeletonLoader width={300} height={210} />
            </div>
            <div className="lg:hidden hidden md:grid grid-cols-1 lg:grid-cols-3 gap-6">
              <CardSkeletonLoader width={700} height={180} />
              <CardSkeletonLoader width={700} height={180} />
              <CardSkeletonLoader width={700} height={180} />
              <CardSkeletonLoader width={700} height={180} />
              <CardSkeletonLoader width={700} height={180} />
              <CardSkeletonLoader width={700} height={180} />
            </div>
          </>
        )}
        {services && !allServicesLoading && !allMerchantServicesLoading && (
          <>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 w-full">
              {services?.map((data: any, _id) => {
                return (
                  <ConnectServicesCard
                    key={data?.id}
                    data={data}
                    setShowModal={setShowModal}
                    setSelectedData={setSelectedData}
                    mode={currentServices?.includes(data?.name) ? true : false}
                  />
                );
              })}
            </div>
          </>
        )}
      </div>

      {showModal && (
        <ConnectServiceModal
          setShowModal={setShowModal}
          showModal={showModal}
          data={selectedData}
          onSubmit={onSubmit}
          loading={loading}
        />
      )}
    </>
  );
};

export default ConnectServices;
