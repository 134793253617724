import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData } from "../apis/apiMethods";
import CONFIG from "../helpers/config";

export const getTransfersList = async ({ queryKey }: { queryKey: any }) => {
  const { page, size, from, to, userId, limit } = queryKey[1];

  let queryParams = `?start=${from}&end=${to}&page=${page}&user_id=${userId}&limit=${limit}`;

  if (!from) {
    queryParams = `?end=${to}&page=${page}&user_id=${userId}&limit=${limit}`;
  }

  if (!to) {
    queryParams = `?start=${from}&page=${page}&user_id=${userId}&limit=${limit}`;
  }

  if (!from && !to) {
    queryParams = `?page=${page}&user_id=${userId}&limit=${limit}`;
  }
  const res: any = await getData(
    `${CONFIG.BASE_URL2}${apiEndpoints.GET_TRANSFER_LIST}${queryParams}`
  );
  return res;
};

export const useTransfersList = ({
  page,
  size,
  to,
  from,
  userId,
  limit,
}: {
  page?: string | number | undefined;
  size?: string | number | undefined;
  to?: string | number | undefined;
  from?: string | number | undefined;
  userId?: string | undefined;
  limit: number;
}) => {
  return useQuery(
    ["getTransferList", { page, size, from, to, userId, limit }],
    getTransfersList,
    {
      enabled: userId ? true : false,
      refetchOnWindowFocus: false,
    }
  );
};

export const getBulkList = async ({ queryKey }: { queryKey: any }) => {
  const { page, from, to, userId, limit } = queryKey[1];

  let queryParams = `?start=${from}&end=${to}&page=${page}&user_id=${userId}&limit=${limit}`;

  if (!from) {
    queryParams = `?end=${to}&page=${page}&user_id=${userId}&limit=${limit}`;
  }

  if (!to) {
    queryParams = `?start=${from}&page=${page}&user_id=${userId}&limit=${limit}`;
  }

  if (!from && !to) {
    queryParams = `?page=${page}&user_id=${userId}&limit=${limit}`;
  }

  const res: any = await getData(
    `${CONFIG.BASE_URL2}${apiEndpoints.GET_BULK_TRANSFER_LIST}${queryParams}`
  );
  return res;
};

export const useBulkList = ({
  page,
  from,
  to,
  userId,
  limit,
}: {
  page: string | number | undefined;
  from: string | number | undefined;
  to: string | number | undefined;
  userId: string | undefined;
  limit: string | number;
}) => {
  return useQuery(
    ["getBulkTransferList", { page, from, to, userId, limit }],
    getBulkList,
    {
      //   enabled: ? true : false,
      refetchOnWindowFocus: false,
    }
  );
};

export const getGroupedList = async ({ queryKey }: { queryKey: any }) => {
  const { selected, userId, limit, page } = queryKey[1];

  let queryParams = `/transfer/merchant/list/${selected}?user_id=${userId}&page=${page}&limit=${limit}`;

  const res: any = await getData(`${CONFIG.BASE_URL2}${queryParams}`);
  return res;
};

export const useGroupedList = ({
  limit,
  userId,
  selected,
  page,
}: {
  limit?: string | number;
  selected: string | number | undefined;
  userId: string;
  page: string | number;
}) => {
  return useQuery(
    ["getGroupedTransferList", { selected, limit, userId, page }],
    getGroupedList,
    {
      // enabled: selected ? true : false,
      refetchOnWindowFocus: false,
    }
  );
};
