import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData } from "../apis/apiMethods";
import CONFIG from "../helpers/config";

export const getAllSubAccountTransactions = async ({
  queryKey,
}: {
  queryKey: any;
}) => {
  const { page, size, id } = queryKey[1];
  let queryParams = `?page=${page}&per_page=${size}`;

  const data = await getData(
    `${CONFIG.BASE_URL2}${apiEndpoints.SUBACCOUNTS}/${id}/transactions${queryParams}`
  );
  return data;
};

export const useAllSubAccountTransactions = (props: {
  page: number;
  size: number;
  id: string;
}) => {
  const { page, size, id } = props;
  return useQuery(
    ["getAllSubAccountTransactions", { page, size, id }],

    getAllSubAccountTransactions,
    {
      refetchOnWindowFocus: false, // Refetch on window focus
      enabled: id ? true : false,
      retry: 1,
    }
  );
};
