import { useState } from "react";
import { useQueries } from "react-query";
import { useNavigate } from "react-router-dom";
import { format, parse } from "date-fns";

import { ReactComponent as SearchIcon } from "../../../assets/images/svg/search-icon.svg";
import { ReactComponent as ChevronDown } from "../../../assets/images/svg/white-dropdown-icon.svg";
import { ReactComponent as BlueChevronDown } from "../../../assets/images/svg/blue-chevron-down.svg";
import { ReactComponent as FilterIcon } from "../../../assets/images/svg/filter-transaction-icon.svg";
import Pagination from "../../../component/Pagination/Pagination.component";
import { ApproveOrDeclineLoanRequestModal } from "../Modal/ApproveOrDeclineLoanRequestModal";
import { getEmployerLoansFn } from "../queries";
import { truncateString } from "../../../helpers/formatter";
import { ROUTES } from "../../../helpers/routes";
import { useLoansContext } from "../EmployeeLoans";
import { ExportLoanBook } from "../Modal/ExportLoanBookModal";
import { formatMoney } from "../../../helpers/formatMoney";
import { ErrorMessage } from "../ErrorMessage";
import { EmptyList } from "../EmptyEmployees";
import { SecondaryLoader } from "../../../component/Loader/SecondaryLoader";
import { FilterLoanBookModal } from "../Modal/FilterLoanBookModal";
import { LoanBookAction } from "../Modal/Action";
import { getFromLocalStorage } from "../../../helpers/localStorage";
import { LOCAL_STORAGE_KEYS } from "../../../helpers/localStorageKeys";

export const CompletedLoans = () => {
  const navigate = useNavigate();
  const role = getFromLocalStorage(LOCAL_STORAGE_KEYS.ROLE);
  const { updateContext } = useLoansContext();
  const perPage = 50;
  const [selectedList, setSelectedList] = useState([]);
  const [filterObj, setFilterObj] = useState(null);
  const [state, setState] = useState({
    currentPage: 1,
    bulkActionModal: false,
    searchTerm: "",
    filterModal: false,
    searchParams: "",
    statusModal: false,
    status: "",
    exportModal: false,
  });

  const handleNavigation = (dataSet) => {
    navigate(ROUTES.LOAN_DETAILS, {
      state: { data: dataSet },
    });
    updateContext({
      showHeader: false,
    });
  };

  const updateState = (payload) => {
    setState((prev) => ({ ...prev, ...payload }));
  };

  const [completedLoans] = useQueries([
    {
      queryKey: [
        "completed loans",
        filterObj,
        state?.searchParams,
        state?.currentPage,
      ],
      queryFn: () =>
        getEmployerLoansFn({
          page: state.currentPage,
          perPage,
          status: "completed",
          searchQuery: state?.searchParams,
          minAmount: filterObj?.startAmount,
          maxAmount: filterObj?.endAmount,
          startDate: filterObj?.start
            ? format(new Date(filterObj?.start), "yyyy-MM-dd")
            : null,
          endDate: filterObj?.end
            ? format(new Date(filterObj?.end), "yyyy-MM-dd")
            : null,
        }),
    },
  ]);

  const loansList = completedLoans?.data?.data?.loans;

  const handleSelectAll = () => {
    if (completedLoans?.isFetching) return;
    if (selectedList?.length === loansList?.length) {
      setSelectedList([]);
    } else setSelectedList(loansList);
  };
  const handleCheckboxClick = (item: any) => {
    if (selectedList.find((each) => item === each)) {
      setSelectedList(selectedList.filter((x: any) => x !== item));
    } else {
      setSelectedList([...selectedList, item]);
    }
  };

  const renderList = () => {
    if (completedLoans?.isFetching) {
      return <SecondaryLoader count={10} />;
    }

    if (completedLoans?.isError) {
      return <ErrorMessage onClick={() => completedLoans?.refetch()} />;
    }

    if (loansList?.length === 0 && state?.searchParams) {
      return <EmptyList message="No record found with the search parameter" />;
    }
    if (loansList?.length === 0 && filterObj) {
      return <EmptyList message="No record found" />;
    }

    if (loansList?.length === 0) {
      return <EmptyList message="No record found" />;
    }

    return loansList?.map((each) => {
      return (
        <div
          key={each?.id}
          className="flex justify-between w-[280%] sm:w-[180%] md:w-full  hide_scrollbar items-center border-b border-b-[#EDEFF5] h-12 gap-x-1 px-5 text-sm text-[#7B7B7B] font-light cursor-pointer"
        >
          <input
            type="checkbox"
            checked={selectedList?.find((x) => x?.id === each?.id)}
            onChange={() => {
              handleCheckboxClick(each);
            }}
            className="w-4 h-4 border-red mr-4"
          />

          <p onClick={() => handleNavigation(each)} className="w-[15%] mr-4">
            {truncateString(each?.staff?.id, 12) ?? "N/A"}
          </p>
          <p onClick={() => handleNavigation(each)} className="w-[15%]">
            {each?.staff?.full_name ?? "N/A"}
          </p>
          <p
            onClick={() => handleNavigation(each)}
            className="w-[15%] mr-4 truncate"
          >
            {each?.staff?.email_address ?? "N/A"}
          </p>
          <p onClick={() => handleNavigation(each)} className="w-[15%]">
            ₦{formatMoney(each?.amount ?? 0)}
          </p>
          <p
            onClick={() => handleNavigation(each)}
            className="w-[19%] text-center"
          >
            {each?.created_at
              ? format(
                  parse(
                    each?.created_at,
                    "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'",
                    new Date()
                  ),
                  "dd MMM, yyyy"
                )
              : "N/A"}
          </p>

          <p
            onClick={() => handleNavigation(each)}
            className="w-[19%] text-center"
          >
            {each?.start_date
              ? format(
                  parse(
                    each?.start_date,
                    "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'",
                    new Date()
                  ),
                  "dd MMM, yyyy"
                )
              : "N/A"}
          </p>

          <p
            onClick={() => handleNavigation(each)}
            className="w-[19%] text-right"
          >
            {each?.end_date
              ? format(
                  parse(
                    each?.end_date,
                    "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'",
                    new Date()
                  ),
                  "dd MMM, yyyy"
                )
              : "N/A"}
          </p>
        </div>
      );
    });
  };

  return (
    <div className="bg-white w-full h-full py-[13px] px-4 lg:px-10">
      {state.bulkActionModal && (
        <div
          className="fixed top-0 left-0 w-full h-full overflow-hidden bg-[#0B1E4B]/40 z-20"
          onClick={() =>
            updateState({
              bulkActionModal: false,
            })
          }
        />
      )}
      <div className="w-full flex flex-wrap flex-col gap-x-2 gap-y-4 md:flex-row items-stretch lg:items-center justify-between">
        <div className={`flex items-center w-auto gap-x-4 relative opacity-0`}>
          <p className="text-[#232323] font-light text-sm text-center min-w-[80px]">
            {selectedList?.length} Selected
          </p>
          {(role === "Super Admin" || role === "Admin") && (
            <div className="bg-[#BFC7D9] w-[1px] h-10" />
          )}
          <button
            disabled={!selectedList?.length}
            onClick={() => {
              updateState({
                bulkActionModal: true,
              });
            }}
            type="button"
            className={`text-aellaBlue inline-flex items-center gap-x-[5px] border border-aellaBlue text-sm font-light h-10 px-[10px] rounded-[4px] invisible opacity-0`}
          >
            Bulk Action
            <BlueChevronDown />
          </button>

          <LoanBookAction
            showAction={state.bulkActionModal}
            onDecline={() => {
              updateState({
                bulkActionModal: false,
                statusModal: true,
                status: "Decline",
              });
            }}
            isDeclining={false}
            isApproving={false}
            onApprove={() => {
              updateState({
                bulkActionModal: false,
                statusModal: true,
                status: "Approve",
              });
            }}
            canApprove={false}
          />
        </div>
        <div className="flex items-center justify-between md:justify-end flex-wrap gap-x-4 gap-y-4 flex-1">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              updateState({
                searchParams: state.searchTerm,
                currentPage: 1,
              });
              setFilterObj(null);
            }}
            className={`flex-1 md:flex-none sm:w-[250px] md:[w-300px] lg:w-[250px] xl:w-[400px] flex items-center gap-x-2 px-3 h-10 rounded-[4px] border border-[#EDEFF5]`}
          >
            <SearchIcon />
            <input
              value={state.searchTerm}
              onChange={(e: any) =>
                updateState({
                  searchTerm: e.target.value,
                })
              }
              className="bg-inherit h-full placeholder:text-[#5B5B5B] text-aellaBlack flex-1 text-sm placeholder:text-[14px] font-light placeholder:font-light outline-none"
              placeholder="Search by staff ID, amount"
            />
          </form>
          <div className="flex items-center gap-x-5">
            <button
              type="button"
              onClick={() =>
                updateState({
                  filterModal: true,
                })
              }
              className={`flex items-center justify-center h-10 px-4 text-[#5b5b5b] text-sm font-light bg-[#F5F5F5] border border-[#EDEFF5] rounded-[4px] cursor-pointer gap-x-2`}
            >
              <FilterIcon />
              Filter By
            </button>

            {/* <button
              type="button"
              onClick={() => {
                updateState({
                  exportModal: true,
                });
              }}
              className="flex items-center gap-x-2 bg-aellaBlue rounded-[4px] px-3 py-1 h-10 font-light text-white cursor-pointer"
            >
              <span>Export</span>
              <ChevronDown />
            </button> */}
          </div>
        </div>
      </div>

      <div className="hide_scrollbar overflow-x-scroll w-full">
        <div className="flex justify-between w-[280%] sm:w-[180%] md:w-full  items-center border-b border-b-[#EDEFF5] mt-10 hide_scrollbar h-10 gap-x-1 px-5 text-sm cursor-pointer font-[400] text-[#000810]">
          <input
            type="checkbox"
            checked={
              !completedLoans?.isFetching &&
              loansList?.length >= 1 &&
              loansList?.length === selectedList?.length
            }
            onChange={handleSelectAll}
            className="w-4 h-4 border-red mr-4"
          />
          <p className="w-[15%] mr-4">Employee ID</p>
          <p className="w-[15%]">Employee Name</p>
          <p className="w-[15%] mr-4">Email Address</p>
          <p className="w-[15%]">Loan Amount</p>
          <p className="w-[19%] text-center">Requested Date</p>
          <p className="w-[19%] text-center">Start Date</p>
          <p className="w-[19%] text-right">End Date</p>
        </div>
        {renderList()}
      </div>

      {loansList?.length > 0 && !completedLoans?.isError ? (
        <Pagination
          postsPerPage={perPage}
          totalPosts={completedLoans?.data?.data?.meta?.totalCount}
          currentPage={state.currentPage}
          setCurrentPage={(e) =>
            updateState({
              currentPage: e,
            })
          }
          totalPages={completedLoans?.data?.data?.meta?.totalPages}
        />
      ) : null}

      <ApproveOrDeclineLoanRequestModal
        showModal={state.statusModal}
        status={state.status}
        closeModal={() => {
          updateState({
            statusModal: false,
          });
        }}
        setSelectedEmployees={setSelectedList}
        selectedEmployees={selectedList}
      />

      <FilterLoanBookModal
        showModal={state.filterModal}
        closeModal={() => updateState({ filterModal: false })}
        setFilterObj={setFilterObj}
        updateState={updateState}
      />

      <ExportLoanBook
        showModal={state?.exportModal}
        closeModal={() =>
          updateState({
            exportModal: false,
          })
        }
      />
    </div>
  );
};
