import React, { useState } from "react";
import { useQueryClient } from "react-query";
import CloseIcon from "../../../assets/images/svg/close-icon.svg";
import editIcon from "../../../assets/images/svg/edit2-icon.svg";
import { ReactComponent as InfoIcon } from "../../../assets/images/svg/info-icon.svg";
import { ReactComponent as PreviewIcon } from "../../../assets/images/svg/preview-icon.svg";
import { RequestsType } from "../Pos";
import { ModalContainer } from "../../../modal/ModalContainer";
import { Player } from "@lottiefiles/react-lottie-player";
import SuccessAnimation from "../../../assets/files/Stars.json";
import BlueButton from "../../../component/Button/BlueButton";
import { ReactComponent as CloseModalIcon } from "../../../assets/images/svg/close-icon.svg";
import moment from "moment";
import { attachClassNameToTag } from "../../../helpers/attachClassNameToTag";

interface TPosModal {
  modalType: string;
  setModalType: (newState: string) => void;
  setIsOpen: (newState: boolean) => void;
  onSubmitRequest: () => void;
  loading: boolean;
  requests: Array<RequestsType>;
  setRequests: (newState: Array<RequestsType>) => void;
  response: Array<RequestsType>;
  setSingleAddress: (newState: boolean) => void;
  details: any;
  setDetails: any;
}

const PosModal: React.FC<TPosModal> = ({
  modalType,
  setModalType,
  setIsOpen,
  loading,
  requests,
  onSubmitRequest,
  setRequests,
  response,
  setSingleAddress,
  details,
  setDetails,
}) => {
  const disabledValue = (id?: number) => {
    const exactField = requests.find((req) => req.id === id);
    return !exactField?.edit;
  };
  const setEditTrue = (id?: number) => {
    const exactField = requests.find((req) => req.id === id);
    return !exactField?.edit;
  };

  const onEdit = (id?: number) => {
    // setSelected([...selected, id])
    // const exactField = requests.find((req) => req.id === id);
    // // eslint-disable-next-line array-callback-return
    // requests?.map((obj: RequestsType) => {
    //   if (exactField) {
    //     return { ...obj, edit: true };
    //   }
    // });

    // if(selected?.includes(exactField.id){
    // setRequests([...requests,])
    // })

    const newData = requests?.map((req: any) => {
      if (req.id === id) {
        return { ...req, edit: !req.edit };
      }
      return req;
    });

    setRequests(newData);
  };

  const onChange = (e: any, id?: number) => {
    e.preventDefault();
    const requestToUpdate = requests.find((request) => request.id === id);
    const otherRequests = requests.filter(
      (request: RequestsType) => request.id !== id
    );
    const { name, value } = e.target;
    setRequests([...otherRequests, { ...requestToUpdate, [name]: value }]);
  };

  const queryClient = useQueryClient();

  const handleClose = () => {
    queryClient?.invalidateQueries("posRequests");
    setModalType("");
    setRequests([
      {
        id: 1,
        delivery_address: "",
        quantity: 0,
        edit: true,
      },
    ]);
    setDetails({});
  };


  return (
    <>
      <div className="fixed justify-center items-center flex overflow-x-hidden overflow-y-auto inset-0 z-50 outline-none focus:outline-none">
        <div className="relative mt-2 mx-auto w-[464px]">
          <div className="flex mb-2 justify-between">
            <div></div>
            <button
              onClick={() => {
                setModalType("");
                setSingleAddress(true);
                setRequests([
                  {
                    id: 1,
                    delivery_address: "",
                    quantity: 0,
                    edit: true,
                  },
                ]);
                setDetails({});
              }}
              type="button"
              className="cursor-pointer"
            >
              <img src={CloseIcon} alt="close modal" />
            </button>
          </div>
          {/*content*/}
          <div className="border-0 bg-white rounded-lg p-4 lg:p-8 flex flex-col w-screen md:w-full bg-colors-white outline-none focus:outline-none ">
            {(() => {
              switch (modalType) {
                case "step1":
                  return (
                    <>
                      {/*header*/}
                      <div className="flex flex-col items-center">
                        <PreviewIcon />
                        <h1 className="leading-6 font-[400] text-[20px] tracking-[0.4px] mt-3">
                          Preview
                        </h1>
                        <p className="text-[16px] leading-[19px] font-[200] text-[#232323] pt-[5px]">
                          Kindly confirm your request.
                        </p>
                        <div className="bg-[#F6F8FA] p-[16px] mt-[23px]">
                          {requests
                            ?.sort((a: any, b: any) => (a?.id > b?.id ? 1 : -1))
                            ?.map((request: RequestsType) => (
                              <div
                                className="rounded flex items-center mt-2.5 first:mt-0"
                                key={request.id}
                              >
                                <div className="flex flex-col">
                                  <label className=" text-[#0B1E4B] lg:leading-[18px] text-[16px] font-[300] mb-2 ">
                                    Delivery Address
                                  </label>
                                  <textarea
                                    className="bg-white appearance-none border px-4 py-2 h-[53px] font-[100] rounded placeholder:text-[#DADADA] placeholder:text-[16px] placeholder:lg:leading-[24px] placeholder:font-[100] text-aellaGray request"
                                    name="delivery_address"
                                    defaultValue={request.delivery_address}
                                    // disabled
                                    disabled={disabledValue(request.id)}
                                    onChange={(e: any) => {
                                      onChange(e, request.id);
                                    }}
                                  />
                                </div>
                                <div className="flex flex-col pr-2.5 pl-2.5">
                                  <label className=" text-[#0B1E4B] lg:leading-[18px] text-[16px] font-[300] mb-2  ">
                                    Quantity
                                  </label>
                                  <input
                                    type="number"
                                    min={1}
                                    className="bg-white appearance-button border px-4 py-2 h-[53px] w-[93px] font-[100] rounded placeholder:text-[#DADADA] placeholder:text-[16px] placeholder:lg:leading-[24px] placeholder:font-[100] text-aellaGray request"
                                    placeholder="e.g 5"
                                    name="quantity"
                                    defaultValue={request.quantity}
                                    // disabled
                                    disabled={disabledValue(request.id)}
                                    onChange={(e: any) => {
                                      onChange(e, request.id);
                                    }}
                                  />
                                </div>

                                <img
                                  src={editIcon}
                                  alt=""
                                  className="mt-6 cursor-pointer"
                                  onClick={() => onEdit(request.id)}
                                />
                              </div>
                            ))}
                        </div>
                      </div>
                      <div className="flex justify-end">
                        <button
                          type="submit"
                          className={`bg-aellaBlue rounded text-white py-4 px-10 items-center flex justify-center cursor-pointer mt-8 ${
                            loading ? "opacity-30" : ""
                          } `}
                          onClick={() => onSubmitRequest()}
                          disabled={loading}
                        >
                          Submit Request
                        </button>
                      </div>
                    </>
                  );
                case "step2":
                  return (
                    <ModalContainer showModal={modalType === "step2"}>
                      <div className="w-full lg:w-[35vw] relative right-0 h-auto max-h-[90vh] z-50 flex flex-col items-center text-center p-10 rounded-[8px] bg-white">
                        <div className="flex items-center justify-center h-auto relative">
                          <Player
                            autoplay
                            controls
                            loop
                            src={SuccessAnimation}
                            style={{
                              width: "200px",
                              height: "120px",
                            }}
                          />
                          <p className="absolute top-[30%] right-[25%] text-2xl text-aellaBlue">
                            Successful
                          </p>
                        </div>
                        <div
                          className="absolute -top-8 -right-0 cursor-pointer"
                          onClick={handleClose}
                        >
                          <CloseModalIcon />
                        </div>

                        <p className="text-[#5B5B5B] text-base mt-4 font-light">
                          POS request has been successfully received. An update
                          will be sent to you after a review.
                        </p>
                        <BlueButton
                          title="View Details"
                          onClick={() => setModalType("step3")}
                          // onClick={handleClose}
                          className="px-12 text-[#F3F5FC] mt-10"
                        />
                      </div>
                    </ModalContainer>
                  );
                case "step3":
                  return (
                    <>
                      {/*header*/}
                      <div className="flex flex-col max-h-[80vh] overflow-y-auto hide_scrollbar">
                        <h1 className="leading-6 font-[400] text-[20px] tracking[0.4px] pb-[17px] border-b">
                          POS Request Details
                        </h1>
                        <div className="">
                          <div className="flex flex-col gap-y-4 md:flex-row py-[18px] md:border-b justify-between items-top">
                            <div className="flex flex-col">
                              <p className="leading-[19px] font-[200] text-[16px] tracking[0.2px] text-aellaGray">
                                Status
                              </p>
                              <p
                                className={`self-start leading-[19px] font-[200] text-[16px] tracking[0.2px] mt-2 ${attachClassNameToTag(
                                  "Pending"
                                )}`}
                              >
                                {/* {request?.status} */}
                                Pending
                              </p>
                            </div>
                            <div className="flex flex-col">
                              <p className="leading-[19px] font-[200] text-[16px] tracking[0.2px] text-aellaGray">
                                Terminal ID
                              </p>
                              {/* {request?.terminal_id && (
                                <ul className="list-decimal list-inside">
                                  {singleTid?.map((data: string) => {
                                    return (
                                      <li className="leading-[19px] font-[200] text-[16px] tracking[0.2px] pt-2">
                                        {data}
                                      </li>
                                    );
                                  })}
                                </ul>
                              )} */}
                            </div>
                          </div>
                          <div className="flex flex-col gap-y-4 items-start md:flex-row py-[18px] md:border-b justify-between md:items-center">
                            <div className="flex flex-col">
                              <p className="leading-[19px] font-[200] text-[16px] tracking[0.2px] text-aellaGray">
                                Request Date
                              </p>
                              <p className="leading-[19px] font-[200] text-[16px] tracking[0.2px] pt-2">
                                {moment(new Date()).format("MMM. DD, YYYY")}
                              </p>
                            </div>
                            <div className="flex flex-col">
                              <p className="leading-[19px] font-[200] text-[16px] tracking[0.2px] text-aellaGray">
                                Delivery Date
                              </p>
                              <p className="leading-[19px] font-[200] text-[16px] tracking[0.2px] pt-2">
                                {/* {request?.delivery_date
                                  ? moment(request?.delivery_date).format(
                                      "MMM. DD, YYYY"
                                    )
                                  : "N/A"} */}
                                -
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="">
                          <div className="flex flex-col gap-y-4 md:flex-row py-[18px] md:border-b justify-between items-top">
                            <div className="flex flex-col">
                              <p className="leading-[19px] font-[200] text-[16px] tracking[0.2px] text-aellaGray">
                                Full Name
                              </p>
                              <p
                                className={`self-start leading-[19px] font-[200] text-[16px] tracking[0.2px] mt-2`}
                              >
                                {details?.fullName || "-"}
                              </p>
                            </div>
                            <div className="flex flex-col">
                              <p className="leading-[19px] font-[200] text-[16px] tracking[0.2px] text-aellaGray">
                                Phone Number
                              </p>
                              <p
                                className={`self-start leading-[19px] font-[200] text-[16px] tracking[0.2px] mt-2`}
                              >
                                {details?.phoneNumber || "-"}
                              </p>
                            </div>
                          </div>
                          <div className="flex flex-col gap-y-4 items-start md:flex-row py-[18px] md:border-b justify-between md:items-center">
                            <div className="flex flex-col">
                              <p className="leading-[19px] font-[200] text-[16px] tracking[0.2px] text-aellaGray">
                                Quantity
                              </p>
                              <p className="leading-[19px] font-[200] text-[16px] tracking[0.2px] pt-2">
                                <ol type="i" className={`list-inside ${requests?.length > 1 ? 'list-disc' : ''}`}>
                                  {requests?.map((req: any) => {
                                    return (
                                      <li key={Math.random()}>
                                        {req?.quantity}
                                      </li>
                                    );
                                  })}
                                </ol>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="py-[18px]">
                          <p className="leading-[19px] font-[200] text-[16px] tracking[0.2px] text-aellaGray">
                            Delivery address
                          </p>
                          <div className="mt-2 border break-words border-[#EDEFF5] rounded-lg px-[16px] py-[22px] leading-[17px] font-[200] text-[14px] text-[#5B5B5B]">
                            <ul className="list-decimal list-inside">
                              {requests?.map((req: any) => {
                                return (
                                  <li key={Math.random()}>
                                    {" "}
                                    {req?.delivery_address}
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </>
                  );

                case "step4":
                  return (
                    <>
                      <div className="flex flex-col items-center ">
                        <PreviewIcon />
                        <h1 className="leading-6 font-[400] text-[20px] tracking[0.4px] mt-3 ">
                          Success
                        </h1>
                        <p className="text-[16px] leading-[19px] font-[200] text-[#232323] pt-[5px]">
                          Your POS request has been received.
                        </p>
                        <div className="bg-[#F6F8FA] p-[16px] mt-[23px] overflow-y-auto  max-h-[40vh]">
                          {requests?.map((request: RequestsType) => (
                            <div
                              className="rounded flex items-center mt-2.5 first:mt-0"
                              key={request.id}
                            >
                              <div className="flex flex-col">
                                <label className=" text-[#0B1E4B] lg:leading-[18px] text-[16px] font-[300] mb-2 ">
                                  Delivery Address
                                </label>
                                <textarea
                                  className="bg-white appearance-none border px-4 py-2 h-[53px] font-[100] rounded placeholder:text-[#DADADA] placeholder:text-[16px] placeholder:lg:leading-[24px] placeholder:font-[100] text-aellaGray"
                                  name="delivery_address"
                                  defaultValue={request.delivery_address}
                                  disabled
                                />
                              </div>
                              <div className="flex flex-col pr-2.5 pl-2.5">
                                <label className=" text-[#0B1E4B] lg:leading-[18px] text-[16px] font-[300] mb-2  ">
                                  Quantity
                                </label>
                                <input
                                  type="number"
                                  min={1}
                                  className="bg-white appearance-none border px-4 py-2 h-[53px] w-[93px] font-[100] rounded placeholder:text-[#DADADA] placeholder:text-[16px] placeholder:lg:leading-[24px] placeholder:font-[100] text-aellaGray"
                                  placeholder="e.g 5"
                                  name="quantity"
                                  defaultValue={request.quantity}
                                  disabled
                                />
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="flex mt-[17px] mx-5 lg:mx-7 items-start bg-[#F6F8FA] py-2 px-5 rounded-[4px]">
                        <div className="shrink-0">
                          <InfoIcon />
                        </div>
                        <h1 className="ml-2.5 text-[12px] lg:leading-[13px] font-[100]">
                          Your Aella POS Terminal will be delivered in a few
                          days. If it takes any longer, please send us a message
                          at&nbsp;
                          <span className="text-aellaBlue">
                            support@aellacredit.com.
                          </span>
                        </h1>
                      </div>
                      <div className="flex justify-end">
                        <button
                          type="submit"
                          onClick={() => {
                            queryClient?.invalidateQueries("posRequests");
                            setModalType("");
                            setRequests([
                              {
                                id: 1,
                                delivery_address: "",
                                quantity: 0,
                                edit: true,
                              },
                            ]);
                          }}
                          className="bg-aellaBlue rounded text-white py-2 px-10 items-center flex justify-center cursor-pointer mt-8"
                        >
                          Done
                        </button>
                      </div>
                    </>
                  );
                default:
                  return null;
              }
            })()}
          </div>
        </div>
      </div>
      <div
        className="bg-opacity-25 fixed inset-0 z-40 bg-[#0B1E4B]"
        onClick={() => {
          setModalType("");
          setRequests([
            {
              id: "",
              delivery_address: "",
              quantity: null,
            },
          ]);
        }}
      ></div>
    </>
  );
};
export default PosModal;
