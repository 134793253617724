import { Dispatch, SetStateAction } from "react";
import { ReactComponent as MarkIcon } from "../../assets/images/svg/double-check-icon.svg";
import { Switch } from "@headlessui/react";

export type ConnectServicesCardType = {
  id: string;
  name: string;
  benefits: [];
  logo_url_white: string;
  logo_url_blue: string;
  desc: string;
  disabled: boolean;
};
const ConnectServicesCard = ({
  data,
  setShowModal,
  setSelectedData,
  mode,
}: {
  data: ConnectServicesCardType;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  setSelectedData: Dispatch<SetStateAction<ConnectServicesCardType>>;
  mode: boolean;
}) => {
  return (
    <div
      className={`bg-white rounded-[5.9px] border-[0.7px] border-[#EDEFF5] flex-col justify-between w-full  p-[17px] cursor-pointer`}
      key={data?.id}
    >
      <div className="flex flex-row justify-between items-center border-b-[0.5px] pb-[22px] border-[rgba(11, 30, 75, 0.2)]">
        <div className="flex flex-row items-center">
          <img src={data.logo_url_white} alt="" className="w-8 h-8" />

          <h1 className="pl-2 text-[18px] text-[#011C34] lg:leading-[22px] font-[400]">
            {data.name}
          </h1>
        </div>
        <div className="flex items-center">
          <p className={`text-[12px] leading-[14px] text-[#0B1E4B] pr-4`}>
            {mode ? "Connected" : "Not Connected"}
          </p>
          <Switch
            checked={mode}
            disabled={mode === true}
            onChange={() => {
              setShowModal(true);
              setSelectedData(data);
            }}
            className={`${
              mode ? "bg-[#D8A28F]" : "bg-gray-200 cursor-pointer"
            } relative inline-flex  h-[20px] w-[32.9px] items-center rounded-full`}
          >
            <span className="sr-only">Connect</span>
            <span
              className={`${
                mode ? "translate-x-3.5" : "translate-x-0.5"
              } inline-block h-[17.42px] w-[17.42px] transform rounded-full bg-white transition`}
            />
          </Switch>
        </div>
      </div>

      <p className="text-[16px] lg:leading-[24px] text-[#737373] font-[200] py-4 ">
        {data.desc}
      </p>
      <div className="font-[400] text-[16px] lg:leading-[19px] text-[#737373]"></div>
      {data?.benefits?.map((data: any, _id) => {
        // const { benefit } = data;
        return <Benefits key={_id} benefit={data} />;
      })}
    </div>
  );
};
export default ConnectServicesCard;

export const Benefits = ({ benefit }: { benefit: string }) => {
  return (
    <div className={`flex items-center w-full`}>
      <div className="pr-3">
        <MarkIcon />
      </div>

      <p className="pl-1 font-[300] text-[14px] lg:leading-[19px] text-[#737373]">
        {benefit}
      </p>
    </div>
  );
};
