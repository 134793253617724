import moment from "moment";
import React, { Dispatch, SetStateAction, useEffect } from "react";
import CloseIcon from "../../../assets/images/svg/close-icon.svg";
import InfoIcon from "../../../assets/images/svg/blue-info.svg";
import {
  attachClassNameToStatus,
  attachClassNameToTag,
  getStatusName,
} from "../../../helpers/attachClassNameToTag";
import { getData } from "../../../apis/apiMethods";

interface TPosRequestModal {
  modalType: string;
  setModalType: (newState: string) => void;
  request: any;
  password: string;
  setPassword: Dispatch<SetStateAction<string>>;
  setShowOtp: Dispatch<SetStateAction<boolean>>;
  handleOTP: any;
}

const PosRequestModal: React.FC<TPosRequestModal> = ({
  modalType,
  setModalType,
  request,
  password,
  setPassword,
  setShowOtp,
  handleOTP,
}) => {
  const singleTid = request?.terminal_id?.split(",");
  return (
    <>
      <div className="fixed justify-center items-center flex overflow-x-hidden inset-0 z-50 outline-none focus:outline-none">
        <div className="relative mt-2 mx-auto w-[464px]">
          <div className="flex mb-2 justify-between">
            <div></div>
            <button
              onClick={() => {
                setModalType("");
              }}
              type="button"
              className="cursor-pointer"
            >
              <img src={CloseIcon} alt="close modal" />
            </button>
          </div>
          {/*content*/}
          <div className="border-0 bg-white rounded-lg p-10 flex flex-col w-screen max-h-[85vh] overflow-y-auto md:w-full bg-colors-white outline-none focus:outline-none ">
            {(() => {
              switch (modalType) {
                case "request":
                  return (
                    <>
                      {/*header*/}
                      <div className="flex flex-col max-h-[80vh] overflow-y-auto hide_scrollbar">
                        <h1 className="leading-6 font-[400] text-[20px] tracking[0.4px] pb-[17px] border-b">
                          POS Request Details
                        </h1>
                        <div className="">
                          <div className="flex flex-col gap-y-4 md:flex-row py-[18px] md:border-b justify-between items-top">
                            <div className="flex flex-col">
                              <p className="leading-[19px] font-[200] text-[16px] tracking[0.2px] text-aellaGray">
                                Status
                              </p>
                              <p
                                className={`self-start leading-[19px] font-[200] text-[16px] tracking[0.2px] mt-2 ${attachClassNameToTag(
                                  request?.status
                                )}`}
                              >
                                {request?.status}
                              </p>
                            </div>
                            <div className="flex flex-col">
                              <p className="leading-[19px] font-[200] text-[16px] tracking[0.2px] text-aellaGray">
                                Terminal ID
                              </p>
                              {request?.terminal_id && (
                                <ul className="list-decimal list-inside">
                                  {singleTid?.map((data: string) => {
                                    return (
                                      <li className="leading-[19px] font-[200] text-[16px] tracking[0.2px] pt-2">
                                        {data}
                                      </li>
                                    );
                                  })}
                                </ul>
                              )}
                            </div>
                          </div>
                          <div className="flex flex-col gap-y-4 md:flex-row py-[18px] md:border-b justify-between md:items-center">
                            <div className="flex flex-col">
                              <p className="leading-[19px] font-[200] text-[16px] tracking[0.2px] text-aellaGray">
                                Request Date
                              </p>
                              <p className="leading-[19px] font-[200] text-[16px] tracking[0.2px] pt-2">
                                {moment(request?.request_date).format(
                                  "MMM. DD, YYYY"
                                )}
                              </p>
                            </div>
                            <div className="flex flex-col">
                              <p className="leading-[19px] font-[200] text-[16px] tracking[0.2px] text-aellaGray">
                                Delivery Date
                              </p>
                              <p className="leading-[19px] font-[200] text-[16px] tracking[0.2px] pt-2">
                                {request?.delivery_date
                                  ? moment(request?.delivery_date).format(
                                      "MMM. DD, YYYY"
                                    )
                                  : "N/A"}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="">
                          <div className="flex flex-col gap-y-4 md:flex-row py-[18px] md:border-b justify-between items-top">
                            <div className="flex flex-col">
                              <p className="leading-[19px] font-[200] text-[16px] tracking[0.2px] text-aellaGray">
                                Full Name
                              </p>
                              <p
                                className={`self-start leading-[19px] font-[200] text-[16px] tracking[0.2px] mt-2 capitalize`}
                              >
                                {request?.customer_name || "-"}
                              </p>
                            </div>
                            {/* <div className="flex flex-col">
                              <p className="leading-[19px] font-[200] text-[16px] tracking[0.2px] text-aellaGray">
                                Phone Number
                              </p>
                              <p
                                className={`self-start leading-[19px] font-[200] text-[16px] tracking[0.2px] mt-2`}
                              >
                                {request?.phone_number || "-"}
                              </p>
                            </div> */}
                            <div className="flex flex-col">
                              <p className="leading-[19px] font-[200] text-[16px] tracking[0.2px] text-aellaGray">
                                Quantity
                              </p>
                              <p className="leading-[19px] font-[200] text-[16px] tracking[0.2px] pt-2">
                                <div className="list-disc list-inside">
                                  <p>{request?.quantity}</p>
                                </div>
                              </p>
                            </div>
                          </div>
                          {/* <div className="flex flex-col gap-y-4 items-start md:flex-row py-[18px] md:border-b justify-between md:items-center"></div> */}
                        </div>
                        <div className="py-[18px]">
                          <p className="leading-[19px] font-[200] text-[16px] tracking[0.2px] text-aellaGray">
                            Delivery address
                          </p>
                          <div className="mt-2 border break-words border-[#EDEFF5] rounded-lg px-[16px] py-[22px] leading-[17px] font-[200] text-[14px] text-[#5B5B5B]">
                            {request?.delivery_address}
                          </div>
                        </div>

                        {(request?.status === "Approved" ||
                          request?.status === "Delivered") && (
                          <div className="text-aellaBlack border-t py-5">
                            <p className=" text-[18px] font-medium">Security</p>
                            <p className="text-sm font-light">
                              Protect this POS terminal against unauthorized
                              access.
                            </p>
                            <div className="text-[14px] mt-2 p-4 rounded-[8px] border border-[#C5D4F6] bg-[#F3F5FC]">
                              <div className="flex gap-x-3">
                                <img
                                  src={InfoIcon}
                                  alt="info icon"
                                  className="self-start"
                                />
                                <div className="flex flex-col gap-y-1">
                                  <p className="font-medium">Please Note</p>
                                  <p className="font-light">
                                    For safety reasons, this POS came with an
                                    in-built password. To activate it for your
                                    use ONLY, kindly click on the “Reset
                                    password” below to set up your new password.
                                  </p>
                                </div>
                              </div>
                            </div>
                            {/* <div className="mt-5 text-light">
                            <p>Password</p>
                            <input
                              disabled
                              type="text"
                              placeholder="**********"
                              className="placeholder:font-thin w-full border border-[#EDEFF5] text-[#5B5B5B] text-sm rounded px-4 py-3 my-2 focus:outline-none"
                              onChange={(e) => setPassword(e.target.value)}
                            />
                            <p
                              onClick={() => {
                                // setModalType("");
                                // setShowOtp(true)
                              }}
                              className="font-light text-sm"
                            >
                              Click on here to{" "}
                              <strong
                                onClick={handleOTP}
                                className="text-[#2054D2] cursor-pointer"
                              >
                                Reset Password{" "}
                              </strong>
                            </p>
                          </div> */}
                          </div>
                        )}

                        {request?.status === "Declined" && (
                          <div className="text-aellaBlack border-t py-5">
                            <p className=" text-[18px] font-medium">
                              Reason for Decline
                            </p>

                            <div className="text-[14px] mt-2 p-4 rounded-[8px] border border-[#D8A28F] bg-[#F9EEE9] text-[#5B5B5B]">
                              <p className="font-light">
                                {request?.declined_reason || "-"}
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    </>
                  );
                default:
                  return null;
              }
            })()}
          </div>
        </div>
      </div>
      <div
        className="bg-opacity-25 fixed inset-0 z-40 bg-[#0B1E4B]"
        onClick={() => {
          setModalType("");
        }}
      ></div>
    </>
  );
};
export default PosRequestModal;
