import { toast } from "react-toastify";
import { apiEndpoints } from "../../../apis/apiEndpoints";
import { patchData } from "../../../apis/apiMethods";
import CloseIcon from "../../../assets/images/svg/close-icon.svg";
import { ReactComponent as ChangeRole } from "../../../assets/images/svg/ChangeRole.svg";
import CONFIG from "../../../helpers/config";
import { errorHandler } from "../../../helpers/errorHandler";
import { useState } from "react";
import Lottie from "react-lottie-player";
import Sparkles from "../../../assets/lottie/sparkles.json";
import { Loader } from "../../../component/Loader/Loader.component";

const ResendInvite = ({
  setCloseModal,
  showModal,
  id,
  refetchUsers,
  mail,
}: {
  setCloseModal: (newState: boolean) => void;
  showModal: boolean;
  id: string;
  refetchUsers: any;
  mail: string;
}) => {
  const [stage, setStage] = useState("mode");
  const [loading, setLoading] = useState(false);
  const style = {
    height: 200,
    width: 200,
  };

  const changeRole = async () => {
    const reqBody = {
      id,
    };

    setLoading(true);

    try {
      const res = await patchData(
        `${CONFIG.BASE_URL2}${apiEndpoints.INVITE_ACTION}/${id}`,
        reqBody
      );
      setLoading(false);
      refetchUsers();
      toast.success(res.message);
      setStage("success");
    } catch (error) {
      setLoading(false);
      toast.error(errorHandler(error));
    }
  };

  const renderContent = () => {
    switch (stage) {
      case "mode":
        return (
          <div className="border-0 rounded-lg flex flex-col w-screen md:w-full bg-colors-white outline-none focus:outline-none">
            <div className="bg-white flex flex-col justify-center rounded-[8px]">
              <div className="">
                <div className="p-[40px] text-center">
                  <ChangeRole className="mx-auto" />
                  <p className="text-[24px] mb-[16px] mt-[40px]">
                    Confirmation Request
                  </p>
                  <p className="text-[#5B5B5B] font-thin mb-[5px] w-[80%] mx-auto">
                    You are about to resend an Invite is this user
                  </p>

                  <div className="flex justify-center gap-[35px]">
                    <button
                      onClick={() => {
                        setCloseModal(!showModal);
                      }}
                      className="px-[44px] py-[16px] text-[14px] mt-[50px] rounded-[4px] text-[#2054D2] border border-[#2054D2]"
                    >
                      Close
                    </button>
                    <button
                      onClick={changeRole}
                      className="px-[29px] inline-flex py-[16px] text-[14px] mt-[50px] rounded-[4px] text-white bg-[#2054D2]"
                    >
                      Send Invite <span>{loading && <Loader />}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );

      case "success":
        return (
          <div className="border-0 rounded-lg flex flex-col w-screen md:w-full bg-colors-white outline-none focus:outline-none">
            <div className="bg-white flex flex-col justify-center rounded-[8px]">
              <div className="">
                <div className="">
                  <div className="p-[40px] pt-[88px] relative">
                    <div className="absolute left-[50%] translate-x-[-50%] top-[40px]">
                      <Lottie
                        animationData={Sparkles}
                        style={style}
                        play
                        speed={1}
                      />
                    </div>

                    <p className="text-[26px] text-[#2054D2] text-center mb-[80px]">
                      Successful
                    </p>

                    <p className="text-[24px] text-[#0B1E4B] text-center">
                      Invite Resent
                    </p>

                    <p className="mt-[16px] text-[#5B5B5B] font-thin text-center mb-[40px]">
                      Invite has been resent out to{" "}
                      <span className="text-[#0B1E4B] font-normal">{mail}</span>
                    </p>

                    <div className="flex justify-center">
                      <button
                        onClick={() => {
                          setCloseModal(!showModal);
                        }}
                        className="px-[42px] py-[16px] rounded-[4px] text-white font-thin bg-[#2054D2] inline-block "
                      >
                        Go Back!
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
    }
  };

  return (
    <>
      <div className="absolute justify-center items-center flex inset-0 z-50 outline-none focus:outline-none  overflow-y-scroll">
        <div className="translate-50 mx-auto max-w-[501px]">
          <div className="flex mb-2 justify-between w-[501px]">
            <div></div>
            <div
              onClick={() => {
                setCloseModal(!showModal);
              }}
            >
              <img
                src={CloseIcon}
                alt="close modal"
                className="cursor-pointer"
              />
            </div>
          </div>
          {renderContent()}
        </div>
      </div>
      <div className="bg-opacity-25 fixed inset-0 z-40 bg-[#0B1E4B]"></div>
    </>
  );
};

export default ResendInvite;
