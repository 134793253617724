import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData } from "../apis/apiMethods";
import CONFIG from "../helpers/config";

export const getMerchantWithdrawalAccountDetails = async ({
  queryKey,
}: {
  queryKey: any;
}) => {
  const { merchantId } = queryKey[1];
  const { data } = await getData(
    `${CONFIG.BASE_URL2}${apiEndpoints.GET_MERCHANT_WITHDRAWAL_ACCOUNTS}/${merchantId}`
  );
  return data;
};

export const useMerchantWithdrawalAccountDetails = ({
  merchantId,
}: {
  merchantId: string;
}) => {
  return useQuery(
    ["merchantWithdrawalAccountDetails", { merchantId }],
    getMerchantWithdrawalAccountDetails,
    {
      refetchOnWindowFocus: false, // Refetch on window focus
      retry: 1,
    }
  );
};
