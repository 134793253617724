import { ModalContainer } from "../../../modal/ModalContainer";
import { ReactComponent as CloseIcon } from "../../../assets/images/svg/white-close-modal.svg";
import BlueButton from "../../../component/Button/BlueButton";

const EnterOTP = ({
  showModal,
  setShowModal,
  setOtp,
  setShowPasswordModal,
  handleSubmit,
  error,
}: {
  showModal: boolean;
  setShowModal: (newState: boolean) => void;
  setOtp: (newState: string) => void;
  setShowPasswordModal: (newState: boolean) => void;
  handleSubmit: any;
  error: any;
}) => {
  return (
    <ModalContainer
      showModal={showModal}
      closeModal={() => {
        setShowModal(false);
      }}
    >
      <form className="w-full lg:w-[30vw] relative h-auto lg:max-h-[85vh] z-50 text-aellaBlack flex flex-col rounded-[8px] bg-white p-6">
        <div className="flex justify-between items-center">
          <div onClick={()=> setShowModal(false)} className="absolute -top-8 -right-0 cursor-pointer">
            <CloseIcon />
          </div>
        </div>
        <p className="text-2xl">Enter OTP</p>
        <div className="mt-5 text-light">
          <p className="text-[#5b5b5b] font-light">
            For security reasons, an OTP has been sent to your registered email.
            Enter the code below to delete this account.{" "}
          </p>
          <p className="mt-4">Enter OTP</p>
          <input
            type="text"
            placeholder="**********"
            className={`placeholder:font-thin w-full border text-[#5B5B5B] text-sm rounded px-4 py-3 my-2 focus:outline-none ${error ? "border-[#EB0C00] " : 'border-[#EDEFF5]'}`}
            onChange={(e) => setOtp(e.target.value)}
          />
          {error && (
            <p className="text-[#EB0C00] text-light mt-1 text-sm">You’ve entered an incorrect OTP. Please enter the correct OTP</p>
          )}
        </div>
        <BlueButton
          title="Continue"
          onClick={() => {
            // handleSubmit();
            setShowModal(false);
            setShowPasswordModal(true);
          }}
          className="self-end mt-6"
        />
      </form>
    </ModalContainer>
  );
};

export default EnterOTP;
