import { FC } from "react";

type inputProps = {
  allowPadding?: boolean;
  icon?: boolean;
  type?: string;
  label: string;
  min?: string;
  max?: string;
  placeholder?: string;
  inputClassName?: string;
  value?: any;
  name?: string;
  errors?: any;
  className?: string;
  isRequired?: boolean;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  maxLength?: number;
  errorMessage?: string;
};

const TextField: FC<inputProps> = ({
  allowPadding = true,
  icon,
  type = "text",
  label,
  min,
  max,
  placeholder,
  inputClassName,
  value,
  name,
  errors,
  className,
  isRequired,
  onChange,
  maxLength,
  errorMessage,
}) => {
  return (
    <div className={`${className} flex flex-col text-aellaBlack gap-y-1 mb-5 `}>
      <label htmlFor={name} className="font-light text-aellaBlack">
        {label} {isRequired && <sup className="text-[#DD4F05]">*</sup>}
      </label>
      <input
        min={min}
        max={max}
        className={`w-full h-11 rounded-[4px] border border-aellaLightGray focus:border-aellaBlue text-aellaBlack outline-none font-light text-sm placeholder:text-[#B6B6B6] ${
          allowPadding ? "px-4" : ""
        } ${inputClassName}`}
        placeholder={placeholder}
        type={type}
        value={value}
        name={name}
        onChange={onChange}
        maxLength={maxLength}
      />
      {!!errors && value && (
        <p className="text-[13px] text-[#dd4f05] font-light text-left">
          {typeof errors === "string" ? errors : errorMessage}
        </p>
      )}
    </div>
  );
};

export default TextField;
