import moment from "moment";
import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData } from "../apis/apiMethods";
import CONFIG from "../helpers/config";

export const getInflowTransactionsSum = async ({
  queryKey,
}: {
  queryKey: any;
}) => {
  const { status, walletID, startDate, endDate } = queryKey[1];

  let queryParams = `?`;

  if (walletID) {
    queryParams = `${queryParams}query=${walletID}`;
  }
  if (status) {
    queryParams = `${queryParams}&status=${status}`;
  }
  if (startDate) {
    queryParams = `${queryParams}&from=${moment(startDate).format(
      "YYYY-MM-DD"
    )}`;
  }
  if (endDate) {
    queryParams = `${queryParams}&to=${moment(endDate).format("YYYY-MM-DD")}`;
  }
  const { data } = await getData(
    `${CONFIG.BASE_URL2}${apiEndpoints.GET_INFLOW_TRANSACTIONS_SUM}${queryParams}`
  );
  return data;
};

export const useInflowTransactionsSum = (props: {
  status?: string | undefined;
  walletID?: string | undefined;
  startDate?: Date | undefined;
  endDate?: Date | undefined;
}) => {
  const { walletID, status, startDate, endDate } = props;
  return useQuery(
    ["getInflowTransactionsSum", { status, walletID, startDate, endDate }],

    getInflowTransactionsSum,
    {
      refetchOnWindowFocus: false, // Refetch on window focus
      enabled: false,
      
      
      // props.hasOwnProperty("walletID")
      //   ? walletID
      //     ? true
      //     : false
      //   : false,
      retry: 1,
    }
  );
};
