import React from "react";
import {
  enforceMaxLength,
  enforcePositiveValues,
} from "../../helpers/enforceMaxLength";
import { ReactComponent as CheckIcon } from "../../assets/images/svg/green-check-icon.svg";

interface ICustomInputFieldProps {
  type: string;
  name?: string;
  placeholder?: string;
  label?: string;
  errors?: any;
  maxLength?: undefined | number;
  max?: number | string;
  defaultValue?: string | number;
  value?: string | number;
  minLength?: undefined | number;
  readOnly?: boolean;
  onChange?: any;
  min?: number | string;
  showRequiredIcon?: boolean;
  passwordError?: boolean;
  hasActionButton?: boolean;
  removeBottomBorder?: boolean;
  autoComplete?: string;
  actionButtonText?: any;
  handleOnBlur?: () => void;
  onClickActionButton?: () => void;
  onClickIcon?: () => void;
  extraLabel?: string;
  hasIcon?: number | any;
  addPadding?: boolean;
  children?: React.ReactNode | undefined;
  errorMessage?: string;
  checkNegative?: boolean;
  disabled?: boolean;
}

const CustomInputField: React.FC<ICustomInputFieldProps> = React.forwardRef(
  (
    {
      extraLabel,
      min,
      removeBottomBorder,
      showRequiredIcon,
      hasActionButton,
      actionButtonText,
      handleOnBlur,
      onClickActionButton,
      type,
      name,
      placeholder,
      label,
      errors,
      maxLength,
      max,
      defaultValue,
      value,
      minLength,
      readOnly,
      onChange,
      children,
      hasIcon,
      addPadding,
      onClickIcon,
      passwordError,
      autoComplete,
      errorMessage,
      checkNegative,
      disabled,
      ...otherProps
    },
    ref: any
  ) => {
    return (
      <div className={`${removeBottomBorder ? "" : "mb-4 lg:mb-[23px]"}`}>
        <div className="flex items-center mb-2">
          <label className="block text-aellaBlack lg:leading-[18px] text-[16px] font-[300] ">
            {label}
          </label>
          {showRequiredIcon && (
            <sup className="ml-1 text-[#EB5757] leading-[16px]">*</sup>
          )}
        </div>
        {extraLabel?.length ? (
          <h1 className="text-[#DD4F05] text-[14px] lg:leading-[16px] tracking-[0.03px] font-[500] mb-2">
            {extraLabel}
          </h1>
        ) : null}
        <div className="relative">
          {children && (
            <div className="absolute flex items-center ml-2 h-full">
              <span>{children}</span>
            </div>
          )}
          {hasIcon && (
            <div
              className={`absolute inset-y-0 left-0 px-2 border-r flex items-center text-sm text-aellaGray font-[300] leading-5 cursor-pointer`}
              onClick={onClickIcon}
            >
              <span className="text-capitalize">{hasIcon}</span>
            </div>
          )}
          <input
            className={`bg-white appearance-none border placeholder:text-[#DADADA] placeholder:text-[16px] placeholder:lg:leading-[24px] placeholder:font-[100] ${
              addPadding ? "pl-10" : ""
            } ${
              readOnly
                ? "text-aellaGray focus:border-aellaLightGray"
                : "text-aellaBlack"
            } ${
              errors ? "border-[#EB5757]" : "border-aellaLightGray"
            }   rounded w-full h-[48px] py-2 ${
              hasIcon ? "pl-14 pr-4" : "px-4"
            } leading-6 text-[16px] font-[300] focus:outline-none focus:bg-white  ${
              errors ? "border-[#EB5757]" : "focus:border-aellaBlue"
            } ${hasActionButton && ""} ${children && "border-l-0"}`}
            type={type}
            name={name}
            max={max}
            min={min}
            onBlurCapture={handleOnBlur}
            placeholder={placeholder}
            onKeyDown={(e) => {
              if (checkNegative) {
                enforcePositiveValues(e);
              }
            }}
            onWheel={(e) => {
              const event = e.target as HTMLElement;
              event.blur();
            }}
            onKeyPress={enforceMaxLength}
            maxLength={maxLength}
            minLength={minLength}
            defaultValue={defaultValue}
            onChange={onChange}
            readOnly={readOnly}
            autoComplete={autoComplete}
            ref={ref}
            disabled={disabled}
            {...otherProps}
          />
          {hasActionButton && (
            <div
              className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 cursor-pointer"
              onClick={onClickActionButton}
            >
              <span className="text-capitalize">{actionButtonText}</span>
            </div>
          )}
        </div>

        {passwordError && errors ? (
          <div className="flex flex-col">
            <div className="flex flex-col lg:flex-row lg:items-center pt-2">
              <p className="flex items-center lg:pr-[136px] text-[13px] font-[200] leading-[16px]">
                {errors?.types?.matches?.includes(
                  "Password must contain a lowercase alphabet"
                ) ? (
                  <span className="inline-flex border-[#2054D2] rounded-full h-[8px] w-[8px] bg-[#2054D2]"></span>
                ) : (
                  <CheckIcon />
                )}
                <span className="pl-2">One lowercase character</span>
              </p>
              <p className="flex items-center text-[13px] font-[200] leading-[16px]">
                {errors?.types?.matches?.includes(
                  "Password must contain a number"
                ) ? (
                  <span className="inline-flex border-[#2054D2] rounded-full h-[8px] w-[8px] bg-[#2054D2]"></span>
                ) : (
                  <CheckIcon />
                )}
                <span className="pl-2">One number</span>
              </p>
            </div>
            <div className="flex flex-col lg:flex-row lg:items-center lg:pt-2">
              <p className="flex items-center lg:pr-[136px] text-[13px] font-[200] leading-[16px]">
                {errors?.types?.matches?.includes(
                  "Password must contain an uppercase alphabet"
                ) ? (
                  <span className="inline-flex border-[#2054D2] rounded-full h-[8px] w-[8px] bg-[#2054D2]"></span>
                ) : (
                  <CheckIcon />
                )}
                <span className="pl-2">One uppercase character</span>
              </p>
              <p className="flex items-center  text-[13px] font-[200] leading-[16px]">
                {errors?.types?.min?.includes(
                  "Password must have at least 6 characters"
                ) ? (
                  <span className="inline-flex border-[#2054D2] rounded-full h-[8px] w-[8px] bg-[#2054D2]"></span>
                ) : (
                  <CheckIcon />
                )}
                <span className="pl-2">6 characters minimum</span>{" "}
              </p>
            </div>
          </div>
        ) : null}

        {errors && !passwordError && (
          <p className="text-[#DD4F05] font-light text-[0.8rem]">
            {errors?.message}
          </p>
        )}
      </div>
    );
  }
);

export default CustomInputField;
