import { useState } from "react";
import { Link } from "react-router-dom";

import { ROUTES } from "../../helpers/routes";
import { postToLocalStorage } from "../../helpers/localStorage";
import { LOCAL_STORAGE_KEYS } from "../../helpers/localStorageKeys";
import { truncateString } from "../../helpers/formatter";
import { getFirstLetter } from "../../helpers/capitalizeFirstLetter";

const Loader = () => (
  <div className="w-[20px] h-[20px] border-aellaBlue loader__container" />
);

const AccountSelection = ({
  completeSignIn,
  state,
  isLoading,
}: {
  completeSignIn: () => void;
  state: any;
  isLoading: boolean;
}) => {
  const [selectedOrgId, setSelectedOrgId] = useState("");

  const renderContent = () => {
    if (state?.isLoading) {
      return (
        <div className="h-[50vh] w-full flex items-center justify-center">
          <div className="w-20 h-20 border-aellaBlue loader__container" />
        </div>
      );
    }

    if (state?.organizationData?.length === 0)
      return (
        <div className="">
          <p>No Organization Found</p>
        </div>
      );

    return (
      <div className="flex flex-col gap-y-7">
        {state?.organizationData?.map((organization) => {
          return (
            <div
              key={organization?.id}
              className="border border-[#EDEFF5] rounded-[8px] p-4 flex justify-between items-center"
            >
              <div className="flex gap-x-[10px] items-center flex-1">
                {organization?.organization_logo ? (
                  <img
                    src={organization?.organization_logo || ""}
                    alt="bank logo"
                    width="55"
                    height="55"
                    className="object-cover rounded-[4px]"
                  />
                ) : (
                  <div className="rounded-[4px] shrink-0 h-[55px] w-[55px] flex justify-center items-center bg-[#D8A28F] text-aellaBlue text-2xl uppercase">
                    {getFirstLetter(organization?.organization_name || "")}
                  </div>
                )}
                <div className="flex flex-col">
                  <p className="text-base text-aellaBlack font-light capitalize truncate">
                    {truncateString(organization?.organization_name, 15)}
                  </p>
                  <p className="text-sm text-[#7b7b7b]">
                    Role: {organization?.role?.name}
                  </p>
                </div>
              </div>
              <button
                disabled={isLoading}
                type="button"
                onClick={() => {
                  setSelectedOrgId(organization?.id);
                  postToLocalStorage(
                    LOCAL_STORAGE_KEYS.MEMBER_ID,
                    organization?.id
                  );
                  postToLocalStorage(
                    LOCAL_STORAGE_KEYS.ROLE,
                    organization?.role?.name
                  );
                  completeSignIn();

                  postToLocalStorage(
                    LOCAL_STORAGE_KEYS.BUSINESSNAME,
                    organization?.organization_name
                  );
                }}
                className="text-aellaBlue p-4 rounded-[4px] inline-flex items-center gap-[10px] border border-aellaBlue text-sm cursor-pointer"
              >
                Go to Dashboard{" "}
                {selectedOrgId === organization?.id && <Loader />}
              </button>
            </div>
          );
        })}
      </div>
    );
  };
  return (
    <div className="h-full w-full md:w-[550px] flex flex-col">
      <p className="text-[32px] text-aellaBlack">Sign In</p>
      <p className="text-[#7b7b7b] text-base font-light mb-7">
        Choose a workspace
      </p>

      {renderContent()}

      <p className="flex items-center justify-center mt-8 text-[16px] lg:leading-[20px] font-[300]">
        <span className="text-aellaBlack pr-2">Don't have an account?</span>
        <Link className="text-aellaBlue cursor-pointer" to={ROUTES.SIGNUP}>
          Sign Up
        </Link>
      </p>
    </div>
  );
};

export default AccountSelection;
