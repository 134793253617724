import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData } from "../apis/apiMethods";
import CONFIG from "../helpers/config";

export const getCheckEmailExist = async ({ queryKey }: { queryKey: any }) => {
  const { email } = queryKey[1];
  const { data } = await getData(
    `${CONFIG.BASE_URL2}${apiEndpoints.CHECK_EMAIL_EXIT}?email=${email}`
  );
  return data;
};

export const useCheckEmailExist = ({ email }: { email: string }) => {
  return useQuery(
    ["getCheckEmailExist", { email }],

    getCheckEmailExist,
    {
      refetchOnWindowFocus: false, // Refetch on window focus
      enabled: email ? true : false,
      retry: 1,
    }
  );
};
