import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData } from "../apis/apiMethods";
import CONFIG from "../helpers/config";

export const getApprovalLog = async ({ queryKey }: { queryKey: any }) => {
  const { merchantId } = queryKey[1];
  const { data } = await getData(
    `${CONFIG.BASE_URL2}${apiEndpoints.CHECK_APPROVAL_LOG}/${merchantId}`
  );
  return data;
};

export const useApprovalLog = ({ merchantId }) => {
  return useQuery(
    ["getApprovalLog", { merchantId }],

    getApprovalLog,
    {
      refetchOnWindowFocus: false, // Refetch on window focus
      retry: 1,
      enabled: merchantId ? true : false,
    }
  );
};
