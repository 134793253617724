import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData } from "../apis/apiMethods";
import CONFIG from "../helpers/config";

export const getMerchantTransferFee = async ({
  queryKey,
}: {
  queryKey: any;
}) => {
  const { amount } = queryKey[1];

  const { data } = await getData(
    `${CONFIG.BASE_URL2}${apiEndpoints.GET_TRANSFER_FEE}?amount=${amount}`
  );
  return data;
};

export const useMerchantTransferFee = ({
  loadFee,
  amount,
}: {
  loadFee: boolean;
  amount: string;
}) => {
  return useQuery(
    ["getMerchantTransferFee", { amount }],
    getMerchantTransferFee,
    {
      enabled: loadFee && amount ? true : false,
      refetchOnWindowFocus: false, // Refetch on window focus
    }
  );
};
