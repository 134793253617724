import { toast } from "react-toastify";
import { apiEndpoints } from "../../../apis/apiEndpoints";
import { deleteData, patchData } from "../../../apis/apiMethods";
import CloseIcon from "../../../assets/images/svg/close-icon.svg";
import { ReactComponent as ChangeRole } from "../../../assets/images/svg/ChangeRole.svg";
import CONFIG from "../../../helpers/config";
import { errorHandler } from "../../../helpers/errorHandler";
import { useState } from "react";

const ChangeUserAccess = ({
  setCloseModal,
  showModal,
  id,
  refetchUsers,
  userRole,
}: {
  setCloseModal: (newState: boolean) => void;
  showModal: boolean;
  id: string;
  refetchUsers: any;
  userRole: string;
}) => {
  const [loading, setLoading] = useState(false);

  const changeRole = async () => {
    const reqBody = {
      memberId: id,
      roleId: userRole,
    };

    setLoading(true);

    try {
      const res = await patchData(
        `${CONFIG.BASE_URL2}${apiEndpoints.CHANGE_TEAM_ROLE}`,
        reqBody
      );
      setLoading(false);
      refetchUsers();
      toast.success(res.message);
      setCloseModal(!showModal);
    } catch (error) {
      setLoading(false);
      toast.error(errorHandler(error));
    }
  };

  return (
    <>
      <div className="absolute justify-center items-center flex inset-0 z-50 outline-none focus:outline-none  overflow-y-scroll">
        <div className="translate-50 mx-auto max-w-[501px]">
          <div className="flex mb-2 justify-between w-[501px]">
            <div></div>
            <div
              onClick={() => {
                setCloseModal(!showModal);
              }}
            >
              <img
                src={CloseIcon}
                alt="close modal"
                className="cursor-pointer"
              />
            </div>
          </div>

          <div className="border-0 rounded-lg flex flex-col w-screen md:w-full bg-colors-white outline-none focus:outline-none">
            <div className="bg-white flex flex-col justify-center rounded-[8px]">
              <div className="">
                <div className="p-[40px] text-center">
                  <ChangeRole className="mx-auto" />
                  <p className="text-[24px] mb-[16px] mt-[40px]">
                    Confirmation Request
                  </p>
                  <p className="text-[#5B5B5B] font-thin mb-[5px] w-[80%] mx-auto">
                    You are about to{" "}
                    <span className="font-medium">
                      change access of this user
                    </span>
                    . Click the change access button below if you're sure you
                    want to perform this action or keep user instead.
                  </p>

                  <div className="flex justify-center gap-[35px]">
                    <button
                      onClick={() => {
                        setCloseModal(!showModal);
                      }}
                      className="px-[44px] py-[16px] text-[14px] mt-[50px] rounded-[4px] text-[#2054D2] border border-[#2054D2]"
                    >
                      Close
                    </button>
                    <button
                      onClick={changeRole}
                      className="px-[29px] py-[16px] text-[14px] mt-[50px] rounded-[4px] text-white bg-[#2054D2]"
                    >
                      {loading ? "Loading..." : "Change Access"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-opacity-25 fixed inset-0 z-40 bg-[#0B1E4B]"></div>
    </>
  );
};

export default ChangeUserAccess;
