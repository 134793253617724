import { ModalContainer } from "../../../modal/ModalContainer";
import { ReactComponent as CloseModalIcon } from "../../../assets/images/svg/close-icon.svg";

export const RemoveItemModal = ({
  deleteUser,
  showModal,
  closeModal,
  setSelectedItems,
}) => {
  return (
    <ModalContainer showModal={showModal} closeModal={closeModal}>
      <div
        className={`w-full lg:w-[35vw] relative right-0 min-h-[30vh] h-auto max-h-[90vh] z-50 flex flex-col items-center justify-center text-center p-10 rounded-[8px] bg-white fast_animation ${
          showModal
            ? "mt-0 opacity-100 visible"
            : "mt-[5rem] opacity-0 invisible"
        }`}
      >
        <button
          type="button"
          className="absolute -top-8 -right-0 cursor-pointer"
          onClick={closeModal}
        >
          <CloseModalIcon />
        </button>
        <p className="text-aellaBlack text-2xl">Delete Employee(s)</p>
        <p className="text-[#5B5B5B] font-light mt-4">
          Are you sure you want to delete these employees? <br /> Once done,
          this cannot be reversed.
        </p>

        <button
          onClick={() => {
            deleteUser();
            closeModal();
            setSelectedItems([]);
          }}
          type="button"
          className="bg-[#EB5757] text-white text-sm font-light mt-10 h-10 w-[90px] rounded-[4px]"
        >
          Delete
        </button>
      </div>
    </ModalContainer>
  );
};

export const RemoveSingleEmployee = ({
  deleteUser,
  showModal,
  closeModal,
  isDeleting,
}) => {
  return (
    <ModalContainer showModal={showModal} closeModal={closeModal}>
      <div
        className={`w-full lg:w-[35vw] relative right-0 min-h-[30vh] h-auto max-h-[90vh] z-50 flex flex-col items-center justify-center text-center p-10 rounded-[8px] bg-white fast_animation ${
          showModal
            ? "mt-0 opacity-100 visible"
            : "mt-[5rem] opacity-0 invisible"
        }`}
      >
        <button
          type="button"
          className="absolute -top-8 -right-0 cursor-pointer"
          onClick={closeModal}
        >
          <CloseModalIcon />
        </button>
        <p className="text-aellaBlack text-2xl">Delete Employee</p>
        <p className="text-[#5B5B5B] font-light mt-4">
          Are you sure you want to delete this employee? <br /> Once done, this
          cannot be reversed.
        </p>

        <button
          onClick={deleteUser}
          disabled={isDeleting}
          type="button"
          className={`bg-[#EB5757] text-white text-sm font-light mt-10 h-10 w-[90px] rounded-[4px] ${
            isDeleting ? "opacity-60" : "opacity-100"
          }`}
        >
          {isDeleting ? "Deleting.." : "Delete"}
        </button>
      </div>
    </ModalContainer>
  );
};
