import { ModalContainer } from "../../modal/ModalContainer";
import { ReactComponent as CloseModalIcon } from "../../assets/images/svg/close-icon.svg";
import { ReactComponent as FailedIcon } from "../../assets/images/svg/failed-transfer.svg";
import { ReactComponent as ProcessingIcon } from "../../assets/images/svg/processing-icon.svg";
import { Player } from "@lottiefiles/react-lottie-player";
import SuccessAnimation from "../../assets/files/Stars.json";
import BlueButton from "../../component/Button/BlueButton";
import { useNavigate } from "react-router-dom";
import { useApprovalLog } from "../../hooks/useApprovalLog";

const Failed = ({ retry, comment }: { retry: Function; comment: string }) => {
  return (
    <div className="flex flex-col items-center justify-center">
      <div className="flex items-center justify-center h-auto relative">
        <ProcessingIcon />
      </div>
      <p className="text-[#0B1E4B] text-2xl mt-8">
        Your upgrade request was declined
      </p>
      <p className="text-[#5B5B5B] text-base mt-4 font-light">
        This is due to the reason{' '}
        <span className="uppercase font-medium">{comment}</span>. <br /> Please click
        on the link below to return to the previous page and retry the process.
        If you have any questions, please do not hesitate to contact us. Thank
        you for entrusting us with your business.
      </p>
      <BlueButton onClick={retry} title="Retry" className="mt-10" />
    </div>
  );
};

const Processing = ({ handleClose }: { handleClose: Function }) => {
  return (
    <div className="flex flex-col items-center justify-center">
      <div className="flex items-center justify-center h-auto relative">
        <ProcessingIcon />
      </div>
      <p className="text-[#0B1E4B] text-2xl mt-8">Processing...</p>
      <p className="text-[#5B5B5B] text-base mt-4 font-light">
        We have received your request to upgrade your account and are currently
        reviewing it. If you have any questions or concerns, please do not
        hesitate to reach out to us. Thank you for entrusting us with your
        business
      </p>
      <BlueButton onClick={handleClose} title="Go Back" className="mt-10" />
    </div>
  );
};

const Successful = ({ handleClose }: { handleClose: Function }) => {
  return (
    <div className="flex flex-col items-center justify-center">
      <div className="flex items-center justify-center h-auto relative">
        <Player
          autoplay
          controls
          loop
          src={SuccessAnimation}
          style={{
            width: "200px",
            height: "120px",
          }}
        />
        <p className="absolute top-[30%] right-[25%] text-2xl text-aellaBlue">
          Successful
        </p>
      </div>
      <p className="text-[#0B1E4B] text-2xl mt-8">Upgrade successful</p>
      <p className="text-[#5B5B5B] text-base mt-4 font-light">
        Your account has been upgraded.
      </p>
      <BlueButton onClick={handleClose} title="Go Back" className="mt-10" />
    </div>
  );
};

const StatusModal = ({
  showModal,
  setShowModal,
  setModalType,
  merchantDetails,
  retry,
}: {
  showModal: boolean;
  setShowModal: (newstate: boolean) => void;
  setModalType: (newstate: string) => void;
  merchantDetails: any;
  retry?: Function;
}) => {
  const navigate = useNavigate();
  const handleClose = () => {
    navigate("/home");
  };

  const { data: log } = useApprovalLog({
    merchantId: merchantDetails?.merchant?.id,
  });

  const comment = log?.find((x) => x?.approval_type === "Registered")?.comment;

  return (
    <ModalContainer
      showModal={showModal}
      closeModal={() => {
        setShowModal(false);
        setModalType("");
      }}
    >
      <div className="w-full text-center lg:w-[35vw] relative right-0 h-auto max-h-[90vh] z-50 text-[#232323] flex flex-col items-center rounded-[8px] bg-white py-10 px-10">
        <div
          onKeyDown={() => {}}
          className="absolute -top-8 -right-0 cursor-pointer"
          onClick={handleClose}
        >
          <CloseModalIcon />
        </div>

        {merchantDetails?.merchant?.business_type === "Registered" &&
        merchantDetails?.attributes?.hasOwnProperty("registeredAddress") ? (
          <Successful handleClose={handleClose} />
        ) : merchantDetails?.merchant?.registered_status === "Disapproved" ? (
          <Failed retry={retry} comment={comment} />
        ) : (
          <Processing handleClose={handleClose} />
        )}
      </div>
    </ModalContainer>
  );
};

export default StatusModal;
