import { useEffect, useState } from "react";

import { ModalContainer } from "../../../modal/ModalContainer";
import { ReactComponent as CloseIcon } from "../../../assets/images/svg/close-icon.svg";
import { InnerTab } from "../../../component/Tab/Tab.component";
import { formatMoney } from "../../../helpers/formatMoney";
import { LoanSummary } from "../Tabs/LoanSummary";
import { RepaymentSchedule } from "../Tabs/RepaymentSchedule";
import { RepaymentHistory } from "../Tabs/RepaymentHistory";
import { RepaymentTransactionDetails } from "./RepaymentTransactionDetails";
import { LoadingState } from "../LoadingState";
import { Loan } from "../../../interfaces/employee-loans";
import { attachClassToLoanStatus, attachNameToLoanStatus } from "../../../helpers/attachClassNameToTag";

export const LoanDetailsModal = ({
  showModal,
  closeModal,
  loan,
}: {
  showModal: boolean;
  closeModal: () => void;
  loan: Loan;
}) => {
  const [activeTab, setActiveTab] = useState("Loan Summary");
  const [showDetails, setShowDetails] = useState(false);
  const [selected, setSelected] = useState(null);
  const isFetching = false;

  useEffect(() => {
    if (!showModal)
      setTimeout(() => {
        setShowDetails(false);
      }, 1000);
  }, [showModal]);

  const renderContent = () => {
    if (activeTab === "Loan Summary") return <LoanSummary loan={loan} />;
    if (activeTab === "Repayment Schedule")
      return <RepaymentSchedule loan={loan} />;
    if (activeTab === "Repayment History")
      return (
        <RepaymentHistory
          setShowDetails={setShowDetails}
          setSelected={setSelected}
          selected={selected}
        />
      );
  };

  const renderBody = () => {
    if (isFetching) return <LoadingState />;
    if (showDetails)
      return (
        <RepaymentTransactionDetails close={() => setShowDetails(false)} />
      );
    else
      return (
        <div className="flex flex-col h-full">
          <div className="flex justify-between items-center pb-3 border-b border-b-[#EDEFF5]">
            <p className="text-xl text-aellaBlack">Loan Details</p>

            <CloseIcon
              onClick={closeModal}
              className="h-10 w-10 shrink-0 cursor-pointer"
            />
          </div>

          <div className="mt-4 text-sm w-full font-light flex flex-col gap-y-4">
            <div className="flex justify-between items-center">
              <p className="text-aellaGray">Status</p>
              <p className={`px-4 py-1 rounded-[4px] ${attachClassToLoanStatus(loan?.status)}`}>
                {attachNameToLoanStatus(loan?.status)}
              </p>
            </div>
            <div className="flex justify-between items-center gap-x-2 flex-wrap gap-y-2">
              <p className="text-aellaGray">Loan ID</p>
              <p className="text-[#232323]">{loan?.id}</p>
            </div>
            <div className="flex justify-between items-center">
              <p className="text-aellaGray">Still owing</p>
              <p className="text-[#232323]">
                ₦{formatMoney(loan?.balance ?? 0)}
              </p>
            </div>
          </div>

          <div className="w-full bg-white pt-2 mt-8">
            <div className="border-b border-[#C5D4F6] pb-[1px] w-full">
              <div className="flex flex-wrap gap-4">
                <InnerTab
                  text="Loan Summary"
                  id="Loan Summary"
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                />
                <InnerTab
                  text="Repayment Schedule"
                  id="Repayment Schedule"
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                />
                {/* <InnerTab
                  text="Repayment History"
                  id="Repayment History"
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                /> */}
              </div>
            </div>

            <div className="py-2 flex-1">{renderContent()}</div>
          </div>
        </div>
      );
  };

  return (
    <ModalContainer showModal={showModal} closeModal={closeModal}>
      <div
        className={`w-full lg:w-[30rem] absolute top-0 h-screen z-50 text-[#232323] flex flex-col bg-white py-10 px-6 overflow-y-scroll animation ${
          showModal ? "right-0" : "-right-[30rem]"
        } `}
      >
        {renderBody()}
      </div>
    </ModalContainer>
  );
};
