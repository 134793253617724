import { FC } from "react";
import { ReactComponent as Chevron } from "../../assets/images/svg/blue-button-chevron.svg";

type blueBtnType = {
  title: string;
  className?: string;
  onClick?: any;
  disabled?: boolean;
  type?: "button" | "submit" | "reset";
  icon?: boolean;
};

const BlueButton: FC<blueBtnType> = ({
  title,
  className,
  onClick,
  disabled,
  type = "button",
  icon,
}) => {
  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled}
      className={`flex items-center gap-x-2 py-3 px-6 bg-aellaBlue text-[#F3F5FC] rounded-[4px] cursor-pointer ${className} ${
        disabled ? "opacity-30" : ""
      } `}
    >
      {title} {icon && <Chevron />}
    </button>
  );
};

export const OutlineButton: FC<blueBtnType> = ({
  title,
  className,
  onClick,
  disabled,
  type = "button",
}) => {
  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled}
      className={`flex items-center py-3 px-6 bg-white border border-aellaBlue text-aellaBlue rounded-[4px]  ${className} ${
        disabled ? "opacity-50 cursor-default" : "cursor-pointer"
      } `}
    >
      {title}
    </button>
  );
};

export default BlueButton;
