import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData } from "../apis/apiMethods";
import CONFIG from "../helpers/config";

export const getTeamInvite = async () => {
  const res: any = await getData(
    `${CONFIG.BASE_URL2}${apiEndpoints.TEAM_INVITE}`
  );
  return res;
};

export const useTeamInvite = () => {
  return useQuery(["getAllFilteredMembers"], getTeamInvite, {
    retry: 1,
    refetchOnWindowFocus: false,
  });
};
