export const getFirstLetter = (text?: string) => {
  return text?.charAt(0)?.toUpperCase();
};

export const capitalizeFirstLetter = (word: string) => {
  // Check if the input is not an empty string
  if (word.length === 0) return word;

  // Capitalize the first letter and concatenate it with the rest of the word
  return word.charAt(0).toUpperCase() + word.slice(1);
};
