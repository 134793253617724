import { ReactComponent as More } from "../../../assets/images/svg/more.svg";
import { ReactComponent as Empty } from "../../../assets/images/svg/empty.svg";
import { useState } from "react";
import CardSkeletonLoader from "../../../component/Skeleton/CardSkeletonLoader.component";
import { getFromLocalStorage } from "../../../helpers/localStorage";
import { LOCAL_STORAGE_KEYS } from "../../../helpers/localStorageKeys";
import { useTeamInvite } from "../../../hooks/useTeamInvite";
import RevokeInvite from "../Modal/RevokeInvite";
import ResendInvite from "../Modal/ResendInvite";

const PendingInviteList = () => {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openResendModal, setOpenResendModal] = useState(false);
  const [userId, setUserId] = useState("");
  const { data, isFetching, refetch } = useTeamInvite();
  const [userEmail, setUserEmail] = useState("");
  const user = getFromLocalStorage(LOCAL_STORAGE_KEYS.USERID);
  const role = getFromLocalStorage(LOCAL_STORAGE_KEYS.ROLE);

  return (
    <>
      {isFetching ? (
        <div className="flex flex-col md:flex-row justify-between px-10 pt-[32px] lg:pt-[30px]">
          <CardSkeletonLoader width={320} height={458} />
          <CardSkeletonLoader width={320} height={458} />
          <CardSkeletonLoader width={320} height={458} />
        </div>
      ) : (
        <div className="w-full border border-[#EDEFF5]">
          <div className="bg-[#F3F5FC] flex p-[14px]">
            <p className="w-[25%] text-[#0B1E4B]">Full Name</p>
            <p className="w-[25%] text-[#0B1E4B] ">Email Address</p>
            <p className="w-[25%] text-[#0B1E4B] ">Role</p>
            <p className="w-[25%] text-[#0B1E4B] ">Action</p>
          </div>
          {data?.data?.length === 0 ? (
            <div className="flex flex-col gap-[32px] mt-[77px] w-[40%] mx-auto justify-center items-center">
              <div className="text-center py-[30px]">
                <Empty className="mx-auto" />
                <p className="text-[#232323] my-[8px]">
                  No Invite has been sent yet
                </p>
                <p className="font-thin text-[#5B5B5B] w-[319px] text-center px-[30px]">
                  You're yet to send an invite. When you do, it will appear
                  here.
                </p>
              </div>
            </div>
          ) : (
            <div className="flex flex-col gap-[16px] my-[16px]">
              {data?.data?.map((each: any) => (
                <div key={each?.id} className="flex p-[14px] ">
                  <div className="w-[25%] text-[#3C4249] font-thin">
                    {each?.first_name} {each?.last_name}
                  </div>
                  <div className="w-[25%] text-[#3C4249] font-thin">
                    <p className="w-[95%] truncate"> {each?.email_address}</p>
                  </div>
                  <div className="w-[25%] text-[#3C4249] font-thin flex gap-[14px] items-center">
                    <p>{each?.merchant_role?.name}</p>
                  </div>
                  {each?.user?.id === user || role !== "Super Admin" ? null : (
                    <button className="w-[25%] relative myHovered cursor-pointer text-[#3C4249] font-thin flex gap-[14px] items-center">
                      <More />
                      <div className="myHovered2 invisible transition-all py-[10px] cursor-pointer absolute text-left left-0 top-[100%] z-20 w-[200px] p-[8px] bg-white shadow-md rounded-[3px]">
                        <div
                          onMouseOver={() => {
                            setUserId(each?.id);
                          }}
                          onFocus={() => {
                            setUserId(each?.id);
                          }}
                          onClick={() => setOpenDeleteModal(true)}
                          className="text-[14px] text-[#FE4149] px-[11px] py-[8px] transition-all hover:bg-[#F3F5FC]"
                        >
                          Revoke Invite
                        </div>

                        <div
                          onMouseOver={() => {
                            setUserId(each?.id);
                            setUserEmail(each?.email_address);
                          }}
                          onFocus={() => {
                            setUserId(each?.id);
                            setUserEmail(each?.email_address);
                          }}
                          onClick={() => setOpenResendModal(true)}
                          className="text-[14px] text-[#6A7581] px-[11px] py-[8px] transition-all hover:bg-[#F3F5FC]"
                        >
                          Resend Invite
                        </div>
                      </div>
                    </button>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      )}

      {openDeleteModal && (
        <RevokeInvite
          refetchUsers={refetch}
          id={userId}
          showModal={openDeleteModal}
          setCloseModal={setOpenDeleteModal}
        />
      )}

      {openResendModal && (
        <ResendInvite
          mail={userEmail}
          refetchUsers={refetch}
          id={userId}
          showModal={openResendModal}
          setCloseModal={setOpenResendModal}
        />
      )}
    </>
  );
};

export default PendingInviteList;
