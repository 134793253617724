import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { RegisteredStepOneForm } from "../../../../interfaces/profileSetUp";
import { Loader } from "../../../../component/Loader/Loader.component";
import { Dispatch, SetStateAction, useState } from "react";
import CONFIG from "../../../../helpers/config";
import { apiEndpoints } from "../../../../apis/apiEndpoints";
import { useQueryClient } from "react-query";
import { putData } from "../../../../apis/apiMethods";
import { errorHandler } from "../../../../helpers/errorHandler";
import { toast } from "react-toastify";
import { registeredBusinessTypes } from "../../../../helpers/json";

const schema = yup.object().shape({
  businessType: yup.string().required(),
});

const ProfileStepOne = ({
  merchantDetails,
  setBusinessType,
}: {
  merchantDetails: any;
  setBusinessType: Dispatch<SetStateAction<string>>;
}) => {
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    // setValue,
    // watch,
    formState: { errors },
  } = useForm<RegisteredStepOneForm>({
    resolver: yupResolver(schema),
  });
  const queryClient = useQueryClient();

  const onSubmit = async ({ businessType }: { businessType: string }) => {
    setLoading(true);
    const reqBody = {
      registration_type: businessType,
    };
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const res: any = await putData(
        `${CONFIG.BASE_URL2}${apiEndpoints.CREATE_REG_BUSINESS_TYPE}`,
        reqBody
      );
      setBusinessType(businessType);
      queryClient.invalidateQueries("getMerchantDetails");
    } catch (error) {
      toast.error(errorHandler(error));
    }
    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex  pb-[26px] items-center">
        <h3 className="text-[20px] lg:leading-[24px] font-[300] tracking-[0.03px]">
          Business Type
        </h3>
        <h1 className="ml-1 text-[12px] leading-[24px] text-[#EB5757]">*</h1>
      </div>

      <label className="text-aellaGray text-[14px] lg:leading-[16px] tracking-[0.03px] font-[300] mb-2">
        Select business type
      </label>
      <select
        className={` ${
          errors?.businessType ? "border-[#EB5757]" : "border-aellaLightGray"
        } bg-white appearance-none border px-4 rounded w-full h-[48px] py-2 leading-6 text-[16px] font-[300] focus:outline-none focus:bg-white `}
        {...register("businessType")}
        disabled={
          merchantDetails?.attributes?.registeredDocument?.registration_type
        }
        value={
          merchantDetails?.attributes?.registeredDocument?.registration_type
        }
      >
        <option
          value=""
          className="text-[#DADADA] text-[16px] lg:leading-[24px] font-[100]"
        >
          {/* Select Identification */}
        </option>
        {registeredBusinessTypes?.map((data: any, _id: any) => {
          const { id, name } = data;
          return (
            <option value={id} key={_id}>
              {name}
            </option>
          );
        })}
      </select>

      <div className="flex justify-between mt-6">
        <div></div>
        <div></div>
        <div></div>
        {!merchantDetails?.attributes?.registeredDocument
          ?.registration_type && (
          <button
            type="submit"
            className="bg-aellaBlue disabled:opacity-75 rounded text-white p-[15.5px] items-center flex xxl:mt-7 mb-28 lg:mb-0 justify-center cursor-pointer"
            disabled={loading}
          >
            Save and Continue
            {loading && <Loader />}
          </button>
        )}
      </div>
    </form>
  );
};
export default ProfileStepOne;
