import { format, parseISO } from "date-fns";
import { formatMoney } from "../../../helpers/formatMoney";
import { handleCopy, maskBVN } from "../../../helpers/others";
import { Employee } from "../../../interfaces/employee-loans";

const ListItem = ({
  name,
  value,
  className,
}: {
  name: string;
  value: any;
  className?: string;
}) => {
  return (
    <div
      className={`flex flex-col gap-y-[5px] w-[45%] font-light text-sm sm:text-base ${className}`}
    >
      <p className="text-aellaGray">{name}</p>
      <p className="text-[#232323]">{value}</p>
    </div>
  );
};
export const EmployeeInfo = ({ employee }: { employee: Employee }) => {
  return (
    <div className="p-0 sm:p-6 flex flex-wrap justify-between gap-x-4 gap-y-6">
      <ListItem
        name="Full Name"
        value={employee?.full_name ?? "N/A"}
        className="capitalize"
      />
      <ListItem
        name="Date Of Birth"
        value={
          employee?.date_of_birth
            ? format(parseISO(employee?.date_of_birth), "dd/MM/yyyy")
            : "N/A"
        }
        className="text-right sm:text-left"
      />
      <ListItem name="Phone Number" value={employee?.phone_number ?? "N/A"} />
      <ListItem
        name="Employment Date"
        value={
          employee?.date_of_employment
            ? format(parseISO(employee?.date_of_employment), "dd/MM/yyyy")
            : "N/A"
        }
        className="text-right sm:text-left"
      />
      <ListItem name="Email Address" value={employee?.email_address ?? "N/A"} />
      <ListItem
        name="BVN"
        value={employee?.bvn ? maskBVN(employee?.bvn) : "N/A"}
        className="text-right sm:text-left"
      />
      {/* <ListItem name="Address" value="" /> */}
      <ListItem
        name="Net Monthly Pay"
        value={`₦${formatMoney(employee?.net_monthly_pay || 0)}`}
        className=""
      />
      <ListItem name="Pay Day" value={employee?.payday ?? "N/A"} className="text-right sm:text-left" />
      <ListItem
        name="Net Annual Pay"
        value={`₦${formatMoney(employee?.net_annual_pay || 0)}`}
        className=""
      />

      <div className="w-full sm:w-[90%] mb-2">
        <p className="text-[#7b7b7b] mb-2 font-light">Salary Bank Account</p>
        <div className="bg-[#F3F5FC] p-2 sm:p-4 flex flex-col gap-y-[16px] rounded-[8px] text-sm sm:text-base">
          <div>
            <p className="text-[#737373] font-light">Account Name</p>
            <p className="text-aellaBlack mt-[5px]">
              {employee?.account_name ?? "N/A"}
            </p>
          </div>

          <div className="flex flex-col sm:flex-row gap-x-10 gap-y-4">
            <div className="w-full sm:w-[50%]">
              <p className="text-[#737373] font-light">Bank Name</p>
              <p className="text-aellaBlack mt-[5px]">
                {employee?.bank_name ?? "N/A"}
              </p>
            </div>
            <div className="flex items-center gap-x-6 w-full sm:w-[50%]">
              <div>
                <p className="text-[#737373] font-light">Account Number</p>
                <p className="text-aellaBlack mt-[5px]">
                  {employee?.account_number ?? "N/A"}
                </p>
              </div>

              <button
                type="button"
                onClick={() => {
                  handleCopy(employee?.account_number);
                }}
                className="text-[#828282] font-light ml-auto sm:ml-0 bg-white rounded-[16px] px-[8px] py-1 cursor-pointer"
              >
                Copy
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
