// AuthContext.tsx
import React, {
  createContext,
  useContext,
  useReducer,
  useMemo,
  useEffect,
} from "react";
import {
  getFromLocalStorage,
  postToLocalStorage,
} from "../helpers/localStorage";
import { LOCAL_STORAGE_KEYS } from "../helpers/localStorageKeys";

interface initialStateType {
  isAuth: boolean;
  details: any;
  products: any;
  merchantCntxtProfile: string;
  merchantCntxtStatus: string;
  merchantCntxtTerms: string;
}

interface Children {
  children: any;
}

// interface Action  { type: "LOGIN", payload: { isAuth: boolean, details: any } }

const initialState: initialStateType = {
  isAuth: false,
  details: {},
  products: {},
  merchantCntxtProfile: "",
  merchantCntxtStatus: "",
  merchantCntxtTerms: "",
};

const AuthContext = createContext<any>(initialState);

// Define reducer
const reducer = (state: initialStateType, action: any): any => {
  switch (action.type) {
    case "GET-CREDENTIALS":
      const login = localStorage.getItem("Details") || "";
      const allProducts =
        getFromLocalStorage(LOCAL_STORAGE_KEYS.PRODUCTS) || "";
      return {
        ...state,
        isAuth: getFromLocalStorage(LOCAL_STORAGE_KEYS.TOKEN) ? true : false,
        details: login ? JSON.parse(login)?.merchantDetails : "",
        merchantCntxtProfile: getFromLocalStorage(
          LOCAL_STORAGE_KEYS.MERCHANTCREATED
        ),
        merchantCntxtStatus: getFromLocalStorage(
          LOCAL_STORAGE_KEYS.MERCHANTSTATUS
        ),
        merchantCntxtTerms: getFromLocalStorage(
          LOCAL_STORAGE_KEYS.MERCHANTTERMS
        ),
        products: allProducts ? JSON.parse(allProducts)?.merchantDetails : "",
      };
    case "LOGIN":
      localStorage?.setItem(
        "Details",
        JSON.stringify({
          merchantDetails: action.payload.merchantDetails,
        })
      );
      return {
        ...state,
        isAuth: action.payload.isAuth,
        details: action.payload.merchantDetails,
      };
    case "SET_MERCHANT_PRODUCTS":
      postToLocalStorage(
        LOCAL_STORAGE_KEYS.PRODUCTS,
        JSON.stringify(action.payload)
      );
      return { ...state, products: action.payload };
    case "SET_MERCHANT_CNTEXT_STATUS":
      postToLocalStorage(LOCAL_STORAGE_KEYS.MERCHANTSTATUS, action.payload);
      return { ...state, merchantCntxtStatus: action.payload };

    case "SET_MERCHANT_CNTXT_TERMS":
      postToLocalStorage(LOCAL_STORAGE_KEYS.MERCHANTTERMS, action.payload);
      return { ...state, merchantCntxtTerms: action.payload };

    case "SET_MERCHANT_CNTXT_PROFILE":
      postToLocalStorage(LOCAL_STORAGE_KEYS.MERCHANTCREATED, action.payload);
      return { ...state, merchantCntxtProfile: action.payload };
    default:
      return state;
  }
};

// Create context provider
const AuthProvider = ({ children }: Children): JSX.Element => {
  const [state, dispatch] = useReducer(reducer, initialState);
  useEffect((): void => {
    dispatch({ type: "GET-CREDENTIALS" });
  }, [state?.isAuth]);
  const contextValue = useMemo(() => {
    return {
      isAuth: state?.isAuth,
      details: state?.details,
      getCredentials: () => dispatch({ type: "GET-CREDENTIALS" }),
      setCredentials: (payload: string) => dispatch({ type: "LOGIN", payload }),
      setMerchantCntxtStatus: (payload: string) =>
        dispatch({ type: "SET_MERCHANT_CNTEXT_STATUS", payload }),
      setMerchantProducts: (payload: string) =>
        dispatch({ type: "SET_MERCHANT_PRODUCTS", payload }),
      setMerchantCntxtTerms: (payload: string) =>
        dispatch({ type: "SET_MERCHANT_CNTXT_TERMS", payload }),
      setMerchantCntxtProfile: (payload: string) =>
        dispatch({ type: "SET_MERCHANT_CNTXT_PROFILE", payload }),
      merchantCntxtProfile: state?.merchantCntxtProfile,
      merchantCntxtStatus: state?.merchantCntxtStatus,
      merchantCntxtTerms: state?.merchantCntxtTerms,
      products: state?.products,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, dispatch]);

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

// Custom hook to use context
const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
