import { useState } from "react";
import { useQuery } from "react-query";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import TextField from "../../../component/CustomHTMLElements/TextField";

import { ReactComponent as Info } from "../../../assets/images/svg/info-icon.svg";
import CalendarIcon from "../../../assets/images/svg/calendar.svg";

import BlueButton, {
  OutlineButton,
} from "../../../component/Button/BlueButton";
import { LoanConfigConfirmationModal } from "../Modal/LoanConfigConfirmationModal";
import { SecondaryLoader } from "../../../component/Loader/SecondaryLoader";
import { format } from "date-fns";

const LoanConfiguration = () => {
  const [percentage, setPercentage] = useState("short-term");
  const [maxAge, setMaxAge] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [employmentDate, setEmploymentDate] = useState(null);

  const isNotValid =
    +percentage > 100 ||
    +percentage < 1 ||
    !/^(?!$)\d+(\.\d+)?$/.test(percentage) ||
    !maxAge ||
    +maxAge < 1 ||
    !/^\d+$/.test(maxAge) ||
    !employmentDate;

  const { data, isLoading } = useQuery({
    queryFn: () => {},
    queryKey: ["loan config"],
  });

  return (
    <div className="bg-aellaDimGray w-full h-full hide_scrollbar ">
      <div className="bg-white mx-4 lg:mx-10 mt-8 px-6 py-6 h-auto hide_scrollbar">
        <p className="text-aellaBlack text-[20px] lg:text-[24px]">
          Loan Configuration Settings
        </p>

        <p className="text-[#232323] font-light mt-1 pb-[10px] border-b border-b-[#EDEFF5]">
          Choose the type of loan you want to configure for your employees and
          set parameters for it below.
        </p>

        {isLoading ? (
          <SecondaryLoader count={10} />
        ) : (
          <form className="py-4 px-0 lg:p-6 mt-6">
            <div className="w-full bg-aellaDimGray p-6 rounded-[4px]">
              <div className="xs:w-full sm:w-[90%] lg:w-[60%]">
                <div className="flex items-center gap-x-[10px]">
                  <input
                    type="radio"
                    onChange={() => {
                      setSelectedType("short-term");
                      setPercentage("");
                    }}
                    checked={selectedType === "short-term"}
                    className="accent-aellaBlue"
                  />
                  <p className="text-[18px]">Short term loan</p>
                </div>
                {selectedType === "short-term" && (
                  <div className="">
                    <p className="mt-2 text-aellaGray font-light">
                      This is a 1-3 month loan that your employees can have
                      access to.
                    </p>

                    <div className="mt-4 text-aellaBlack">
                      <TextField
                        label="Percentage of Net Monthly Pay"
                        placeholder="0%"
                        className="w-full"
                        inputClassName="border-aellaLightGray"
                        isRequired
                        value={percentage}
                        onChange={(e) => setPercentage(e.target.value)}
                        max="100"
                        min="1"
                        // maxLength={3}
                        errors={
                          +percentage > 100 ||
                          +percentage < 1 ||
                          !/^(?!$)\d+(\.\d+)?$/.test(percentage)
                        }
                        errorMessage="Percentage is invalid!"
                      />
                    </div>

                    <div className="flex flex-col gap-y-5 sm:gap-y-0 sm:flex-row justify-between items-center">
                      <TextField
                        label="Employee's Maximum Age"
                        placeholder="Enter age"
                        className="w-full sm:w-[47%] mb-0"
                        inputClassName="border-aellaLightGray"
                        isRequired
                        value={maxAge}
                        onChange={(e) => setMaxAge(e.target.value)}
                        min="1"
                        maxLength={3}
                        errors={+maxAge < 1 || !/^\d+$/.test(maxAge)}
                        errorMessage="Age is invalid!"
                      />
                      <div className="w-full sm:w-[47%] flex flex-col gap-y-0 mt-[-6px] sm:gap-y-2 lg:mt-[2px]">
                        <p className="text-aellaBlack text-left lg:leading-[16px] text-[16px] font-[300]">
                          Employment Date{" "}
                          <sup className="text-[#DD4F05]">*</sup>
                        </p>
                        <div className="bg-white flex items-center border border-aellaLightGray rounded-[4px] px-3">
                          <DatePicker
                            dateFormat="dd/MM/yyyy"
                            placeholderText="dd/mm/yyyy"
                            selected={employmentDate ?? null}
                            maxDate={new Date()}
                            onChange={(date: Date | null) => {
                              setEmploymentDate(date);
                            }}
                            showYearDropdown
                            scrollableYearDropdown
                            yearDropdownItemNumber={100}
                            className="h-11 w-full text-aellaBlack lg:leading-[16px] text-[16px] font-[300] outline-0"
                          />
                          <img
                            src={CalendarIcon}
                            className="w-[20px] h-[20px] cursor-pointer"
                            alt="calendar icon"
                          />
                        </div>
                      </div>
                    </div>

                    {percentage && maxAge && employmentDate && (
                      <div className="mt-4 flex gap-x-2 bg-[#FFFCF4] border border-[#EED3C5] p-2 rounded-[4px]">
                        <Info />

                        <div className="text-sm flex flex-col text-[#232323]">
                          <span> Please Note:</span>
                          <span className="font-light mt-2">
                            The configuration above means that: <br />
                            1. Your employees can get a loan that is{" "}
                            {percentage}% of their monthly net pay. <br />
                            2. This loan will be available to employees that are{" "}
                            {maxAge}
                            years of age and below. <br /> 3. This loan will NOT
                            be available to employees that started working with
                            you after{" "}
                            {employmentDate
                              ? format(new Date(employmentDate), "dd MMM yyyy")
                              : "nil"}{" "}
                            . <br /> 
                            {/* 4. Your employees have a month to repay
                            this loan */}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="w-full bg-aellaDimGray p-6 rounded-[4px] mt-6">
              <div className="xs:w-full sm:w-[90%] lg:w-[60%] flex flex-col">
                <div className="flex items-center gap-x-[10px]">
                  <input
                    type="radio"
                    disabled
                    onChange={() => {
                      setSelectedType("long-term");
                      setPercentage("");
                    }}
                    checked={selectedType === "long-term"}
                    className="accent-aellaBlue"
                  />
                  <p className="text-[18px]">Long term loan</p>
                </div>

                {selectedType === "long-term" && (
                  <div className="">
                    <p className="mt-2 text-aellaGray font-light">
                      Enter the percentage of the net annual pay you want to be
                    </p>

                    <div className="mt-4">
                      <TextField
                        label="Percentage of Net Monthly Pay"
                        placeholder="0%"
                        className="w-full"
                        inputClassName="border-aellaLightGray"
                        isRequired
                        value={percentage}
                        onChange={(e) => setPercentage(e.target.value)}
                        max="100"
                        min="1"
                        maxLength={3}
                        errors={
                          +percentage > 100 ||
                          +percentage < 1 ||
                          !/^\d+$/.test(percentage)
                        }
                        errorMessage="Percentage is invalid!"
                      />
                    </div>
                    {percentage && (
                      <div className="mt-4 flex gap-x-2 bg-[#FFFCF4] border border-[#EED3C5] p-2 rounded-[4px]">
                        <Info />

                        <div className="text-sm flex flex-col text-[#232323]">
                          <span> Please Note:</span>
                          <span className="font-light mt-2">
                            The configuration above means that the maximum
                            amount your employees can have access to will be{" "}
                            {percentage}% of their net monthly pay.
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>

            <div className="flex gap-x-6 flex-col xs:flex-row sm:flex-row gap-y-4 mt-8">
              <BlueButton
                disabled={isNotValid}
                onClick={(e) => {
                  e.preventDefault();

                  setShowModal(true);
                }}
                title="Save"
                className="px-[55px] w-full xs:w-auto sm:w-auto justify-center"
                type="submit"
              />
              <OutlineButton
                title="Reset"
                className="px-[55px] w-full xs:w-auto sm:w-auto justify-center"
                type="reset"
                onClick={() => {
                  setPercentage("");
                  setEmploymentDate(null);
                  setMaxAge("");
                }}
              />
            </div>
          </form>
        )}
      </div>
      <LoanConfigConfirmationModal
        showModal={showModal}
        closeModal={() => setShowModal(false)}
        formField={{ percentage, selectedType, employmentDate, maxAge }}
      />
    </div>
  );
};

export default LoanConfiguration;
