import PushNotification from "../Components/PushNotification";
import EmailNotification from "../Components/EmailNotification";
import SMSNotification from "../Components/SMSNotification";
import { useMerchantDetails } from "../../../hooks/useMerchantDetails";
import { getFromLocalStorage } from "../../../helpers/localStorage";
import { LOCAL_STORAGE_KEYS } from "../../../helpers/localStorageKeys";
import { TabMenu } from "../Components/TabMenu";

const Notification = () => {
  const userId = getFromLocalStorage(LOCAL_STORAGE_KEYS.USERID);

  const {
    data: merchantDetails,
    isLoading: merchantDetailLoading,
    refetch,
  } = useMerchantDetails({ userId });

  return (
    <>
      <div className="border-y border-[#e1dddd] bg-aellaDimGray overflow-x-auto h-screen">
        <div className="bg-white pt-[7px] px-[10px] lg:px-10 overflow-x-auto">
          <div className="flex justify-between bg-white items-center pt-2 pb-[1px] flex-wrap border-t border-[#F1F1F1] w-[200%] xs:w-[165%] sm:w-full">
            <TabMenu />
          </div>
        </div>
        <div className="py-[32px] px-[10px] lg:p-10">
          <div className="bg-white border border-[#EDEFF5] p-[10px] lg:p-10 pt-[30px] min-h-[70vh]">
            <div className="">
              <div className="pb-[12px] border-b border-[#EDEFF5]">
                <p className="text-[24px] text-[#0B1E4B]">Notification</p>
              </div>
            </div>

            <div className="pt-[34px] w-full md:w-[65%] flex flex-col gap-[26px]">
              <PushNotification
                details={merchantDetails?.notificationPreferences}
                isLoading={merchantDetailLoading}
                refetch={refetch}
              />
              <EmailNotification
                details={merchantDetails?.notificationPreferences}
                isLoading={merchantDetailLoading}
                refetch={refetch}
              />
              <SMSNotification
                details={merchantDetails?.notificationPreferences}
                isLoading={merchantDetailLoading}
                refetch={refetch}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Notification;
