import DatePicker from "react-datepicker";
import closeIcon from "../../../assets/images/svg/close-icon.svg";
import calendar from "../../../assets/images/svg/calendar-icon.svg";
import CustomSelectDropdown from "../../../component/CustomHTMLElements/CustomSelectDropdown";
import { Dispatch, SetStateAction } from "react";
import { SearchType } from "../Tab/Disputes";
import { useSearchParams } from "react-router-dom";
import moment from "moment";

const disputeStatus = [
  { id: "Accepted", name: "Accepted" },
  { id: "Declined", name: "Declined" },
  { id: "Reopened", name: "Reopened" },
  { id: "Awaiting Feedback", name: "Awaiting Feedback" },
  // { id: "Accept Dispute", name: "Accept Dispute" },
  // { id: "In-Progress", name: "In-Progress" },
  // { id: "Pending", name: "Pending" },
];

export default function DisputeFilterModal({
  setModalType,
  setSearchState,
  searchState,
  setPreSearchState,
  preSearchState,
  setCurrentPage,
}: {
  setModalType: Dispatch<SetStateAction<boolean>>;
  setSearchState: Dispatch<SetStateAction<SearchType>>;
  setPreSearchState: Dispatch<SetStateAction<SearchType>>;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  searchState: SearchType;
  preSearchState: SearchType;
}) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams({});
  const setFilterUrlParams = () => {
    let params = {
      page: "1",
      size: "10",
    } as any;
    if (searchState.startDate) {
      params = {
        ...params,
        startDate: moment(searchState.startDate).format("YYYY-MM-DD"),
      };
    }
    if (searchState.endDate) {
      params = {
        ...params,
        endDate: moment(searchState.endDate).format("YYYY-MM-DD"),
      };
    }
    if (searchState.category) {
      params = {
        ...params,
        category: searchState.category?.toLowerCase(),
      };
    }
    if (searchState.status) {
      params = {
        ...params,
        status: searchState.status?.toLowerCase(),
      };
    }
    if (searchState.search) {
      params = {
        ...params,
        search: searchState.search,
      };
    }

    setSearchParams({ ...params });
  };
  return (
    <>
      <div className="fixed justify-center items-center flex overflow-x-hidden inset-0 z-50 outline-none focus:outline-none">
        <div className="relative my-auto mx-auto max-w-[501px]">
          <div
            className="flex mb-2 justify-between max-w-[501px]"
            onClick={() => setModalType(false)}
          >
            <div></div>
            <img src={closeIcon} alt="" className="cursor-pointer" />
          </div>
          {/*content*/}
          <div className="border-0 rounded-lg flex flex-col w-screen md:w-full bg-colors-white outline-none focus:outline-none  overflow-y-auto">
            <div className="bg-white p-6 flex flex-col justify-center">
              <p className="pb-4 border-b">Filter By:</p>

              <div className="mt-6 w-full">
                <CustomSelectDropdown
                  label="Dispute Category"
                  name="category"
                  value={searchState.category}
                  onChange={(e: any): any =>
                    setSearchState({
                      ...searchState,
                      category: e.target.value,
                    })
                  }
                >
                  <option value="">Select dispute category</option>;
                  <option value="Fraud">Fraud</option>;
                  <option value="Chargeback">Chargeback</option>;
                </CustomSelectDropdown>
              </div>
              <div className="w-full">
                <CustomSelectDropdown
                  label="Status"
                  name="disputeStatus"
                  onChange={(e): any =>
                    setSearchState({
                      ...searchState,
                      status: e.target.value,
                    })
                  }
                  value={searchState.status}
                >
                  <option value="">Select dispute status</option>;
                  {disputeStatus?.map((data: any) => {
                    const { id, name } = data;
                    return <option value={id}>{name}</option>;
                  })}
                </CustomSelectDropdown>
              </div>

              <div className="w-full">
                <p className="pb-2 text-aellaBlack lg:leading-[16px] text-[16px] font-[300]">
                  Date Range:
                </p>
                <div className="flex justify-between items-center">
                  <div className="relative w-[45%]">
                    <DatePicker
                      dateFormat="yyyy-MM-dd"
                      placeholderText={"Start date"}
                      selected={searchState.startDate}
                      maxDate={new Date()}
                      onChange={(date: Date) => {
                        setSearchState({ ...searchState, startDate: date });
                      }}
                      locale="en-GB"
                      className="h-11 w-full placeholder:text-gray-400 border border-gray-300 rounded-lg pl-2 dark:border-gray-600/30 text-aellaBlack lg:leading-[16px] text-[16px] font-[300]"
                    />
                    <div className="absolute h-11 inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                      <img className="w-3  z-20" src={calendar} alt="" />
                    </div>
                  </div>

                  <div className="relative w-[45%]">
                    <DatePicker
                      dateFormat="yyyy-MM-dd"
                      placeholderText={"End date"}
                      selected={searchState.endDate}
                      minDate={searchState.startDate}
                      maxDate={new Date()}
                      onChange={(date: Date) => {
                        setSearchState({ ...searchState, endDate: date });
                      }}
                      className="h-11 w-full placeholder:text-gray-400 border border-gray-300 rounded-lg pl-2 dark:border-gray-600/30 text-aellaBlack lg:leading-[16px] text-[16px] font-[300]"
                    />
                    <div className="absolute h-11 inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                      <img className="w-3  z-20" src={calendar} alt="" />
                    </div>
                  </div>
                </div>

                <div className="flex justify-between bg-white pt-10">
                  <div></div>
                  <button
                    type="button"
                    className="border-aellaBlue border rounded py-4 cursor-pointer flex items-center text-aellaBlue px-10"
                    onClick={() => {
                      setPreSearchState({
                        ...preSearchState,
                        startDate: null,
                        endDate: null,
                        category: "",
                        status: "",
                        search: "",
                      });
                      setSearchState({
                        ...searchState,
                        startDate: null,
                        endDate: null,
                        category: "",
                        status: "",
                        search: "",
                      });
                      setSearchParams({ page: "1", size: "10" });
                      setCurrentPage(1);
                    }}
                  >
                    Reset
                  </button>
                  <button
                    className="rounded bg-aellaBlue text-white px-10 py-3 cursor-pointer"
                    onClick={() => {
                      setCurrentPage(1);
                      setPreSearchState({ ...searchState });
                      setModalType(false);
                      setFilterUrlParams();
                    }}
                    type="button"
                  >
                    Filter
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="bg-opacity-25 fixed inset-0 z-40 bg-[#0B1E4B]"
        onClick={() => setModalType(false)}
      ></div>
    </>
  );
}
