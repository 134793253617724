import React, { Dispatch, SetStateAction } from "react";
import BlueButton from "../Button/BlueButton";

export default function NoDataState({
  icon,
  textHeader,
  buttonIcon,
  buttonText,
  firstParaText,
  secondParaText,
  buttonAction,
}: {
  icon: string;
  textHeader: string;
  firstParaText: string;
  secondParaText?: string;
  buttonIcon?: string;
  buttonText?: string;
  buttonAction?: Dispatch<SetStateAction<string>>;
}) {
  return (
    <>
      <div className=" flex flex-col justify-center items-center pt-[18px] pb-10">
        <div className="w-[89px] h-[60px]">
          <img src={icon} alt="" />
        </div>
        <h1 className="text-[16px] lg:leading-[19px] font-[400] mt-8 text-[#232323]">
          {textHeader}
        </h1>
        <p className="text-[16px] leading-[19px] font-[100]  w-[250px] md:w-[426px] pt-2 text-center">
          {firstParaText} <br></br>
          {secondParaText}
        </p>
        {buttonText && (
          <button
            className="bg-aellaBlue hover:bg-opacity-75 flex items-center py-4 px-[64px] rounded text-white mt-8 "
            onClick={() => buttonAction}
          >
            {buttonIcon && <img src={buttonIcon} alt="button icon" />}
            <span className={`${buttonIcon && "ml-2.5"}`}>{buttonText}</span>
          </button>
        )}
      </div>
    </>
  );
}

export function NoListState({
  icon,
  textHeader,
  firstParaText,
  secondParaText,
  title,
  onClick,
}: {
  icon: string;
  textHeader: string;
  firstParaText?: string;
  secondParaText?: string;
  title?: string;
  onClick?: any;
}) {
  return (
    <>
      <div className=" flex flex-col justify-center items-center py-[30px]">
        <div>
          <img src={icon} alt="" className="w-[89px] h-[60px]" />
        </div>
        <h1 className="text-[16px] lg:leading-[19px] font-[400] mt-8 text-[#232323]">
          {textHeader}
        </h1>
        <p className="text-[16px] leading-[19px] font-[100]  w-[250px] md:w-[426px] mt-4 text-center">
          {firstParaText} <br></br>
          {secondParaText}
        </p>
        {!!title && (
          <BlueButton className="mt-8" title={title} onClick={onClick} />
        )}
      </div>
    </>
  );
}
