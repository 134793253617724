export const truncateString = (textString: string, num: number) => {
  if (!textString) {
    return "N/A";
  }
  if (textString.length <= num) {
    return textString;
  }
  return textString.slice(0, num) + "...";
};

export const removerAsterisk = (textString: string) => {
  if (textString) {
    const newText = textString?.replace("*", "");
    return newText;
  }
  return textString;
};

export const currencyFormat = (amount: any) => {
  if (typeof amount === "string") {
    amount = parseFloat(amount);
  }
  if (amount === null || amount === undefined) {
    return "N/A";
  }
  if (amount % 1 === 0) {
    return new Intl.NumberFormat("en-NG", {
      style: "currency",
      currency: "NGN",
      minimumFractionDigits: 2,
    }).format(amount);
  }
  return new Intl.NumberFormat("en-NG", {
    style: "currency",
    currency: "NGN",
    minimumFractionDigits: 2,
  }).format(amount);
};
