import Drawer from "../../../component/Drawer/Drawer";
import successIcon from "../../../assets/images/svg/success-icon.svg";
import arrowUpIcon from "../../../assets/images/svg/black-arrow-up-icon.svg";
import copyButton from "../../../assets/images/svg/copy-icon.svg";
import { currencyFormat } from "../../../helpers/formatter";
import {
  attachClassNameToStatus,
  getStatusNameByNumber,
} from "../../../helpers/attachClassNameToTag";
import moment from "moment";
import { useEffect, useState } from "react";
import { isJSONOrDefault } from "../../../helpers/checkJson";

export default function InflowTransactionDrawer({
  isOpen,
  setIsOpen,
  transaction,
}: {
  isOpen: boolean;
  setIsOpen: (newState: boolean) => void;
  transaction: any;
}) {
  const [transactionDescription, setTransactionDescription] =
    useState<any>(null);
  const [copiedText, setCopiedText] = useState("");
  useEffect(() => {
    if (transaction?.description) {
      const parsedtransactionDescription = isJSONOrDefault(
        transaction?.description
      );
      setTransactionDescription(parsedtransactionDescription);
    }
  }, [transaction]);

  const handleCopy = (text: string | undefined) => {
    if (text) {
      navigator.clipboard.writeText(text);
      setCopiedText("Copied");
    }
    setTimeout(() => setCopiedText(""), 3000);
  };
  return (
    <>
      <Drawer isOpen={isOpen} setIsOpen={setIsOpen}>
        <div className="flex justify-between items-center border-b pb-[17px] overflow-auto">
          <div className="text-[#0B1E4B] font-[200]  text-[24px] leading-[29px] tracking-[0.2px]">
            Transaction Details
          </div>
          <div onClick={() => setIsOpen(false)} className="cursor-pointer">
            <svg
              width="32"
              height="33"
              viewBox="0 0 32 33"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect y="0.5" width="32" height="32" rx="16" fill="white" />
              <rect
                x="11.7305"
                y="18.6338"
                width="2.13343"
                height="2.13343"
                fill="#6D6D6D"
              />
              <rect
                x="9.59766"
                y="20.7671"
                width="2.13343"
                height="2.13343"
                fill="#6D6D6D"
              />
              <rect
                width="2.13343"
                height="2.13343"
                transform="matrix(1 0 0 -1 11.7305 14.3672)"
                fill="#6D6D6D"
              />
              <rect
                width="2.13343"
                height="2.13343"
                transform="matrix(1 0 0 -1 9.59766 12.2334)"
                fill="#6D6D6D"
              />
              <rect
                width="2.13343"
                height="2.13343"
                transform="matrix(1 0 0 -1 13.8633 16.5005)"
                fill="#6D6D6D"
              />
              <rect
                width="2.13343"
                height="2.13343"
                transform="matrix(1 0 0 -1 15.9961 18.6338)"
                fill="#6D6D6D"
              />
              <rect
                x="18.1328"
                y="12.2334"
                width="2.13343"
                height="2.13343"
                fill="#6D6D6D"
              />
              <rect
                x="20.2617"
                y="10.1001"
                width="2.13343"
                height="2.13343"
                fill="#6D6D6D"
              />
              <rect
                width="2.13343"
                height="2.13343"
                transform="matrix(1 0 0 -1 18.1328 20.7671)"
                fill="#6D6D6D"
              />
              <rect
                width="2.13343"
                height="2.13343"
                transform="matrix(1 0 0 -1 20.2617 22.9009)"
                fill="#6D6D6D"
              />
            </svg>
          </div>
        </div>

        <div className="mt-8 flex flex-col">
          <img
            src={successIcon}
            className="w-[40px] h-[40px] self-center"
            alt=" "
          />
          <h1 className="text-[16px] font-light tracking-[0.2] leading-[16px] text-[#7B7B7B] mt-[9px] self-center">
            Amount:
          </h1>
          <h1 className="text-[32px] font-normal tracking-[0.2] leading-[38px] mt-[9px] self-center">
            {currencyFormat(transaction?.amount)}
          </h1>

          <div className="flex flex-col  border rounded-[6px] border-[#EDF2FF] p-6 mt-8">
            <div className="flex justify-between items-top border-b border-[#C5D4F6]">
              <h4 className="font-light text-[16px] text[#172B4D] leading-[19px] mr-8 pb-4">
                Details
              </h4>
              <div className="h-[20px] w-[20px]">
                <img src={arrowUpIcon} alt="" />
              </div>
            </div>
            <div className="flex justify-between mt-6">
              <h4 className="font-light text-[16px] text-aellaGray leading-[19px] ">
                Transaction Status:
              </h4>
              <h6
                className={`font-light text-[14px] leading-[17px] ${attachClassNameToStatus(
                  transaction?.status
                )}`}
              >
                {getStatusNameByNumber(transaction?.status)}
              </h6>
            </div>
            <div className="flex justify-between mt-6">
              <h4 className="font-light text-[14px] text-aellaGray leading-[17px] ">
                Transaction:
              </h4>
              <h4 className="font-normal text-[14px] leading-[17px] ">
                {transaction?.transaction_type?.name}
              </h4>
            </div>
            {transactionDescription?.mode === "P2P" && (
              <div className="flex justify-between mt-6">
                <h4 className="font-light text-[14px] text-aellaGray leading-[17px] ">
                  Sender Name:
                </h4>
                <h4 className="font-normal text-[14px] leading-[17px] ">
                  {transactionDescription?.sender_name}
                </h4>
              </div>
            )}
            <div className="flex justify-between mt-6">
              <h4 className="font-light text-[14px] text-aellaGray leading-[17px] ">
                Transaction Type:
              </h4>
              <h4
                className={`font-normal text-[14px] leading-[17px]  ${
                  transaction?.type === "cr"
                    ? "text-[#219653]"
                    : transaction?.type === "dr"
                    ? "text-[#EB5757]"
                    : "text-aellaGray"
                }`}
              >
                {transaction?.type === "dr"
                  ? "Debit"
                  : transaction?.type === "cr"
                  ? "Credit"
                  : ""}
              </h4>
            </div>
            <div className="flex justify-between mt-6">
              <h4 className="font-light text-[14px] text-aellaGray leading-[17px] ">
                Reference:
              </h4>
              <h4 className="font-normal text-[14px] leading-[17px] ">
                {transaction?.transaction_reference}
              </h4>
            </div>
            <div className="flex justify-between mt-6">
              <h4 className="font-light text-[14px] text-aellaGray leading-[17px] ">
                Time stamp:
              </h4>
              <h4 className="font-normal text-[14px] leading-[17px] ">
                {moment(transaction?.created_at).format("DD MMM, YYYY | LT")}
              </h4>
            </div>
            {transactionDescription?.hasOwnProperty("mode") &&
              transactionDescription?.mode === "Wallet Transfer" && (
                <>
                  <div className="flex justify-between mt-6">
                    <h4 className="font-light text-[14px] text-aellaGray leading-[17px] ">
                      Mode:
                    </h4>
                    <h4 className="font-normal text-[14px] leading-[17px] ">
                      {transactionDescription?.mode}
                    </h4>
                  </div>
                  <div className="flex justify-between mt-6">
                    <h4 className="font-light text-[14px] text-aellaGray leading-[17px] ">
                      Source:
                    </h4>
                    <h4 className="font-normal text-[14px] leading-[17px] ">
                      {transactionDescription?.source}
                    </h4>
                  </div>
                  <div className="flex justify-between mt-6">
                    <h4 className="font-light text-[14px] text-aellaGray leading-[17px] ">
                      Destination:
                    </h4>
                    <h4 className="font-normal text-[14px] leading-[17px] ">
                      {transactionDescription?.destination}
                    </h4>
                  </div>
                </>
              )}
          </div>
          {transactionDescription?.hasOwnProperty("sender_account") && (
            <div className="flex flex-col  border rounded-[6px] border-[#EDF2FF] p-6 mt-8">
              <div className="flex justify-between items-top border-b border-[#C5D4F6]">
                <h4 className="font-light text-[16px] text[#172B4D] leading-[19px] mr-8 pb-4">
                  More Details
                </h4>
                <div className="h-[20px] w-[20px]">
                  <img src={arrowUpIcon} alt="" />
                </div>
              </div>

              <div className="flex flex-col bg-[#F5F7F9] rounded-[8px] p-4 mt-4">
                {/* <div className="flex justify-between items-center">
                  <div className="flex flex-col">
                    <h1 className="font-light text-[#737373] text-[14px] leading-[17px]">
                      Bank Name
                    </h1>
                    <h4 className="font-normal text-[16px] leading-[19px] mt-1.5">
                      {transactionDescription?.bank_name || "N/A"}
                    </h4>
                  </div> */}
                <div className="flex flex-col flex-wrap">
                  <h1 className="font-light text-[#737373] text-[14px] leading-[17px]">
                    Sender Name
                  </h1>
                  <h4 className="font-normal text-[16px] leading-[19px] mt-1.5 ">
                    {transactionDescription?.sender_name || "N/A"}
                  </h4>
                </div>
                {/* </div> */}

                <div className="flex justify-between items-center mt-4 ">
                  <div className="flex flex-col">
                    <h1 className="font-light text-[#737373] text-[14px] leading-[17px] pr-2.5">
                      Account Number
                    </h1>
                    <h4 className="font-normal text-[16px] leading-[19px] mt-1.5">
                      {transactionDescription?.sender_account || "N/A"}
                    </h4>
                  </div>

                  {transactionDescription?.sender_account && (
                    <div
                      onClick={() =>
                        handleCopy(transactionDescription?.sender_account)
                      }
                      className="border border-[#2054D2] items-end rounded-[15.75px] min-w-[55.78px] py-1 px-2 flex cursor-pointer"
                    >
                      <img src={copyButton} alt="" />

                      <div className="font-light text-aellaBlue text-[14px] leading-[17px] pl-1">
                        {copiedText ? copiedText : "Copy"}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </Drawer>
    </>
  );
}
