import { useState } from "react";

import { ModalContainer } from "../../../modal/ModalContainer";
import { EnterOtp } from "../Components/EnterOtp";
import { ConfirmPin } from "../Components/ConfirmPin";
import { Stage } from "../../../interfaces/settings";
import { PinResetSuccessful } from "../Components/PinResetSuccessful";
import { ReactComponent as CloseIcon } from "../../../assets/images/svg/close-icon.svg";

export const ResetTransactionPin = ({
  showModal,
  closeModal,
  counter,
  setCounter,
}: {
  showModal: boolean;
  closeModal: () => void;
  counter: number;
  setCounter: (state: number) => void;
}) => {
  const [stage, setStage] = useState<Stage>("otp");
  const [otp, setOtp] = useState("");

  const handleClose = () => {
    closeModal();
    setTimeout(() => {
      setStage("otp");
    }, 300);
  };

  const renderContent = () => {
    if (stage === "otp") {
      return (
        <EnterOtp
          setStage={setStage}
          stage={stage}
          showModal={showModal}
          setOtp={setOtp}
          counter={counter}
          setCounter={setCounter}
        />
      );
    }

    if (stage === "pin") {
      return <ConfirmPin setStage={setStage} otp={otp} setOtp={setOtp} />;
    }

    if (stage === "status") {
      return <PinResetSuccessful closeModal={handleClose} />;
    }
  };
  return (
    <ModalContainer showModal={showModal} closeModal={handleClose}>
      <div
        className={`w-full sm:w-[90%] md:w-[40rem] relative h-auto sm:min-h-[30vh] max-h-[90vh] z-50 flex flex-col items-center px-4 py-7 sm:p-10 rounded-[8px] bg-white fast_animation ${
          showModal
            ? "mt-0 opacity-100 visible"
            : "mt-[3rem] opacity-0 invisible"
        }`}
      >
        <button
          onClick={handleClose}
          className="absolute -top-8 -right-0 cursor-pointer"
        >
          <CloseIcon />
        </button>
        {renderContent()}
      </div>
    </ModalContainer>
  );
};
