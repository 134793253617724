import { Dispatch, SetStateAction, useEffect, useState } from "react";
import Drawer from "../../../component/Drawer/Drawer";
import editIcon from "../../../assets/images/svg/white-edit-icon.svg";
import { ReactComponent as InfoIcon } from "../../../assets/images/svg/info2-icon.svg";
import { Loader } from "../../../component/Loader/Loader.component";
import { currencyFormat } from "../../../helpers/formatter";
import { useAllMerchantServices } from "../../../hooks/useAllMerchantServices";
import { useAuth } from "../../../context/auth";

export default function TransferDrawer({
  isOpen,
  setIsOpen,
  setModalType,
  setWithdrawalAmount,
  setWithdrawalDescription,
  loading,
  withdrawalAmount,
  setFromService,
  setToService,
  setToServiceName,
  setFromServiceName,
  toService,
  fromService,
}: {
  isOpen: boolean;
  setIsOpen: (newState: boolean) => void;
  setModalType: (newState: any) => void;
  setWithdrawalAmount: Dispatch<SetStateAction<number>>;
  setWithdrawalDescription: Dispatch<SetStateAction<string>>;
  setFromService: Dispatch<SetStateAction<string>>;
  setToService: Dispatch<SetStateAction<string>>;
  setToServiceName: Dispatch<SetStateAction<string>>;
  setFromServiceName: Dispatch<SetStateAction<string>>;
  toService: string;
  fromService: string;
  loading: boolean;
  withdrawalAmount: number;
}) {
  const { details, merchantCntxtStatus } = useAuth();
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [service, setService] = useState<any>({});
  const [newMerchantServicesData, setNewMerchantServicesData] = useState([]);
  const { data: merchantServicesData } = useAllMerchantServices({
    merchantId: details?.merchant?.id,
    status: merchantCntxtStatus,
    main: true,
    services: true,
  });

  useEffect(() => {
    if (merchantServicesData) {
      const service = merchantServicesData?.find(
        (service: any) => service.category?.toLowerCase() === "main"
      );
      setService(service);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantServicesData]);

  useEffect(() => {
    if (toService) {
      const toServiceObj = merchantServicesData?.find(
        (service: any) => service.id === toService
      );
      setToServiceName(toServiceObj?.label);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toService]);

  useEffect(() => {
    if (fromService) {
      const fromServiceObj = merchantServicesData?.find(
        (service: any) => service.id === fromService
      );

      setFromServiceName(
        fromServiceObj?.category === "main"
          ? "General Wallet"
          : fromServiceObj?.label
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromService]);

  useEffect(() => {
    if (fromService && service) {
      const newService = merchantServicesData.filter((data: any) => {
        return fromService === service.id
          ? data.id !== fromService
          : data.id === service.id;
      });
      setNewMerchantServicesData(newService);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromService, merchantServicesData]);
  return (
    <>
      <Drawer isOpen={isOpen} setIsOpen={setIsOpen}>
        <div className=" w-full">
          <div className="flex justify-between items-center border-b pb-[17px]">
            <div className="text-[#0B1E4B] font-[200]  text-[24px] leading-[29px] tracking-[0.2px]">
              Transfer Money
            </div>
            <div onClick={() => setIsOpen(false)} className="cursor-pointer">
              <svg
                width="32"
                height="33"
                viewBox="0 0 32 33"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect y="0.5" width="32" height="32" rx="16" fill="white" />
                <rect
                  x="11.7305"
                  y="18.6338"
                  width="2.13343"
                  height="2.13343"
                  fill="#6D6D6D"
                />
                <rect
                  x="9.59766"
                  y="20.7671"
                  width="2.13343"
                  height="2.13343"
                  fill="#6D6D6D"
                />
                <rect
                  width="2.13343"
                  height="2.13343"
                  transform="matrix(1 0 0 -1 11.7305 14.3672)"
                  fill="#6D6D6D"
                />
                <rect
                  width="2.13343"
                  height="2.13343"
                  transform="matrix(1 0 0 -1 9.59766 12.2334)"
                  fill="#6D6D6D"
                />
                <rect
                  width="2.13343"
                  height="2.13343"
                  transform="matrix(1 0 0 -1 13.8633 16.5005)"
                  fill="#6D6D6D"
                />
                <rect
                  width="2.13343"
                  height="2.13343"
                  transform="matrix(1 0 0 -1 15.9961 18.6338)"
                  fill="#6D6D6D"
                />
                <rect
                  x="18.1328"
                  y="12.2334"
                  width="2.13343"
                  height="2.13343"
                  fill="#6D6D6D"
                />
                <rect
                  x="20.2617"
                  y="10.1001"
                  width="2.13343"
                  height="2.13343"
                  fill="#6D6D6D"
                />
                <rect
                  width="2.13343"
                  height="2.13343"
                  transform="matrix(1 0 0 -1 18.1328 20.7671)"
                  fill="#6D6D6D"
                />
                <rect
                  width="2.13343"
                  height="2.13343"
                  transform="matrix(1 0 0 -1 20.2617 22.9009)"
                  fill="#6D6D6D"
                />
              </svg>
            </div>
          </div>

          <div className="mt-8 flex flex-col w-full">
            <p className="pb-2 text-aellaGray lg:leading-[16px] text-[16px] font-[100]">
              What service do you want to transfer from?
            </p>

            <select
              className="bg-white appearance-none border px-4 placeholder:text-[#DADADA] placeholder:text-[16px] placeholder:lg:leading-[24px] placeholder:font-[100] text-aellaBlack focus:border-aellaBlue  rounded w-full h-[48px] py-2  leading-6 text-[16px] font-[300] focus:outline-none focus:bg-white"
              onChange={(e) => setFromService(e.target.value)}
            >
              <option value=" ">All Services</option>
              {merchantServicesData?.map((data: any, _id: any) => {
                const { id, label, available_balance, category } = data;
                return (
                  <option value={id} key={_id}>
                    {category === "main" ? "General Wallet" : label}{" "}
                    {currencyFormat(available_balance)}
                  </option>
                );
              })}
            </select>

            <div className="mt-[28px] ">
              <p className="pb-2 text-aellaGray lg:leading-[16px] text-[16px] font-[100]">
                What service do you want to transfer to?
              </p>
              <select
                className="bg-white appearance-none border px-4 placeholder:text-[#DADADA] placeholder:text-[16px] placeholder:lg:leading-[24px] placeholder:font-[100] text-aellaBlack focus:border-aellaBlue  rounded w-full h-[48px] py-2  leading-6 text-[16px] font-[300] focus:outline-none focus:bg-white"
                onChange={(e) => {
                  setToService(e.target.value);
                }}
                disabled={!fromService}
              >
                <option value=" ">All Services</option>
                {newMerchantServicesData?.map((data: any, _id: any) => {
                  const { id, category, available_balance, label } = data;

                  return (
                    <option value={id} key={_id}>
                      {category === "main" ? "General Wallet" : label}{" "}
                      {currencyFormat(available_balance)}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="mt-[28px] ">
              <p className="pb-2 text-aellaBlack lg:leading-[16px] text-[16px] font-[300]">
                Enter Amount
              </p>

              <div className="flex">
                <span className="inline-flex items-center px-3 text-sm text-aellaGray bg-[#E5B7A0] rounded-l-md border border-r-0 border-[#D9D9D9]">
                  &#8358;
                </span>
                <input
                  type="number"
                  placeholder="0.00"
                  name="amount"
                  className=" placeholder:text-eallaGray rounded-none rounded-r bg-white border min-w-0 w-full text-[16px] lg:leading-[20px] font-[300] border-[#D9D9D9] p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-aellaGray dark:text-white dark:focus:ring-aellaBlue dark:focus:border-aellaBlue"
                  onChange={(e) => {
                    const amount = Number(e.target.value);
                    setWithdrawalAmount(amount);
                  }}
                />
              </div>
            </div>

            <div className="mt-[28px] ">
              <p className="pb-2 text-aellaGray lg:leading-[16px] text-[16px] font-[100]">
                Short description
              </p>
              <div className="relative flex ">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                  <img className="w-5" src={editIcon} alt="" />
                </div>
                <input
                  type="text"
                  placeholder="Enter here"
                  className="placeholder:text-eallaGray  rounded bg-white border  min-w-full text-[16px] lg:leading-[20px] font-[100] border-[#D9D9D9] pl-10 py-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-aellaGray dark:text-white dark:focus:ring-aellaBlue dark:focus:border-aellaBlue"
                  onChange={(e) => setWithdrawalDescription(e.target.value)}
                />
              </div>
            </div>
            <div className="flex mt-[28px] ">
              <input
                className="border-aellaGray focus:ring-0 form-check-input h-6 w-6  md:h-4 md:w-4 border rounded-sm bg-white checked:bg-aellaBlue checked:border-aellaBlue focus:outline-none transition duration-200 md:mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                type="checkbox"
                value=""
                onChange={(e: any) => setTermsAccepted(e.target.checked)}
                id="terms"
              />
              <label
                className="font-[200] form-check-label inline-block text-aellaGray lg:leading-[24px] text-[16px]"
                htmlFor="flexCheckDefault"
              >
                I accept that once this transaction is made, it cannot be
                reversed
              </label>
            </div>
            <div className="flex mt-[34px] items-start bg-[#F3F5FC] border-[#C5D4F6] border rounded-lg p-4">
              <div className="shrink-0">
                <InfoIcon />
              </div>
              <div className="ml-2.5">
                <h1 className="text-[14px] lg:leading-[14px] font-[400] ">
                  Please Note
                </h1>
                <h1 className="text-[12px] lg:leading-[21px] font-[100] mt-2">
                  This transfer will be transferred to your specified
                  destination wallet, and funds transferred to the general
                  wallet can be withdrawn into any bank account.
                </h1>
              </div>
            </div>
            <button
              type="submit"
              className="bg-aellaBlue disabled:opacity-75 rounded text-white py-4 px-6 items-center w-full flex xxl:mt-7 justify-center cursor-pointer mt-[33px]"
              onClick={() => {
                setModalType("step5");
                setIsOpen(false);
              }}
              disabled={
                !toService ||
                !fromService ||
                !withdrawalAmount ||
                !termsAccepted
              }
            >
              Continue
              {loading && <Loader />}
            </button>
          </div>
        </div>
      </Drawer>
    </>
  );
}
