import CustomInputField from "../../../../component/CustomHTMLElements/CustomInputField";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { yupValidators } from "../../../../helpers/yupValidators";
import { useForm } from "react-hook-form";
import { ProfileSetUpFormData } from "../../../../interfaces/profileSetUp";
import { Loader } from "../../../../component/Loader/Loader.component";
import CustomSelectDropdown from "../../../../component/CustomHTMLElements/CustomSelectDropdown";
import { useAllCountries } from "../../../../hooks/useAllCountries";
import { useAllStates } from "../../../../hooks/useAllStates";
import { useEffect, useState } from "react";
import CONFIG from "../../../../helpers/config";
import { apiEndpoints } from "../../../../apis/apiEndpoints";
import { useQueryClient } from "react-query";
import { postData, putData } from "../../../../apis/apiMethods";
import { errorHandler } from "../../../../helpers/errorHandler";
import { toast } from "react-toastify";

const schema = yup.object().shape({
  country: yupValidators.genericRequired({}),
  streetNumber: yupValidators.genericRequired({}),
  state: yupValidators.genericRequired({}),
  streetName: yupValidators.genericRequired({
    message: "Please enter your address",
    min: 1,
    max: 100,
  }),
  city: yupValidators.genericRequired({
    message: "Please enter your city",
    min: 1,
    max: 30,
  }),
});

const ProfileStepThree = (merchantDetails: any) => {
  const [loading, setLoading] = useState(false);
  const { data: countryData } = useAllCountries();
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<ProfileSetUpFormData>({
    resolver: yupResolver(schema),
  });
  const queryClient = useQueryClient();
  const country = watch("country");
  const { data: statesData } = useAllStates({ country });

  useEffect(() => {
    if (countryData) {
      const country = countryData.find((data: any) => {
        return data.country_name.toLowerCase() === "nigeria";
      });
      setValue("country", country?.country_name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryData]);

  useEffect(() => {
    if (merchantDetails?.merchantDetails?.attributes?.unregisteredAddress) {
      setValue(
        "state",
        merchantDetails?.merchantDetails?.attributes?.unregisteredAddress?.state
      );

      setValue(
        "city",
        merchantDetails?.merchantDetails?.attributes?.unregisteredAddress?.area
      );
      setValue(
        "streetNumber",
        merchantDetails?.merchantDetails?.attributes?.unregisteredAddress?.house
      );
      setValue(
        "streetName",
        merchantDetails?.merchantDetails?.attributes?.unregisteredAddress
          ?.landmark
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantDetails]);

  const onSubmit = async ({
    country,
    city,
    state,
    streetName,
    streetNumber,
  }: {
    country: string;
    state: string;
    streetName: string;
    streetNumber: string;
    city: string;
  }) => {
    setLoading(true);
    const reqBody = {
      house: streetNumber,
      area: city,
      state,
      country,
      landmark: streetName,
    };
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const res: any = await putData(
        `${CONFIG.BASE_URL2}${apiEndpoints.CREATE_UNREG_ADDRESS}`,
        reqBody
      );

      queryClient.invalidateQueries("getMerchantDetails");
    } catch (error) {
      toast.error(errorHandler(error));
    }
    setLoading(false);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex  pb-[26px] items-center">
          <h3 className="text-[20px] lg:leading-[24px] font-[300] tracking-[0.03px]">
            Business Address
          </h3>
          <h1 className="ml-1 text-[12px] leading-[24px] text-[#EB5757]">*</h1>
        </div>

        <CustomSelectDropdown
          label="Country"
          errors={errors.country}
          {...register("country")}
          readOnly
        >
          <option value="">Select Country</option>
          {countryData?.map((data: any, id: number) => {
            const { country_name } = data;
            return (
              <option value={country_name} key={id}>
                {country_name}
              </option>
            );
          })}
        </CustomSelectDropdown>

        <CustomSelectDropdown
          label="State"
          errors={errors.state}
          {...register("state")}
          readOnly={
            !country ||
            merchantDetails?.merchantDetails?.attributes?.unregisteredAddress
              ?.state
          }
        >
          <option value="">Select State</option>
          {statesData?.map((data: any, id: number) => {
            return (
              <option value={data} key={id}>
                {data}
              </option>
            );
          })}
        </CustomSelectDropdown>

        <CustomInputField
          type="text"
          maxLength={128}
          label="City"
          errors={errors.city}
          {...register("city")}
          readOnly={
            merchantDetails?.merchantDetails?.attributes?.unregisteredAddress
              ?.area
          }
        />
        <div className="flex flex-col lg:flex-row">
          <div className=" w-full lg:pr-6">
            <CustomInputField
              type="text"
              checkNegative
              maxLength={10}
              label="Office number"
              errors={errors.streetNumber}
              {...register("streetNumber")}
              readOnly={
                merchantDetails?.merchantDetails?.attributes
                  ?.unregisteredAddress?.house
              }
            />
          </div>
          <div className="w-full">
            <CustomInputField
              type="text"
              maxLength={128}
              label="Street Name"
              errors={errors.streetName}
              {...register("streetName")}
              readOnly={
                merchantDetails?.merchantDetails?.attributes
                  ?.unregisteredAddress?.landmark
              }
            />
          </div>
        </div>
        <div className="flex justify-between">
          <div></div>
          <div></div>
          <div></div>

          {!merchantDetails?.merchantDetails?.attributes?.hasOwnProperty(
            "unregisteredAddress"
          ) && (
            <button
              type="submit"
              className="bg-aellaBlue disabled:opacity-75 rounded text-white p-[15.5px] items-center flex xxl:mt-7 mb-28 lg:mb-0 justify-center cursor-pointer"
              disabled={loading}
            >
              Save and Continue
              {loading && <Loader />}
            </button>
          )}
        </div>
      </form>
    </>
  );
};
export default ProfileStepThree;
