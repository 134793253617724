import { FC, useEffect, useRef } from "react";
import moment from "moment";
import { toast } from "react-toastify";

import { ReactComponent as SuccessIcon } from "../../assets/images/svg/successful-transfer-icon.svg";
import { ReactComponent as CloseIcon } from "../../assets/images/svg/white-close-modal.svg";
import { ReactComponent as ChevronIcon } from "../../assets/images/svg/chevron-up.svg";
import { ModalContainer } from "../../modal/ModalContainer";
import { LOCAL_STORAGE_KEYS } from "../../helpers/localStorageKeys";
import { useMerchantDetails } from "../../hooks/useMerchantDetails";
import { getFromLocalStorage } from "../../helpers/localStorage";
import { formatMoney } from "../../helpers/formatMoney";
import { handleScrollToTop } from "../../helpers/scrollToTop";

type stateType = {
  type?: string;
  showModal: boolean;
  setShowModal: (newState: boolean) => void;
  selectedTransfer: any;
  setSelectedTransfer: (newState: Object) => void;
  id?: string;
  receiverDetails?: any;
  bank?: any;
  transactionReference?: string;
  resetMyForm?: any;
  source?: any;
  accountName;
};

type listType = {
  name: string;
  value: any;
  valueClassName?: string;
  onClick?: any;
  className?: string;
};

const TransferDetailsModal: FC<stateType> = ({
  type,
  showModal,
  setShowModal,
  selectedTransfer,
  setSelectedTransfer,
  id,
  receiverDetails,
  bank,
  transactionReference,
  resetMyForm,
  source,
  accountName,
}) => {
  const ListItem: FC<listType> = ({
    name,
    value,
    valueClassName,
    onClick,
    className,
  }) => {
    return (
      <div
        className={`flex text-[#232323] text-base justify-between px-4 py-3 rounded-[5px] capitalize ${className}`}
      >
        <p className="min-w-[50%]">{name}</p>
        <p
          onKeyDown={() => {}}
          onClick={onClick}
          className={`font-normal max-w-[45%] ${valueClassName}`}
        >
          {value}
        </p>
      </div>
    );
  };

  const modalRef = useRef(null);
  const userId = getFromLocalStorage(LOCAL_STORAGE_KEYS.USERID);
  const { data: merchant } = useMerchantDetails({ userId });

  const handleCopy = (text: string) => {
    if (text !== "-") {
      navigator.clipboard.writeText(text);
    }
    toast.success("Copied successfully");
  };

  useEffect(() => {
    handleScrollToTop(modalRef);
  }, [showModal]);

  return (
    <ModalContainer
      showModal={showModal}
      closeModal={() => {
        setShowModal(false);
        resetMyForm();
        setTimeout(() => {
          setSelectedTransfer({});
        }, 700);
      }}
    >
      <div
        ref={modalRef}
        className={`w-full lg:w-[32vw] absolute top-0 h-screen z-50 text-[#232323] flex flex-col rounded-[8px] bg-white py-10 px-4 lg:px-8 overflow-y-auto animation ${
          showModal ? "right-0" : "-right-[32vw]"
        } `}
      >
        <div className="flex justify-between items-center">
          <p className="text-xl text-aellaBlack">Transfer Details</p>

          <CloseIcon
            onClick={() => {
              setShowModal(false);
              resetMyForm();
              setTimeout(() => {
                setSelectedTransfer({});
              }, 700);
            }}
            className="w-10 h-10 shrink-0 cursor-pointer"
          />
        </div>
        <hr className="mt-4 bg-[#EDEFF5]" />

        <div className="flex flex-col items-center mt-8">
          <SuccessIcon />
          <p className="text-[#7B7B7B] font-light mt-2">Amount:</p>
          <p className="mt-2 text-3xl text-[#232323]">
            ₦{formatMoney(selectedTransfer?.data?.amount)}
          </p>

          <div className="bg-[#F3F5FC] w-full py-6 px-2 lg:px-4 mt-8 flex items-center gap-x-2 md:gap-x-4 border border-[#C5D4F6]">
            <div className="rounded-[50%] shrink-0 h-14 w-14 flex justify-center items-center bg-[#D8A28F] text-aellaBlue text-2xl uppercase">
              {(
                accountName ||
                (receiverDetails?.first_name || "") +
                  " " +
                  (receiverDetails?.last_name || "") ||
                bank?.account_name
              )
                ?.split(" ")
                ?.map((name: any) => name[0])
                ?.join("")
                .substring(0, 2)}
            </div>
            <div className="flex flex-col">
              <p className="text-[1.1rem] md:text-[1.3rem] text-[#232323] truncate capitalize">
                {(
                  accountName ||
                  (receiverDetails?.first_name || "") +
                    " " +
                    (receiverDetails?.last_name || "") ||
                  bank?.account_name
                )?.toLowerCase()}
              </p>
              <div className="flex items-center gap-x-1 text-[#7B7B7B] font-light text-sm">
                <p className="text-[0.75rem] md:text-sm">
                  {type === "Bank"
                    ? selectedTransfer?.data?.account_number ||
                      bank?.account_number
                    : type === "Wallet"
                    ? receiverDetails?.phone_number
                    : selectedTransfer?.account?.number ||
                      selectedTransfer?.data?.account_number ||
                      bank?.account_number ||
                      receiverDetails?.phone_number}
                </p>

                {type === "Bank" &&
                  (selectedTransfer?.data?.bank?.name || bank?.bank_name) && (
                    <p className="text-[0.75rem] md:text-sm capitalize">
                      (
                      {(
                        selectedTransfer?.data?.bank?.name || bank?.bank_name
                      )?.toLowerCase()}
                      )
                    </p>
                  )}
              </div>
            </div>
          </div>

          <div className="w-full py-4 mt-8 border rounded-[10px] font-light text-base">
            <div className="flex items-center justify-between px-4">
              <p className="text-[#172B4D]">More Details</p>
              <div className="h-5 w-5">
                <ChevronIcon />
              </div>
            </div>
            <hr className="my-4" />
            <ListItem
              name="status:"
              value={selectedTransfer?.status}
              valueClassName={`font-light px-2 py-1 rounded-[4px] ${
                selectedTransfer?.status === "Success"
                  ? "text-[#14804A] bg-[#E1FCEF]"
                  : selectedTransfer?.status === "Pending"
                  ? "text-[#AA5B00] bg-[#FCF2E6]"
                  : "text-[#D1293D] bg-[#FFEDEF]"
              } `}
            />
            <ListItem
              name="source:"
              value={source?.toLowerCase()}
              valueClassName="text-right"
            />

            <ListItem
              name="Destination Account:"
              value={
                selectedTransfer?.data?.account_number || receiverDetails?.email
              }
              valueClassName="lowercase text-right"
            />
            {/* ₦ */}
            {selectedTransfer?.data?.fee === undefined ||
            selectedTransfer?.data?.fee === "0" ? (
              <ListItem name="Fee:" value="NA" />
            ) : (
              <ListItem
                name="Fee:"
                value={`N${selectedTransfer?.data?.fee} `}
              />
            )}
            <ListItem
              name="Transfer Reference:"
              value={
                selectedTransfer?.data?.reference ||
                selectedTransfer?.data?.transaction_reference ||
                transactionReference ||
                "-"
              }
              valueClassName="text-right cursor-pointer normal-case"
              onClick={() => {
                handleCopy(
                  selectedTransfer?.data?.reference ||
                    selectedTransfer?.data?.transaction_reference
                );
              }}
            />
            <ListItem
              name="Time Stamp:"
              value={moment(selectedTransfer?.data?.transaction_date).format(
                "DD MMM YYYY , LT"
              )}
            />
            <ListItem
              name="Narration:"
              value={selectedTransfer?.data?.narration || "-"}
              valueClassName="break-words text-right"
            />
            <hr className="mx-4 mt-2 border" />
            <div className="px-4 mt-5">
              <p className="capitalize">transfer made by:</p>
              <div className="bg-[#F5F7F9] py-4 px-6 flex justify-between gap-x-5 mt-4 rounded-[8px]">
                <div className="flex flex-col w-[60%]">
                  <p className="text-[#737373]">Name</p>
                  <p className="text-sm md:text-base text-[#011C34] font-medium capitalize">
                    {merchant?.first_name?.toLowerCase() +
                      " " +
                      merchant?.last_name?.toLowerCase()}
                  </p>
                </div>
                <div className="flex flex-col w-[30%] text-left">
                  <p className="text-[#737373]">Date</p>
                  <p className="text-sm md:text-base text-[#011C34] font-medium">
                    {moment(selectedTransfer?.transaction_date).format(
                      "DD MMM YYYY"
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="py-4 px-6 bg-[#f5f5f5] mt-8 flex items-center gap-x-3 align-self-center rounded-[4px] cursor-pointer">
            <div className="h-4 w-4">
              <DownloadIcon />
            </div>
            <p
              onClick={handleReceiptDownload}
              className="text-base text-[#5B5B5B] font-light"
            >
              Download Receipt
            </p>
          </div> */}
        </div>
      </div>
    </ModalContainer>
  );
};

export default TransferDetailsModal;
