import { format } from "date-fns";

import { ReactComponent as CloseIcon } from "../../../assets/images/svg/close-icon.svg";
import { ReactComponent as SuccessIcon } from "../../../assets/images/svg/successful-transfer-icon.svg";
import arrowUpIcon from "../../../assets/images/svg/black-arrow-up-icon.svg";
import { ReactComponent as DownloadIcon } from "../../../assets/images/svg/download-icon.svg";
import { formatMoney } from "../../../helpers/formatMoney";

const ListItem = ({
  name,
  value,
}: {
  name: string;
  value: string | number;
}) => {
  return (
    <div className="flex items-center justify-between full font-light">
      <p className="text-aellaGray">{name}</p>
      <p
        className={`p-1 rounded-[4px] ${
          value === "Running Loan"
            ? "text-[#14804A] bg-[#E1FCEF] text-[12px]"
            : "text-[#232323] text-[14px]"
        }`}
      >
        {value}
      </p>
    </div>
  );
};

export const RepaymentTransactionDetails = ({ close }) => {
  return (
    <div>
      <div className="flex justify-between items-center pb-3 border-b border-b-[#EDEFF5]">
        <p className="text-xl text-aellaBlack">Repayment Transaction Details</p>

        <CloseIcon
          onClick={close}
          className="h-10 w-10 shrink-0 cursor-pointer"
        />
      </div>

      <div className="mt-8 flex flex-col items-center">
        <SuccessIcon />
        <p className="mt-2 text-aellaGray font-light">Amount Paid</p>
        <p className="mt-2 text-[#232323] text-[32px]">₦292,892</p>
        <div className="w-full mt-8 flex items-center p-6 gap-4 bg-[#F3F5FC] border border-[#C5D4F6]">
          <div className="rounded-full inline-flex bg-[#E5B7A0] w-[64px] h-[64px] items-center justify-center cursor-pointer text-aellaBlue text-[24px]">
            DA
          </div>
          <div className="flex flex-col">
            <p className="text-[#232323] text-[24px]">Kareen Mula</p>
            <p className="text-[18px] text-aellaGray font-[200]">
              karren@gmail.com
            </p>
          </div>
        </div>

        <div className="flex flex-col border rounded-[6px] border-[#EDF2FF] mt-8 w-full">
          <div className="flex justify-between px-4 py-4 items-top border-b border-[#EDF2FF]">
            <p className="font-light text-[18px] text[#172B4D] leading-[19px]">
              Loan Details
            </p>
            <div className="h-[20px] w-[20px] rotate-180">
              <img src={arrowUpIcon} alt="" />
            </div>
          </div>

          <div className="flex flex-col gap-y-4 py-6 px-4">
            <ListItem name="Loan Status" value="Running Loan" />
            <ListItem
              name="Amount Borrowed"
              value={`₦${formatMoney("123456")}`}
            />
            <ListItem name="Purpose" value="Education Loan" />
            <ListItem name="Interest Rate" value="2%" />
            <ListItem name="Tenor" value="2 months" />
            <ListItem
              name="Repayment Date"
              value={format(new Date(), "dd MMM, yyyy")}
            />
            <ListItem
              name="Repayment Amount"
              value={`₦${formatMoney("1234560")}`}
            />
            <ListItem
              name="Total Repayment"
              value={`₦${formatMoney("1234560")}`}
            />
            <ListItem
              name="To repay this month"
              value={`₦${formatMoney("1234560")}`}
            />
            <ListItem name="Still owing" value={`₦${formatMoney("1234560")}`} />
          </div>

        </div>
          {/* <div className="py-4 px-6 bg-[#f5f5f5] mt-8 self-center flex items-center gap-x-3 align-self-center rounded-[4px] cursor-pointer">
            <div className="h-4 w-4">
              <DownloadIcon />
            </div>
            <p className="text-base text-[#5B5B5B] font-light">
              Download Receipt
            </p>
          </div> */}
      </div>
    </div>
  );
};
