import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useMutation, useQueries, useQueryClient } from "react-query";
import { format } from "date-fns";

import { EmptyEmployees, EmptyList } from "../EmptyEmployees";
import { InitiateUpload } from "../InitiateUpload";
import { EmployeesPageState } from "../../../interfaces/employee-loans";
import { ReactComponent as BackIcon } from "../../../assets/images/svg/back-icon.svg";
import { ReactComponent as SearchIcon } from "../../../assets/images/svg/search-icon.svg";
import { ReactComponent as ChevronDown } from "../../../assets/images/svg/blue-chevron-down.svg";
import { ReactComponent as FilterIcon } from "../../../assets/images/svg/filter-transaction-icon.svg";
import { useLoansContext } from "../EmployeeLoans";
import { PreviewUploadedData } from "../PreviewUploadedData";
import { FilterEmployees } from "../Modal/FilterEmployeesModal";
import { ROUTES } from "../../../helpers/routes";
import {
  deleteEmployeesFn,
  getAllEmployeesFn,
  uploadEmployeesFileFn,
} from "../queries";
import Pagination from "../../../component/Pagination/Pagination.component";
import { errorHandler } from "../../../helpers/errorHandler";
import {
  attachClassToId,
  attachNameToId,
} from "../../../helpers/attachClassNameToTag";
import { formatDate } from "../../../helpers/others";
import { CreateEmployeeModal } from "../Modal/CreateEmployeeModal";
import { truncateString } from "../../../helpers/formatter";
import { DeletionConfirmationModal } from "../Modal/DeletionConfirmationModal";
import { ErrorMessage } from "../ErrorMessage";
import { SecondaryLoader } from "../../../component/Loader/SecondaryLoader";
import { EmployeeAction } from "../Modal/Action";
import { getFromLocalStorage } from "../../../helpers/localStorage";
import { LOCAL_STORAGE_KEYS } from "../../../helpers/localStorageKeys";

const Employees = () => {
  const queryClient = useQueryClient();
  const location = useLocation();
  const role = getFromLocalStorage(LOCAL_STORAGE_KEYS.ROLE);
  const canEdit =
    role === "Super Admin" || role === "Admin" || role === "Manager";

  const invalidateQueries = () =>
    queryClient.invalidateQueries("all employees");
  const perPage = 50;
  const navigate = useNavigate();
  const { addEmployee, toggleHeader, updateContext, loanContextState } =
    useLoansContext();
  const [filterObj, setFilterObj] = useState(null);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [state, setState] = useState<EmployeesPageState>({
    currentPage: 1,
    stage: "initial",
    bulkActionModal: false,
    searchTerm: "",
    filterModal: false,
    searchParams: "",
    createEmployeeModal: false,
    category: "",
    searchCategory: "",
    deleteModal: false,
  });
  const [searchParams] = useSearchParams();
  const params: any = Object.fromEntries([...searchParams]);

  const updateState = (payload: Partial<EmployeesPageState>) => {
    setState((prev) => ({ ...prev, ...payload }));
  };

  useEffect(() => {
    updateContext({
      showHeader: true,
    });
  }, [location]);

  const [employeesData] = useQueries([
    {
      queryKey: [
        "all employees",
        state.currentPage,
        state.searchParams,
        filterObj,
        state.searchCategory,
      ],
      queryFn: () =>
        getAllEmployeesFn({
          page: state.currentPage,
          perPage,
          statusId: filterObj?.status,
          key: state.searchCategory,
          value: state.searchParams,
          startDate: filterObj?.start
            ? format(new Date(filterObj?.start), "yyyy-MM-dd")
            : null,
          endDate: filterObj?.end
            ? format(new Date(filterObj?.end), "yyyy-MM-dd")
            : null,
        }),
    },
  ]);

  const { data: employeesList, meta } = employeesData.data || {};

  const handleCheckboxClick = (itemId: any) => {
    if (selectedEmployees.includes(itemId)) {
      setSelectedEmployees(
        selectedEmployees.filter((id: any) => id !== itemId)
      );
    } else {
      setSelectedEmployees([...selectedEmployees, itemId]);
    }
  };
  const handleSelectAll = () => {
    if (employeesData?.isFetching) return;
    if (selectedEmployees?.length === employeesList?.length) {
      setSelectedEmployees([]);
    } else setSelectedEmployees(employeesList?.map((i: any) => i?.id));
  };

  const {
    mutateAsync,
    isLoading: isUploading,
    data,
  } = useMutation({
    mutationFn: uploadEmployeesFileFn,
    onSuccess: () => {
      updateState({
        stage: "initial",
      });
      updateContext({
        addEmployee: false,
        showHeader: true,
      });
      toast.success("Employees data uploaded successfully");
      employeesData?.refetch();
    },
    onError: (error) => {
      toast.error(errorHandler(error));
    },
  });

  const handleUpload = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);

    try {
      await mutateAsync({
        payload: formData,
      });
    } catch (error) {}
  };

  const handleNavigation = (x) => {
    navigate(ROUTES.EMPLOYEE_DETAILS, {
      state: { data: x },
    });
    updateContext({
      showHeader: false,
    });
  };

  const { mutateAsync: deleteEmployeesAsync, isLoading: isDeleting } =
    useMutation({
      mutationFn: deleteEmployeesFn,
      onSuccess: (data) => {
        toast.success(data?.message);
        invalidateQueries();
        updateState({
          deleteModal: false,
        });
        setSelectedEmployees([]);
      },
      onError: (error) => {
        toast.error(errorHandler(error));
      },
    });

  const handleDeletion = async () => {
    try {
      await deleteEmployeesAsync({
        payload: { employees_id: selectedEmployees },
      });
    } catch (error) {}
  };

  const renderBody = () => {
    if (
      state.stage === "initial" &&
      employeesList?.length === 0 &&
      !filterObj &&
      !state?.searchCategory &&
      !state?.searchParams &&
      !addEmployee
    ) {
      return (
        <div className="flex h-full justify-center items-center">
          <EmptyEmployees updateState={updateState} />
        </div>
      );
    }

    if ((state.stage == "initiate" && !isUploading) || addEmployee) {
      return (
        <InitiateUpload updateState={updateState} handleUpload={handleUpload} />
      );
    }

    if (
      (addEmployee && state.stage === "preview") ||
      state.stage === "preview"
    ) {
      return (
        <PreviewUploadedData
          updateParentState={updateState}
          isLoading={isUploading}
          dataSet={data?.data}
        />
      );
    } else return null;
  };

  const renderList = () => {
    if (state.stage !== "initial" || addEmployee) return;
    if (employeesData?.isFetching || employeesData?.isLoading) {
      return <SecondaryLoader count={10} />;
    }

    if (employeesData?.isError) {
      return <ErrorMessage onClick={() => employeesData?.refetch()} />;
    }

    if (employeesList?.length === 0) {
      return <EmptyList message="No employee found with this filter" />;
    }

    return employeesList?.map((each) => {
      return (
        <div
          key={each?.id}
          className="flex justify-between w-[280%] sm:w-[180%] md:w-full hide_scrollbar bg-white -px-5 items-center border-b border-b-[#EDEFF5] h-12 gap-x-1 px-5 text-sm text-[#7B7B7B] font-light cursor-pointer"
        >
          <input
            type="checkbox"
            checked={selectedEmployees?.includes(each?.id)}
            onChange={(e) => {
              handleCheckboxClick(each?.id);
            }}
            className="w-4 h-4 border-red mr-4"
          />
          <p
            onClick={() => handleNavigation(each)}
            className="w-[15%] mr-4 md:mr-0"
          >
            {truncateString(each?.staff_id, 15)}
          </p>
          <p
            onClick={() => handleNavigation(each)}
            className="w-[20%] capitalize"
          >
            {each?.full_name}
          </p>
          <p
            onClick={() => handleNavigation(each)}
            className="w-[10%] mr-4 md:mr-0"
          >
            {formatDate(each?.date_of_birth)}
          </p>
          <p onClick={() => handleNavigation(each)} className="w-[15%]">
            {each?.phone_number}
          </p>
          <p onClick={() => handleNavigation(each)} className="w-[10%]">
            {each?.payday}
          </p>
          <p
            onClick={() => handleNavigation(each)}
            className={`w-[8%] text-center ${attachClassToId(each?.status_id)}`}
          >
            {attachNameToId(each?.status_id)}
          </p>
          <p
            onClick={() => handleNavigation(each)}
            className="w-[15%] text-right"
          >
            {formatDate(each?.date_of_employment)}
          </p>
        </div>
      );
    });
  };

  return (
    <div className="bg-aellaDimGray w-full h-full flex-1 hide_scrollbar">
      {state.bulkActionModal && (
        <div
          className="fixed top-0 left-0 w-full h-screen bg-[#0B1E4B]/40 z-20"
          onClick={() =>
            updateState({
              bulkActionModal: false,
            })
          }
        />
      )}
      {(state.stage === "initiate" ||
        state.stage === "preview" ||
        addEmployee) && (
        <button
          onClick={() => {
            updateState({
              stage: state.stage === "preview" ? "initiate" : "initial",
            });
            toggleHeader(true);
            updateContext({
              addEmployee: false,
            });
          }}
          className="h-20 w-full bg-white px-4 lg:px-10 flex items-center gap-x-4 text-[18px] text-[#011C34] font-light border-t cursor-pointer"
        >
          <BackIcon />
          Back
        </button>
      )}
      <div className="px-4 lg:px-10 py-[32px] h-full hide_scrollbar">
        {renderBody()}
        {employeesData?.isLoading ? (
          <SecondaryLoader baseColor="#fff" count={15} />
        ) : (
          !renderBody() && (
            <div className="bg-white w-full h-full py-[13px]">
              <div className="h-auto w-full flex-col gap-y-4 md:flex-row flex-wrap flex items-stretch md:items-center justify-between gap-x-16 xl:gap-x-20 px-4">
                <div
                  className={`flex items-center w-auto gap-x-4 relative ${
                    !canEdit && "invisible"
                  } ${
                    selectedEmployees?.length ? "opacity-100" : "opacity-50"
                  }`}
                >
                  <p className="text-[#232323] font-light text-sm text-center min-w-[80px]">
                    {selectedEmployees?.length} Selected
                  </p>
                  <div className="bg-[#BFC7D9] w-[1px] h-10" />
                  <button
                    disabled={!selectedEmployees?.length}
                    onClick={() => {
                      updateState({
                        bulkActionModal: true,
                      });
                    }}
                    type="button"
                    className="text-aellaBlue inline-flex items-center gap-x-[5px] border border-aellaBlue text-sm font-light h-10 px-[10px] rounded-[4px]"
                  >
                    Bulk Action
                    <ChevronDown />
                  </button>

                  <EmployeeAction
                    showAction={state.bulkActionModal}
                    onDelete={() =>
                      updateState({
                        deleteModal: true,
                        bulkActionModal: false,
                      })
                    }
                    isDeleting={isDeleting}
                  />
                </div>

                <div className="flex w-full flex-col md:w-auto md:flex-row md:items-center justify-between flex-wrap gap-x-4 gap-y-4 flex-1">
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      if (!state.category) {
                        updateState({
                          searchCategory: "",
                          searchParams: "",
                        });

                        // return toast.error("Please select a category");
                        return;
                      }

                      if (!state.searchTerm) {
                        updateState({
                          searchCategory: "",
                          searchParams: "",
                        });
                        // return toast.error("Please enter search term");
                        return;
                      }

                      updateState({
                        searchParams: state.searchTerm,
                        searchCategory: state.category,
                        currentPage: 1,
                      });
                      setFilterObj(null);
                    }}
                    className={`md:flex-auto md:w-[320px] flex justify-between flex-col gap-y-3 items-stretch sm:flex-row sm:items-center gap-x-3 px-3 py-3 md:py-0 md:h-11 divide-y sm:divide-y-0 h-auto rounded-[4px] border border-[#EDEFF5]`}
                  >
                    <div className="flex flex-1 items-center gap-x-2">
                      <SearchIcon />
                      <input
                        value={state.searchTerm}
                        onChange={(e: any) =>
                          updateState({
                            searchTerm: e.target.value,
                          })
                        }
                        className="bg-inherit w-full flex-1 lg:w-[250px] h-full placeholder:text-[#7b7b7b] text-aellaBlack text-sm placeholder:text-[14px] font-light placeholder:font-light outline-none"
                        placeholder="Search by phone number, email, staff ID, full name, account number"
                      />
                    </div>
                    <select
                      value={state.category}
                      className="w-full sm:w-[10rem] md:w-[8rem] pt-3 pb-1 border-t sm:border-t-0 sm:py-0 sm:h-[95%] text-sm bg-transparent appearance-none sm:border-l border-l-[#EDEFF5] outline-none font-[300] text-black/60 pl-2"
                      onChange={(e) =>
                        updateState({
                          category: e.target.value,
                        })
                      }
                    >
                      <option value="">Select Category</option>
                      <option value="phone_number">Phone number</option>
                      <option value="email_address">Email Address</option>
                      <option value="staff_id">Staff ID</option>
                      <option value="full_name">Full name</option>
                      <option value="account_number">Account number</option>
                    </select>
                  </form>
                  <div className="flex flex-wrap items-center gap-x-5 gap-y-4">
                    <button
                      type="button"
                      onClick={() =>
                        updateState({
                          filterModal: true,
                        })
                      }
                      className={`flex  items-center justify-center h-10 px-4 text-[#5b5b5b] text-sm font-light bg-[#F5F5F5] border border-[#EDEFF5] rounded-[4px] cursor-pointer gap-x-2`}
                    >
                      <FilterIcon />
                      Filter By
                    </button>
                  </div>
                </div>
              </div>

              <div className="overflow-x-scroll hide_scrollbar">
                <div className="flex justify-between items-center w-[280%] sm:w-[180%] md:w-full hide_scrollbar bg-[#F8FAFC] border-b h-10 gap-x-1 px-5 mt-10 text-sm text-[#000810]">
                  <input
                    type="checkbox"
                    checked={
                      !employeesData?.isFetching &&
                      employeesList?.length >= 1 &&
                      employeesList?.length === selectedEmployees?.length
                    }
                    onChange={handleSelectAll}
                    className="w-4 h-4 border-red mr-4"
                  />
                  <p className="w-[15%] mr-4 md:mr-0">Staff ID</p>
                  <p className="w-[20%]">Full Name</p>
                  <p className="w-[10%] mr-4 md:mr-0">Date Of Birth</p>
                  <p className="w-[15%]">Phone Number</p>
                  <p className="w-[10%]">Payday</p>
                  <p className="w-[8%]">Status</p>
                  <p className="w-[15%] text-right">Employment Date</p>
                </div>

                {renderList()}
              </div>

              {employeesList?.length > 0 && !employeesData?.isError ? (
                <Pagination
                  postsPerPage={perPage}
                  totalPosts={meta?.total}
                  currentPage={state.currentPage}
                  setCurrentPage={(e) =>
                    updateState({
                      currentPage: e,
                    })
                  }
                  totalPages={Math.ceil(meta?.total / perPage) || 1}
                />
              ) : null}
            </div>
          )
        )}
      </div>

      <FilterEmployees
        showModal={state.filterModal}
        closeModal={() =>
          updateState({
            filterModal: false,
          })
        }
        setFilterObj={setFilterObj}
        updateState={updateState}
      />

      <CreateEmployeeModal
        showModal={loanContextState.createEmployeeModal}
        closeModal={() =>
          updateContext({
            createEmployeeModal: false,
          })
        }
      />

      <DeletionConfirmationModal
        showModal={state?.deleteModal}
        closeModal={() => {
          updateState({ deleteModal: false });
        }}
        handleDeletion={handleDeletion}
        isDeleting={isDeleting}
      />
    </div>
  );
};

export default Employees;
