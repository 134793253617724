import HeaderBackNavigation from "../../component/Header/HeaderBackNavigation.component";
import { useLocation } from "react-router-dom";
import Unregistered from "./ProfileCategory/Unregistered";
import Registered from "./ProfileCategory/Registered";

const ProfileSetUp = () => {
  const { state } = useLocation();
  const { merchantDetails } = Object(state) as {
    merchantDetails?: any;
  };
  return (
    <div className="bg-white border-t border-[#F1F1F1]">
      <HeaderBackNavigation />
      <div className="px-10 bg-aellaDimGray h-screen overflow-y-auto pt-[32px]">
        {merchantDetails?.merchant?.business_type === "Unregistered" && (
          <Unregistered />
        )}

        {merchantDetails?.merchant?.business_type === "Registered" && (
          <Registered />
        )}
      </div>
    </div>
  );
};
export default ProfileSetUp;
