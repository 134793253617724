import { format } from "date-fns";

import { formatMoney } from "../../../helpers/formatMoney";
import { EmptyList } from "../EmptyEmployees";
import {
  EmployerRepaymentSchedule,
} from "../../../interfaces/employee-loans";
import { truncateString } from "../../../helpers/formatter";

export const EmployeeRepaymentHistory = ({
  setShowDetails,
  loans,
  setSelected,
  loanStatus,
}: {
  setShowDetails: (state: boolean) => void;
  loans: EmployerRepaymentSchedule[];
  setSelected: (state: any) => void;
  loanStatus: string;
}) => {

  const statuses = ["Running", "Completed", "Defaulted", "NPL"];

  const canHaveRepayment = statuses?.includes(loanStatus);

  if (loanStatus === "Declined")
    return (
      <EmptyList message="Looks like this loan wasn't approved. Which means that repayment cannot happen on this loan" />
    );


  else if (!canHaveRepayment)
    return <EmptyList message="There is no repayment on this loan" />;

  return (
    <div className="overflow-x-scroll hide_scrollbar">
      <div className="flex w-[200%] sm:w-[150%] md:w-full overflow-x-auto hide_scrollbar justify-between items-center bg-[#F8FAFC] border-b h-10 gap-x-1 px-5 text-sm text-[#000810] capitalize">
        <p className="w-[20%]">amount</p>
        <p className="w-[25%] mr-4 md:mr-0">reference</p>
        <p className="w-[10%] mr-4 md:mr-0">source</p>
        <p className="w-[25%] text-right">date & time</p>
      </div>
      {loans?.length === 0 ? (
        <EmptyList message="No repayment has been done yet" />
      ) : (
        loans?.map((each) => (
          <div
            key={each?.id}
            onClick={() => {
              setShowDetails(true);
              setSelected(each);
            }}
            className="flex w-[200%] sm:w-[150%] md:w-full overflow-x-auto hide_scrollbar justify-between items-center bg-white border-b h-12 gap-x-1 px-5 text-sm text-[#7B7B7B] font-light cursor-pointer"
          >
            <p className="w-[20%]">₦{formatMoney(each?.amount || 0)}</p>
            <p className="w-[25%] mr-4 md:mr-0 truncate">
              {truncateString(each?.reference, 25) ?? "N/A"}
            </p>
            <p className="w-[10%] mr-4 md:mr-0 capitalize">
              {each?.payment_channel || "N/A"}
            </p>
            <p className="w-[25%] text-right">
              {each?.created_at
                ? format(new Date(each?.created_at), "dd MMM, yyyy | p")
                : "N/A"}
            </p>
          </div>
        ))
      )}
    </div>
  );
};
