import { useState } from "react";
import { Player } from "@lottiefiles/react-lottie-player";

import { ModalContainer } from "../../../modal/ModalContainer";
import { ReactComponent as FailedTransferIcon } from "../../../assets/images/svg/failed-transfer.svg";
import { ReactComponent as CloseIcon } from "../../../assets/images/svg/white-close-modal.svg";
import BlueButton from "../../../component/Button/BlueButton";
import SuccessAnimation from "../../../assets/files/Stars.json";


const PasswordChangeStatus = ({
  showModal,
  setShowModal,
}: {
  showModal: boolean;
  setShowModal: (newState: boolean) => void;
}) => {
  const [status, setStatus] = useState("Success");
  const FailedStatus = () => {
    return (
      <>
        <div className="flex justify-between items-center">
          <div className="absolute -top-8 -right-0 cursor-pointer">
            <CloseIcon />
          </div>
        </div>
        <div className="flex flex-col justify-between items-center">
          <FailedTransferIcon />
          <p className="text-aellaBlack text-[24px] mt-6">
            Oops.. Something went wrong
          </p>
          <p className="text-[#5b5b5b] font-light text-center mt-3">
            Sorry, we couldn't proceed with your password reset, please try
            again later.
          </p>
        </div>
      </>
    );
  };

  const SuccessStatus = () => {
    return (
      <>
        <div className="flex justify-between items-center">
          <div className="absolute -top-8 -right-0 cursor-pointer">
            <CloseIcon />
          </div>
        </div>
        <div className="flex flex-col justify-between items-center">
        <div className="flex items-center justify-center h-auto relative">
            <Player
              autoplay
              controls
              loop
              src={SuccessAnimation}
              style={{
                width: "200px",
                height: "120px",
              }}
            />
            <p className="absolute top-[30%] right-[25%] text-2xl text-aellaBlue">
              Successful
            </p>
          </div>
          <p className="text-aellaBlack text-[24px] mt-6">
           Password Updated
          </p>
          <p className="text-[#5b5b5b] font-light text-center mt-3">
           You can now use your new password on this POS terminal
          </p>
          <BlueButton title='Got it!' className="mt-8" />
        </div>
      </>
    );
  };

  const renderBody = () => {
    switch (status) {
      case "Success":
        return <SuccessStatus />;
      case "Failed":
        return <FailedStatus />;
      default:
        break;
    }
  };

  return (
    <ModalContainer
      showModal={showModal}
      closeModal={() => {
        setShowModal(false);
      }}
    >
      <div className="w-full lg:w-[30vw] relative h-auto lg:max-h-[85vh] z-50 text-aellaBlack flex flex-col rounded-[8px] bg-white py-8 px-6">
        <>{renderBody()}</>
      </div>
    </ModalContainer>
  );
};

export default PasswordChangeStatus;
