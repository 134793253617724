import { useState } from "react";
import AddAccountModal from "../Modal/AddAccountModal";
import { useMerchantWithdrawalAccountDetails } from "../../../hooks/useMerchantWithdrawalAccountDetails.tsx";
import DeleteAccountModal from "../Modal/DeleteAccountModal";
import moment from "moment";
import { ReactComponent as AddIcon } from "../../../assets/images/svg/white-add-icon.svg";
import { DetailObjectType } from "../../Home/Home";
import { truncateString } from "../../../helpers/formatter";
import { apiEndpoints } from "../../../apis/apiEndpoints";
import { postData } from "../../../apis/apiMethods";
import CONFIG from "../../../helpers/config";
import { errorHandler } from "../../../helpers/errorHandler";
import { toast } from "react-toastify";
import { useQueryClient } from "react-query";
import { ROUTES } from "../../../helpers/routes";
import Tab from "../../../component/Tab/Tab.component";
import CardSkeletonLoader from "../../../component/Skeleton/CardSkeletonLoader.component";
import { useAuth } from "../../../context/auth";

const Widthrawals = () => {
  const { details } = useAuth();
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [withdrawalAmount, setWithdrawalAmount] = useState(0);
  const {
    data,
    isLoading: bankAccountLoading,
    refetch,
  } = useMerchantWithdrawalAccountDetails({
    merchantId: details?.merchant?.id,
  });
  const [modalType, setModalType] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [loadingTrnxPin, setLoadingTrnxPin] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fromService, setFromService] = useState("");
  const [withdrawalDescription, setWithdrawalDescription] = useState("");
  const [toService, setToService] = useState("");
  const [toServiceName, setToServiceName] = useState("");
  const [transferAmount, setTransferAmount] = useState(0);
  const [accountNumber, setAccountNumber] = useState("");
  const [transferMessage, setTransferMessage] = useState("");
  const [trnxMessage, setTrnxMessage] = useState("");
  const queryClient = useQueryClient();
  const [detail, setDetail] = useState<DetailObjectType>({
    account_number: "",
    account_name: "",
    bank_name: "",
    id: "",
    bank_id: "",
  });

  const onSubmit = async (pin: string) => {
    setLoading(true);

    const reqBody = {
      amount: withdrawalAmount,
      id: detail?.id,
      narration: withdrawalDescription,
      account_number: detail?.account_number,
      bank_id: detail?.bank_id,
      account_name: detail?.account_name,
      code: pin,
    };

    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const res: any = await postData(
        `${CONFIG.BASE_URL2}${apiEndpoints.WITHDRAW_FROM_WALLET}`,
        reqBody
      );
      setTrnxMessage(res.message);
      setModalType("step4");
      queryClient.invalidateQueries("merchantWithdrawalAccountDetails");
      queryClient.invalidateQueries("getWalletBalance");
      queryClient.invalidateQueries("getWalletTransactionSummary");
      queryClient.invalidateQueries("getMerchantServices");
    } catch (error) {
      toast.error(errorHandler(error));
    }
    setLoading(false);
    setLoadingTrnxPin(false);
  };

  const onWalletTransferSubmit = async (pin: string) => {
    setLoadingTrnxPin(true);

    const reqBody = {
      amount: transferAmount,
      currency: "NGN",
      from: fromService,
      code: pin,
      to: toService,
    };

    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const res: any = await postData(
        `${CONFIG.BASE_URL2}${apiEndpoints.WALLET_TO_WALLET_TRANSFER}`,
        reqBody
      );
      setTransferMessage(res.message);
      setModalType("step6");
      queryClient.invalidateQueries("merchantWithdrawalAccountDetails");
      queryClient.invalidateQueries("getWalletBalance");
      queryClient.invalidateQueries("getWalletTransactionSummary");
      queryClient.invalidateQueries("getMerchantServices");
    } catch (error) {
      toast.error(errorHandler(error));
    }
    setLoading(false);
    setLoadingTrnxPin(false);
  };

  if (bankAccountLoading) {
    return (
      <div className="flex flex-col md:flex-row justify-between px-10 pt-[32px] lg:pt-[30px]">
        <CardSkeletonLoader width={320} height={458} />
        <CardSkeletonLoader width={320} height={458} />
        <CardSkeletonLoader width={320} height={458} />
      </div>
    );
  }

  return (
    <>
      <div className="border-y border-[#e1dddd] bg-aellaDimGray overflow-x-auto h-screen">
        <div className="bg-white pt-[7px] px-[10px] lg:px-10 overflow-x-auto">
          <div className="flex justify-between bg-white items-center pt-2 pb-[1px] flex-wrap border-t border-[#F1F1F1] w-[200%] xs:w-[165%] sm:w-full">
            <div className="flex ">
              <Tab text="Profile" path={ROUTES.SETTINGS_PROFILE} />
              <Tab text="Security" path={ROUTES.SETTINGS_SECURITY} />
              <Tab text="Notifications" path={ROUTES.SETTINGS_NOTIFICATIONS} />
              {/* <Tab text="Manage Team" path={ROUTES.SETTINGS_MANAGE_TEAM} />
              <Tab
                text="Withdrawal Bank Accounts"
                path={ROUTES.SETTINGS_WITHDRAWAL}
              /> */}
              {/* <Tab text="APi Keys & Webhooks" path={ROUTES.SETTINGS_API} /> */}
            </div>
          </div>
        </div>
        <div className="py-[32px] px-[10px] lg:p-10">
          <div className="bg-white border border-[#EDEFF5] p-[10px] lg:p-10 pt-[30px] min-h-[70vh]">
            <div className="">
              <div className="pb-[12px] border-b border-[#EDEFF5] flex flex-col md:flex-row gap-y-[20px] justify-between">
                <p className="text-[16px] md:text-[24px] text-[#0B1E4B]">
                  {data?.length === 0
                    ? "Withdrawal Bank Accounts"
                    : `Withdrawal Bank Accounts(${
                        data?.filter(
                          (account: any) => account.status === "active"
                        ).length
                      })`}
                </p>

                {data?.length === 0 ? (
                  ""
                ) : (
                  <button
                    onClick={() => setModalType("step3")}
                    className="px-[20px] flex justify-center items-center gap-[11px] py-[14px] rounded-[4px] text-[14px] text-white bg-[#2054D2]"
                  >
                    <AddIcon />
                    <p> Add Bank Account</p>
                  </button>
                )}
              </div>
            </div>

            <div>
              {data?.length === 0 ? (
                <div className="flex flex-col gap-[32px] mt-[77px] w-[40%] mx-auto justify-center items-center">
                  <div className="text-center">
                    <p className="text-[#232323] mb-[8px]">No Bank Account </p>
                    <p className="font-thin text-[#5B5B5B]">
                      You have not added a bank account to this platform. Click
                      the button below to add your bank account.
                    </p>
                  </div>

                  <button
                    onClick={() => setModalType("step3")}
                    className="px-[50px] py-[16px] rounded-[4px] text-white bg-[#2054D2]"
                  >
                    Link Bank Account
                  </button>
                </div>
              ) : (
                <div className="overflow-x-auto">
                  <div className="p-[10px] md:p-[24px] w-[250%] xs:w-[200%] sm:w-[130%] lg:w-full rounded-[8px] border border-[#F1F1F1] mt-[24px]">
                    {data
                      ?.filter((account: any) => account.status === "active")
                      ?.map((eachData: any, index: number) => (
                        <div
                          key={index}
                          className="p-[24px] border-b border-[#F1F1F1] last:border-b-0 flex justify-between items-center"
                        >
                          <div className="flex gap-[14px] items-center w-[26%]">
                            <img
                              src={eachData?.bank_logo}
                              alt="i"
                              className="rounded-[50%] w-[30px] h-[30px]  md:w-[45px] md:h-[45px]"
                            />

                            <div>
                              <p className="text-[#7B7B7B] font-thin mb-[8px]">
                                Bank Name
                              </p>
                              <p className="text-[#0B1E4B] text-[14px] md:text-[18px] font-thin">
                                {eachData?.bank_name}
                              </p>
                            </div>
                          </div>

                          <div className="flex gap-[14px] items-center w-[19%]">
                            <div>
                              <p className="text-[#7B7B7B] font-thin mb-[8px]">
                                Account Name
                              </p>
                              <p className="text-[#0B1E4B] text-[14px] md:text-[18px] font-thin">
                                {truncateString(eachData?.account_name, 13)}
                              </p>
                            </div>
                          </div>
                          <div className="flex gap-[14px] items-center w-[19%]">
                            <div>
                              <p className="text-[#7B7B7B] font-thin mb-[8px]">
                                Account Number
                              </p>
                              <p className="text-[#0B1E4B] text-[14px] md:text-[18px] font-thin">
                                {eachData?.recipient}
                              </p>
                            </div>
                          </div>

                          <div className="flex gap-[14px] items-center w-[19%]">
                            <div>
                              <p className="text-[#7B7B7B] font-thin mb-[8px]">
                                Date Created
                              </p>
                              <p className="text-[#0B1E4B] text-[14px] md:text-[18px] font-thin">
                                {moment(eachData?.created_at).format(
                                  "MMM. Do, YYYY"
                                )}
                              </p>
                            </div>
                          </div>

                          <div
                            onClick={() => {
                              setAccountNumber(eachData?.recipient);
                              setOpenDeleteModal(true);
                            }}
                            className="flex gap-[14px] items-center justify-center w-[15%] cursor-pointer"
                          >
                            <p className="text-[#FE4149] font-thin">
                              Deactivate
                            </p>
                          </div>
                        </div>
                      ))}
                  </div>

                  <div className="mt-[40px]">
                    <div className="mt-[24px] opacity-30">
                      <p className="text-[16px] md:text-[24px] text-[#0B1E4B]">
                        {`Deactivated Withdrawal Bank Accounts(${
                          data?.filter(
                            (account: any) => account.status !== "active"
                          ).length
                        })`}
                      </p>
                      {data
                        ?.filter((account: any) => account.status !== "active")
                        ?.map((eachData: any, index: number) => (
                          <div className="p-[10px] md:p-[24px] border-b border-[#F1F1F1] flex justify-between items-center">
                            <div className="flex gap-[14px] items-center w-[26%]">
                              <img
                                src={eachData?.bank_logo}
                                alt="i"
                                className="rounded-[50%] w-[45px] h-[45px]"
                              />

                              <div>
                                <p className="text-[#7B7B7B] font-thin mb-[8px]">
                                  Bank Name
                                </p>
                                <p className="text-[#0B1E4B] text-[18px] font-thin">
                                  {eachData?.bank_name}
                                </p>
                              </div>
                            </div>

                            <div className="flex gap-[14px] items-center w-[19%]">
                              <div>
                                <p className="text-[#7B7B7B] font-thin mb-[8px]">
                                  Account Name
                                </p>
                                <p className="text-[#0B1E4B] text-[18px] font-thin">
                                  {truncateString(eachData?.account_name, 13)}
                                </p>
                              </div>
                            </div>
                            <div className="flex gap-[14px] items-center w-[19%]">
                              <div>
                                <p className="text-[#7B7B7B] font-thin mb-[8px]">
                                  Account Number
                                </p>
                                <p className="text-[#0B1E4B] text-[18px] font-thin">
                                  {eachData?.recipient}
                                </p>
                              </div>
                            </div>

                            <div className="flex gap-[14px] items-center w-[19%]">
                              <div>
                                <p className="text-[#7B7B7B] font-thin mb-[8px]">
                                  Date Created
                                </p>
                                <p className="text-[#0B1E4B] text-[18px] font-thin">
                                  {moment(eachData?.created_at).format(
                                    "MMM. Do, YYYY"
                                  )}
                                </p>
                              </div>
                            </div>

                            <div className="flex gap-[14px] items-center w-[15%]">
                              <div>
                                <p className="text-[#7B7B7B] font-thin mb-[8px]">
                                  Status
                                </p>
                                <p className="text-[#0B1E4B] text-[18px] font-thin">
                                  Deactivated
                                </p>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {modalType && (
        <AddAccountModal
          modalType={modalType}
          setModalType={setModalType}
          withdrawalAmount={withdrawalAmount}
          setIsOpen={setIsOpen}
          setDetail={setDetail}
          onSubmitTrnxPin={onSubmit}
          loadingTrnxPin={loadingTrnxPin}
          submitTransferPin={onWalletTransferSubmit}
          toServiceName={toServiceName}
          transferAmount={transferAmount}
          detail={detail}
          transferMessage={transferMessage}
          trnxMessage={trnxMessage}
        />
      )}

      {openDeleteModal && (
        <DeleteAccountModal
          refetch={refetch()}
          accountNumber={accountNumber}
          showModal={openDeleteModal}
          setCloseModal={setOpenDeleteModal}
        />
      )}
    </>
  );
};

export default Widthrawals;
