import { useState } from "react";

import PinInput from "react-pin-input";

interface ITransaction {
  setStep: (newState: number) => void;
  setState: any;
  state: {
    currentPin: string;
  };
}
const OldTransaction: React.FC<ITransaction> = ({
  setStep,
  setState,
  state,
}) => {
  // const [otp, setOtp] = useState("");
  const [pin, setPin] = useState("");
  // const onChange = (value: string) => setOtp(value);

  const submitForm = () => {
    setStep(2);
    setState({
      ...state,
      currentPin: pin,
    });
  };

  return (
    <>
      <div>
        <div className="p-[15px] md:p-[40px]">
          <p className="text-[24px] mb-[30px]">
            Enter your Old Transaction pin
          </p>

          <div className="mt-[28px]">
            <label className="text-[#011C34] inline-block mb-[15px]">
              Enter Pin
            </label>

            <div className="mx-auto">
              <PinInput
                length={4}
                placeholder="0"
                initialValue=""
                secret={true}
                onChange={(value, index) => setPin(value)}
                type="numeric"
                inputMode="number"
                style={{ display: "flex", justifyContent: "space-between" }}
                inputStyle={{
                  border: "1px solid #7B7B7B80",
                  width: "23%",
                  height: "54px",
                  paddingRight: "0px",
                  paddingLeft: "0px",
                  fontWeight: 100,
                  borderRadius: "3px",
                }}
                inputFocusStyle={{ border: "1px solid #2054D2" }}
                onComplete={(value, index) => {}}
                autoSelect={true}
              />
            </div>
          </div>

          <button
            onClick={submitForm}
            type="submit"
            className="bg-[#2054D2] mt-[45px] w-full text-white py-[15px] rounded-[7px]"
          >
            Continue
          </button>
        </div>
      </div>
    </>
  );
};

export default OldTransaction;
