import { useEffect, useState } from "react";
import { format } from "date-fns";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
import { useMutation } from "react-query";
import "react-datepicker/dist/react-datepicker.css";
import { Player } from "@lottiefiles/react-lottie-player";

import { ModalContainer } from "../../../modal/ModalContainer";
import calendarIcon from "../../../assets/images/svg/calendar.svg";
import { ReactComponent as CloseIcon } from "../../../assets/images/svg/white-close-modal.svg";
import BlueButton from "../../../component/Button/BlueButton";
import SuccessAnimation from "../../../assets/files/Stars.json";
import blueInfo from "../../../assets/images/svg/blue-info.svg";
import { exportEmployerLoansFn } from "../queries";
import { errorHandler } from "../../../helpers/errorHandler";
import { getFromLocalStorage } from "../../../helpers/localStorage";
import { LOCAL_STORAGE_KEYS } from "../../../helpers/localStorageKeys";

export const ExportLoanBook = ({
  showModal,
  closeModal,
  loanStatus,
}: {
  showModal: boolean;
  closeModal: () => void;
  loanStatus?: string;
}) => {
  const email = getFromLocalStorage(LOCAL_STORAGE_KEYS.EMAIL);
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);
  const [status, setStatus] = useState("");

  useEffect(() => {
    if (!showModal)
      setTimeout(() => {
        setStatus("");
      }, 1000);
  }, [showModal]);

  const { isLoading, mutateAsync } = useMutation({
    mutationFn: exportEmployerLoansFn,
    onSuccess: () => {
      setStatus("success");
      setStart(null);
      setEnd(null);
    },
    onError: (error) => {
      toast.error(errorHandler(error));
    },
  });

  const handleSubmit = async () => {
    try {
      await mutateAsync({
        payload: {
          startDate: format(new Date(start), "yyyy-MM-dd"),
          endDate: format(new Date(end), "yyyy-MM-dd"),
        },
      });
    } catch (error) {}
  };

  return (
    <ModalContainer showModal={showModal} closeModal={closeModal}>
      <div
        className={`w-[98%] sm:w-[95%] md:w-[45vw] relative h-auto lg:max-h-[85vh] z-50 text-aellaBlack flex flex-col rounded-[8px] bg-white fast_animation p-5 lg:p-10 ${
          showModal
            ? "mt-0 opacity-100 visible"
            : "mt-[5rem] opacity-0 invisible"
        }`}
      >
        <button
          type="button"
          onClick={closeModal}
          className="absolute -top-8 -right-0 cursor-pointer"
        >
          <CloseIcon />
        </button>
        {status === "" ? (
          <>
            <p className="text-aellaBlack text-[24px]">Export Data</p>
            <p className="text-[#5b5b5b] font-light">
              Select date range you want your data to be exported
            </p>
            <div className="w-full flex flex-col gap-y-6 lg:flex-row gap-x-3 mt-10 justify-between items-center">
              <div className="flex-col w-full lg:w-[40%]">
                <p className="font-light">From</p>
                <div className="z-50 flex items-center border border-aellaLightGray rounded-[4px] px-3">
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    placeholderText="Start date"
                    selected={start ?? null}
                    maxDate={end ?? new Date()}
                    onChange={(date: Date) => {
                      setStart(date);
                    }}
                    showYearDropdown
                    scrollableYearDropdown
                    yearDropdownItemNumber={100}
                    className="h-11 w-full text-aellaBlack lg:leading-[16px] text-[16px] font-[300] outline-0 cursor-pointer"
                  />
                  <img
                    src={calendarIcon}
                    className="w-[20px] h-[20px]"
                    alt="calendar icon"
                  />
                </div>
              </div>
              <div className="flex-col w-full lg:w-[40%]">
                <p className="font-light">To</p>
                <div className="z-50 flex items-center border border-aellaLightGray rounded-[4px] px-3">
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    placeholderText="End date"
                    selected={end ?? null}
                    minDate={start}
                    maxDate={new Date()}
                    onChange={(date: Date) => {
                      setEnd(date);
                    }}
                    showYearDropdown
                    scrollableYearDropdown
                    yearDropdownItemNumber={100}
                    className="h-11 w-full text-aellaBlack lg:leading-[16px] text-[16px] font-[300] outline-0 cursor-pointer"
                  />
                  <img
                    src={calendarIcon}
                    className="w-[20px] h-[20px]"
                    alt="calendar icon"
                  />
                </div>
              </div>

              <BlueButton
                onClick={handleSubmit}
                disabled={!start || !end || isLoading}
                title={isLoading ? "Exporting" : "Export CSV"}
                className="h-11 font-light w-full text-sm justify-center mt-4 lg:mt-0 lg:w-[10rem] lg:self-end"
              />
            </div>
            <div className="text-[14px] mt-10 p-4 rounded-[8px] border border-[#C5D4F6] bg-[#F3F5FC]">
              <div className="flex gap-x-3">
                <img src={blueInfo} className="w-4 h-4" alt="" />

                <div className="flex flex-col gap-y-1">
                  <p className="font-medium text-[12px]">
                    Large amount of data included
                  </p>
                  <p className="font-light text-[12px]">
                    You&apos;re about to export a large amount of data that may
                    take a while to export and appear in your email.
                  </p>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="flex flex-col items-center justify-center bg-white">
            <div className="flex items-center justify-center h-auto relative">
              <Player
                autoplay
                controls
                loop
                src={SuccessAnimation}
                style={{
                  width: "200px",
                  height: "120px",
                }}
              />
              <p className="absolute top-[30%] right-[25%] text-2xl text-aellaBlue">
                Successful
              </p>
            </div>
            <p className="text-[#0B1E4B] text-2xl mt-8">Data Exported</p>
            <p className="text-[#5B5B5B] text-base mt-4 font-light text-center">
              Your data has been exported to the email <br />{" "}
              <span className="font-[400] text-aellaBlack">{email}</span>
            </p>
            <BlueButton
              onClick={closeModal}
              title="Got it!"
              className="mt-10"
            />
          </div>
        )}
      </div>
    </ModalContainer>
  );
};
