import { useState, useEffect, Fragment } from "react";
import { Switch } from "@headlessui/react";
import { toast } from "react-toastify";
import { useQuery, useQueryClient } from "react-query";
import { Popover, Transition } from "@headlessui/react";

import { errorHandler } from "../../helpers/errorHandler";
import { SecondaryLoader } from "../../component/Loader/SecondaryLoader";
import { useEnvironmentData } from "../../hooks/useEnvironmentData";
import { apiEndpoints } from "../../apis/apiEndpoints";
import { postData } from "../../apis/apiMethods";
import CONFIG from "../../helpers/config";
import { useMerchantDetails } from "../../hooks/useMerchantDetails";
import { ReactComponent as SearchIcon } from "../../assets/images/svg/search-icon.svg";
import { ReactComponent as CopyIcon } from "../../assets/images/svg/copy.svg";
import { ReactComponent as DropDownIcon } from "../../assets/images/svg/black-arrow-down-icon.svg";
import { ReactComponent as ConnectIcon } from "../../assets/images/svg/connect-icon.svg";
import { ReactComponent as LogoutIcon } from "../../assets/images/svg/logout.svg";
import {
  deleteFromLocalStorage,
  getFromLocalStorage,
} from "../../helpers/localStorage";
import { LOCAL_STORAGE_KEYS } from "../../helpers/localStorageKeys";
import { NavLink, useNavigate } from "react-router-dom";
import { ROUTES } from "../../helpers/routes";
import { getFirstLetter } from "../../helpers/capitalizeFirstLetter";
import { truncateString } from "../../helpers/formatter";
import { useAuth } from "../../context/auth";
import { getBanksFn } from "../EmployeeLoans.tsx/queries";

const BankCodes = () => {
  const [search, setSearch] = useState("");
  const [mode, setMode] = useState(false);
  const [hovered, setHovered] = useState("");
  const queryClient = useQueryClient();
  const userId = getFromLocalStorage(LOCAL_STORAGE_KEYS.USERID);

  const { data, isLoading } = useQuery({
    queryFn: getBanksFn,
    queryKey: ["bank list"],
  });

  const banks = data?.data;

  const { merchantCntxtStatus } = useAuth();
  const { data: environmentData } = useEnvironmentData({
    status: merchantCntxtStatus,
  });
  const { data: merchantDetails } = useMerchantDetails({ userId });

  const filteredList = banks?.filter((bank) =>
    bank?.name?.toLowerCase()?.includes(search?.toLowerCase())
  );

  const renderList = () => {
    if (isLoading) return <SecondaryLoader count={10} />;

    if (filteredList?.length === 0 && !isLoading) {
      return (
        <div className="h-full flex items-center justify-center">
          <p className="text-2xl">Bank not found.</p>
        </div>
      );
    }

    if (banks?.length) {
      return filteredList
        ?.sort((a, b) => (a?.name > b?.name ? 1 : -1))
        ?.map((bank, index) => (
          <div
            onMouseEnter={() => setHovered(bank?.name)}
            onMouseLeave={() => setHovered("")}
            className={`flex justify-between items-center h-14 hover:bg-[#F6F8FA] px-5 rounded-[4px]  `}
            key={index}
          >
            <p>{bank?.name}</p>
            <div
              onClick={() => {
                navigator.clipboard.writeText(bank?.bank_code);
                toast.success("Bank code copied");
              }}
              className={`${
                hovered === bank?.name ? "visible" : "invisible"
              } cursor-pointer border border-[#DBDFE3] radius bg-[#EDEFF1] rounded-[24px] flex gap-x-2 py-2 px-3 `}
            >
              <CopyIcon />
              Copy
            </div>
          </div>
        ));
    }
  };

  useEffect(() => {
    if (environmentData?.environment) {
      setMode(environmentData?.environment);
    }
  }, [environmentData?.environment]);

  const handleMode = async (mode: boolean) => {
    const reqBody = {
      environment: mode,
    };

    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const res: any = await postData(
        `${CONFIG.BASE_URL2}${apiEndpoints.ENVIRONMENT}`,
        reqBody
      );
      queryClient.invalidateQueries("environmentData");
      setMode(mode);
    } catch (error) {
      toast.error(errorHandler(error));
    }
  };

  const businessName = getFromLocalStorage(LOCAL_STORAGE_KEYS.BUSINESSNAME);
  const navigate = useNavigate();
  const handleLogout = () => {
    deleteFromLocalStorage();
    navigate(ROUTES.LOGIN);
  };

  return (
    <div className="bg-[#F6F8FA] h-screen w-full flex flex-col items-center overflow-hidden">
      <header
        className={`py-[1vh] px-10 lg:flex w-full bg-white justify-between items-center h-[10vh]`}
      >
        <p className="text-[16px] hidden lg:block lg:leading-[16px] font-[300]">
          Employees
        </p>

        <div className="items-center hidden lg:flex">
          <p className={`${mode ? "text-aellaGray" : "text-[#232323]"} pr-4`}>
            Test Mode
          </p>
          <Switch
            checked={mode}
            onChange={() => handleMode(!mode)}
            disabled={merchantDetails?.status !== 1}
            className={`${
              mode ? "bg-[#D8A28F]" : "bg-gray-200"
            } relative inline-flex  h-[16px] w-[30px] items-center rounded-full`}
          >
            <span className="sr-only">Mode</span>
            <span
              className={`${
                mode ? "translate-x-4" : "translate-x-1"
              } inline-block h-2.5 w-2.5 transform rounded-full bg-white transition`}
            />
          </Switch>
          <p className={`${mode ? "text-[#232323]" : "text-aellaGray"} pl-4`}>
            Live Mode
          </p>
        </div>
        <>
          <Popover className="flex flex-col">
            <Popover.Button className="rounded-l-full rounded-r-[3500px] p-[8px] border-[#ECEFF8] bg-[#ECEFF8] flex justify-between items-center self-end cursor-pointer outline-none focus:outline-none">
              <div className="rounded-full inline-flex bg-aellaBlue w-[30.4px] h-[29.6px] items-center justify-center top-6  cursor-pointer mr-2 text-white">
                {getFirstLetter(businessName)}
              </div>
              <div className="flex flex-col">
                <h1 className="text-[14px] tracking-[0.2px] font-[400] capitalize self-start">
                  {truncateString(businessName, 10)}
                </h1>
                <h3 className="text-[12px] tracking-[0.2px] font-[250] text-[#011C34]">
                  Merchant ID:{" "}
                  {truncateString(
                    getFromLocalStorage(LOCAL_STORAGE_KEYS.MERCHANTID),
                    10
                  )}
                </h3>
              </div>
              <DropDownIcon />
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute bg-white top-[12%] right-[40px]">
                <div className="overflow-hidden rounded-lg border-[#f1f1f1] border shadow-lg ring-1 ring-black ring-opacity-5">
                  <div className="grid gap-2">
                    <div className="min-w-[290px] px-4 py-6 border-[#EDEFF5] border-b-[1px] flex items-center gap-4">
                      <div className="rounded-full inline-flex bg-aellaBlue w-[30.4px] h-[29.6px] items-center justify-center top-6  cursor-pointer mr-1 text-white">
                        {getFirstLetter(businessName)}
                      </div>
                      <div className="flex-1">
                        <p className="text-[16px] leading-[16px] font-[400] tracking-[0.03em]">
                          {businessName}
                        </p>
                        <p className="text-[14px] leading-[16px] tracking-[0.03em] text-aellaGray pt-1.5 font-[300]">
                          Super Admin
                        </p>
                      </div>
                    </div>
                    <div className=" py-[4px] border-[#EDEFF5] border-b-[1px] gap-4">
                      <NavLink
                        className={({ isActive }) =>
                          isActive
                            ? `py-2 px-4 rounded-lg transition duration-150 ease-in-out  bg-aellaBlue text-white flex items-center cursor-pointer`
                            : `py-2 px-4 rounded-lg transition duration-150 ease-in-out text-aellaGray  hover:bg-aellaBlue hover:text-white flex items-center cursor-pointer`
                        }
                        to={ROUTES.CONNECT_SERVICES}
                      >
                        <ConnectIcon />
                        <p className="text-[16px] pl-2.5 leading-[18px] tracking-[0.03em] font-[300]">
                          Connect more services
                        </p>
                      </NavLink>
                    </div>
                    <div
                      className=" cursor-pointer rounded-lg py-2 px-4 transition duration-150 ease-in-out hover:bg-aellaBlue hover:text-white focus:outline-none"
                      onClick={() => handleLogout()}
                    >
                      <div className="w-full flex items-center ">
                        <div className="pr-2.5">
                          <LogoutIcon />
                        </div>
                        <p className="text-[14px] leading-[17px] tracking-[0.2px] font-[200] hover:text-white uppercase">
                          Logout
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </Popover>
        </>
      </header>
      <div className="w-full sm:w-[30rem] flex items-center justify-center py-6 px-4 my-6 text-[#232323]">
        <div className="w-full h-[80vh] flex flex-col gap-y-4 justify-center bg-white ">
          <div className="flex flex-col">
            <p className="min-h-12 bg-[#EEF1F5] text-[#232323] py-4 pl-6">
              Bank Name
            </p>
            <div className="flex items-center gap-x-2 mx-6 px-4 font-light my-3 border rounded-[3px]">
              <SearchIcon />
              <input
                className="h-10 outline-none border-none "
                placeholder="Search bank"
                type="text"
                value={search}
                onChange={(e: any) => setSearch(e.target.value)}
              />
            </div>
          </div>
          <div className="mt-3 overflow-y-auto px-6 flex-1 font-light">
            {renderList()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BankCodes;
