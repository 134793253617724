import { ReactComponent as Back } from "../../../assets/images/svg/back-nav.svg";
import { Link, useParams } from "react-router-dom";
import { useTeamDetails } from "../../../hooks/useGetTeamDetails";
import moment from "moment";
import CardSkeletonLoader from "../../../component/Skeleton/CardSkeletonLoader.component";

const UserDetails = () => {
  const { id } = useParams();
  const { data, isFetching, refetch } = useTeamDetails({
    merchantId: id,
  });

  return (
    <div>
      {isFetching ? (
        <div className="flex flex-col md:flex-row justify-between px-10 pt-[32px] lg:pt-[30px]">
          <CardSkeletonLoader width={320} height={458} />
          <CardSkeletonLoader width={320} height={458} />
          <CardSkeletonLoader width={320} height={458} />
        </div>
      ) : (
        <div className="border-y border-[#e1dddd] bg-aellaDimGray overflow-x-auto h-screen">
          <div className="bg-white pt-[7px] px-[10px] lg:px-10 overflow-x-auto">
            <div className="flex justify-between bg-white items-center pt-2 flex-wrap pb-[14px] w-[200%] xs:w-[165%] sm:w-full">
              <Link
                to="/profile-settings/manage-team"
                className="flex gap-[10px] items-center"
              >
                <Back /> <p className="text-[18px] font-thin">Back</p>
              </Link>
            </div>
          </div>
          <div className="py-[32px] px-10">
            <div className="bg-white min-h-[70vh]">
              <div className="py-[16px] px-[40px]">
                <p className="text-[14px] font-extralight text-[#7B7B7B]">
                  Name:
                </p>
                <p className="text-[18px] text-[#011C34]">
                  {data?.user?.first_name} {data?.user?.last_name}
                </p>
              </div>

              <div className="py-[17px] px-[24px] bg-[#EDF2FF]">
                USER DETAILS
              </div>

              <div className="px-[40px] py-[24px] font-extralight flex flex-wrap gap-y-[24px]">
                <div className="w-[40%]">
                  <p className="text-[16px] text-[#7B7B7B]">Email Address</p>
                  <p className="text-[18px] text-[#011C34]">
                    {data?.user?.email ? data?.user?.email : "_"}
                  </p>
                </div>

                <div className="w-[40%]">
                  <p className="text-[16px] text-[#7B7B7B]">Role</p>
                  <p className="text-[18px] text-[#011C34]">
                    {data?.merchant_role?.name
                      ? data?.merchant_role?.name
                      : "_"}
                  </p>
                </div>

                <div className="w-[40%]">
                  <p className="text-[16px] text-[#7B7B7B]">Phone Number</p>
                  <p className="text-[18px] text-[#011C34]">
                    {data?.user?.phone_number ? data?.user?.phone_number : "_"}
                  </p>
                </div>

                <div className="w-[40%]">
                  <p className="text-[16px] text-[#7B7B7B]">Date Invited</p>
                  <p className="text-[18px] text-[#011C34]">
                    {data?.created_at
                      ? moment(data?.created_at).format("DD MMMM, YYYY")
                      : "_"}
                  </p>
                </div>

                <div className="w-[40%]">
                  <p className="text-[16px] text-[#7B7B7B]">Gender</p>
                  <p className="text-[18px] text-[#011C34]">
                    {data?.user?.gender ? data?.user?.gender : "_"}
                  </p>
                </div>

                <div className="w-[40%]">
                  <p className="text-[16px] text-[#7B7B7B]">Invited by</p>
                  <p className="text-[18px] text-[#011C34]">
                    {data?.merchant?.name ? data?.merchant?.name : "_"}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserDetails;
