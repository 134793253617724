import { useState } from "react";
import { useMutation } from "react-query";
import OtpInput from "react-otp-input";
import { toast } from "react-toastify";

import { Stage } from "../../../interfaces/settings";
import BlueButton from "../../../component/Button/BlueButton";
import { resetPinFn } from "../queries";
import { errorHandler } from "../../../helpers/errorHandler";

export const ConfirmPin = ({
  setStage,
  otp,
  setOtp,
}: {
  setStage: (state: Stage) => void;
  otp: string;
  setOtp: (state: string) => void;
}) => {
  const [pin, setPin] = useState("");
  const [confirmPin, setConfirmPin] = useState("");

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: resetPinFn,
    onSuccess: () => {
      setStage("status");
      setOtp("");
      setPin("");
    },
    onError: (error) => {
      toast.error(errorHandler(error));
    },
  });

  const handleReset = async () => {
    try {
      await mutateAsync({
        payload: { pin, otp },
      });
    } catch (error) {}
  };

  return (
    <div className="flex flex-col">
      <p className="text-[20px] sm:text-[24px] text-aellaBlack">
        Enter and confirm your new transaction pin
      </p>

      <p className="capitalize text-aellaBlack mt-10">
        enter new transaction pin
      </p>
      <div
        className={`mt-2 w-full h-[4.5rem] flex justify-between transfer text-base items-center rounded-[2px] border-[2px] ${
          pin
            ? "border border-[#2054D2] outline outline-2 outline-[#cccccc]"
            : "border-[#cccccc]"
        } `}
      >
        <OtpInput
          numInputs={4}
          placeholder=""
          isInputSecure={true}
          onChange={(e: string) => setPin(e)}
          isInputNum={true}
          value={pin}
          inputStyle="each-input w-1/4"
          containerStyle="pincode-pin-input-container w-full"
          separator={false}
          shouldAutoFocus={true}
        />
      </div>
      <p className="capitalize text-aellaBlack mt-8">
        confirm new transaction pin
      </p>

      <div
        className={`mt-2 w-full h-[4.5rem] flex justify-between transfer text-base items-center rounded-[2px] border-[2px] ${
          confirmPin
            ? "border border-[#2054D2] outline outline-2 outline-[#cccccc]"
            : "border-[#cccccc]"
        } `}
      >
        <OtpInput
          numInputs={4}
          placeholder=""
          isInputSecure={true}
          onChange={(e: string) => setConfirmPin(e)}
          isInputNum={true}
          value={confirmPin}
          inputStyle="each-input w-1/4"
          containerStyle="pincode-pin-input-container w-full"
          separator={false}
          shouldAutoFocus={false}
        />
      </div>

      {pin?.length === 4 && confirmPin?.length === 4 && pin !== confirmPin && (
        <p className="text-sm text-[#cc0000] font-light mt-2">
          Pins do not match
        </p>
      )}

      <div className="flex gap-x-4 self-end mt-12">
        <button
          type="button"
          onClick={() => setStage("otp")}
          className="w-[156px] rounded-[4px] text-center bg-[#ECEDF0] text-[#232323]"
        >
          Go back
        </button>

        <BlueButton
          disabled={
            isLoading ||
            pin !== confirmPin ||
            pin?.length !== 4 ||
            confirmPin?.length !== 4
          }
          className="w-[150px] self-end justify-center"
          title={isLoading ? "Please wait.." : "Continue"}
          // onClick={() => setStage("status")}
          onClick={handleReset}
        />
      </div>
    </div>
  );
};
