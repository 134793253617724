import moment from "moment";
import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData } from "../apis/apiMethods";
import CONFIG from "../helpers/config";

export const getWalletTransactionSummary = async ({
  queryKey,
}: {
  queryKey: any;
}) => {
  const { type, walletId, startDate, endDate, merchantId } = queryKey[1];

  let queryParams = merchantId
    ? `?merchant_id=${merchantId}`
    : walletId
    ? `?wallet_id=${walletId}`
    : "?";

  if (type) {
    queryParams = `${queryParams}&type=${type}`;
  }
  if (startDate) {
    queryParams = `${queryParams}&from=${moment(startDate).format(
      "YYYY-MM-DD"
    )}`;
  }
  if (endDate) {
    queryParams = `${queryParams}&to=${moment(endDate).format("YYYY-MM-DD")}`;
  }
  if (walletId && merchantId) {
    queryParams = `${queryParams}&wallet_id=${walletId}`;
  }

  const { data } = await getData(
    `${CONFIG.BASE_URL2}${apiEndpoints.GET_WALLET_TRANSACTION_SUMARRY}${queryParams}`
  );
  return data;
};

export const useWalletTransactionSummary = (props: {
  type?: string;
  startDate?: Date;
  endDate?: Date;
  walletId?: string;
  merchantId?: string;
  mode?: string;
}) => {
  const { type, walletId, startDate, endDate, mode, merchantId } = props;
  return useQuery(
    [
      "getWalletTransactionSummary",
      { type, walletId, startDate, endDate, merchantId },
    ],

    getWalletTransactionSummary,
    {
      enabled:
        props?.hasOwnProperty("mode") && props?.hasOwnProperty("merchantId")
          ? mode === "merchant" && merchantId
            ? true
            : false
          : true,
      refetchOnWindowFocus: false,
      retry: 1,
    }
  );
};
