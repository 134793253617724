import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { ModalContainer } from "../../../modal/ModalContainer";
import CalendarIcon from "../../../assets/images/svg/calendar.svg";
import { ReactComponent as CloseIcon } from "../../../assets/images/svg/white-close-modal.svg";
import { format, parse } from "date-fns";

export const FilterByDate = ({
  setFilterObj,
  showModal,
  closeModal,
  type,
  filterObj,
}: {
  filterObj: any;
  setFilterObj: any;
  showModal: boolean;
  closeModal: () => void;
  type?:
    | "loans-requested"
    | "loans-approved"
    | "loans-declined"
    | "loans-defaulted"
    | string;
}) => {
  const [params, setParams] = useSearchParams({});
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);

  useEffect(() => {
    if (filterObj?.from)
      setStart(parse(filterObj?.from, "dd-MM-yyyy", new Date()));

    if (filterObj?.to) setEnd(parse(filterObj?.to, "dd-MM-yyyy", new Date()));
  }, [showModal]);

  const handleParams = () => {
    const newParams = {
      from: format(new Date(start), "dd-MM-yyyy"),
      to: format(new Date(end), "dd-MM-yyyy"),
      ...(type && { type }),
    };

    // setParams(newParams);
    setFilterObj({
      ...filterObj,
      [newParams?.type]: newParams,
    });
  };

  return (
    <ModalContainer showModal={showModal} closeModal={closeModal}>
      <div
        className={`w-full lg:w-[35vw] relative right-0 min-h-[30vh] h-auto max-h-[90vh] z-50 flex flex-col justify-center text-center p-5 md:p-10 rounded-[8px] bg-white fast_animation ${
          showModal
            ? "mt-0 opacity-100 visible"
            : "mt-[6rem] opacity-0 invisible"
        }`}
      >
        <button
          onClick={closeModal}
          className="absolute -top-8 -right-0 cursor-pointer"
        >
          <CloseIcon />
        </button>
        <div className="flex flex-col">
          <p className="pb-4 border-b text-left text-[24px] text-aellaBlack">
            Filter by:
          </p>

          <div className="mt-4 w-full">
            <p className="pb-2 text-aellaBlack text-left lg:leading-[16px] text-[16px] font-[300]">
              Filter Date:
            </p>
            <div className="flex items-center justify-between">
              <div className="w-[46%] z-50 flex items-center border border-aellaLightGray rounded-[4px] px-3">
                <DatePicker
                  dateFormat="yyyy-MM-dd"
                  placeholderText="Start Date"
                  selected={start}
                  maxDate={end ?? new Date()}
                  onChange={(date: Date) => {
                    setStart(date);
                  }}
                  showYearDropdown
                  scrollableYearDropdown
                  yearDropdownItemNumber={100}
                  className="h-11 w-full text-aellaBlack lg:leading-[16px] text-[16px] font-[300] outline-0"
                />
                <img
                  src={CalendarIcon}
                  className="w-[20px] h-[20px]"
                  alt="calendar icon"
                />
              </div>

              <div className="w-[46%] z-50 flex items-center border border-aellaLightGray rounded-[4px] px-3">
                <DatePicker
                  dateFormat="yyyy-MM-dd"
                  placeholderText="End Date"
                  selected={end}
                  minDate={start}
                  maxDate={new Date()}
                  onChange={(date: Date) => {
                    setEnd(date);
                  }}
                  showYearDropdown
                  scrollableYearDropdown
                  yearDropdownItemNumber={100}
                  className="h-11 w-full text-aellaBlack lg:leading-[16px] text-[16px] font-[300] outline-0"
                />
                <img
                  src={CalendarIcon}
                  className="w-[20px] h-[20px]"
                  alt="calendar icon"
                />
              </div>
            </div>

            <div className="flex justify-end gap-x-8 bg-white pt-10">
              <div></div>
              <button
                className="border-aellaBlue border rounded py-2 cursor-pointer text-center text-aellaBlue w-[121px]"
                onClick={() => {
                  setParams({});
                  setFilterObj({
                    ...filterObj,
                    [type]: { to: null, from: null },
                  });
                  closeModal();
                }}
              >
                Reset
              </button>
              <button
                className="border border-aellaBlue rounded bg-aellaBlue text-white cursor-pointer w-[121px]"
                onClick={() => {
                  setFilterObj({
                    start,
                    end,
                  });
                  closeModal();
                  handleParams();
                }}
              >
                Filter
              </button>
            </div>
          </div>
        </div>
      </div>
    </ModalContainer>
  );
};
