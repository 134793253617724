import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData } from "../apis/apiMethods";
import CONFIG from "../helpers/config";
import { getFromLocalStorage } from "../helpers/localStorage";
import { LOCAL_STORAGE_KEYS } from "../helpers/localStorageKeys";
import moment from "moment";

export const getPosRequests = async ({ queryKey }: { queryKey: any }) => {
  const { size, page, start_date, end_date, status } = queryKey[1];

  const merchantId = getFromLocalStorage(LOCAL_STORAGE_KEYS.MERCHANTID);

  let queryParams = `?merchant_id=${merchantId}&size=${size}&page=${page}`;

  if (start_date) {
    queryParams = `${queryParams}&from=${moment(start_date).format(
      "YYYY-MM-DD"
    )}`;
  }
  if (end_date) {
    queryParams = `${queryParams}&to=${moment(end_date).format("YYYY-MM-DD")}`;
  }

  if (status) {
    queryParams = `${queryParams}&status=${status}`;
  }

  const data = await getData(
    `${CONFIG.BASE_URL2}${apiEndpoints.POS_TERMINALS}${queryParams}`
  );
  return data;
};

export const usePosRequests = ({
  page,
  size,
  start_date,
  end_date,
  status,
}: {
  page: number;
  size: number;
  start_date?: Date | string | null;
  end_date?: Date | string | null;
  status: string;
}) => {
  return useQuery(
    ["posRequests", { size, page, start_date, end_date, status }],
    getPosRequests,
    {
      refetchOnWindowFocus: false, // Refetch on window focus
    }
  );
};
