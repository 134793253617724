import { Fragment, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Popover, Transition } from "@headlessui/react";
import { useQuery, useQueryClient } from "react-query";

import {
  deleteFromLocalStorage,
  getFromLocalStorage,
  postToLocalStorage,
} from "../../helpers/localStorage";
import { LOCAL_STORAGE_KEYS } from "../../helpers/localStorageKeys";
import { ROUTES } from "../../helpers/routes";
import { getFirstLetter } from "../../helpers/capitalizeFirstLetter";
import { truncateString } from "../../helpers/formatter";
import { getOrganizations } from "../../pages/Login/queries";
import { useMerchantDetails } from "../../hooks/useMerchantDetails";
import { useAuth } from "../../context/auth";
import { apiEndpoints } from "../../apis/apiEndpoints";
import { getData } from "../../apis/apiMethods";
import CONFIG from "../../helpers/config";
import { errorHandler } from "../../helpers/errorHandler";
import { ReactComponent as DropDownIcon } from "../../assets/images/svg/black-arrow-down-icon.svg";
import { ReactComponent as ConnectIcon } from "../../assets/images/svg/connect-icon.svg";
import { ReactComponent as LogoutIcon } from "../../assets/images/svg/logout.svg";
import { ReactComponent as DoubleCheckIcon } from "../../assets/images/svg/double-check-icon.svg";
import { ReactComponent as InviteIcon } from "../../assets/images/svg/invite-account.svg";
import { ReactComponent as SettingsIcon } from "../../assets/images/svg/merchant-settings.svg";

export default function ProfileSubMenu() {
  const role = getFromLocalStorage(LOCAL_STORAGE_KEYS.ROLE);
  const businessName = getFromLocalStorage(LOCAL_STORAGE_KEYS.BUSINESSNAME);
  const memberId = getFromLocalStorage(LOCAL_STORAGE_KEYS.MEMBER_ID);
  const { data: loggedInUser } = useMerchantDetails({});
  const {
    setCredentials,
    setMerchantCntxtTerms,
    setMerchantCntxtProfile,
    setMerchantCntxtStatus,
  } = useAuth();
  const queryClient = useQueryClient();

  const navigate = useNavigate();
  const [switching, setSwitching] = useState(false);
  const handleLogout = () => {
    deleteFromLocalStorage();
    navigate(ROUTES.LOGIN);
  };

  const completeSignIn = async () => {
    setSwitching(true);
    try {
      const { data } = await getData(
        `${CONFIG.BASE_URL2}${apiEndpoints.GET_MERCHANT_DETAILS}`
      );

      if (data?.is_member) {
        postToLocalStorage(LOCAL_STORAGE_KEYS.MERCHANTID, data?.merchant?.id);
        // postToLocalStorage(
        //   LOCAL_STORAGE_KEYS.BUSINESSNAME,
        //   data?.merchant?.name
        // );
        setCredentials({ isAuth: true, merchantDetails: data });
        setMerchantCntxtStatus("1");
        setMerchantCntxtProfile("1");
        setMerchantCntxtTerms("1");
        navigate(ROUTES.HOME, {
          state: { profile: true, profileSuccess: true },
        });
        setSwitching(false);
        return;
      }

      if (data) {
        postToLocalStorage(LOCAL_STORAGE_KEYS.MERCHANTID, data?.merchant?.id);
        // postToLocalStorage(
        //   LOCAL_STORAGE_KEYS.BUSINESSNAME,
        //   data?.merchant?.name
        // );
        setCredentials({ isAuth: true, merchantDetails: data });

        setMerchantCntxtTerms(
          data?.attributes?.profile?.terms_and_conditions === "accepted" ||
            data?.attributes?.Profile?.terms_and_conditions === "accepted"
            ? "1"
            : "0"
        );

        setMerchantCntxtProfile(
          data?.attributes?.profile?.upgrade_status === "Disapproved"
            ? "0"
            : data?.isTransactionPinSet === true &&
              data.isProfileCompleted === true
            ? "1"
            : "0"
        );

        setMerchantCntxtStatus(
          data?.merchant?.suspended_at === null &&
            data?.merchant?.deactivated_at === null &&
            data?.isTransactionPinSet === true &&
            data.isProfileCompleted === true
            ? "1"
            : "0"
        );

        switch (true) {
          case data?.stage?.next === "INITIATE":
            return navigate(ROUTES.SIGNUP, { state: { signUpStep: 0 } });
          case data?.stage?.next === "VERIFY":
            return navigate(ROUTES.SIGNUP, { state: { signUpStep: 2 } });

          case data?.stage?.next === "ADD_SERVICE":
            return navigate(ROUTES.SIGNUP, { state: { signUpStep: 3 } });

          case data?.attributes?.profile?.terms_and_conditions === undefined ||
            data?.attributes?.Profile?.terms_and_conditions === undefined:
            return navigate(ROUTES.TERMS);

          case data?.isProfileCompleted === false:
            postToLocalStorage(LOCAL_STORAGE_KEYS.PROFILE_SETUP, false);
            return navigate(ROUTES.HOME, {
              state: { profile: false, profileSuccess: false },
            });
          case data?.isTransactionPinSet === false &&
            data?.merchant?.suspended_at !== null:
            postToLocalStorage(LOCAL_STORAGE_KEYS.PROFILE_SETUP, false);
            return navigate(ROUTES.HOME, {
              state: { profile: false, profileSuccess: false },
            });

          case data?.merchant?.suspended_at !== null &&
            data?.attributes?.profile?.upgrade_status === "Disapproved":
            postToLocalStorage(LOCAL_STORAGE_KEYS.PROFILE_SETUP, true);
            postToLocalStorage(LOCAL_STORAGE_KEYS.REFRESH, true);
            return navigate(ROUTES.HOME, {
              state: { profile: false, profileSuccess: false },
            });
          case data?.merchant?.suspended_at !== null &&
            data?.attributes?.profile?.upgrade_status === "Completed":
            postToLocalStorage(LOCAL_STORAGE_KEYS.PROFILE_SETUP, true);
            postToLocalStorage(LOCAL_STORAGE_KEYS.REFRESH, true);
            return navigate(ROUTES.HOME, {
              state: { profile: false, profileSuccess: false },
            });
          case data?.merchant?.suspended_at === null:
            postToLocalStorage(LOCAL_STORAGE_KEYS.PROFILE_SETUP, true);
            return navigate(ROUTES.HOME, {
              state: { profile: false, profileSuccess: false },
            });

          case data?.merchant?.suspended_at !== null ||
            data?.merchant?.deactivated_at:
            postToLocalStorage(LOCAL_STORAGE_KEYS.PROFILE_SETUP, true);
            return navigate(ROUTES.HOME, {
              state: { profile: true, profileSuccess: true },
            });

          default:
            return navigate(ROUTES.SIGNUP, { state: { signUpStep: 0 } });
        }
      } else if (data === null) {
        return navigate(ROUTES.SIGNUP, { state: { signUpStep: 0 } });
      }
    } catch (error) {
      toast.error(errorHandler(error));
    } finally {
      setSwitching(false);
    }
  };

  const handleSwitch = (
    memberId: string,
    role: string,
    organizationName: string
  ) => {
    //save required info then attempt a sign in behind the scene using the selected member's id

    completeSignIn();
    postToLocalStorage(LOCAL_STORAGE_KEYS.BUSINESSNAME, organizationName);
    postToLocalStorage(LOCAL_STORAGE_KEYS.MEMBER_ID, memberId);
    postToLocalStorage(LOCAL_STORAGE_KEYS.ROLE, role);
    queryClient.invalidateQueries("organizations");
    window.location.reload();
  };

  const { data: organizationList, isLoading } = useQuery({
    queryFn: getOrganizations,
    queryKey: "organizations",
  });

  const otherAccounts = organizationList?.filter((org) => org?.id !== memberId);
  return (
    <Popover className="flex flex-col z-50 ">
      <Popover.Button className="rounded-l-full rounded-r-[3500px] p-[8px] border-[#ECEFF8] bg-[#ECEFF8] flex justify-between items-center cursor-pointer outline-none focus:outline-none">
        <div className="rounded-full inline-flex bg-aellaBlue w-[30.4px] h-[29.6px] items-center justify-center top-6  cursor-pointer mr-2 text-white">
          {getFirstLetter(businessName)}
        </div>
        <div className="flex flex-col">
          <h1 className="text-[14px] tracking-[0.2px] font-[400] capitalize self-start">
            {truncateString(businessName, 10)}
          </h1>
          <h3 className="text-[12px] tracking-[0.2px] font-[250] text-[#011C34]">
            Merchant ID:{" "}
            {truncateString(
              getFromLocalStorage(LOCAL_STORAGE_KEYS.MERCHANTID),
              10
            )}
          </h3>
        </div>
        <DropDownIcon />
      </Popover.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel className="absolute bg-white top-[120%] right-[40px]">
          <div className="overflow-hidden rounded-lg border-[#f1f1f1] border shadow-lg ring-1 ring-black ring-opacity-5">
            <div className="grid gap-2">
              <div className="min-w-[290px] px-4 py-6 border-[#EDEFF5] border-b-[1px] flex items-center gap-4">
                {loggedInUser?.merchant?.logo ? (
                  <img
                    src={loggedInUser?.merchant?.logo || ""}
                    alt="organization logo"
                    className="object-cover rounded-[50%]  h-[55px] w-[55px] border border-aellaBlue"
                  />
                ) : (
                  <div className="rounded-full shrink-0 h-[55px] w-[55px] flex justify-center items-center bg-aellaBlue text-white uppercase text-2xl">
                    {getFirstLetter(businessName)}
                  </div>
                )}
                <div className="flex-1">
                  <p className="text-[16px] leading-[16px] font-[400] tracking-[0.03em]">
                    {businessName}
                  </p>
                  <p className="text-[14px] leading-[16px] tracking-[0.03em] text-aellaGray pt-1.5 font-[300]">
                    {getFromLocalStorage(LOCAL_STORAGE_KEYS.ROLE)}
                  </p>
                </div>
                <div className="ml-auto">
                  <DoubleCheckIcon />
                </div>
              </div>
              {isLoading || switching ? (
                <div className="py-10 w-full flex flex-col gap-y-4 items-center justify-center border-b">
                  <div className="w-10 h-10 border-aellaBlue loader__container" />
                  {!!isLoading && (
                    <p className="text-aellaBlack text-sm font-light">
                      Getting other accounts
                    </p>
                  )}
                </div>
              ) : (
                otherAccounts?.map((organization) => {
                  return (
                    <div
                      key={organization?.id}
                      onClick={() =>
                        handleSwitch(
                          organization?.id,
                          organization?.role?.name,
                          organization?.organization_name
                        )
                      }
                      className="min-w-[290px] px-4 py-6 border-[#EDEFF5] border-b-[1px] flex items-center gap-3 cursor-pointer"
                    >
                      {organization?.organization_logo ? (
                       <img
                       src={organization?.organization_logo || ""}
                       alt="org logo"
                       className="object-cover rounded-[50%]  h-[30px] w-[30px]"
                     />
                      ) : (
                        <div className="rounded-full shrink-0 h-[30px] w-[30px] flex justify-center items-center bg-aellaBlue text-white uppercase">
                          {getFirstLetter(
                            organization?.organization_name || ""
                          )}
                        </div>
                      )}

                      <div className="flex-1">
                        <p className="text-[16px] leading-[16px] font-[400] tracking-[0.03em]">
                          {organization?.organization_name}
                        </p>
                        <p className="text-[14px] leading-[16px] tracking-[0.03em] text-aellaGray pt-1.5 font-[300]">
                          {organization?.role?.name}
                        </p>
                      </div>
                    </div>
                  );
                })
              )}
              <div className=" py-[4px] border-[#EDEFF5] border-b-[1px] gap-4">
                <NavLink
                  className={({ isActive }) =>
                    isActive
                      ? `py-2 px-4 my-2 rounded-lg transition duration-150 ease-in-out  bg-aellaBlue text-white flex items-center cursor-pointer`
                      : `py-2 px-4 my-2 rounded-lg transition duration-150 ease-in-out text-aellaGray  hover:bg-aellaBlue hover:text-white flex items-center cursor-pointer`
                  }
                  to={ROUTES.SETTINGS_PROFILE}
                >
                  <SettingsIcon />
                  <p className="text-[16px] pl-2.5 leading-[18px] tracking-[0.03em] font-[300]">
                    Merchant Settings
                  </p>
                </NavLink>
                {(role === "Super Admin" || role === "Admin") && (
                  <NavLink
                    className={({ isActive }) =>
                      isActive
                        ? `py-2 px-4 my-2 rounded-lg transition duration-150 ease-in-out  bg-aellaBlue text-white flex items-center cursor-pointer`
                        : `py-2 px-4 my-2 rounded-lg transition duration-150 ease-in-out text-aellaGray  hover:bg-aellaBlue hover:text-white flex items-center cursor-pointer`
                    }
                    to={ROUTES.CONNECT_SERVICES}
                  >
                    <ConnectIcon />
                    <p className="text-[16px] pl-2.5 leading-[18px] tracking-[0.03em] font-[300]">
                      Connect more services
                    </p>
                  </NavLink>
                )}
                {(role === "Super Admin" || role === "Admin") && (
                  <NavLink
                    className={({ isActive }) =>
                      isActive
                        ? `py-2 px-4 my-2 rounded-lg transition duration-150 ease-in-out  bg-aellaBlue text-white flex items-center cursor-pointer`
                        : `py-2 px-4 my-2 rounded-lg transition duration-150 ease-in-out text-aellaGray  hover:bg-aellaBlue hover:text-white flex items-center cursor-pointer`
                    }
                    to={ROUTES.SETTINGS_MANAGE_TEAM}
                  >
                    <InviteIcon />
                    <p className="text-[16px] pl-2.5 leading-[18px] tracking-[0.03em] font-[300]">
                      Invite Team
                    </p>
                  </NavLink>
                )}
              </div>
              <div
                className=" cursor-pointer rounded-lg py-2 px-4 transition duration-150 ease-in-out hover:bg-aellaBlue hover:text-white focus:outline-none"
                onClick={() => handleLogout()}
              >
                <div className="w-full flex items-center ">
                  <div className="pr-2.5">
                    <LogoutIcon />
                  </div>
                  <p className="text-[14px] leading-[17px] tracking-[0.2px] font-[200] hover:text-white text-aellaGray">
                    Logout
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}
