import { ReactComponent as FailureIcon } from "../../assets/images/svg/failed-transfer.svg";
import BlueButton from "../../component/Button/BlueButton";

export const ErrorMessage = ({ onClick }: { onClick: () => void }) => {
  return (
    <div className="flex flex-col justify-between items-center py-20">
      <FailureIcon />
      <p className="text-aellaBlack text-[20px] md:text-[24px] mt-6 text-center">
        Oops..
      </p>
      <p className="text-[#5b5b5b] font-light text-center mt-3">
        An error occurred while fetching data
      </p>
      <BlueButton className="mt-8 mb-4" title="Try Again" onClick={onClick} />
    </div>
  );
};
