import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData } from "../apis/apiMethods";
import CONFIG from "../helpers/config";
import { toast } from "react-toastify";

export const getWalletDetails = async ({ queryKey }: { queryKey: any }) => {
  const { email_or_phone } = queryKey[1];
  let queryParams = `/${email_or_phone}`;
  const { data } = await getData(
    `${CONFIG.BASE_URL1}${apiEndpoints.GET_WALLET_DETAILS}${queryParams}`
  );
  return data;
};

export const useWalletDetails = ({
  email_or_phone,
}: {
  email_or_phone: string;
}) => {
  return useQuery(["getWalletDetails", { email_or_phone }], getWalletDetails, {
    onError: (error: any) => {
      if (
        error?.response?.data?.message ===
        "The user you are trying to find needs to verify their BVN"
      ) {
        toast.error(
          "The user you are trying to find needs to verify their BVN"
        );
      }
    },
    enabled: email_or_phone?.length > 5 ? true : false,
    refetchOnWindowFocus: false,
    retry: false,
  });
};
