import { format } from "date-fns";

import arrowUpIcon from "../../../assets/images/svg/black-arrow-up-icon.svg";
import { formatMoney } from "../../../helpers/formatMoney";
import { Loan } from "../../../interfaces/employee-loans";

const ListItem = ({
  name,
  value,
}: {
  name: string;
  value: string | number;
}) => {
  return (
    <div className="flex gap-x-4 justify-between full font-light">
      <p className="text-aellaGray max-w-[35%]">{name}</p>
      <p className="text-[#232323] text-right max-w-[60%]">{value}</p>
    </div>
  );
};

export const LoanSummary = ({ loan }: { loan: Loan }) => {
  return (
    <div className="flex flex-col border rounded-[6px] border-[#EDF2FF] py-6 mt-8">
      <div className="flex justify-between px-4 pb-7 items-top border-b border-[#EDF2FF]">
        <p className="font-light text-[18px] text[#172B4D] leading-[19px]">
          Loan Summary
        </p>
        <div className="h-[20px] w-[20px] rotate-180">
          <img src={arrowUpIcon} alt="" />
        </div>
      </div>

      <div className="px-4 pt-8 pb-4 flex flex-col gap-y-4">
        <ListItem
          name="Loan Start Date"
          value={
            loan?.start_date
              ? format(new Date(loan?.start_date), "dd MMM, yyyy")
              : "N/A"
          }
        />
        <ListItem name="Purpose" value={loan?.loan_purpose}  />
        <ListItem name="Interest Rate" value={`${loan?.interest_rate ?? 0}%`} />
        <ListItem
          name="To repay"
          value={`₦${formatMoney(loan?.to_repay ?? 0)}`}
        />
        <ListItem
          name="To repay this month"
          value={`₦${formatMoney(loan?.to_repay_this_month ?? 0)}`}
        />
        <ListItem
          name="Next due date"
          value={
            loan?.next_due_date
              ? format(new Date(loan?.next_due_date), "dd MMM, yyyy")
              : "N/A"
          }
        />
        <ListItem
          name="Next end date"
          value={
            loan?.end_date
              ? format(new Date(loan?.end_date), "dd MMM, yyyy")
              : "N/A"
          }
        />

        <ListItem
          name="Total repayment"
          value={`₦${formatMoney(loan?.total_repayment ?? 0)}`}
        />
      </div>
    </div>
  );
};
