import { useState, Fragment, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Menu, Transition } from "@headlessui/react";
import { format, formatDistance } from "date-fns";
import { useMutation, useQueries } from "react-query";

import { InnerTab } from "../../component/Tab/Tab.component";
import updateIcon from "../../assets/images/svg/update-icon.svg";
import markAsInactive from "../../assets/images/svg/mark-as-inactive.svg";
import deleteEmployee from "../../assets/images/svg/delete-employee.svg";
import { ReactComponent as BackIcon } from "../../assets/images/svg/back-icon.svg";
import { ReactComponent as DropDownIcon } from "../../assets/images/svg/white-dropdown-icon.svg";
import { useLoansContext } from "./EmployeeLoans";
import {
  deleteEmployeesFn,
  getEmployeeByIdFn,
  getEmployeeLoanHistoryFn,
  getLoanSummaryFn,
  suspendEmployeeFn,
} from "./queries";
import { EmployeeInfo } from "./Tabs/EmployeeInfo";
import { getMultiWordFirstLetters } from "../../helpers/others";
import { attachClassNameToTag, attachClassToLoanStatus, attachNameToLoanStatus } from "../../helpers/attachClassNameToTag";
import { LoanHistory } from "./Tabs/LoanHistory";
import { LoanDetailsModal } from "./Modal/LoanDetailsModal";
import { truncateString } from "../../helpers/formatter";
import { toast } from "react-toastify";
import { errorHandler } from "../../helpers/errorHandler";
import { EditEmployeeDetailsModal } from "./Modal/EditEmployeeDetailsModal";
import { ErrorMessage } from "./ErrorMessage";
import { RemoveSingleEmployee } from "./Modal/RemoveItemModal";
import { getFromLocalStorage } from "../../helpers/localStorage";
import { LOCAL_STORAGE_KEYS } from "../../helpers/localStorageKeys";
import { formatAmount } from "../../helpers/formatMoney";
import { FilterEmployeeLoanModal } from "./Modal/FilterEmployeeLoanModal";

const EmployeeDetails = () => {
  const merchantId = getFromLocalStorage(LOCAL_STORAGE_KEYS.MERCHANTID);
  const role = getFromLocalStorage(LOCAL_STORAGE_KEYS.ROLE);
  const canEdit =
  role === "Super Admin" || role === "Admin" || role === "Manager";
  const { updateContext } = useLoansContext();
  const { state: locationState } = useLocation();
  const navigate = useNavigate();
  const { data } = Object(locationState) as {
    data: any;
  };
  const perPage = 20;
  const [filterObj, setFilterObj] = useState(null);
  const [activeTab, setActiveTab] = useState("Employee Information");
  const [selectedLoan, setSelectedLoan] = useState(null);
  const [state, setState] = useState({
    currentPage: 1,
    searchParam: "",
    searchTerm: "",
    loanDetailsModal: false,

    editModal: false,
    deleteModal: false,
    filterModal: false,
  });

  const updateState = (payload) => {
    setState((prev) => ({ ...prev, ...payload }));
  };

  useEffect(() => {
    updateContext({
      showHeader: false,
    });
  }, []);

  const [employeeData, employeeLoanHistory, loanSummaryData] = useQueries([
    {
      queryKey: ["employee", data?.id],
      queryFn: () =>
        getEmployeeByIdFn({
          id: data?.id,
        }),
      enabled: !!data?.id,
      refetchOnWindowFocus: false,
    },
    {
      queryKey: [
        "employee loans history",
        data?.staff_id,
        state?.currentPage,
        state?.searchParam,
        filterObj,
      ],
      queryFn: () =>
        getEmployeeLoanHistoryFn({
          page: state?.currentPage,
          perPage,
          staffId: data?.staff_id,
          keyword: state?.searchParam,
          startDate: filterObj?.start
            ? format(new Date(filterObj?.start), "yyyy-MM-dd")
            : null,
          endDate: filterObj?.end
            ? format(new Date(filterObj?.end), "yyyy-MM-dd")
            : null,
          minAmount: filterObj?.startAmount,
          maxAmount: filterObj?.endAmount,
        }),
      enabled: !!data?.staff_id,
      refetchOnWindowFocus: false,
    },
    {
      queryKey: ["employee loans summary", merchantId, data?.staff_id],
      queryFn: () =>
        getLoanSummaryFn({
          key: merchantId,
          value: data?.staff_id,
        }),
      enabled: !!(merchantId && data?.staff_id),
      refetchOnWindowFocus: false,
    },
  ]);

  const { created_at, full_name, id, statusName } =
    employeeData?.data?.data ?? {};

  const { recentLoan, totalLoanSum, loanRequest } =
    loanSummaryData?.data?.data || {};

  const options = [
    {
      icon: updateIcon,
      name: "Update Employment Details",
    },
    {
      icon: markAsInactive,
      name: `${
        statusName === "Active" ? "Mark as inactive" : "Mark as active"
      }`,
    },
    {
      icon: deleteEmployee,
      name: "Delete Employee",
    },
  ];

  const { mutateAsync, isLoading: isDeleting } = useMutation({
    mutationFn: deleteEmployeesFn,
    onSuccess: (data) => {
      updateState({
        deleteModal: false,
      });
      toast.success(data?.message);
      navigate(-1);
    },
    onError: (error) => {
      toast.error(errorHandler(error));
    },
  });

  const { isLoading: isSuspending, mutateAsync: suspendEmployeeAsync } =
    useMutation({
      mutationFn: suspendEmployeeFn,
      onSuccess: (data) => {
        toast.success("Employee's status updated successfully");
        employeeData?.refetch();
      },
      onError: (error) => {
        toast.error(errorHandler(error));
      },
    });

  const handleDelete = () => {
    try {
      mutateAsync({
        payload: { employees_id: [data?.id] },
      });
    } catch (error) {}
  };

  const handleStatus = async () => {
    try {
      await suspendEmployeeAsync({ id: data?.id });
    } catch (error) {}
  };

  const handleClick = (e) => {
    if (e === "Delete Employee") updateState({ deleteModal: true });

    if (e === "Mark as inactive" || e === "Mark as active") handleStatus();

    if (e === "Update Employment Details")
      updateState({
        editModal: true,
      });
  };

  if (employeeData?.isFetching) {
    return (
      <div className="bg-aellaDimGray h-screen w-full flex items-center justify-center">
        <div className="w-10 h-10 border-aellaBlue loader__container" />
      </div>
    );
  }

  if (employeeData?.isError)
    return (
      <div className="h-[90vh] w-full flex justify-center items-center">
        <ErrorMessage onClick={() => employeeData?.refetch()} />
      </div>
    );

  return (
    <div className="bg-aellaDimGray">
      <div className="flex items-center py-[12px] lg:py-[14px] sticky top-0 right-0 justify-between px-4 lg:px-10 bg-white border-y border-[#EDEFF5]">
        <button
          type="button"
          onKeyDown={() => {}}
          className="flex items-center cursor-pointer"
          onClick={() => {
            navigate(-1);
            updateContext({
              showHeader: true,
            });
          }}
        >
          <BackIcon />
          <span className="pl-[10px] text-[18px] font-[100] lg:leading-[30px] tracking-[0.3px]">
            Back
          </span>
        </button>

        {canEdit &&<Menu as="div" className="relative inline-block">
          <div>
            <Menu.Button className="inline-flex w-full items-center rounded bg-aellaBlue px-3 py-3 text-sm font-[300] text-white tracking-[0.02] hover:bg-opacity-80 focus:outline-none disabled:bg-opacity-30">
              Action
              <div className="w-[20px] h-[16px] ml-2">
                <DropDownIcon />
              </div>
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right p-2 rounded-md bg-white shadow-md ring-1 ring-black ring-opacity-5 focus:outline-none ">
              {options?.map((option) => (
                <Menu.Item key={option.icon} as={Fragment}>
                  {({ active }) => (
                    <button
                      disabled={isDeleting}
                      onClick={() => handleClick(option.name)}
                      className={`${
                        active
                          ? "bg-aellaBlue text-white rounded"
                          : "text-aellaGray"
                      } px-[11px] w-full py-2 text-sm flex items-center cursor-pointer font-light`}
                    >
                      <div className="pr-2.5">
                        <img src={option.icon} alt="" className="" />
                      </div>
                      {option.name}
                    </button>
                  )}
                </Menu.Item>
              ))}
            </Menu.Items>
          </Transition>
        </Menu>}
      </div>

      <div className="flex-1 overflow-y-auto overflow-x-hidden flex flex-col lg:flex-row items-start justify-between my-10 px-4 lg:px-10 w-full">
        <div className="w-full lg:w-[35%]">
          <div className="py-4 px-4 w-full bg-white rounded-lg border border-[#EDEFF5] mr-6 flex items-center">
            <div className="rounded-full inline-flex shrink-0 bg-[#E5B7A0] w-[56px] h-[56px] items-center justify-center top-6  cursor-pointer mr-1 text-aellaBlue text-[18px]">
              {getMultiWordFirstLetters(full_name)}
            </div>
            <div className="ml-4">
              <p className="text-[16px] text-aellaBlack leading[19px] font-[300] tracking-[0.2px] capitalize">
                {full_name}
              </p>
              <p className="text-sm text-aellaBlue font-light">
                Employee ID: {id}
              </p>
            </div>
          </div>

          <div className="bg-white py-4 px-4 mt-4 rounded-lg border-[#EDEFF5] border w-full ">
            <p className="text-[16px] leading[20px] font-[400] py-4 border-b border-[#EDEFF5]">
              ACCOUNT INFO
            </p>
            <div className="pt-4">
              <div className="flex items-center justify-between">
                <p className="text-sm text-[#7B7B7B] font-light">
                  Account Status
                </p>
                <p
                  className={`text-[14px] leading[17px] font-[200] tracking-[0.2px] pl-2 ${attachClassNameToTag(
                    statusName
                  )}`}
                >
                  {statusName}
                </p>
              </div>
              <div className="flex items-center justify-between mt-4">
                <p className="text-sm text-[#7B7B7B] font-light">
                  Date Created
                </p>
                <p className="text-[14px] leading[17px] font-[200] tracking-[0.2px] text-[#232323] pl-2">
                  {created_at
                    ? format(new Date(created_at), "MMM. dd, yyyy")
                    : null}
                </p>
              </div>
            </div>
          </div>
          <div className="bg-white py-4 px-4 mt-4 rounded-lg border-[#EDEFF5] border w-full ">
            <h1 className="text-[16px] leading[20px] font-[400] py-4 border-b border-[#EDEFF5]">
              LOAN SUMMARY
            </h1>
            <div className="py-4 mt-4 px-4 bg-[#F5F7F9]">
              <div className="flex items-center justify-between">
                <p className="text-[14px] leading[17px] font-[200] tracking-[0.2px] text-aellaGray">
                  Status:
                </p>

                <p className={`text-[14px] leading[17px] font-[400] tracking-[0.4px] flex-wrap text-end ${attachClassToLoanStatus(recentLoan?.status)} `}>
                  {attachNameToLoanStatus(recentLoan?.status)}
                </p>
              </div>
              <div className="flex items-center justify-between mt-[17px]">
                <p className="text-[14px] leading[17px] font-[200] tracking-[0.2px] text-aellaGray">
                  Loan Amount:
                </p>

                <p className="text-[14px] leading[17px] font-[400] tracking-[0.4px] text-[#232323]">
                  ₦{formatAmount(recentLoan?.amount ?? 0)}
                </p>
              </div>
              <div className="flex items-center justify-between mt-[17px]">
                <p className="text-[14px] leading[17px] font-[200] tracking-[0.2px] text-aellaGray">
                  Amount Paid:
                </p>

                <p className="text-[14px] leading[17px] font-[400] tracking-[0.4px] text-[#232323]">
                  ₦{formatAmount(recentLoan?.amount_paid ?? 0)}
                </p>
              </div>
              <div className="flex items-center justify-between mt-[17px]">
                <p className="text-[14px] leading[17px] font-[200] tracking-[0.2px] text-aellaGray">
                  Amount to repay:
                </p>

                <p className="text-[14px] leading[17px] font-[400] tracking-[0.4px] [text-[#232323]]">
                  ₦{formatAmount(recentLoan?.to_repay ?? 0)}
                </p>
              </div>
            </div>
          </div>

          <div className="bg-white py-4 px-4 mt-4 rounded-[12px] border-[#EDEFF5] border w-full ">
            <h1 className="text-[16px] py-4 text-[#7B7B7B]">
              TOTAL LOAN COLLECTED
            </h1>
            <p className="text-[32px] tracking-[0.2px] text-aellaBlack font-[400]">
              ₦{formatAmount(totalLoanSum ?? 0)}
            </p>
          </div>
          <div className="bg-white py-4 px-4 mt-4 rounded-[12px] border-[#EDEFF5] border w-full ">
            <h1 className="text-[16px] py-4 text-[#7B7B7B]">
              TOTAL NUMBER OF LOAN REQUESTS
            </h1>
            <p className="text-[32px] tracking-[0.2px] text-aellaBlack font-[400]">
              {loanRequest ?? 0}
            </p>
          </div>
        </div>
        <div className="lg:w-[60%] w-full mt-3 lg:mt-0 justify-self-end mr-10">
          <div className="w-full bg-white rounded-t-lg px-0 lg:px-6 pt-2">
            <div className="border-b border-[#C5D4F6] pb-[1px] w-full">
              <div className="flex gap-x-3 flex-wrap">
                <InnerTab
                  text="Employee Information"
                  id="Employee Information"
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                />
                <InnerTab
                  text="Loan History"
                  id="Loan History"
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                />
              </div>
            </div>
          </div>
          <div className="w-full py-[17.5px] px-3 lg:px-6 bg-[#EDF2FF]">
            <p className="text-[16px] leading[16px] font-[400] uppercase">
              {activeTab}
            </p>
          </div>
          <div className="bg-white px-3 lg:px-6 pt-[24px] rounded-b-lg">
            {activeTab === "Employee Information" ? (
              <EmployeeInfo employee={employeeData?.data?.data} />
            ) : (
              <LoanHistory
                loanHistoryData={employeeLoanHistory}
                updateState={updateState}
                state={state}
                setSelectedLoan={setSelectedLoan}
                postsPerPage={perPage}
                setFilterObj={setFilterObj}
              />
            )}
          </div>
        </div>
      </div>
      <LoanDetailsModal
        showModal={state.loanDetailsModal}
        closeModal={() => {
          updateState({
            loanDetailsModal: false,
          });
        }}
        loan={selectedLoan}
      />

      <EditEmployeeDetailsModal
        showModal={state.editModal}
        closeModal={() =>
          updateState({
            editModal: false,
          })
        }
        employee={employeeData?.data?.data}
      />

      <RemoveSingleEmployee
        showModal={state?.deleteModal}
        closeModal={() =>
          updateState({
            deleteModal: false,
          })
        }
        deleteUser={handleDelete}
        isDeleting={isDeleting}
      />

      <FilterEmployeeLoanModal
        updateState={updateState}
        showModal={state.filterModal}
        closeModal={() => updateState({ filterModal: false })}
        setFilterObj={setFilterObj}
      />
    </div>
  );
};

export default EmployeeDetails;
