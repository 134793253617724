import logIcon from "../../../assets/images/svg/activitylog-icon.svg";
import { getStatusName } from "../../../helpers/attachClassNameToTag";
import filterIcon from "../../../assets/images/svg/filter-icon.svg";
import searchIcon from "../../../assets/images/svg/search-icon.svg";
import { format, formatDistance } from "date-fns";
import DatePicker from "react-datepicker";
import { useState } from "react";
import { ModalContainer } from "../../../modal/ModalContainer";
import { ReactComponent as CloseIcon } from "../../../assets/images/svg/white-close-modal.svg";
import calendarIcon from "../../../assets/images/svg/calendar.svg";
import BlueButton from "../../../component/Button/BlueButton";
import { toast } from "react-toastify";
import { SecondaryLoader } from "../../../component/Loader/SecondaryLoader";

export default function SubAccountActivities({
  activityLog,
  filter,
  setFilter,
  showModal,
  setShowModal,
  isLoading,
}: {
  activityLog: any;
  filter?: any;
  setFilter?: any;
  showModal?: any;
  setShowModal?: any;
  isLoading: boolean;
}) {
  const [start, setStart] = useState<Date | undefined>(filter.startDate);
  const [end, setEnd] = useState<Date | undefined>(filter.endDate);

  return (
    <>
      <div className="w-full px-6  bg-white ">
        <div className="flex justify-end">
          <div className="relative w-full lg:w-[190px]">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <img className="w-4" src={searchIcon} alt="" />
            </div>
            <input
              type="text"
              placeholder="Search"
              className="placeholder:font-thin border border-[#EDEFF5] text-[#5B5B5B] text-sm rounded pl-10 py-3 focus:outline-none "
              onChange={(e) => setFilter({ ...filter, query: e.target.value })}
            />
          </div>
          <div
            className="w-[150px] flex justify-center items-center ml-4 h-12 border bg-[#F5F5F5] px-2 py-2 cursor-pointer rounded xxs:justify-center md:justify-around active:shadow-md transition duration-150 ease-in-out"
            onClick={(e: any) => {
              if (isLoading) {
                e.stoPropogation();
              } else {
                if (filter?.startDate) {
                  setStart(new Date(filter?.startDate));
                }
                if (filter?.endDate) {
                  setEnd(new Date(filter?.endDate));
                }
                setShowModal(true);
              }
            }}
          >
            <div className="xxs:px-1 md:px-2 py-1 h-[24px] flex items-center">
              <img
                className="object-contain w-[18px] h-[18px]"
                src={filterIcon}
                alt=""
              />
              <span className="ml-[12px] font-thin  text-gray-400  text-sm focus:outline-none focus:ring-0">
                Filter By
              </span>
            </div>
          </div>
        </div>
        {isLoading ? (
          <SecondaryLoader count={10} />
        ) : (
          <div className="w-full overflow-x-auto mt-6">
            {activityLog?.data?.length > 0 ? (
              activityLog?.data?.map((activity: any) => {
                const activityDetail = activity?.details
                  ? JSON.parse(activity?.details)
                  : "";

                return (
                  <div className="w-full flex gap-3" key={activity?.id}>
                    <div className="flex flex-col items-center">
                      <div className="w-10 h-10 flex justify-center items-center rounded-full bg-gray-100">
                        <img src={logIcon} alt="" width={30} height={30} />
                      </div>
                      <div className="w-[1px] flex-1 bg-black" />
                    </div>
                    <div className="pt-2 w-full pb-3">
                      <div>
                        <p className="tracking-[0.2px] text-[16px] leading-[19px] font-[400] capitalize">
                          {activity?.action}
                        </p>
                        <p className="tracking-[0.2px] text-[14px] leading-[17px] font-[350] mt-1 flex items-center">
                          <span className="opacity-70">Status:</span>
                          <span
                            className={`${
                              activity?.status.toLowerCase() === "successful"
                                ? "text-[#14804A]"
                                : "text-[#EB5757]"
                            } ml-2`}
                          >
                            {getStatusName(activity?.status)}
                          </span>
                          <p className="rounded-full w-1 h-1 ml-2 mr-2 bg-aellaGray "></p>

                          <span
                            className={`text-[#232323] tracking-[0.2px] text-[14px] leading-[17px] font-[250]`}
                          >
                            {activity?.user?.user?.type}:{" "}
                            {activity?.user?.user?.first_name}
                          </span>
                          <p className="rounded-full w-1 h-1 ml-2 mr-2 bg-aellaGray "></p>
                          <span
                            className={`text-aellaGray tracking-[0.2px] text-[14px] leading-[17px] font-[350]`}
                          >
                            {" "}
                            {formatDistance(
                              new Date(activity?.created_at),
                              new Date(),
                              {
                                addSuffix: true,
                              }
                            )}{" "}
                            |{" "}
                            {format(new Date(activity?.created_at), "hh:mm 	a")}
                          </span>
                        </p>

                        <div className="w-[90%] bg-[#EDEFF5] p-3 rounded-[5px] mt-4">
                          {activityDetail &&
                            Object.entries(activityDetail).map(
                              ([key, value]: any) => (
                                <div className="w-full tracking-[0.03em] text-aellaGray text-[14px] leading-[17px] font-[400] mt-4 flex flex-col gap-y-4 last:mb-4">
                                  <div className="w-full flex flex-wrap items-start gap-3">
                                    <p className="w-[45%]">{key}:</p>
                                    <p className="flex-1 ">{value}</p>
                                  </div>
                                </div>
                              )
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="py-20 flex items-center justify-center text-center">
                No activity available
              </div>
            )}
          </div>
        )}
      </div>

      <ModalContainer
        showModal={showModal}
        closeModal={() => {
          setShowModal(false);
          setFilter({});
        }}
      >
        <div className="w-full lg:w-[40vw] relative h-auto lg:max-h-[85vh] z-50 text-aellaBlack flex flex-col rounded-[8px] bg-white py-8 px-6">
          <div
            onClick={() => {
              setShowModal(false);
            }}
            className="absolute -top-8 -right-0 cursor-pointer"
          >
            <CloseIcon />
          </div>
          <p className="text-aellaBlack text-[20px]">Filter by: </p>
          <p className="pb-2 text-aellaBlack lg:leading-[16px] text-[16px] font-[300] mt-6">
            Date Range:
          </p>
          <div className="w-full flex  justify-between items-center">
            <div className="relative w-[45%]">
              <DatePicker
                dateFormat="yyyy-MM-dd"
                placeholderText={"Start Date"}
                selected={start}
                maxDate={new Date()}
                onChange={(date: Date) => {
                  setStart(date);
                }}
                locale="en-GB"
                className="h-11 w-full placeholder:text-gray-400 border border-gray-300 rounded-lg pl-2 dark:border-gray-600/30 text-aellaBlack lg:leading-[16px] text-[16px] font-[300]"
              />
              <div className="absolute h-11 inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                <img className="w-3  z-20" src={calendarIcon} alt="" />
              </div>
            </div>

            <div className="relative w-[45%]">
              <DatePicker
                dateFormat="yyyy-MM-dd"
                placeholderText={"End Date"}
                selected={end}
                minDate={start}
                maxDate={new Date()}
                onChange={(date: Date) => {
                  setEnd(date);
                }}
                className="h-11 w-full placeholder:text-gray-400 border border-gray-300 rounded-lg pl-2 dark:border-gray-600/30 text-aellaBlack lg:leading-[16px] text-[16px] font-[300]"
              />
              <div className="absolute h-11 inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                <img className="w-3  z-20" src={calendarIcon} alt="" />
              </div>
            </div>
          </div>

          <div className="flex gap-x-4 justify-end mt-6">
            <button
              onClick={() => {
                setShowModal(false);
                setFilter({ ...filter, startDate: "", endDate: "" });
                setStart(undefined);
                setEnd(undefined);
              }}
              className="border-aellaBlue border rounded h-10 cursor-pointer text-center text-aellaBlue  w-[121px] "
            >
              Reset
            </button>
            <BlueButton
              // disabled={exporting}
              onClick={() => {
                if (!(start && end)) {
                  toast.error("Please select date range");
                  return;
                }

                setFilter({ ...filter, startDate: start, endDate: end });

                setShowModal(false);
                setStart(undefined);
                setEnd(undefined);
              }}
              // title={exporting ? "Exporting" : "Export CSV"}
              title="Filter"
              className="h-10 font-light self-end w-[121px] flex items-center justify-center"
            />
          </div>
        </div>
      </ModalContainer>
    </>
  );
}
