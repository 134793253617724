import { useState } from "react";
import BusinessForm from "../FormComponents/BusinessForm";
import PersonalForm from "../FormComponents/PersonalForm";
import { getFromLocalStorage } from "../../../helpers/localStorage";
import { LOCAL_STORAGE_KEYS } from "../../../helpers/localStorageKeys";
import { useMerchantDetails } from "../../../hooks/useMerchantDetails";
import CardSkeletonLoader from "../../../component/Skeleton/CardSkeletonLoader.component";
import { useMerchantDocuments } from "../../../hooks/useGetDocuments";
import { TabMenu } from "../Components/TabMenu";

const Profile = () => {
  const [stage, setStage] = useState("business");
  const userId = getFromLocalStorage(LOCAL_STORAGE_KEYS.USERID);

  const {
    data: merchantDetails,
    isLoading: merchantDetailLoading,
    refetch,
  } = useMerchantDetails({ userId });

  const {
    data: merchantDocuments,
    isLoading: merchantDocumenetLoading,
    refetch: refetchDocument,
  } = useMerchantDocuments({ userId });

  const RenderContent = () => {
    switch (stage) {
      case "business":
        return (
          <BusinessForm
            userDocument={merchantDocuments}
            refetchDocument={() => refetchDocument()}
            refetchProfile={() => refetch()}
            userData={merchantDetails}
          />
        );
      case "personal":
        return <PersonalForm userData={merchantDetails} />;
      default:
        return;
    }
  };

  if (merchantDetailLoading || merchantDocumenetLoading) {
    return (
      <div className="flex flex-col md:flex-row justify-between px-10 pt-[32px] lg:pt-[30px]">
        <CardSkeletonLoader width={320} height={458} />
        <CardSkeletonLoader width={320} height={458} />
        <CardSkeletonLoader width={320} height={458} />
      </div>
    );
  }

  return (
    <>
      <div className="border-y border-[#e1dddd] bg-aellaDimGray overflow-x-auto h-screen">
        <div className="bg-white pt-[7px] px-[10px] lg:px-10 overflow-x-auto">
          <div className="flex justify-between bg-white items-center pt-2 pb-[1px] flex-wrap border-t border-[#F1F1F1] w-[200%] xs:w-[165%] sm:w-full">
            <TabMenu />
          </div>
        </div>
        <div className="py-[32px] px-[10px] lg:p-10">
          <div className="bg-white border border-[#EDEFF5] p-[10px] lg:p-10 pt-[30px]">
            <div className="">
              <div className="pb-[12px] border-b border-[#EDEFF5]">
                <div className="mb-[32px] bg-[#F7F8F8] border border-[#EDF2FF] py-[4px] px-[8px] gap-[16px] rounded-[5rem] inline-flex">
                  <div
                    onClick={() => setStage("business")}
                    className={`text-[#232323] font-thin cursor-pointer  ${
                      stage === "business"
                        ? "border-[#ECEDF0] border bg-white shadow-md"
                        : ""
                    }  px-[16px] text-[12px] xs:text-[14px] sm:text-[16px] py-[8px] rounded-[4rem]`}
                  >
                    Business Profile
                  </div>

                  <div
                    onClick={() => setStage("personal")}
                    className={`text-[#232323] font-thin cursor-pointer ${
                      stage === "personal"
                        ? "border-[#ECEDF0] border bg-white shadow-md"
                        : ""
                    }  px-[16px] text-[12px] xs:text-[14px] sm:text-[16px] py-[8px] rounded-[4rem] `}
                  >
                    Personal Profile
                  </div>
                </div>

                <p className="text-[24px] text-[#0B1E4B]">
                  {stage === "business"
                    ? "Business Information"
                    : "Personal Information"}
                </p>
              </div>
            </div>
            <div className="py-[32px]">{RenderContent()}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
