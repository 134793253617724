import { useState } from "react";
import PasswordInput from "../Components/PasswordInput";
import ChangePasswordModal from "../Modal/ChangePasswordModal";
import ChangeTransactionPin from "../Modal/ChangeTransactionPin";
import { TabMenu } from "../Components/TabMenu";
import { getFromLocalStorage } from "../../../helpers/localStorage";
import { LOCAL_STORAGE_KEYS } from "../../../helpers/localStorageKeys";
import { ResetTransactionPin } from "../Modal/ResetTransactionPin";
import { useMutation } from "react-query";
import { initiateResetPinFn } from "../queries";

const Security = () => {
  const role = getFromLocalStorage(LOCAL_STORAGE_KEYS.ROLE);

  const [openPasswordModal, setPasswordOpenModal] = useState(false);
  const [openPinModal, setOpenPinModal] = useState(false);
  const [showResetModal, setShowResetModal] = useState(false);
  const [counter, setCounter] = useState(0);

  const { mutateAsync } = useMutation({
    mutationFn: initiateResetPinFn,
  });

  const handleResetInitiation = async () => {
    setShowResetModal(true);
    setCounter(Date.now() + 60000 * 5)

    try {
      await mutateAsync();
    } catch (error) {}
  };

  return (
    <>
      <div className="border-y border-[#e1dddd] bg-aellaDimGray overflow-x-auto h-screen">
        <div className="bg-white pt-[7px] px-[10px] lg:px-10 overflow-x-auto">
          <div className="flex justify-between bg-white items-center pt-2 pb-[1px] flex-wrap border-t border-[#F1F1F1] w-[200%] xs:w-[165%] sm:w-full">
            <TabMenu />
          </div>
        </div>
        <div className="py-[32px] px-[10px] lg:p-10">
          <div className="bg-white border border-[#EDEFF5] p-[10px] lg:p-10 pt-[30px] min-h-[70vh]">
            <div className="">
              <div className="pb-[12px] border-b border-[#EDEFF5]">
                <p className="text-[24px] text-[#0B1E4B]">Security</p>
              </div>
            </div>

            <div className="w-full lg:w-[65%]">
              <div className="flex flex-col gap-[32px] mt-[32px]">
                <PasswordInput
                  label="Password"
                  value="Password"
                  actionText="Change Password"
                  action={() => setPasswordOpenModal(true)}
                />

                <PasswordInput
                  label="Transaction Pin"
                  value="pin"
                  // actionText={
                  //   role === "Super Admin" ? "change transaction pin" : null
                  // }
                  action={() => setOpenPinModal(true)}
                  actionText={null}
                />
                {role === "Super Admin" && (
                  <div className="-mt-7 text-[12px] font-thin text-[#6A7581]">
                    Click here to{" "}
                    <span
                      onClick={handleResetInitiation}
                      className="font-[400] text-[#2054D2] cursor-pointer"
                    >
                      reset transaction pin
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {openPasswordModal && (
        <ChangePasswordModal
          setCloseModal={setPasswordOpenModal}
          showModal={openPasswordModal}
        />
      )}

      {openPinModal && (
        <ChangeTransactionPin
          showModal={openPinModal}
          setCloseModal={setOpenPinModal}
        />
      )}

      <ResetTransactionPin
        showModal={showResetModal}
        closeModal={() => setShowResetModal(false)}
        counter={counter}
        setCounter={setCounter}
      />
    </>
  );
};

export default Security;
