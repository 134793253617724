import { useState } from "react";
import { errorHandler } from "../../helpers/errorHandler";
import { ReactComponent as NextIcon } from "../../assets/images/svg/right-arrow-icon.svg";
import { Loader } from "../../component/Loader/Loader.component";
import { toast } from "react-toastify";
import { apiEndpoints } from "../../apis/apiEndpoints";
import CONFIG from "../../helpers/config";
import { postData } from "../../apis/apiMethods";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../helpers/routes";
import OtpInput from "react-otp-input";

interface IResetStepThree {
  state: {
    email: string;
    otp: string;
    newPassword: string;
    confirmNewPassword: string;
  };
}

const ResetStepThree: React.FC<IResetStepThree> = ({ state }) => {
  const [loading, setLoading] = useState(false);
  const [pin, setPin] = useState("");
  const navigate = useNavigate();
  const onSubmit = async () => {
    setLoading(true);
    const reqBody = {
      confirm_new_password: state.confirmNewPassword,
      email_or_phone_number: state.email,
      new_password: state.newPassword,
      otp: pin,
    };

    try {
      const res: any = await postData(
        `${CONFIG.BASE_URL1}${apiEndpoints.RESET_PASSWORD}`,
        reqBody
      );
      toast.success(res.message);
      navigate(ROUTES.LOGIN);
    } catch (error) {
      toast.error(errorHandler(error));
    }
    setLoading(false);
  };

  return (
    <>
      <div className="mb-7 pt-6 lg:pt-[150px] pr-[196px]">
        <h3 className="text-[26px] md:text-[32px] lg:leading-[38px] font-[300] pb-2">
          Check your Phone
        </h3>
        <p className="text-aellaGray text-[14px] md:text-[16px] font-[200] lg:leading-[20px]">
          Enter OTP in the form below to change your password
        </p>
      </div>

      <OtpInput
        numInputs={6}
        placeholder="000000"
        onChange={(otp: string) => setPin(otp)}
        isInputNum={true}
        value={pin}
        inputStyle={{
          borderBottom: "1px solid #2054D2",
          marginRight: "13px",
          width: "50px",
          height: "50px",
          paddingLeft: "0px",
        }}
      />
      <button
        type="submit"
        className="bg-aellaBlue disabled:opacity-75 rounded text-white py-4 px-6 items-center w-full md:w-1/2 flex justify-center  cursor-pointer mt-[39px]"
        disabled={loading || pin?.length !== 6}
        onClick={onSubmit}
      >
        Continue
        <span className="pl-2">
          <NextIcon />{" "}
        </span>
        {loading && <Loader />}
      </button>
    </>
  );
};

export default ResetStepThree;
