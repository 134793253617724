import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData } from "../apis/apiMethods";
import CONFIG from "../helpers/config";

export const getTeamDetails = async ({ queryKey }: { queryKey: any }) => {
  const { merchantId } = queryKey[1];
  const { data } = await getData(
    `${CONFIG.BASE_URL2}${apiEndpoints.SINGLE_MEMBER}${merchantId}`
  );
  return data;
};

export const useTeamDetails = ({ merchantId }) => {
  return useQuery(
    ["getTeamDetails", { merchantId }],

    getTeamDetails,
    {
      refetchOnWindowFocus: false, // Refetch on window focus
      enabled: merchantId ? true : false,
      retry: 1,
    }
  );
};
