import { currencyFormat, truncateString } from "../../../helpers/formatter";
import { ReactComponent as NextIcon } from "../../../assets/images/svg/blue-right-arrow-icon.svg";
import { ReactComponent as CalendarIcon } from "../../../assets/images/svg/calendar-icon.svg";
import { ReactComponent as ArrowIcon } from "../../../assets/images/svg/black-arrow-down-icon.svg";
import transactionIcon from "../../../assets/images/svg/transaction-icon.svg";
import {
  attachClassNameToTag,
  getStatusName,
} from "../../../helpers/attachClassNameToTag";
import moment from "moment";
import CardSkeletonLoader from "../../../component/Skeleton/CardSkeletonLoader.component";
import NoDataState, {
  NoListState,
} from "../../../component/NoDataState/NoDataState";
import { useInflowTransactionsSum } from "../../../hooks/useInflowTransactionsSum";
import NoDataIcon from "../../../assets/images/svg/empty-list-icon.svg";
import { useEffect, useState } from "react";
import { SearchStateType } from "../../../interfaces/home";
import DateRangeModal from "../../../modal/DateRangeModal";
import { usePosTransaction } from "../../../hooks/usePosTransaction";
import { usePosContext } from "../Pos";
import { ROUTES } from "../../../helpers/routes";
import { useNavigate, useSearchParams } from "react-router-dom";
export default function Overview() {
  const { walletId, setIsOpen } = usePosContext();
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth();
  const [showDateRangeModal, setShowDateRangeModal] = useState(false);

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams({});

  const [searchState, setSearchState] = useState<SearchStateType>({
    startDateForTrnx: new Date(year, month, 1),
    endDateForTrnx: new Date(),
  });

  const {
    data: allPosTransactionsForTransfer,
    isLoading: allPosTransactionsForTransferLoading,
  } = usePosTransaction({
    page: 1,
    size: 15,
  });
  const {
    data: posSuccessfullTrnxSum,
    isLoading: posSuccessfullTrnxSumLoading,
  } = useInflowTransactionsSum({
    walletID: walletId,
    status: "success",
    startDate: searchState.startDateForTrnx,
    endDate: searchState.endDateForTrnx,
  });

  const { data: posFailedTrnxSum, isLoading: posFailedTrnxSumLoading } =
    useInflowTransactionsSum({
      walletID: walletId,
      status: "failed",
      startDate: searchState.startDateForTrnx,
      endDate: searchState.endDateForTrnx,
    });

  const { data: posPendingTrnxSum, isLoading: posPendingTrnxSumLoading } =
    useInflowTransactionsSum({
      walletID: walletId,
      status: "Pending",
      startDate: searchState.startDateForTrnx,
      endDate: searchState.endDateForTrnx,
    });

  //update the  react-query with the url params
  useEffect(() => {
    const currentParams: any = Object.fromEntries([...searchParams]);

    if (currentParams?.startDateForTrnx || currentParams?.endDateForTrnx) {
      setSearchState({
        startDateForTrnx: new Date(currentParams?.startDateForTrnx),
        endDateForTrnx: new Date(currentParams?.endDateForTrnx),
      });
    }
  }, [searchParams]);

  //update the url params with the initial query
  useEffect(() => {
    const currentParams: any = Object.fromEntries([...searchParams]);

    if (!currentParams?.startDateForTrnx || !currentParams?.endDateForTrnx) {
      setSearchParams({
        startDateForTrnx: moment(searchState.startDateForTrnx).format(
          "YYYY-MM-DD"
        ),
        endDateForTrnx: moment(searchState.endDateForTrnx).format("YYYY-MM-DD"),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchState.startDateForTrnx, searchState.endDateForTrnx, searchParams]);

  if (
    allPosTransactionsForTransferLoading ||
    posSuccessfullTrnxSumLoading ||
    posPendingTrnxSumLoading
  ) {
    return (
      <div className="flex flex-col md:flex-row justify-between px-4 lg:px-10 pt-[32px] lg:pt-[120px]">
        <CardSkeletonLoader width={370} height={458} />
        <CardSkeletonLoader width={370} height={458} />
        <CardSkeletonLoader width={370} height={458} />
      </div>
    );
  }

  return (
    <>
      <div className="bg-aellaDimGray w-full px-4 lg:px-10 py-[32px]">
        {/* <div className="flex flex-col bg-white px-0 lg:px-10 pt-[24px] pb-10">
          <div className="flex flex-col lg:flex-row items-center justify-between pb-[13.5px] w-full border-b border-[#EDEFF5] mb-6">
            <h1 className="text-[24px] lg:leading-[29px] font-[300]  items-center">
              Analytics
            </h1>
            <div
              className="text-[13px] font-[300] pt-4 lg:pl-6 flex items-center cursor-pointer"
              onClick={() => setShowDateRangeModal(true)}
            >
              <span className="py-2 pr-4 lg:leading-[18px] text-aellaGray">
                Showing:
              </span>
              <span className="border rounded py-2 flex flex-wrap px-2.5">
                <CalendarIcon />
                <span className="pl-1 lg:leading-[20px] tracking-[0.2px] pr-1">
                  {moment(searchState.startDateForTrnx).format("DD MMM YYYY")} -{" "}
                  {moment(searchState.endDateForTrnx).format("DD MMM YYYY")}
                </span>
                <div>
                  <ArrowIcon />
                </div>
              </span>
            </div>
          </div>
          <div className="flex flex-col lg:flex-row">
            <div className="flex flex-col py-4 px-6 lg:border-r border-[#EDEFF5] w-full shadow-[0_20px_60px_20px_rgba(197, 212, 246, 0.15)]">
              <h1 className="text-[13px] lg:leading-[16px] font-[300] tracking-[0.03em]">
                TOTAL TRANSACTIONS
              </h1>

              <h1 className="text-[32px] lg:leading-[38px] font-[300] tracking-[-0.2px] pt-[18px]">
                {posSuccessfullTrnxSum?.total_amount ||
                posFailedTrnxSum?.total_amount ||
                posPendingTrnxSum?.total_amount
                  ? currencyFormat(
                      posSuccessfullTrnxSum?.total_amount +
                        posFailedTrnxSum?.total_amount +
                        posPendingTrnxSum?.total_amount
                    )
                  : currencyFormat(0)}
              </h1>
              <p className="text-[13px]  lg:leading-[20px] font-[200] text-aellaGray pt-2 pb-[30px]">
                This is the total amount of transactions made.
              </p>
            </div>
            <div className="flex flex-col py-4 px-6 lg:border-r border-[#EDEFF5] w-full">
              <h1 className="text-[13px] lg:leading-[16px] font-[300] tracking-[0.03em]">
                TOTAL TRANSACTION APPROVED
              </h1>

              <h1 className="text-[32px] lg:leading-[38px] font-[300] tracking-[-0.2px] pt-[18px]">
                {posSuccessfullTrnxSum?.total_amount
                  ? currencyFormat(posSuccessfullTrnxSum?.total_amount)
                  : currencyFormat(0)}
              </h1>
              <p className="text-[13px]  lg:leading-[20px] font-[200] text-aellaGray pt-2 pb-[30px]">
                This is the total amount of transactions that have been
                approved.
              </p>
            </div>
            <div className="flex flex-col py-4 px-6 w-full">
              <h1 className="text-[13px] lg:leading-[16px] font-[300] tracking-[0.03em]">
                TOTAL TRANSACTION DECLINED
              </h1>

              <h1 className="text-[32px] lg:leading-[38px] font-[300] tracking-[-0.2px] pt-[18px]">
                {posFailedTrnxSum?.total_amount
                  ? currencyFormat(posFailedTrnxSum?.total_amount)
                  : currencyFormat(0)}
              </h1>
              <p className="text-[13px]  lg:leading-[20px] font-[200] text-aellaGray pt-2 pb-[30px]">
                This is the total amount of transactions that have been
                declined.
              </p>
            </div>
          </div>
        </div> */}
        <div className="py-6 border border-aellaBorderGray  bg-white mb-5">
          <div className="flex justify-between items-center pb-[18px] px-6 lg:px-10 ">
            <h1 className="text-[16px] lg:text-[24px] lg:leading-[29px] font-[350]">
              Transaction History
            </h1>
            {allPosTransactionsForTransfer?.data?.transactions?.length > 0 && (
              <div
                onClick={() => navigate(`${ROUTES.POS}${ROUTES.TRANSACTIONS}`)}
              >
                <h2 className="text-[12px] lg:text-[16px] lg:leading-[20px] font-[350] flex items-center text-aellaBlue cursor-pointer">
                  See All
                  <NextIcon />
                </h2>
              </div>
            )}
          </div>
          <div className="pt-10 border-t border-[#F1F1F1] px-6 lg:px-10">
            <div className="overflow-x-auto">
              {allPosTransactionsForTransfer?.data?.transactions?.length ===
              0 ? (
                <NoListState
                  icon={NoDataIcon}
                  textHeader="No transactions"
                  firstParaText="There is no history of transactions because you do not have a POS Machine."
                  secondParaText="Click the button below to request for a POS Machine."
                  title="Request POS"
                  onClick={() => setIsOpen(true)}
                />
              ) : (
                <div className="">
                  <div className="flex items-center bg-[#F7F9FC] w-[400%] gap-x-4 overflow-x-auto md:overflow-x-hidden md:gap-x-0 md:w-full px-4 h-10 text-aellaBlack text-sm border-b">
                    <p className="w-[15%]">Terminal ID</p>
                    <p className="w-[20%]">Customer Name</p>
                    <p className="w-[20%]">Amount</p>
                    {/* <p className="w-[10%] text-center"> Fee</p>
                    <p className="w-[25%] text-center"> Net Amount</p> */}
                    <p className="w-[20%] text-center"> Status</p>
                    <p className="w-[15%]">Reference</p>
                    <p className="w-[20%] text-right">Date</p>
                  </div>

                  {allPosTransactionsForTransfer?.data?.transactions?.map(
                    (trxn: any) => {
                      return (
                        <div
                          key={trxn?.id}
                          className="flex items-center bg-white w-[400%] gap-x-4 overflow-x-auto md:overflow-x-hidden md:gap-x-0 md:w-full p-4 text-[#5B5B5B] text-sm font-light border-b"
                        >
                          <p className="w-[15%]">
                            {truncateString(trxn?.terminal_id, 7)}
                          </p>
                          <p className="w-[20%] capitalize">
                            {truncateString(trxn?.card_holder, 8)}
                          </p>
                          <p className="w-[20%]">
                            {trxn?.amount ? currencyFormat(trxn?.amount) : "-"}
                          </p>
                          {/* <p className="w-[10%] text-center">
                            {trxn?.fee ? currencyFormat(trxn?.fee) : "-"}
                          </p>
                          <p className="w-[25%] text-center">
                            {trxn?.amount_funded
                              ? currencyFormat(trxn?.amount_funded)
                              : "-"}
                          </p> */}

                          <p className={`w-[20%] text-center`}>
                            <span
                              className={`w-full text-center ${attachClassNameToTag(
                                trxn?.status
                              )} `}
                            >
                              {getStatusName(trxn?.status)}
                            </span>
                          </p>

                          <p className="w-[15%]">
                            {truncateString(trxn?.transaction_reference, 8)}
                          </p>
                          <p className="w-[20%] text-right">
                            {trxn?.paid_at
                              ? moment(trxn?.paid_at).format("MMM. DD, YYYY")
                              : "-"}
                          </p>
                        </div>
                      );
                    }
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {showDateRangeModal && (
        <DateRangeModal
          startDate={searchState.startDateForTrnx}
          setSearchState={setSearchState}
          endDate={searchState.endDateForTrnx}
          searchState={searchState}
          setCloseModal={setShowDateRangeModal}
          startDateName="startDateForTrnx"
          endDateName="endDateForTrnx"
          showModal={showDateRangeModal}
        />
      )}
    </>
  );
}
