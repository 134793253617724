import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData } from "../apis/apiMethods";
import CONFIG from "../helpers/config";

export const getAllWithdrawalBanks = async () => {
  const { data } = await getData(
    `${CONFIG.BASE_URL2}${apiEndpoints.GET_WITHDRAWAL_BANKS}`
  );
  return data;
};

export const useAllWithdrawalBanks = () => {
  return useQuery(
    "getAllWithdrawalBanks",

    getAllWithdrawalBanks,
    {
      refetchOnWindowFocus: false, // Refetch on window focus
      retry: 1,
    }
  );
};
