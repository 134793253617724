import { apiEndpoints } from "../../apis/apiEndpoints";
import { deleteData, getData, postData, putData } from "../../apis/apiMethods";
import CONFIG from "../../helpers/config";
import {
  ApproveLoansPayloadType,
  BareResponse,
  ChangePendingLoanStatusResponse,
  CreateEmployeePayload,
  DeclineLoansPayloadType,
  ExportEmployerLoansPayload,
  IDeleteEmployeesPayload,
  IDeleteEmployeesResponse,
  IDownloadTemplate,
  IGetBanks,
  IGetEmployeeLoanDetails,
  IGetEmployeeLoanHistory,
  IGetEmployees,
  IGetEmployerLoanDetails,
  IGetEmployerLoansResponse,
  IGetLoanDisbursementMetrics,
  IGetLoanMetrics,
  IGetLoanRequestLog,
  IGetLoanSchedule,
  IGetLoanSummary,
  IGetSingleEmployee,
  IResolveBankDetailsResponse,
  LoanConfigPayload,
  LoanConfigReponse,
  SearchOrFilterEmployeeLoansPayload,
} from "../../interfaces/employee-loans";

export const setPasswordFn = async ({ payload }) => {
  const { data } = await postData(
    `${CONFIG.BASE_URL2}${apiEndpoints.WITHDRAW_FROM_WALLET}`,
    payload
  );
  return data;
};

export const getAllEmployeesFn = async ({
  page,
  perPage,
  searchParams,
  statusId,
  key,
  value,
  startDate,
  endDate,
}: {
  page?: number;
  perPage?: number;
  searchParams?: string;
  statusId?: string;
  key?: string;
  value?: string;
  startDate?: string;
  endDate?: string;
}) => {
  const params = new URLSearchParams();

  if (page !== undefined) {
    params.append("page", page.toString());
  }

  if (perPage !== undefined) {
    params.append("perPage", perPage.toString());
  }

  if (searchParams) {
    params.append("searchParams", searchParams);
  }

  if (statusId) {
    params.append("status_id", statusId);
  }

  if (key) {
    params.append("searchKey", key);
  }

  if (value) {
    params.append("searchValue", value);
  }

  if (startDate) {
    params.append("start_date", startDate);
  }

  if (endDate) {
    params.append("end_date", endDate);
  }

  const getParams = () => {
    if (key && value) {
      return apiEndpoints.SEARCH_ALL_EMPLOYEES;
    }

    if (statusId || startDate || endDate) {
      return apiEndpoints.FILTER_ALL_EMPLOYEES;
    }

    return apiEndpoints.GET_ALL_EMPLOYEES;
  };

  const url = `${CONFIG.BASE_URL2}${getParams()}${
    params.toString() ? `?${params.toString()}` : ""
  }`;

  const data = await getData(url);
  return data as IGetEmployees;
};

export const getEmployeeByIdFn = async ({ id }) => {
  const data = await getData(
    `${CONFIG.BASE_URL2}${apiEndpoints.GET_SINGLE_EMPLOYEE}/${id}`
  );
  return data as IGetSingleEmployee;
};

export const uploadEmployeesFileFn = async ({
  payload,
}: {
  payload: FormData;
}) => {
  const data = await postData(
    `${CONFIG.BASE_URL2}${apiEndpoints.UPLOAD_EMPLOYEE_FILE}`,
    payload,
    "Multipart/form-data"
  );
  return data as IGetSingleEmployee;
};

export const deleteEmployeesFn = async ({
  payload,
}: {
  payload: IDeleteEmployeesPayload;
}) => {
  const data = await deleteData(
    `${CONFIG.BASE_URL2}${apiEndpoints.GET_ALL_EMPLOYEES}`,
    payload
  );
  return data as IDeleteEmployeesResponse;
};

export const suspendEmployeeFn = async ({ id }) => {
  const data = await getData(
    `${CONFIG.BASE_URL2}${apiEndpoints.SUSPEND_EMPLOYEE}/${id}`
  );
  return data as BareResponse;
};

export const createEmployeeFn = async ({
  payload,
}: {
  payload: CreateEmployeePayload;
}) => {
  const data = await postData(
    `${CONFIG.BASE_URL2}${apiEndpoints.GET_SINGLE_EMPLOYEE}`,
    payload
  );
  return data as BareResponse;
};

export const updateEmployeeFn = async ({
  payload,
}: {
  payload: CreateEmployeePayload;
}) => {
  const data = await putData(
    `${CONFIG.BASE_URL2}${apiEndpoints.GET_SINGLE_EMPLOYEE}`,
    payload
  );
  return data as BareResponse;
};

export const getEmployerLoansFn = async ({
  page,
  perPage,
  status,
  searchQuery,
  minAmount,
  maxAmount,
  startDate,
  endDate,
}: {
  page?: number;
  perPage?: number;
  status?: string;
  searchQuery?: string;
  minAmount?: string;
  maxAmount?: string;
  startDate?: string;
  endDate?: string;
}) => {
  const params = new URLSearchParams();

  if (page !== undefined) {
    params.append("page", page.toString());
  }

  if (perPage !== undefined) {
    params.append("perPage", perPage.toString());
  }

  if (status) {
    params.append("loanStatus", status);
  }

  if (searchQuery) {
    params.append("searchQuery", searchQuery);
  }

  if (minAmount) {
    params.append("minAmount", minAmount);
  }

  if (maxAmount) {
    params.append("maxAmount", maxAmount);
  }

  if (startDate) {
    params.append("startDate", startDate);
  }

  if (endDate) {
    params.append("endDate", endDate);
  }

  const getQuery = () => {
    if (searchQuery) {
      return apiEndpoints.SEARCH_EMPLOYER_LOAN;
    }

    if (minAmount || maxAmount || startDate || endDate) {
      params.delete("staffId");
      return `${apiEndpoints.FILTER_EMPLOYER_LOAN}`;
    }

    return apiEndpoints.GET_EMPLOYER_LOANS;
  };

  const url = `${CONFIG.BASE_URL2}${getQuery()}${
    params.toString() ? `?${params.toString()}` : ""
  }`;

  const data = await getData(url);
  return data as IGetEmployerLoansResponse;
};
export const getEmployeeLoansFn = async ({
  page,
  perPage,
  staffId,
  loanId,
}: {
  page?: number;
  perPage?: number;
  staffId?: string;
  loanId?: string;
}) => {
  const params = new URLSearchParams();

  if (page !== undefined) {
    params.append("page", page.toString());
  }

  if (perPage !== undefined) {
    params.append("perPage", perPage.toString());
  }

  //userid a1b0f69c-b00d-44ce-9604-c81fde8abc32
  const url = `${CONFIG.BASE_URL2}${apiEndpoints.GET_EMPLOYER_LOANS}/loan/${loanId}/${staffId}`;

  const data = await getData(url);
  return data as IGetEmployerLoanDetails;
};

export const validateAccountNumberFn = async ({
  payload,
}: {
  payload: {
    account_number: string;
    bank_id: string;
  };
}) => {
  const data: any = await postData(
    `${CONFIG.BASE_URL2}${apiEndpoints.VALIDATE_BANKS}`,
    payload
  );
  return data;
};

export const getEmployeeLoanHistoryFn = async ({
  staffId,
  page,
  perPage,
  keyword,
  minAmount,
  maxAmount,
  startDate,
  endDate,
}: {
  staffId: string;
  page: number;
  perPage: number;
  keyword?: string;
  minAmount?: string;
  maxAmount?: string;
  startDate?: string;
  endDate?: string;
}) => {
  const params = new URLSearchParams();

  if (staffId) {
    params.append("staffId", staffId);
  }

  if (page !== undefined) {
    params.append("page", page.toString());
  }

  if (perPage !== undefined) {
    params.append("perPage", perPage.toString());
  }

  if (minAmount) {
    params.append("minAmount", minAmount);
  }

  if (maxAmount) {
    params.append("maxAmount", maxAmount);
  }

  if (startDate) {
    params.append("startDate", startDate);
  }

  if (endDate) {
    params.append("endDate", endDate);
  }

  if (keyword) {
    params.append("keyword", keyword);
  }

  const getQuery = () => {
    if (keyword) {
      return apiEndpoints.SEARCH_EMPLOYEE_LOAN;
    }

    if (minAmount || maxAmount || startDate || endDate) {
      params.delete("staffId");
      return `${apiEndpoints.FILTER_EMPLOYEE_LOAN}/${staffId}`;
    }

    return apiEndpoints.GET_EMPLOYEE_LOANS;
  };

  const url = `${CONFIG.BASE_URL2}${getQuery()}${
    params.toString() ? `?${params.toString()}` : ""
  }`;

  const data = await getData(url);
  return data as IGetEmployeeLoanHistory;
};

export const searchEmployeeLoansFn = async (
  payload: SearchOrFilterEmployeeLoansPayload
) => {
  const data = await postData(
    `${CONFIG.BASE_URL2}${apiEndpoints.SEARCH_EMPLOYEE_LOAN}`,
    payload
  );
  return data as IGetEmployeeLoanHistory;
};

export const setLoanConfigurationFn = async (payload: LoanConfigPayload) => {
  const data: any = await postData(
    `${CONFIG.BASE_URL2}${apiEndpoints.SET_LOAN_CONFIGURATION}`,
    payload
  );

  return data as LoanConfigReponse;
};

export const exportEmployerLoansFn = async ({
  payload,
}: {
  payload: ExportEmployerLoansPayload;
}) => {
  const params = new URLSearchParams();

  if (payload.startDate) {
    params.append("startDate", payload.startDate);
  }

  if (payload.endDate) {
    params.append("endDate", payload.endDate);
  }

  const url = `${CONFIG.BASE_URL2}${apiEndpoints.EXPORT_LOANS}${
    params.toString() ? `?${params.toString()}` : ""
  }`;

  const data = await getData(url);
  return data;
};

export const getLoanSummaryFn = async ({
  key,
  value,
}: {
  key: string;
  value: string;
}) => {
  const data = await getData(
    `${CONFIG.BASE_URL2}${apiEndpoints.GET_LOAN_SUMMARY}/${key}/${value}`
  );

  return data as IGetLoanSummary;
};

export const getLoanMetricsFn = async ({ loanStatus, startDate, endDate }) => {
  const params = new URLSearchParams();

  if (loanStatus) {
    params.append("loanStatus", loanStatus);
  }

  if (startDate) {
    params.append("startDate", startDate);
  }

  if (endDate) {
    params.append("endDate", endDate);
  }

  const data = await getData(
    `${CONFIG.BASE_URL2}${apiEndpoints.GET_LOAN_STATUS_METRICS}${
      params.toString() ? `?${params.toString()}` : ""
    }`
  );
  return data as IGetLoanMetrics;
};

export const getLoanDisbursementMetricsFn = async () => {
  const data = await getData(
    `${CONFIG.BASE_URL2}${apiEndpoints.GET_LOAN_DISBURSEMENT_METRICS}`
  );
  return data as IGetLoanDisbursementMetrics;
};

export const getBanksFn = async () => {
  const data = await getData(
    `${CONFIG.BASE_URL2}${apiEndpoints.GET_ALL_BANKS}`
  );
  return data as IGetBanks;
};

export const resolveAccountFn = async ({
  payload,
}: {
  payload: {
    account_number: string;
    bank_id: string;
  };
}) => {
  const response = await postData(
    `${CONFIG.BASE_URL2}${apiEndpoints.RESOLVE_ACCOUNT}`,
    payload
  );

  return response as IResolveBankDetailsResponse;
};

export const getEmployeeLoanScheduleFn = async ({
  loanId,
}: {
  loanId: string;
}) => {
  const data = await getData(
    `${CONFIG.BASE_URL2}${apiEndpoints.GET_LOAN_SCHEDULE}/${loanId}`
  );
  return data as IGetLoanSchedule;
};

export const approveLoansFn = async (payload: ApproveLoansPayloadType) => {
  const response = await postData(
    `${CONFIG.BASE_URL2}${apiEndpoints.APPROVE_LOAN_REQUEST}`,
    payload
  );
  return response as ChangePendingLoanStatusResponse;
};

export const declineLoansFn = async (payload: DeclineLoansPayloadType) => {
  const response = await postData(
    `${CONFIG.BASE_URL2}${apiEndpoints.DECLINE_LOAN_REQUEST}`,
    payload
  );
  return response as ChangePendingLoanStatusResponse;
};

export const getLoanRequestLogsFn = async ({
  loanId,
  page,
  perPage,
}: {
  loanId: string;
  page: number;
  perPage: number;
}) => {
  const params = new URLSearchParams();
  if (loanId) params.append("loanId", loanId);
  if (page) params.append("page", page.toString());
  if (perPage) params.append("take", perPage?.toString());
  const data = await getData(
    `${CONFIG.BASE_URL2}${apiEndpoints.LOAN_REQUEST_LOG}${
      params.toString() ? `?${params.toString()}` : ""
    }`
  );
  return data as IGetLoanRequestLog;
};

export const downloadTemplateFn = async() => {
  const data = await getData(
    `${CONFIG.BASE_URL2}${apiEndpoints.EMPLOYEE_LOANS_MISCELLANEOUS}`
  );

  return data as IDownloadTemplate
}
