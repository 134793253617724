import moment from "moment";

import { currencyFormat, truncateString } from "../../../helpers/formatter";
import {
  attachClassNameToTag,
  getStatusName,
} from "../../../helpers/attachClassNameToTag";

import { useEffect, useState } from "react";
import NoDataState, {
  NoListState,
} from "../../../component/NoDataState/NoDataState";
import filterIcon from "../../../assets/images/svg/filter-icon.svg";
import { ReactComponent as SearchIcon } from "../../../assets/images/svg/search-icon.svg";
import NoDataIcon from "../../../assets/images/svg/empty-list-icon.svg";
import Pagination from "../../../component/Pagination/Pagination.component";
import { usePosTransaction } from "../../../hooks/usePosTransaction";
import PosTransactionDetailsModal from "../Modal/PosTransactionDetailsModal";
import { SecondaryLoader } from "../../../component/Loader/SecondaryLoader";
import { useSearchParams } from "react-router-dom";
import { usePosContext } from "../Pos";
import CardTransactionsModal from "../Modal/CardTransactionsModal";
import POSFilterModal from "../Modal/POSFilterModal";
import { getFromLocalStorage } from "../../../helpers/localStorage";

export default function Transactions() {
  const [modalType, setModalType] = useState("");
  const [transaction, setTransaction] = useState<any>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(15);
  const [showDateRangeModal, setShowDateRangeModal] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams({});
  const [listType, setListType] = useState(
    getFromLocalStorage("LISTTYPE") || "cards"
  );
  const [searchTerm, setSearchTerm] = useState("");
  const [showCardModal, setShowCardModal] = useState(false);

  const { setShowExportModal, setIsOpen } = usePosContext();

  const [filter, setFilter] = useState({
    start: null,
    end: null,
    status: null,
  });
  const [searchState, setSearchState] = useState<any>({
    type: listType,
    transaction_status: null,
    startDate: null,
    endDate: null,
  });

  const type = ["cards"];

  const {
    data: allPosTransactionsForTransfer,
    isLoading: allPosTransactionsForTransferLoading,
  } = usePosTransaction({
    page: currentPage,
    size: postsPerPage,
    startDate: searchState?.startDate,
    endDate: searchState?.endDate,
    amount_from: filter?.start,
    amount_to: filter?.end,
    status: filter?.status,
  });


  //filtered list
  const searchToReturn =
    allPosTransactionsForTransfer?.data?.transactions?.filter((item: any) =>
      item?.transaction_reference
        ?.toLowerCase()
        ?.includes(searchTerm?.toLowerCase())
    );

  //update the  react-query with the url params
  useEffect(() => {
    const currentParams: any = Object.fromEntries([...searchParams]);

    if (currentParams?.startDate || currentParams?.endDate) {
      setSearchState({
        transaction_status: currentParams?.transaction_status,
        type: listType?.toLowerCase(),
        startDate: currentParams?.startDate
          ? new Date(currentParams?.startDate)
          : null,
        endDate: currentParams?.endDate
          ? new Date(currentParams?.endDate)
          : null,
      });
    }
    if (currentParams?.page || currentParams?.size) {
      setCurrentPage(JSON.parse(currentParams?.page));
      setPostsPerPage(JSON.parse(currentParams?.size));
    }
  }, [searchParams, currentPage, listType]);

  //update the url params with the initial query
  useEffect(() => {
    const currentParams: any = Object.fromEntries([...searchParams]);

    if (
      !currentParams?.startDate ||
      !currentParams?.endDate ||
      !currentParams?.page ||
      !currentParams?.size
    ) {
      if (searchState.startDate || searchState.endDate) {
        setSearchParams({
          transaction_status: searchState?.transaction_status,
          type: listType,
          page: JSON.stringify(currentPage),
          size: JSON.stringify(postsPerPage),
          startDate: searchState.startDate
            ? moment(searchState.startDate).format("YYYY-MM-DD")
            : "",
          endDate: searchState.endDate
            ? moment(searchState.endDate).format("YYYY-MM-DD")
            : "",
        });
      }
      if (searchState.transaction_status) {
        setSearchParams({
          transaction_status: searchState?.transaction_status,
          type: listType,
          page: JSON.stringify(currentPage),
          size: JSON.stringify(postsPerPage),
        });
      } else {
        setSearchParams({
          type: listType?.toLowerCase(),
          page: JSON.stringify(currentPage),
          size: JSON.stringify(postsPerPage),
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchState.startDate, searchState.endDate, searchParams, listType]);

  return (
    <>
      <div className="flex px-4 lg:px-[36px] py-4 sm:border-t border-t-0 justify-between items-center bg-[#ffffff]">
        {/* <div className="flex items-center gap-x-2 border border-aellaBlue rounded-[4px] px-2 py-1 text-aellaBlue cursor-pointer">
          <p onClick={() => setShowExportModal(true)}>Export</p>
          <ChevronDown />
        </div> */}
        <div className="flex flex-row-reverse gap-x-3 ml-auto">
          <div className="flex flex-row-reverse">
            <label
              className="min-w-[114px] h-10 border bg-[#F5F5F5] px-2 lg:px-5 py-2 cursor-pointer rounded xxs:justify-center md:justify-around active:shadow-md transition duration-150 ease-in-out"
              htmlFor="filterModal"
              onClick={() => setShowDateRangeModal(true)}
            >
              <div className="xxs:px-1 md:px-2 py-1 h-[24px] flex items-center">
                <img
                  className="object-contain w-[18px] h-[18px]"
                  src={filterIcon}
                  alt=""
                />
                <span className="ml-[12px] font-thin  text-gray-400  text-sm focus:outline-none focus:ring-0">
                  Filter By
                </span>
              </div>
            </label>
          </div>
          <div
            className={`w-[10rem] flex items-center gap-x-2 px-2 h-10 rounded-[4px] border border-[#EDEFF5]`}
          >
            <div className="shrink-0">
              <SearchIcon />
            </div>
            <input
              value={searchTerm}
              onChange={(e: any) => setSearchTerm(e.target.value)}
              className="bg-inherit h-full placeholder:text-[#5B5B5B] text-aellaBlack flex-1 text-sm placeholder:text-sm placeholder:font-light outline-none"
              placeholder={
                listType === "cards"
                  ? "Search by reference"
                  : "Search by Terminal ID"
              }
            />
          </div>
        </div>
      </div>
      {/* <div className="bg-[#E8EEF4] rounded-[56px] flex justify-around gap-x-4 mx-10 mt-8 py-2 px-2 w-[20rem]">
        {type.map((each: string) => (
          <p
            key={each}
            onClick={() => {
              setListType(each);
              postToLocalStorage("LISTTYPE", each);
            }}
            className={`py-2 px-4 capitalize cursor-pointer text-[#232323]  ${
              listType === each
                ? "bg-white rounded-[24px] font-medium"
                : "bg-transparent font-normal"
            }`}
          >
            {each}
          </p>
        ))}
      </div> */}
      <div className="bg-aellaDimGray w-full px-4 lg:px-10 py-[32px] mb-10">
        <div className="py-6 border border-aellaBorderGray bg-white">
          <div className="flex justify-between flex-wrap items-center pb-[18px] px-6 lg:px-10 ">
            <h1 className="text-[16px] lg:text-[24px] lg:leading-[29px] font-[350]">
              Transactions History
            </h1>
          </div>
          <div className="pt-10 border-t border-[#F1F1F1] px-6 lg:px-10 overflow-x-auto w-full hide_scrollbar">
            {allPosTransactionsForTransfer?.data?.transactions?.length === 0 ? (
              <NoListState
                icon={NoDataIcon}
                textHeader="No transactions"
                firstParaText="There is no history of transactions because you do not have a POS Machine."
                secondParaText="Click the button below to request for a POS Machine."
                title="Request POS"
                onClick={() => setIsOpen(true)}
              />
            ) : searchToReturn?.length === 0 ? (
              <NoDataState
                icon={NoDataIcon}
                textHeader="Transaction Not Found"
                firstParaText=""
              />
            ) : (
              <div className="">
                <div className="flex items-center bg-[#F7F9FC] w-[280%] gap-x-4 overflow-x-auto md:overflow-x-hidden md:gap-x-0 md:w-full px-4 h-10 text-aellaBlack text-sm border-b hide_scrollbar">
                  <p className="w-[20%]">Terminal Id</p>
                  <p className="w-[20%]">Reference</p>
                  <p className="w-[30%]">Customer Name</p>
                  <p className="w-[25%]">Amount</p>
                  <p className="w-[18%] "> Fee</p>
                  <p className="w-[18%]"> Net Amount</p>
                  <p className="w-[20%] text-center">Transaction Status</p>
                  <p className="w-[20%] text-right">Date</p>
                </div>
                {allPosTransactionsForTransferLoading ? (
                  <SecondaryLoader count={10} />
                ) : (
                  searchToReturn?.map((trxn: any) => {
                    return (
                      <div
                        key={trxn?.id}
                        onClick={() => {
                          setTransaction(trxn);
                          setShowCardModal(true);
                          // if (listType !== "cards") {
                          //   setModalType("details");
                          // } else setShowCardModal(true);
                        }}
                        className="flex items-center bg-white w-[280%] gap-x-4 overflow-x-auto md:overflow-x-hidden md:gap-x-0 md:w-full p-4 text-[#5B5B5B] text-sm font-light border-b cursor-pointer hide_scrollbar"
                      >
                        <p className="w-[20%]">
                          {" "}
                          {truncateString(trxn?.terminal_id, 7)}
                        </p>

                        <p className="w-[20%]">
                          {" "}
                          {truncateString(trxn?.transaction_reference, 7)}
                        </p>

                        <p className="w-[30%] capitalize">
                          {truncateString(trxn?.card_holder, 8)}
                        </p>
                        <p className="w-[25%]">
                          {trxn?.amount ? currencyFormat(trxn?.amount) : "-"}
                        </p>
                        <p className="w-[18%]">
                          {trxn?.fee ? currencyFormat(trxn?.fee) : "-"}
                        </p>
                        <p className="w-[18%]">
                          {trxn?.amount_funded
                            ? currencyFormat(trxn?.amount_funded)
                            : "-"}
                        </p>

                        <p className={`w-[20%] text-center`}>
                          <span
                            className={`w-full text-center ${attachClassNameToTag(
                              trxn?.status
                            )} `}
                          >
                            {getStatusName(trxn?.status)}
                          </span>
                        </p>

                        <p className="w-[20%] text-right">
                          {trxn?.paid_at || trxn?.created_at
                            ? moment(trxn?.paid_at || trxn?.created_at).format(
                                "MMM. DD, YYYY"
                              )
                            : "-"}
                        </p>
                      </div>
                    );
                  })
                )}
              </div>
            )}

            {searchToReturn?.length > 0 && (
              <Pagination
                postsPerPage={postsPerPage}
                totalPosts={
                  allPosTransactionsForTransfer?.data?.meta?.totalItems
                }
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalPages={
                  allPosTransactionsForTransfer?.data?.meta?.totalPages
                }
              />
            )}
          </div>
        </div>
      </div>

      {showDateRangeModal && (
        <POSFilterModal
          startDate={searchState.startDate}
          setSearchState={setSearchState}
          endDate={searchState.endDate}
          searchState={searchState}
          setCloseModal={setShowDateRangeModal}
          startDateName="startDate"
          endDateName="endDate"
          transaction_status="transaction_status"
          showModal={showDateRangeModal}
          setSearchParams={setSearchParams}
          setFilter={setFilter}
        />
      )}
      {modalType && (
        <PosTransactionDetailsModal
          modalType={modalType}
          setModalType={setModalType}
          transaction={transaction}
        />
      )}
      <CardTransactionsModal
        showModal={showCardModal}
        setShowModal={setShowCardModal}
        selectedTransfer={transaction}
        setSelectedTransfer={setTransaction}
      />
    </>
  );
}
