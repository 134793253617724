import { Player } from "@lottiefiles/react-lottie-player";

import BlueButton from "../../../component/Button/BlueButton";
import SuccessAnimation from "../../../assets/files/Stars.json";
import { ReactComponent as CloseIcon } from "../../../assets/images/svg/white-close-modal.svg";
export const PinResetSuccessful = ({
  closeModal,
}: {
  closeModal: () => void;
}) => {
  return (
    <>
      <div className="flex justify-between items-center">
        <div className="absolute -top-8 -right-0 cursor-pointer">
          <CloseIcon />
        </div>
      </div>
      <div className="flex flex-col justify-between items-center">
        <div className="flex items-center justify-center h-auto relative">
          <Player
            autoplay
            controls
            loop
            src={SuccessAnimation}
            style={{
              width: "200px",
              height: "120px",
            }}
          />
          <p className="absolute top-[30%] right-[25%] text-2xl text-aellaBlue">
            Successful
          </p>
        </div>
        <p className="text-aellaBlack text-[24px] mt-6">Transaction Pin Set</p>
        <p className="text-[#5b5b5b] font-light text-center mt-3">
          You can now make unlimited transactions across your merchant{" "}
          <br className="hidden sm:block" />
          dashboard. Ensure you do not forget your pin.
        </p>
        <BlueButton onClick={closeModal} title="Got it!" className="mt-8" />
      </div>
    </>
  );
};
