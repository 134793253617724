import { FC, useEffect, useState } from "react";
import moment from "moment";
import { toast } from "react-toastify";

import { ReactComponent as SuccessIcon } from "../../../assets/images/svg/successful-transfer-icon.svg";
import { ReactComponent as CloseIcon } from "../../../assets/images/svg/white-close-modal.svg";
import { ReactComponent as ChevronIcon } from "../../../assets/images/svg/chevron-up.svg";
import { ModalContainer } from "../../../modal/ModalContainer";
import { LOCAL_STORAGE_KEYS } from "../../../helpers/localStorageKeys";
import { useMerchantDetails } from "../../../hooks/useMerchantDetails";
import { getFromLocalStorage } from "../../../helpers/localStorage";
import { formatMoney } from "../../../helpers/formatMoney";
import {
  attachClassNameToTag,
  getStatusName,
} from "../../../helpers/attachClassNameToTag";
import { currencyFormat } from "../../../helpers/formatter";
import CONFIG from "../../../helpers/config";
import { apiEndpoints } from "../../../apis/apiEndpoints";
import { getData } from "../../../apis/apiMethods";

type stateType = {
  type?: string;
  showModal: boolean;
  setShowModal: (newState: boolean) => void;
  selectedTransfer: any;
  setSelectedTransfer: (newState: Object) => void;
  id?: string;
  receiverDetails?: any;
  bank?: any;
  transactionReference?: string;
};

type listType = {
  name: string;
  value: any;
  valueClassName?: string;
  onClick?: any;
  className?: string;
};

const CardTransactionsModal: FC<stateType> = ({
  type,
  showModal,
  setShowModal,
  selectedTransfer,
  setSelectedTransfer,
  id,
  receiverDetails,
  bank,
  transactionReference,
}) => {
  const ListItem: FC<listType> = ({
    name,
    value,
    valueClassName,
    onClick,
    className,
  }) => {
    return (
      <div
        className={`flex text-[#232323] text-base justify-between px-4 py-3 rounded-[5px] capitalize ${className}`}
      >
        <p className="min-w-[50%]">{name}</p>
        <p
          onClick={onClick}
          className={`font-normal max-w-[45%] ${valueClassName}`}
        >
          {value}
        </p>
      </div>
    );
  };

  const userId = getFromLocalStorage(LOCAL_STORAGE_KEYS.USERID);
  const { data: merchant } = useMerchantDetails({ userId });

  const handleCopy = (text: string) => {
    if (text !== "-") {
      navigator.clipboard.writeText(text);
    }
    toast.success("Copied successfully");
  };

  const CardDetails = ({
    name,
    figure,
    className,
  }: {
    name: string;
    figure?: string | number;
    className?: string;
  }) => {
    return (
      <div className={`flex flex-col gap-y-1 w-[50%] ${className}`}>
        <p className="text-[#737373] text-sm">{name}</p>
        <p className="text-aellaBlack font-medium capitalize">{figure}</p>
      </div>
    );
  };
  const accountNumber = selectedTransfer?.payer_account_number;
  const [maskedAccountNumber, setMaskedAccountNumber] = useState("");
  useEffect(() => {
    const maskPhoneNumber = () => {
      const maskedPart = "*".repeat(
        accountNumber?.length
          ? accountNumber?.length - 4
          : accountNumber?.length - 0
      );
      const unmaskedPart = accountNumber?.slice(accountNumber?.length ? -4 : 0);
      setMaskedAccountNumber(maskedPart + unmaskedPart);
    };
    maskPhoneNumber();
  }, [accountNumber, maskedAccountNumber]);

  return (
    <ModalContainer
      showModal={showModal}
      closeModal={() => {
        setShowModal(false);
        setTimeout(() => {
          setSelectedTransfer({});
        }, 700);
      }}
    >
      <div
        className={`w-full lg:w-[32vw] absolute top-0 h-screen z-50 text-[#232323] flex flex-col rounded-[8px] bg-white py-10 px-4 lg:px-8 overflow-y-auto animation ${
          showModal ? "right-0" : "-right-[32vw]"
        } `}
      >
        <div className="flex justify-between items-center">
          <p className="text-xl text-aellaBlack">Transaction Details</p>

          <CloseIcon
            onClick={() => {
              setShowModal(false);
              setTimeout(() => {
                setSelectedTransfer({});
              }, 700);
            }}
            className="w-10 h-10 shrink-0 cursor-pointer"
          />
        </div>
        <hr className="mt-4 bg-[#EDEFF5]" />

        <div className="flex flex-col items-center mt-8">
          <SuccessIcon />
          <p className="text-[#7B7B7B] font-light mt-2">Amount Paid:</p>
          <p className="mt-2 text-3xl text-[#232323]">
            ₦{formatMoney(selectedTransfer?.amount)}
          </p>

          <div className="bg-[#F3F5FC] w-full py-6 px-2 lg:px-4 mt-8 flex items-center gap-x-2 md:gap-x-4 border border-[#C5D4F6]">
            <div className="rounded-[50%] shrink-0 h-14 w-14 flex justify-center items-center bg-[#D8A28F] text-aellaBlue text-2xl uppercase">
              {selectedTransfer?.payer_account_name
                ?.split(" ")
                ?.map((name: any) => name[0])
                ?.join("")
                .substring(0, 2)}
            </div>
            <div className="flex flex-col">
              <p className="text-[1.1rem] md:text-[1.3rem] flex-wrap text-[#232323] capitalize">
                {selectedTransfer?.payer_account_name?.toLowerCase()}
              </p>
              <div className="flex items-center gap-x-1 text-[#7B7B7B] font-light text-sm">
                <p className="text-[0.75rem] md:text-sm">
                  {selectedTransfer?.account?.number}
                </p>
              </div>
            </div>
          </div>

          <div className="w-full py-4 mt-8 border rounded-[10px] font-light text-base">
            <div className="flex items-center justify-between px-4">
              <p className="text-[#172B4D]">More Details</p>
              <div className="h-5 w-5">
                <ChevronIcon />
              </div>
            </div>
            <hr className="my-4" />
            <ListItem
              name="Transaction Status:"
              value={getStatusName(selectedTransfer?.status?.toLowerCase())}
              valueClassName={`font-light px-2 py-1 rounded-[4px] capitalize ${attachClassNameToTag(
                selectedTransfer?.status
              )} `}
            />

            <ListItem
              name="Fee:"
              value={currencyFormat(selectedTransfer?.fee)}
            />

            <ListItem
              name="Net Amount:"
              value={currencyFormat(selectedTransfer?.amount_funded)}
            />

            <ListItem
              name="Terminal ID:"
              value={selectedTransfer?.terminal_id}
            />

            {/* <ListItem
              name="Phone Number:"
              value={selectedTransfer?.net_amount}
            /> */}

            <ListItem
              name="RRN:"
              value={selectedTransfer?.transaction_reference}
              valueClassName="lowercase"
            />

            {/* <ListItem
              name="Auth ID:"
              value={selectedTransfer?.net_amount}
              valueClassName="lowercase"
            />

            <ListItem
              name="STAN:"
              value={selectedTransfer?.net_amount}
              valueClassName="lowercase"
            /> */}

            <ListItem
              name="Time Stamp:"
              value={moment(
                selectedTransfer?.paid_at || selectedTransfer?.created_at
              ).format("DD MMM YYYY | LT")}
              valueClassName="text-right"
            />

            <ListItem
              name="Transaction Reference:"
              value={selectedTransfer?.transaction_reference || "-"}
              valueClassName="text-right cursor-pointer normal-case"
              onClick={() => {
                handleCopy(selectedTransfer?.transaction_reference);
              }}
            />

            {/* <div className="px-4 mt-5">
              <p className="capitalize">transfer made by:</p>
              <div className="bg-[#F5F7F9] py-4 px-6 flex justify-between gap-x-5 mt-4 rounded-[8px]">
                <div className="flex flex-col w-[60%]">
                  <p className="text-[#737373]">Name</p>
                  <p className="text-sm md:text-base text-[#011C34] font-medium capitalize">
                    {merchant?.first_name?.toLowerCase() +
                      " " +
                      merchant?.last_name?.toLowerCase()}
                  </p>
                </div>
                <div className="flex flex-col w-[30%] text-left">
                  <p className="text-[#737373]">Date</p>
                  <p className="text-sm md:text-base text-[#011C34] font-medium">
                    {moment(selectedTransfer?.transaction_date).format(
                      "DD MMM YYYY"
                    )}
                  </p>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        <div className="w-full py-4 mt-8 border rounded-[10px] font-light text-base">
          <div className="flex items-center justify-between px-4">
            <p className="text-[#172B4D]">Card Details</p>
            <div className="h-5 w-5">
              <ChevronIcon />
            </div>
          </div>
          <hr className="my-4" />
          <div className="px-4">
            <div className="bg-[#F5F7F9] border border-[#dadada] p-4 rounded-[8px] flex justify-between flex-wrap gap-y-3">
              <CardDetails
                name="Card Holder's Name"
                figure={selectedTransfer?.card_holder?.toLowerCase() || "-"}
                className="text-left"
              />
              <CardDetails
                name="Card Type"
                figure={selectedTransfer?.card_type || "-"}
                className="text-right"
              />
              <CardDetails
                name="PAN"
                figure={accountNumber ? maskedAccountNumber : "-"}
                className="text-left"
              />
              <CardDetails
                name="Account Type"
                figure={selectedTransfer?.type || "-"}
                className="text-right"
              />
              <CardDetails
                name="Expiry Date"
                figure={
                  selectedTransfer?.card_expiry
                    ? selectedTransfer?.card_expiry?.split("")[0] +
                      selectedTransfer?.card_expiry?.split("")[1] +
                      "/" +
                      selectedTransfer?.card_expiry?.split("")[2] +
                      selectedTransfer?.card_expiry?.split("")[3]
                    : "-"
                }
                className="text-left"
              />
              <CardDetails
                name="Payment Type"
                figure={
                  selectedTransfer?.transaction_type?.toLowerCase() || "-"
                }
                className="text-right"
              />
            </div>
          </div>
        </div>
      </div>
    </ModalContainer>
  );
};

export default CardTransactionsModal;
