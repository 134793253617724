/* eslint-disable jsx-a11y/anchor-is-valid */
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

export default function GroupedTransferPagination({
  postsPerPage,
  totalPosts,
  currentPage,
  setCurrentPage,
}: {
  postsPerPage: number;
  totalPosts: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  currentPage: number;
  totalPages: number;
  params?: boolean;
}) {
  const [totalPages, setTotalPages] = useState(1);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // Change page
  const paginateFront = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  const paginateBack = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  useEffect(() => {
    if (totalPosts >= 0) {
      const total_Pages = Math.ceil(totalPosts / postsPerPage);
      setTotalPages(total_Pages);
    }
  }, [totalPosts, postsPerPage]);

  return (
    <div className="flex items-center justify-between border-t bg-white px-4 py-3 sm:px-6">
      <div className="flex flex-1 justify-between md:hidden">
        <a
          className={`relative inline-flex items-center px-2 py-2 rounded border border-aellaBlue bg-aellaBlue text-sm font-[200] text-white hover:bg-opacity-90 cursor-pointer ${
            currentPage <= 1 ? "opacity-50" : ""
          }    `}
          onClick={() => {
            paginateBack();
          }}
          href="#"
          aria-disabled={currentPage <= 1}
        >
          <span>Previous</span>
        </a>
        <a
          href="#"
          className="relative inline-flex items-center border border-[#EDEFF5] bg-[#F6F8FA] px-4 py-2 text-sm font-[200]"
        >
          {currentPage}
        </a>
        <a
          onClick={(e) => {
            if (currentPage >= totalPages) {
              e.preventDefault();
            } else {
              paginateFront();
            }
          }}
          href=""
          aria-disabled={currentPage >= totalPages}
          className={`relative inline-flex items-center px-2 py-2 rounded border border-aellaBlue bg-aellaBlue text-sm font-[200] text-white hover:bg-opacity-90 cursor-pointer ${
            currentPage >= totalPages ? "opacity-50" : ""
          }`}
        >
          <span>Next</span>
        </a>
      </div>
      <div className="hidden md:flex sm:flex-1 sm:items-center sm:justify-between">
        <div>
          <p className="text-[14px] leading[17px] font-[200] tracking-[0.2px]">
            <span className="font-medium">
              {totalPosts === 0
                ? "0"
                : currentPage * postsPerPage - (postsPerPage - 1)}
            </span>{" "}
            -{" "}
            <span className="font-medium">
              {" "}
              {currentPage < totalPages
                ? currentPage * postsPerPage
                : totalPosts}
            </span>{" "}
            of <span className="font-medium">{totalPosts}</span> total Records
          </p>
        </div>
        <div>
          <nav
            className="isolate inline-flex shadow-sm"
            aria-label="Pagination"
          >
            <a
              onClick={() => {
                paginateBack();
              }}
              className={`relative inline-flex items-center px-2 py-2 rounded border border-aellaBlue bg-aellaBlue text-sm font-[200] text-white hover:bg-opacity-90 cursor-pointer 
               
           ${currentPage <= 1 ? "opacity-50" : ""}
                `}
              aria-disabled={currentPage <= 1}
            >
              <span>Prev</span>
            </a>
            <span className="relative inline-flex items-center border border-[#EDEFF5] bg-[#F6F8FA] px-4 py-2 text-sm font-[200] mx-2">
              {currentPage}
            </span>
            <a
              onClick={(e) => {
                if (currentPage >= totalPages) {
                  e.preventDefault();
                } else {
                  paginateFront();
                }
              }}
              aria-disabled={currentPage >= totalPages}
              className={`relative inline-flex items-center px-2 py-2 rounded border border-aellaBlue bg-aellaBlue text-sm font-[200] text-white hover:bg-opacity-90 cursor-pointer  ${
                currentPage >= totalPages ? "opacity-50" : ""
              }`}
            >
              <span>Next</span>
            </a>
          </nav>
        </div>
      </div>
    </div>
  );
}
