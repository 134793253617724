import { useEffect, useRef, useState } from "react";
import {
  Outlet,
  useLocation,
  useNavigate,
  useOutletContext,
} from "react-router-dom";
import { toast } from "react-toastify";

import { ROUTES } from "../../helpers/routes";
import Tab from "../../component/Tab/Tab.component";
import { ReactComponent as AddIcon } from "../../assets/images/svg/add-account-icon.svg";
import { handleScrollToTop } from "../../helpers/scrollToTop";
import { getFromLocalStorage } from "../../helpers/localStorage";
import { LOCAL_STORAGE_KEYS } from "../../helpers/localStorageKeys";
import { useMerchantServices } from "../../hooks/useMerchantServices";
import { useAuth } from "../../context/auth";

const EmployeeLoans = () => {
  const { merchantCntxtStatus } = useAuth();
  const { data: merchantServices } = useMerchantServices({
    status: merchantCntxtStatus,
  });

  const isSubscribedToEmployeeLoans = merchantServices?.find(
    (obj: any) => obj?.type?.name?.toLowerCase() === "employee loans"
  );

  const role = getFromLocalStorage(LOCAL_STORAGE_KEYS.ROLE);
  const canUpload =
    role === "Super Admin" || role === "Admin" || role === "Manager";
  const pageRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();
  const [state, setState] = useState({
    showHeader: true,
    addEmployee: false,
    createEmployeeModal: false,
  });

  const updateState = (payload) => {
    setState((prev) => ({ ...prev, ...payload }));
  };

  useEffect(() => {
    handleScrollToTop(pageRef);
    if (location.pathname === ROUTES.EMPLOYEE_LOANS) {
      navigate(ROUTES.EMPLOYEE_LOANS_OVERVIEW); // Redirect to the default tab when the component mounts
    }
  }, [location]);

  useEffect(() => {
    if (merchantServices?.length && !isSubscribedToEmployeeLoans) {
      navigate(ROUTES.HOME);
    }
  }, []);

  return (
    <div
      ref={pageRef}
      className=" bg-aellaDimGray h-screen flex flex-col w-full "
    >
      <div className="bg-white px-4 lg:px-10">
        {state.showHeader && (
          <div className="border-t border-[#F1F1F1] flex gap-4 justify-between items-center pt-2 pb-[1px] w-full flex-wrap">
            <div className="flex flex-wrap items-center">
              <Tab text="Overview" path={ROUTES.EMPLOYEE_LOANS_OVERVIEW} />
              <Tab text="Employees" path={ROUTES.EMPLOYEES} />
              <Tab text="Loan Book" path={ROUTES.LOAN_BOOK} />
              {/* {(role === "Super Admin" || role === "Admin") && (
                <Tab
                  text="Loan Configuration"
                  path={ROUTES.LOAN_CONFIGURATION}
                />
              )} */}
            </div>
            {location.pathname ===
              ROUTES.EMPLOYEE_LOANS + "/" + ROUTES.EMPLOYEES &&
              canUpload && (
                <div className="flex items-center gap-x-3">
                  <button
                    className="bg-aellaBlue h-11 py-2 px-3 sm:px-7 cursor-pointer text-center text-white rounded-[6px] flex gap-x-[6px] mb-3 sm:mb-0 items-center"
                    onClick={() => {
                      if (!isSubscribedToEmployeeLoans) {
                        return toast.error(
                          "This feature has not been activated. Turn on Employee Loans in your services"
                        );
                      }
                      updateState({
                        showHeader: false,
                        addEmployee: true,
                      });
                    }}
                  >
                    <AddIcon />
                    Bulk Upload
                  </button>
                  <button
                    onClick={() => {
                      if (!isSubscribedToEmployeeLoans) {
                        return toast.error(
                          "This feature has not been activated. Turn on Employee Loans in your services"
                        );
                      }
                      updateState({
                        createEmployeeModal: true,
                      });
                    }}
                    className="bg-aellaBlue h-11 py-2 px-3 sm:px-7 cursor-pointer text-center text-white rounded-[6px] flex gap-x-[6px] mb-3 sm:mb-0 items-center"
                  >
                    <AddIcon />
                    Add Employee
                  </button>
                </div>
              )}
          </div>
        )}
      </div>
      <div className="overflow-y-auto flex-1 h-auto flex flex-col">
        <Outlet
          context={{
            loanContextState: state,
            showHeader: state.showHeader,
            toggleHeader: (e) =>
              updateState({
                showHeader: e,
                // addEmployee: false,
              }),
            addEmployee: state.addEmployee,
            updateContext: updateState,
            isSubscribedToEmployeeLoans,
          }}
        />
      </div>
    </div>
  );
};

export default EmployeeLoans;

export function useLoansContext() {
  return useOutletContext<any>();
}
