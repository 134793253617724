import { Dispatch, SetStateAction } from "react";
import { Benefits } from "../ConnectServicesCard";
import CloseIcon from "../../../assets/images/svg/close-icon.svg";

const ConnectServiceModal = ({
  data,
  showModal,
  setShowModal,
  onSubmit,
  loading,
}: {
  data: any;
  showModal: boolean;
  loading: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  onSubmit: (newState: string) => void;
}) => {
  return (
    <>
      <div className="fixed justify-center items-center flex overflow-hidden  inset-0 z-50 outline-none focus:outline-none">
        <div className="relative  mt-2 mb-40 mx-auto w-[464px]">
          <div className="flex mb-2 justify-between">
            <div></div>
            <button
              onClick={() => {
                setShowModal(false);
              }}
              type="button"
              className="cursor-pointer"
            >
              <img src={CloseIcon} alt="close modal" />
            </button>
          </div>
          {/*content*/}
          <div className="border-0 bg-white rounded-lg p-10 flex flex-col w-screen md:w-full outline-none focus:outline-none overflow-y-auto">
            <div
              className={`flex flex-col justify-between w-full`}
              key={data?.id}
            >
              <h1 className="pl-2 text-[18px] text-[#011C34] lg:leading-[22px] font-[400]">
                {data.name}
              </h1>

              <p className="text-[16px] lg:leading-[24px] text-[#737373] font-[200] py-4 ">
                {data.desc}
              </p>
              <div className="font-[400] text-[16px] lg:leading-[19px] text-[#737373]"></div>
              {data?.benefits.map((data: any, _id: any) => {
                return <Benefits key={_id} benefit={data} />;
              })}
            </div>
            <div className="flex flex-row items-center w-full justify-end mt-10">
              <button
                className=" px-10 py-4 flex rounded-[4px] bg-white  text-[#5B5B5B] disabled:bg-colors-blue5 items-center transition ease-in-out duration-300  font-normal text-[14px]"
                type="submit"
                onClick={() => setShowModal(false)}
              >
                Cancel
              </button>
              <button
                className=" px-10 py-4 flex rounded-[4px]  bg-aellaBlue  text-white disabled:bg-opacity-20 items-center transition ease-in-out duration-300 font-normal text-[14px]"
                type="submit"
                onClick={() => onSubmit(data?.id)}
                disabled={loading}
              >
                Connect {loading && " ...."}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="bg-opacity-25 fixed inset-0 z-40 bg-[#0B1E4B]"
        onClick={() => setShowModal(false)}
      ></div>
    </>
  );
};

export default ConnectServiceModal;
