import moment from "moment";
import React from "react";
import CloseIcon from "../../../assets/images/svg/close-icon.svg";
import UserAvatar from "../../../assets/images/svg/user-avatar.svg";
import {
  attachClassNameToTag,
  getStatusName,
} from "../../../helpers/attachClassNameToTag";
import { currencyFormat } from "../../../helpers/formatter";

interface TPosTransactionDetailsModal {
  modalType: string;
  setModalType: (newState: string) => void;
  transaction: any;
}

const PosTransactionDetailsModal: React.FC<TPosTransactionDetailsModal> = ({
  modalType,
  setModalType,
  transaction,
}) => {
  return (
    <>
      <div className="fixed justify-center items-center flex overflow-x-hidden inset-0 z-50 outline-none focus:outline-none">
        <div className="relative mt-2 mx-auto w-[464px]">
          <div className="flex mb-2 justify-between">
            <div></div>
            <button
              onClick={() => {
                setModalType("");
              }}
              type="button"
              className="cursor-pointer"
            >
              <img src={CloseIcon} alt="close modal" />
            </button>
          </div>
          {/*content*/}
          <div className="border-0 bg-white rounded-lg p-10 flex flex-col w-screen max-h-[85vh] overflow-y-auto md:w-full bg-colors-white outline-none focus:outline-none ">
            {(() => {
              switch (modalType) {
                case "details":
                  return (
                    <>
                      {/*header*/}
                      <div className="flex flex-col">
                        <h1 className="leading-6 font-[400] text-[20px] tracking[0.4px] pb-[17px] border-b">
                          POS Transaction Details
                        </h1>
                        <div className="pt-8">
                          <div className="flex gap-x-4 mb-6 items-center">
                            <img src={UserAvatar} alt="user-avatar" />

                            <div className="flex flex-col gap-y-2">
                              <p className="leading-[19px] text-[20px] capitalize tracking[0.2px] text-aellaBlack">
                                {transaction?.card_holder?.toLowerCase()}
                              </p>
                              <p className="leading-[19px] font-[200] text-[16px] tracking[0.2px] text-aellaGray">
                                {/* {transaction?.card_holder} */} email
                              </p>
                            </div>
                          </div>
                          <div className="flex flex-col md:flex-row py-[18px] md:border-y justify-between">
                            <div className="flex flex-col">
                              <p className="leading-[19px] font-[200] text-[16px] tracking[0.2px] text-aellaGray">
                                Transaction Status
                              </p>
                              <p
                                className={`${attachClassNameToTag(
                                  transaction?.status
                                )} leading-[19px] font-[200] text-[16px]  mt-2 text-center`}
                              >
                                {getStatusName(transaction?.status)}
                              </p>
                            </div>
                            <div className="flex flex-col">
                              <p className="leading-[19px] font-[200] text-[16px] tracking[0.2px] text-aellaGray md:self-end">
                                Amount
                              </p>

                              <p className="leading-[19px] font-[200] text-[16px] tracking[0.2px] pt-2">
                                {transaction?.amount &&
                                  currencyFormat(transaction?.amount)}
                              </p>
                            </div>
                          </div>
                          <div className="flex flex-col md:flex-row py-[18px] md:border-b justify-between items-top">
                            <div className="flex flex-col">
                              <p className="leading-[19px] font-[200] text-[16px] tracking[0.2px] text-aellaGray">
                                Fee
                              </p>

                              <p className="leading-[19px] font-[200] text-[16px] tracking[0.2px] pt-2">
                                {transaction?.fee
                                  ? currencyFormat(transaction?.fee)
                                  : "-"}
                              </p>
                            </div>
                            <div className="flex flex-col">
                              <p className="leading-[19px] font-[200] text-[16px] tracking[0.2px] text-aellaGray md:self-end">
                                Net Amount
                              </p>

                              <p className="leading-[19px] font-[200] text-[16px] text-right tracking[0.2px] pt-2">
                                {transaction?.amount_funded
                                  ? currencyFormat(transaction?.amount_funded)
                                  : "-"}
                              </p>
                            </div>
                          </div>
                          <div className="flex flex-col md:flex-row py-[18px] md:border-b justify-between items-top">
                            <div className="flex flex-col">
                              <p className="leading-[19px] font-[200] text-[16px] tracking[0.2px] text-aellaGray">
                                Customer Name
                              </p>

                              <p className="leading-[19px] capitalize font-[200] text-[16px] tracking[0.2px] pt-2">
                                {transaction?.payer_account_name
                                  ? transaction?.payer_account_name?.toLowerCase()
                                  : "-"}
                              </p>
                            </div>
                            <div className="flex flex-col">
                              <p className="leading-[19px] font-[200] text-[16px] tracking[0.2px] text-aellaGray md:self-end">
                                Institution Code
                              </p>

                              <p className="leading-[19px] font-[200] text-[16px] tracking[0.2px] pt-2">
                                {/* {transaction?.amount_funded
                                  ? currencyFormat(transaction?.amount_funded)
                                  : "-"} */}
                              </p>
                            </div>
                          </div>
                          <div className="flex flex-col md:flex-row py-[18px] md:border-b justify-between items-top">
                            <div className="flex flex-col">
                              <p className="leading-[19px] font-[200] text-[16px] tracking[0.2px] text-aellaGray">
                                Phone Number
                              </p>

                              <p className="leading-[19px] font-[200] text-[16px] tracking[0.2px] pt-2">
                                {/* {transaction?.fee
                                  ? currencyFormat(transaction?.fee)
                                  : "-"} */}
                              </p>
                            </div>
                            <div className="flex flex-col ">
                              <p className="leading-[19px] font-[200] text-[16px] tracking[0.2px] text-aellaGray md:self-end">
                                Date
                              </p>
                              <p className="leading-[19px] font-[200] text-[16px] tracking[0.2px] pt-2 ">
                                {transaction?.paid_at
                                  ? moment(transaction?.paid_at).format(
                                      "MMM. DD, YYYY"
                                    )
                                  : "-"}
                              </p>
                            </div>
                          </div>
                          <div className="flex flex-col md:flex-row py-[18px] md:border-b justify-between md:items-center">
                            <div className="w-[48%] flex flex-col">
                              <p className="leading-[19px] font-[200] text-[16px] tracking[0.2px] text-aellaGray">
                                Transaction Ref
                              </p>
                              <p className="max-w-[90%] break-words leading-[19px] font-[200] text-[16px] tracking[0.2px] pt-2">
                                {transaction?.transaction_reference}
                              </p>
                            </div>
                            <div className="w-[48%] flex flex-col md:text-right">
                              <p className="leading-[19px] font-[200] text-[16px] tracking[0.2px] text-aellaGray">
                                Audit Ref
                              </p>
                              <p className="leading-[19px] font-[200] text-[16px] tracking[0.2px] pt-2">
                                {/* {transaction?.transaction_type
                                  ? transaction?.transaction_type
                                  : "-"} */}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                default:
                  return null;
              }
            })()}
          </div>
        </div>
      </div>
      <div
        className="opacity-25 fixed inset-0 z-40 bg-[#0B1E4B]"
        onClick={() => {
          setModalType("");
        }}
      ></div>
    </>
  );
};
export default PosTransactionDetailsModal;
