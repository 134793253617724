import { format } from "date-fns";
import { useState } from "react";
import { toast } from "react-toastify";

import { ReactComponent as CloseIcon } from "../../../assets/images/svg/close-icon.svg";
import arrowUpIcon from "../../../assets/images/svg/black-arrow-up-icon.svg";
import { formatMoney } from "../../../helpers/formatMoney";
import { ModalContainer } from "../../../modal/ModalContainer";
import {
  EmployerRepaymentSchedule,
  Loan,
} from "../../../interfaces/employee-loans";
import { getMultiWordFirstLetters } from "../../../helpers/others";
import { attachClassToLoanStatusText } from "../../../helpers/attachClassNameToTag";

const ListItem = ({
  name,
  value,
  hasPadding,
}: {
  name: string;
  value: any;
  hasPadding?: boolean;
}) => {
  return (
    <div
      className={`flex justify-between full font-light ${
        hasPadding && "py-4 border-b border-b-[#ECEDF0]"
      }`}
    >
      <p className="text-aellaGray max-w-[35%]">{name}</p>
      <p
        className={`p-1 rounded-[4px] max-w-[65%] text-right capitalize ${attachClassToLoanStatusText(
          value
        )} ${
          value === "paid"
            ? "text-[#14804A] bg-[#E1FCEF] text-[12px]"
            : value === "partially paid" || value === "partial"
            ? "text-[#F2994A] bg-[#F2994A1A] text-[12px]"
            : value === "late" || value === "unpaid"
            ? "text-[#eb5757] bg-[#FBDDDD] text-[12px] capitalize"
            : "text-[#232323] text-[14px]"
        }`}
      >
        {value}
      </p>
    </div>
  );
};

export const EmployeeRepaymentDetailsModal = ({
  showModal,
  closeModal,
  selectedObj,
  loan,
}: {
  showModal: boolean;
  closeModal: () => void;
  selectedObj: EmployerRepaymentSchedule;
  loan: Loan;
}) => {
  const [copy, setCopy] = useState(false);
  const handleCopy = (text: string | undefined) => {
    if (text) {
      navigator.clipboard.writeText(text);
      setCopy(true);
      toast.success("Copied successfully");
    }
    setTimeout(() => setCopy(false), 3000);
  };

  return (
    <ModalContainer showModal={showModal} closeModal={closeModal}>
      <div
        className={`w-full lg:w-[35vw] absolute top-0 h-screen z-50 text-[#232323] flex flex-col bg-white py-10 px-6 overflow-y-scroll animation ${
          showModal ? "right-0" : "-right-[35vw]"
        } `}
      >
        <div className="flex justify-between items-center pb-3 border-b border-b-[#EDEFF5]">
          <p className="text-xl text-aellaBlack">Repayment Details</p>

          <CloseIcon
            onClick={closeModal}
            className="h-10 w-10 shrink-0 cursor-pointer"
          />
        </div>

        <div className="mt-8 flex flex-col">
          <div className="flex gap-x-8">
            <div className="rounded-full inline-flex bg-[#E5B7A0] w-[64px] h-[64px] items-center justify-center top-6  cursor-pointer mr-1 text-aellaBlue text-[18px]">
              {getMultiWordFirstLetters(
                loan?.first_name + " " + loan?.last_name
              )}
            </div>
            <div className="">
              <p className="text-[24px] text-[#232323] capitalize">
                {(loan?.first_name?.toLowerCase() ?? "") +
                  " " +
                  (loan?.last_name?.toLowerCase() ?? "")}
                {!loan?.first_name && !loan?.last_name && "N/A"}
              </p>
              <p className="text-[#7B7B7B] font-light">
                {loan?.email ?? "N/A"}
              </p>
            </div>
          </div>

          <div className="flex flex-col mt-7">
            <ListItem
              name="Status"
              value={selectedObj?.status_text}
              hasPadding
            />
            <ListItem
              name="Amount"
              value={`₦${formatMoney(selectedObj?.amount ?? 0)}`}
              hasPadding
            />
            <ListItem
              name="Balance"
              value={`₦${formatMoney(selectedObj?.balance ?? 0)}`}
              hasPadding
            />
          </div>

          <div className="flex flex-col border rounded-[6px] border-[#EDF2FF] mt-8 w-full">
            <div className="flex justify-between px-4 py-4 items-top border-b border-[#EDF2FF]">
              <p className="font-light text-[18px] text[#172B4D] leading-[19px]">
                More Details
              </p>
              <div className="h-[20px] w-[20px] rotate-180">
                <img src={arrowUpIcon} alt="" />
              </div>
            </div>

            <div className="flex flex-col gap-y-4 py-6 px-4">
              <ListItem
                name="Reference"
                value={selectedObj?.reference ?? "N/A"}
              />
              <ListItem
                name="Repayment Due Date"
                value={
                  selectedObj?.due_date
                    ? format(new Date(selectedObj?.due_date), "dd MMM, yyyy")
                    : "N/A"
                }
              />
              <ListItem
                name="Source"
                value={selectedObj?.payment_channel || "N/A"}
              />
            </div>
          </div>

          {/* <div className="flex flex-col w-full border rounded-[6px] border-[#EDF2FF] py-6 mt-8">
            <div className="flex justify-between items-top px-6 pb-4">
              <h4 className="font-light text-[16px] text[#172B4D] leading-[19px]">
                Bank Details
              </h4>
              <div className="h-[20px] w-[20px]">
                <img src={arrowUpIcon} alt="" />
              </div>
            </div>
            <hr className="border-[#EDF2FF]" />

            <div className="px-6">
              <p className="text-[#232323] mt-6 font-light">
                Transaction was made from
              </p>
              <div className="flex flex-col bg-[#F5F7F9] rounded-[8px] p-4 mt-4">
                <div className="flex justify-between items-center">
                  <div className="flex flex-col">
                    <h1 className="font-light text-[#737373] text-[14px] leading-[17px]">
                      Bank Name
                    </h1>
                    <h4 className="font-normal text-[16px] leading-[19px] mt-1.5">
                    </h4>
                  </div>
                  <div
                    onClick={() => handleCopy("")}
                    className="border border-[#2054D2] items-end rounded-[15.75px] min-w-[55.78px] py-1 px-2 flex cursor-pointer"
                  >
                    <img src={copyButton} alt="" />

                    <div className="font-light text-aellaBlue text-[14px] leading-[17px] pl-1">
                      Copy
                    </div>
                  </div>
                </div>

                <div className="flex justify-between items-center mt-4 ">
                  <div className="flex flex-col">
                    <h1 className="font-light text-[#737373] text-[14px] leading-[17px] pr-2.5">
                      Account Name
                    </h1>
                    <h4 className="font-normal text-[16px] leading-[19px] mt-1.5">
                      {"N/A"}
                    </h4>
                  </div>

                  <div
                    onClick={() => handleCopy("")}
                    className="border border-[#2054D2] items-end rounded-[15.75px] min-w-[55.78px] py-1 px-2 flex cursor-pointer"
                  >
                    <img src={copyButton} alt="" />

                    <div className="font-light text-aellaBlue text-[14px] leading-[17px] pl-1">
                      Copy
                    </div>
                  </div>
                </div>
                <div className="flex justify-between items-center mt-4 ">
                  <div className="flex flex-col">
                    <h1 className="font-light text-[#737373] text-[14px] leading-[17px] pr-2.5">
                      Account Number
                    </h1>
                    <h4 className="font-normal text-[16px] leading-[19px] mt-1.5">
                      {"N/A"}
                    </h4>
                  </div>

                  <div
                    onClick={() => handleCopy("")}
                    className="border border-[#2054D2] items-end rounded-[15.75px] min-w-[55.78px] py-1 px-2 flex cursor-pointer"
                  >
                    <img src={copyButton} alt="" />

                    <div className="font-light text-aellaBlue text-[14px] leading-[17px] pl-1">
                      Copy
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="px-6">
              <p className="text-[#232323] mt-6 font-light">
                Transaction was sent to
              </p>
              <div className="flex flex-col bg-[#F5F7F9] rounded-[8px] p-4 mt-4">
                <div className="flex justify-between items-center">
                  <div className="flex flex-col">
                    <h1 className="font-light text-[#737373] text-[14px] leading-[17px]">
                      Bank Name
                    </h1>
                    <h4 className="font-normal text-[16px] leading-[19px] mt-1.5">
                      {"N/A"}
                    </h4>
                  </div>
                  <div
                    onClick={() => handleCopy("")}
                    className="border border-[#2054D2] items-end rounded-[15.75px] min-w-[55.78px] py-1 px-2 flex cursor-pointer"
                  >
                    <img src={copyButton} alt="" />

                    <div className="font-light text-aellaBlue text-[14px] leading-[17px] pl-1">
                      Copy
                    </div>
                  </div>
                </div>

                <div className="flex justify-between items-center mt-4 ">
                  <div className="flex flex-col">
                    <h1 className="font-light text-[#737373] text-[14px] leading-[17px] pr-2.5">
                      Account Name
                    </h1>
                    <h4 className="font-normal text-[16px] leading-[19px] mt-1.5">
                      {"N/A"}
                    </h4>
                  </div>

                  <div
                    onClick={() => handleCopy("")}
                    className="border border-[#2054D2] items-end rounded-[15.75px] min-w-[55.78px] py-1 px-2 flex cursor-pointer"
                  >
                    <img src={copyButton} alt="" />

                    <div className="font-light text-aellaBlue text-[14px] leading-[17px] pl-1">
                      Copy
                    </div>
                  </div>
                </div>
                <div className="flex justify-between items-center mt-4 ">
                  <div className="flex flex-col">
                    <h1 className="font-light text-[#737373] text-[14px] leading-[17px] pr-2.5">
                      Account Number
                    </h1>
                    <h4 className="font-normal text-[16px] leading-[19px] mt-1.5">
                      {"N/A"}
                    </h4>
                  </div>

                  <div
                    onClick={() => handleCopy("")}
                    className="border border-[#2054D2] items-end rounded-[15.75px] min-w-[55.78px] py-1 px-2 flex cursor-pointer"
                  >
                    <img src={copyButton} alt="" />

                    <div className="font-light text-aellaBlue text-[14px] leading-[17px] pl-1">
                      Copy
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          {/* <div className="py-4 px-6 bg-[#f5f5f5] mt-8 self-center flex items-center gap-x-3 align-self-center rounded-[4px] cursor-pointer">
            <div className="h-4 w-4">
              <DownloadIcon />
            </div>
            <p className="text-base text-[#5B5B5B] font-light">
              Download Receipt
            </p>
          </div> */}
        </div>
      </div>
    </ModalContainer>
  );
};
