import { useState } from "react";
import { toast } from "react-toastify";

import { ReactComponent as SearchIcon } from "../../assets/images/svg/search-icon.svg";
import { ReactComponent as CloseModalIcon } from "../../assets/images/svg/close-icon.svg";
import { SecondaryLoader } from "../../component/Loader/SecondaryLoader";
import BlueButton, { OutlineButton } from "../../component/Button/BlueButton";
import { RemoveItemModal } from "./Modal/RemoveItemModal";
import { ModalContainer } from "../../modal/ModalContainer";
import { EmployeesPageState } from "../../interfaces/employee-loans";

const ConfirmationModal = ({
  showConfirmation,
  closeModal,
  handleSubmit,
}: {
  showConfirmation: boolean;
  closeModal: () => void;
  handleSubmit: () => void;
}) => {
  return (
    <ModalContainer showModal={showConfirmation} closeModal={closeModal}>
      <div className="w-full lg:w-[35vw] relative h-auto min-h-[30vh] max-h-[90vh] z-50 flex flex-col items-center justify-center text-center p-10 rounded-[8px] bg-white">
        <p className="text-aellaBlack text-2xl">Confirmation Request</p>
        <p className="text-[#5B5B5B] font-light mt-4">
          Are you sure you want to upload these <br />
          employees into the system?
        </p>
        <BlueButton
          onClick={handleSubmit}
          title="Yes, upload"
          className="h-10 mt-10 font-light"
        />
        <button
          type="button"
          className="absolute -top-8 -right-0 cursor-pointer"
          onClick={closeModal}
        >
          <CloseModalIcon />
        </button>
      </div>
    </ModalContainer>
  );
};

export const PreviewUploadedData = ({
  updateParentState,
  isLoading,
  dataSet,
}: {
  updateParentState: (state: Partial<EmployeesPageState>) => void;
  isLoading: boolean;
  dataSet: any;
}) => {
  const totalSuccessful = 2;
  const [accountList, setAccountList] = useState([
    {
      id: "1",
      name: "Hey There",
      dob: "21-09-1970",
      phoneNumber: "08111223344",
      payDay: "22-09",
      employmentDate: "22-09-1970",
    },
    {
      id: "3",
      name: "Hey There",
      dob: "21-09-1970",
      phoneNumber: "08111223344",
      payDay: "22-09",
      employmentDate: "22-09-1970",
    },
  ]);

  const [selectedItems, setSelectedItems] = useState<any>([]);
  const [searchTerm, setSearchTerm] = useState("");

  const [state, setState] = useState({
    removeModal: false,
    confirmation: false,
  });

  const updateState = (payload) => {
    setState((prev) => ({ ...prev, ...payload }));
  };

  const handleCheckboxClick = (itemId: any) => {
    if (selectedItems.includes(itemId)) {
      setSelectedItems(selectedItems.filter((id: any) => id !== itemId));
    } else {
      setSelectedItems([...selectedItems, itemId]);
    }
  };
  const handleSelectAll = () => {
    if (selectedItems?.length === accountList?.length) {
      setSelectedItems([]);
    } else setSelectedItems(accountList?.map((i: any) => i?.id));
  };
  const deselectAll = () => {
    setSelectedItems([]);
  };

  const searchToReturn = accountList?.filter(
    (item: any) =>
      item?.name?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
      item?.account_number?.toString()?.includes(searchTerm?.toLowerCase()) ||
      item?.bank_name?.toLowerCase()?.includes(searchTerm?.toLowerCase())
  );

  const deleteUser = () => {
    const newList = searchToReturn?.filter(
      (i: any) => !selectedItems?.includes(i?.id)
    );
    setAccountList(newList);
  };

  const renderList = () => {
    if (dataSet?.isFetching) {
      return <SecondaryLoader count={5} />;
    }

    return searchToReturn?.map((list: any, index: number) => {
      const { id } = list;
      return (
        <div
          key={index}
          className={`flex items-center justify-between border-b h-12 px-5 gap-x-1 text-sm text-[#5B5B5B] font-light cursor-pointer accent-aellaBlue ${
            selectedItems?.includes(list?.id) ? "bg-[#EDEFF5]" : "bg-[bg-white]"
          }`}
        >
          <input
            type="checkbox"
            value={id}
            checked={selectedItems?.includes(list?.id)}
            onChange={(e) => {
              handleCheckboxClick(list?.id);
            }}
            className="w-4 h-4 border-red mr-4 cursor-pointer"
          />
          <p className="w-[10%] mr-4 md:mr-0">Employee ID</p>
          <p className="w-[26%]">Full Name</p>
          <p className="w-[10%] mr-4 md:mr-0">Date Of Birth</p>
          <p className="w-[18%]">Phone Number</p>
          <p className="w-[10%]">Pay Day</p>
          <p className="w-[15%] text-right">Employment Date</p>
        </div>
      );
    });

    // return (
    //   <div className="flex h-[150px] bg-white w-full items-center justify-center">
    //     <p className="text-2xl">No Data Found.</p>
    //   </div>
    // );
  };

  return (
    <div className="mb-32 overflow-x-auto md:overflow-x-hidden hide_scrollbar">
      <div className="flex justify-between items-center w-full flex-col gap-x-6 gap-y-4 md:flex-row">
        <p className="text-2xl font-light text-[#232323] self-start">
          Uploaded Data
        </p>
      </div>
      <div className="w-[280%] sm:w-[180%] md:w-full overflow-x-auto hide_scrollbar">
        <div className="bg-[#EEF1F5] h-12 mt-5 px-6 flex items-center justify-between "></div>
        <div className="bg-white pt-3 pb-6 px-6">
          <div className="h-10 w-full flex items-center justify-between opacity-0">
            <div
              className={`flex items-center w-auto gap-x-4 ${
                selectedItems?.length ? "opacity-100" : "opacity-50"
              }`}
            >
              <p className="text-[#232323] font-light text-sm pr-4 border-r-[2px] border-[#BFC7D9]">
                {selectedItems?.length} Selected
              </p>
              <button
                onClick={() => {
                  updateState({
                    removeModal: true,
                  });
                }}
                type="button"
                className="text-[#eb5757] border border-[#EB5757] text-sm font-light h-8 w-[90px] rounded-[4px]"
              >
                Delete
              </button>
              {/* <RemoveIcon
                className="cursor-pointer"
                onClick={() => deselectAll()}
              /> */}
            </div>
            <div
              className={`w-[400px] flex items-center gap-x-3 px-3 h-10 rounded-[4px] border border-[#EDEFF5]`}
            >
              <SearchIcon />
              <input
                value={searchTerm}
                onChange={(e: any) => setSearchTerm(e.target.value)}
                className="bg-inherit h-full placeholder:text-[#5B5B5B] text-aellaBlack flex-1 text-sm placeholder:text-sm placeholder:font-light outline-none"
                placeholder="Search by employee ID, full name, name, loan ID, email..."
              />
            </div>
          </div>

          <div className="flex justify-between items-center bg-[#F8FAFC] border-b h-10 gap-x-1 px-5 mt-10 text-sm text-[#0B1E4B]">
            <input
              type="checkbox"
              checked={
                !!(
                  selectedItems?.length === accountList?.length &&
                  accountList?.length
                )
              }
              onChange={handleSelectAll}
              className="w-4 h-4 border-red mr-4"
            />
            <p className="w-[10%] mr-4 md:mr-0">Employee ID</p>
            <p className="w-[26%]">Full Name</p>
            <p className="w-[10%] mr-4 md:mr-0">Date Of Birth</p>
            <p className="w-[18%]">Phone Number</p>
            <p className="w-[10%]">Pay Day</p>
            <p className="w-[15%] text-right">Employment Date</p>
          </div>

          {/* mapped items */}
          {renderList()}
        </div>
      </div>
      <div className="flex justify-end mt-10 gap-x-[10px]">
        <OutlineButton
          onClick={() =>
            updateParentState({
              stage: "initiate",
            })
          }
          title="Cancel"
        />
        <BlueButton
          onClick={() => {
            if (!accountList?.length) {
              return;
            }
            if (totalSuccessful === accountList?.length) {
              updateState({
                confirmation: true,
              });
            } else toast.error("Please validate all Accounts");
          }}
          title="Confirm"
          className="h-12 w-[145px] flex justify-center"
        />
      </div>

      <RemoveItemModal
        showModal={state.removeModal}
        closeModal={() =>
          updateState({
            removeModal: false,
          })
        }
        deleteUser={deleteUser}
        setSelectedItems={setSelectedItems}
      />

      <ConfirmationModal
        showConfirmation={state.confirmation}
        closeModal={() =>
          updateState({
            confirmation: false,
          })
        }
        handleSubmit={() => {}}
      />
    </div>
  );
};
