import { ReactComponent as Eye } from "../../../assets/images/svg/eye-icon.svg";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { RegistrationFormData } from "../../../interfaces/login";
import { useState } from "react";
import CustomInputField from "../../../component/CustomHTMLElements/CustomInputField";
import { Loader } from "../../../component/Loader/Loader.component";

const schemaPassword = yup.object().shape({
  password: yup.string().required("Input must not be empty"),
});

interface IPassword {
  setStep: (newState: number) => void;
  setState: any;
  state: {
    currentPassword: string;
  };
}

const OldPassword: React.FC<IPassword> = ({ setStep, setState, state }) => {
  const [isVisible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<RegistrationFormData>({
    criteriaMode: "all",
    mode: "all",
    resolver: yupResolver(schemaPassword),
  });

  const togglePassword = () => {
    setVisible(!isVisible);
  };

  const onSubmit = async ({ password }: { password: string }) => {
    setLoading(true);
    setStep(2);
    setState({
      ...state,
      currentPassword: password,
    });

    setLoading(false);
  };
  return (
    <div>
      <div className="p-[15px] md:p-[40px]">
        <p className="text-[24px] mb-[30px]">Enter your Old Password</p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="relative">
            <CustomInputField
              type={!isVisible ? "password" : "text"}
              maxLength={128}
              placeholder="********"
              label="Enter password"
              errors={errors.password}
              hasActionButton={true}
              {...register("password")}
            />

            <Eye
              onClick={togglePassword}
              className="absolute right-[12px] top-[70%] translate-y-[-50%] cursor-pointer"
            />
          </div>

          {/* <div className="w-[5px] h-[5px] bg-[#2054D2] rounded-[50%]">{errors.password}</div> */}
          {errors.password && (
            <div className="flex gap-[9px] items-center mt-[-10px]">
              <div className="w-[5px] h-[5px] bg-[#2054D2] rounded-[50%]"></div>
              <p className="text-[12px] font-thin">Input cannot be Empty</p>
            </div>
          )}

          <div className="mt-[45px] flex flex-row-reverse">
            <button
              disabled={loading}
              type="submit"
              className="px-[50px] py-[18px] text-white bg-[#2054D2] rounded-[4px] flex justify-center"
            >
              Continue
              {loading && <Loader />}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default OldPassword;
