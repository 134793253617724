import { useEffect, useState } from "react";
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { format, parse, subYears } from "date-fns";
import { useNavigate } from "react-router-dom";
import { useQueries } from "react-query";

import { ReactComponent as CalendarIcon } from "../../../assets/images/svg/calendar-icon.svg";
import { ReactComponent as ArrowIcon } from "../../../assets/images/svg/black-arrow-down-icon.svg";
import { ReactComponent as ViewAllIcon } from "../../../assets/images/svg/external-link.svg";
import { FilterByDate } from "../Modal/FilterByDateModal";
import { ROUTES } from "../../../helpers/routes";
import {
  getAllEmployeesFn,
  getLoanDisbursementMetricsFn,
  getLoanMetricsFn,
} from "../queries";
import { useLoansContext } from "../EmployeeLoans";
import { SpinLoader } from "../../../component/Loader/Loader.component";
import { formatMoney } from "../../../helpers/formatMoney";
import { ErrorMessage } from "../ErrorMessage";

type State = {
  filterModal: boolean;
  filterType: string;
};

const monthsOfYear = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
].map((month) => ({ month, amount: 0 }));

const formatNaira = (value: number) => `₦${value.toLocaleString()}`;
const today = new Date();
const oneYearAgo = subYears(today, 1);
const firstDayOfTheMonth = format(oneYearAgo, "yyyy-MM-dd");

const parseDate = (dateString: string) => {
  if (!dateString) return null;
  const [day, month, year] = dateString.split("-").map(Number);
  if (year === 1970) return null;
  return new Date(year, month - 1, day);
};

const formatDateString = (date: Date | null) => {
  return date ? format(date, "yyyy-MM-dd") : "";
};

export default function LoanOverview() {
  const { updateContext } = useLoansContext();
  const navigate = useNavigate();
  const [filterObj, setFilterObj] = useState({
    "loans-requested": { to: null, from: null },
    "loans-approved": { to: null, from: null },
    "loans-declined": { to: null, from: null },
    "loans-defaulted": { to: null, from: null },
  });
  const [state, setState] = useState<State>({
    filterModal: false,
    filterType: "",
  });

  const updateState = (payload: Partial<State>) => {
    setState((prev) => ({ ...prev, ...payload }));
  };

  useEffect(() => {
    updateContext({
      showHeader: true,
    });
  }, []);

  const [
    employeesData,
    approvedLoansData,
    pendingLoansData,
    declinedLoansData,
    defaultedLoansData,
    disbursementData,
  ] = useQueries([
    {
      queryKey: ["employees"],
      queryFn: () => getAllEmployeesFn({ page: 1, perPage: 10 }),
    },
    {
      queryKey: ["approved loan number", filterObj["loans-approved"]],
      queryFn: () =>
        getLoanMetricsFn({
          loanStatus: "approved",
          startDate:
            formatDateString(parseDate(filterObj["loans-approved"]?.from)) ||
            firstDayOfTheMonth,
          endDate:
            formatDateString(parseDate(filterObj["loans-approved"]?.to)) ||
            format(today, "yyyy-MM-dd"),
        }),
    },
    {
      queryKey: ["pending loan number", filterObj["loans-requested"]],
      queryFn: () =>
        getLoanMetricsFn({
          loanStatus: "all",
          startDate:
            formatDateString(parseDate(filterObj["loans-requested"]?.from)) ||
            firstDayOfTheMonth,
          endDate:
            formatDateString(parseDate(filterObj["loans-requested"]?.to)) ||
            format(today, "yyyy-MM-dd"),
        }),
    },
    {
      queryKey: ["declined loan number", filterObj["loans-declined"]],
      queryFn: () =>
        getLoanMetricsFn({
          loanStatus: "declined",
          startDate:
            formatDateString(parseDate(filterObj["loans-declined"]?.from)) ||
            firstDayOfTheMonth,
          endDate:
            formatDateString(parseDate(filterObj["loans-declined"]?.to)) ||
            format(today, "yyyy-MM-dd"),
        }),
    },
    {
      queryKey: ["defaulted loan number", filterObj["loans-defaulted"]],
      queryFn: () =>
        getLoanMetricsFn({
          loanStatus: "default",
          startDate:
            formatDateString(parseDate(filterObj["loans-defaulted"]?.from)) ||
            firstDayOfTheMonth,
          endDate:
            formatDateString(parseDate(filterObj["loans-defaulted"]?.to)) ||
            format(today, "yyyy-MM-dd"),
        }),
    },
    {
      queryKey: ["disbursement metrics"],
      queryFn: getLoanDisbursementMetricsFn,
    },
  ]);

  const totalEmployees = employeesData?.data?.meta?.total ?? 0;
  const approvedLoans = approvedLoansData?.data?.data ?? 0;
  const declinedLoans = declinedLoansData?.data?.data ?? 0;
  const pendingLoans = pendingLoansData?.data?.data ?? 0;
  const defaultedLoans = defaultedLoansData?.data?.data ?? 0;
  const disbursement = disbursementData?.data?.data;

  const chartArray = monthsOfYear.map(({ month }) => ({
    shortForm: month.substring(0, 3),
    month,
    amount:
      disbursement?.disbursedLoans?.find((item) => item?.month === month)
        ?.amount ?? 0,
  }));

  const maxAmount = Math.max(...chartArray.map((item) => item.amount));
  const yAxisMax = Math.ceil(maxAmount / 100000) * 100000;

  const renderChart = () => {
    if (disbursementData?.isFetching) {
      return (
        <div className="bg-white h-full py-20 flex-1 flex flex-col gap-y-1 items-center justify-center border border-[#EDF2FF]">
          <div className="w-14 h-14 border-aellaBlue loader__container" />
          <p className="text-aellaBlue font-[300]">
            Getting Disbursement Metrics
          </p>
        </div>
      );
    }

    if (disbursementData?.isError) {
      return (
        <div className="w-full h-full flex-1 flex justify-center items-center bg-white">
          <ErrorMessage onClick={() => disbursementData?.refetch()} />
        </div>
      );
    }

    return (
      <div className="bg-white border border-[#EDF2FF] py-7 px-3 lg:px-6 flex-1 h-full">
        <div className="flex flex-col gap-y-4 items-start sm:flex-row justify-between sm:items-center">
          <div className="text-aellaBlack">
            <p className="text-[18px] font-light">
              Total Value of Loan Disbursed:
            </p>
            <p className="mt-2 text-[24px] lg:text-[2rem]">
              ₦{formatMoney(disbursement?.totalSum || 0)}
            </p>
          </div>
          <div className="text-aellaBlack">
            <p className="text-[18px] font-light">
              Total Number of Loan Disbursed:
            </p>
            <p className="mt-2 text-[24px] lg:text-[2rem]">
              {disbursement?.totalCount || 0}
            </p>
          </div>
        </div>
        <div className="font-light w-full mt-10 sm:mt-6">
          <p className="text-[18px] font-[500] mb-4 text-aellaBlack">
            Overview
          </p>
          <div className="w-full h-[500px] -ml-3 lg:mx-0">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart
                data={chartArray}
                margin={{ top: 0, bottom: 0, left: 0, right: 0 }}
              >
                <CartesianGrid strokeDasharray="3 3" vertical={false} />
                <XAxis dataKey="shortForm" axisLine={false} fontSize={12} />
                <YAxis
                  axisLine={false}
                  fontSize={12}
                  tickCount={11}
                  tickFormatter={formatNaira}
                  domain={[0, yAxisMax || 100000]}
                />
                <Tooltip
                  cursor={false}
                  formatter={(value) => [
                    formatNaira(value as number),
                    "Amount",
                  ]}
                />
                <Line type="monotone" dataKey="amount" stroke="#2054D2" />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    );
  };

  const renderDateRange = (type: string) => {
    const from = parseDate(filterObj[type]?.from);
    const to = parseDate(filterObj[type]?.to);
    return (
      <span className="tracking-[0.2px]">
        {from
          ? format(from, "dd MMM yyyy")
          : format(new Date(firstDayOfTheMonth), "dd MMM yyyy")}{" "}
        - {to ? format(to, "dd MMM yyyy") : format(today, "dd MMM yyyy")}
      </span>
    );
  };

  return (
    <div className="bg-aellaDimGray w-full overflow-x-hidden">
      <div className="px-4 lg:px-10 py-[32px] mb-10 w-full">
        <div className="flex flex-col lg:flex-row gap-x-6 w-full h-auto lg:h-[700px] gap-y-5">
          <div className="w-full lg:w-[35%] h-auto lg:min-h-[700px] lg:max-h-[700px] flex flex-col gap-y-5 lg:gap-y-0">
            <div className="bg-white h-auto lg:h-[350px] flex flex-col justify-center border border-[#EDF2FF] px-6 py-8 lg:py-[60px] w-full">
              <div className="text-aellaBlack">
                <p className="text-[14px] font-light text-[#232323]">
                  TOTAL NUMBER OF EMPLOYEE
                </p>
                <div className="mt-6 text-[24px] lg:text-[2rem]">
                  {employeesData?.isFetching ? <SpinLoader /> : totalEmployees}
                </div>
                <p className="mt-2 text-[13px] text-aellaGray font-light">
                  This is the total number of employee you have on this system.
                </p>
              </div>
            </div>
            <div className="bg-white lg:h-[350px] flex flex-col justify-center border border-[#EDF2FF] px-6 py-8 lg:py-[50px] w-full">
              <div
                className="text-[13px] font-[300] gap-x-3 flex flex-wrap gap-y-2 items-center cursor-pointer"
                onClick={() => {
                  updateState({
                    filterModal: true,
                    filterType: "loans-defaulted",
                  });
                }}
              >
                <span className="text-[14px] text-aellaGray">Showing:</span>
                <span className="border h-9 text-[#232323] rounded-[4px] py-2 flex gap-x-3 flex-wrap px-[10px]">
                  <CalendarIcon />
                  {renderDateRange("loans-defaulted")}
                  <div className="hidden lg:block">
                    <ArrowIcon />
                  </div>
                </span>
              </div>
              <div className="text-aellaBlack mt-10">
                <p className="text-[14px] font-light text-[#232323]">
                  TOTAL LOANS IN DEFAULT
                </p>
                <div className="mt-6 text-[24px] lg:text-[2rem]">
                  {defaultedLoansData?.isFetching ? (
                    <SpinLoader />
                  ) : (
                    defaultedLoans
                  )}
                </div>
                <p className="mt-2 text-[13px] text-aellaGray font-light">
                  This is the total number of loans that have not been repaid.
                </p>
              </div>
            </div>
          </div>
          {renderChart()}
        </div>

        <div className="mt-5 lg:mt-10 h-auto lg:h-[240px] flex flex-wrap gap-y-4 border border-[#EDF2FF]">
          {/* Loans Requested */}
          <div className="h-full p-6 w-full lg:w-[33.333333%] border-r boder-r-[#edf2ff] bg-white flex flex-col justify-center">
            <div
              className="text-[13px] font-[300] gap-x-3 flex  flex-wrap gap-y-2 items-center cursor-pointer"
              onClick={() => {
                updateState({
                  filterModal: true,
                  filterType: "loans-requested",
                });
              }}
            >
              <span className="text-[14px] text-aellaGray">Showing:</span>
              <span className="border h-9 text-[#232323] rounded-[4px] py-2 flex gap-x-3 flex-wrap px-[10px]">
                <CalendarIcon />
                {renderDateRange("loans-requested")}
                <div className="hidden lg:block">
                  <ArrowIcon />
                </div>
              </span>
            </div>
            <div className="text-aellaBlack mt-4">
              <p className="text-[14px] font-light text-[#232323]">
                LOANS REQUESTED
              </p>
              <div className="mt-4 text-[24px] lg:text-[2rem]">
                {pendingLoansData?.isFetching ? <SpinLoader /> : pendingLoans}
              </div>
              <p className="mt-2 text-[13px] text-aellaGray font-light">
                This is the total number of loans that have been requested
              </p>
            </div>
          </div>

          {/* Loans Approved */}
          <div className="h-full p-6 w-full lg:w-[33.333333%] border-r border-r-[#edf2ff] bg-white flex flex-col justify-center">
            <div
              className="text-[13px] font-[300] gap-x-3 flex flex-wrap gap-y-2 items-center cursor-pointer"
              onClick={() => {
                updateState({
                  filterModal: true,
                  filterType: "loans-approved",
                });
              }}
            >
              <span className="text-[14px] text-aellaGray">Showing:</span>
              <span className="border h-9 text-[#232323] rounded-[4px] py-2 flex gap-x-3 flex-wrap px-[10px]">
                <CalendarIcon />
                {renderDateRange("loans-approved")}
                <div className="hidden lg:block">
                  <ArrowIcon />
                </div>
              </span>
            </div>
            <div className="text-aellaBlack mt-4">
              <p className="text-[14px] font-light text-[#232323]">
                LOANS APPROVED
              </p>
              <div className="mt-4 text-[24px] lg:text-[2rem]">
                {approvedLoansData?.isFetching ? <SpinLoader /> : approvedLoans}
              </div>
              <div className="mt-2 flex justify-between items-center w-full gap-x-5">
                <p className="text-[13px] text-aellaGray font-light">
                  This is the total number of loans that have been approved
                </p>
                <button
                  type="button"
                  onClick={() =>
                    navigate(`${ROUTES.EMPLOYEE_LOANS}/${ROUTES.LOAN_BOOK}`, {
                      state: { data: { type: "Approved Loans" } },
                    })
                  }
                  className="text-aellaBlue text-[14px] flex items-center justify-end gap-x-3 flex-1 min-w-[30%] cursor-pointer"
                >
                  <span>View All</span>
                  <ViewAllIcon />
                </button>
              </div>
            </div>
          </div>

          {/* Loans Declined */}
          <div className="h-full p-6 w-full lg:w-[33.333333%] border-r border-r-[#edf2ff] bg-white flex flex-col justify-center">
            <div
              className="text-[13px] font-[300] gap-x-3 flex flex-wrap gap-y-2 items-center cursor-pointer"
              onClick={() => {
                updateState({
                  filterModal: true,
                  filterType: "loans-declined",
                });
              }}
            >
              <span className="text-[14px] text-aellaGray">Showing:</span>
              <span className="border h-9 text-[#232323] rounded-[4px] py-2 flex gap-x-3 flex-wrap px-[10px]">
                <CalendarIcon />
                {renderDateRange("loans-declined")}
                <div className="hidden lg:block">
                  <ArrowIcon />
                </div>
              </span>
            </div>
            <div className="text-aellaBlack mt-4">
              <p className="text-[14px] font-light text-[#232323]">
                LOANS DECLINED
              </p>
              <div className="mt-4 text-[24px] lg:text-[2rem]">
                {declinedLoansData?.isFetching ? <SpinLoader /> : declinedLoans}
              </div>
              <div className="mt-2 flex justify-between items-center w-full gap-x-5">
                <p className="text-[13px] text-aellaGray font-light">
                  This is the total number of loans that have been declined
                </p>
                <button
                  type="button"
                  onClick={() =>
                    navigate(`${ROUTES.EMPLOYEE_LOANS}/${ROUTES.LOAN_BOOK}`, {
                      state: { data: { type: "Declined Requests" } },
                    })
                  }
                  className="text-aellaBlue text-[14px] flex items-center justify-end gap-x-3 flex-1 min-w-[30%] cursor-pointer"
                >
                  <span>View All</span>
                  <ViewAllIcon />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FilterByDate
        showModal={state.filterModal}
        closeModal={() => updateState({ filterModal: false })}
        setFilterObj={setFilterObj}
        filterObj={filterObj}
        type={state.filterType}
      />
    </div>
  );
}
