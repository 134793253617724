import moment from "moment";
import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData } from "../apis/apiMethods";
import CONFIG from "../helpers/config";

export const getAllSubAccounts = async ({ queryKey }: { queryKey: any }) => {
  const { from, to, query, status, page, size } = queryKey[1];
  let queryParams = `?page=${page}&per_page=${size}`;
  if (status) {
    queryParams = `${queryParams}&status=${status}`;
  }
  if (query) {
    queryParams = `${queryParams}&query=${query}`;
  }
  if (from) {
    queryParams = `${queryParams}&from=${moment(from).format("YYYY-MM-DD")}`;
  }

  if (to) {
    queryParams = `${queryParams}&to=${moment(to).format("YYYY-MM-DD")}`;
  }

  const data = await getData(
    `${CONFIG.BASE_URL2}${apiEndpoints.SUBACCOUNTS}${queryParams}`
  );

  return data;
};

export const useAllSubAccounts = ({
  from,
  to,
  query,
  status,
  page,
  size,
}: {
  from: string;
  to: string;
  query: string;
  status: string;
  page: string;
  size: string;
}) => {
  return useQuery(
    ["getAllSubAccounts", { from, to, query, status, page, size }],

    getAllSubAccounts,
    {
      refetchOnWindowFocus: false, // Refetch on window focus
      retry: 1,
    }
  );
};
