import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData } from "../apis/apiMethods";
import CONFIG from "../helpers/config";
import moment from "moment";

export const getPosDisputeActivity = async ({
  queryKey,
}: {
  queryKey: any;
}) => {
  const { id, start_date, end_date } = queryKey[1];

  let queryParams = `/activities/${id}`;

  if (start_date) {
    queryParams = `${queryParams}?start_date=${moment(start_date).format(
      "YYYY-MM-DD"
    )}`;
  }

  if (end_date) {
    queryParams = `${queryParams}&end_date=${moment(end_date).format(
      "YYYY-MM-DD"
    )}`;
  }

  const { data }: any = await getData(
    `${CONFIG.BASE_URL2}${apiEndpoints.POS_DISPUTES}${queryParams}`
  );
  return data;
};

export const usePosDisputeActivity = ({
  id,
  start_date,
  end_date,
}: {
  id: string;
  start_date?: Date | string | null;
  end_date?: Date | string | null;
}) => {
  return useQuery(
    ["getPosDisputeActivity", { id, start_date, end_date }],
    getPosDisputeActivity,
    {
      retry: 1,
      enabled: id ? true : false,
      refetchOnWindowFocus: false,
    }
  );
};
