import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { ReactComponent as Chevron } from "../../assets/images/svg/chev-right.svg";
import { ROUTES } from "../../helpers/routes";

export const EmployeeHeader = () => {
  const location = useLocation();
  const [name, setName] = useState("");
  const [tab, setTab] = useState("");

  useEffect(() => {
    setTab(location?.pathname);
    setName(location?.state?.data?.full_name);
  }, [location]);

  const renderPathName = () => {
    if (tab?.includes(ROUTES.EMPLOYEE_LOANS_OVERVIEW)) return "Overview";
    if (tab === `${ROUTES.EMPLOYEE_LOANS}/${ROUTES.EMPLOYEES}`)
      return "Employees";
    if (tab?.includes(ROUTES.EMPLOYEE_DETAILS)) return "Employee";
    if (tab?.includes(ROUTES.LOAN_CONFIGURATION)) return "Loan Configuration";
    if (tab?.includes(ROUTES.LOAN_BOOK)) return "Loan Book";
  };

  return (
    <div className="flex items-center gap-x-3">
      <p className="text-[#7B7B7B]">{renderPathName()}</p>
      {name && (
        <>
          <Chevron />
          <p className="text-[#232323] capitalize">{name}</p>
        </>
      )}
    </div>
  );
};
