import Skeleton from "react-loading-skeleton";
export const SecondaryLoader = ({
  count,
  height,
  margin,
  full,
  baseColor,
}: {
  count?: any;
  height?: any;
  margin?: any;
  full?: any;
  baseColor?: string;
}) => {
  return (
    <div
      className={`w-full ${
        full ? "h-screen" : "min-h-full"
      } px-4 lg:px-10 py-6 flex flex-col`}
    >
      <Skeleton
        containerClassName={`block`}
        className={`w-full ${
          height ? height : "h-[1.5rem]"
        } rounded-[20px] loader-gradient ${margin ? margin : "mt-6"}`}
        count={count}
        duration={3.5}
        baseColor={baseColor ?? "#F7F8F8"}
      />
      <div className={`flex-1 h-[200px] w-full ${margin ? margin : "mt-6"}`}>
        <Skeleton
          containerClassName="block w-full"
          className="w-full min-h-[180px] rounded-[3px] "
          duration={3.5}
          baseColor={baseColor ?? "#F7F8F8"}
        />
      </div>
    </div>
  );
};
