import moment from "moment";
import { useEffect, useState } from "react";
import NoDataState, {
  NoListState,
} from "../../../component/NoDataState/NoDataState";
import transactionIcon from "../../../assets/images/svg/transaction-icon.svg";
import filterIcon from "../../../assets/images/svg/filter-icon.svg";
import Pagination from "../../../component/Pagination/Pagination.component";
import DisputeFilterModal from "../Modal/DisputeFilterModal";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAllPosDisputes } from "../../../hooks/useAllPosDisputes";
import { formatMoney } from "../../../helpers/formatMoney";
import { SecondaryLoader } from "../../../component/Loader/SecondaryLoader";
import { ROUTES } from "../../../helpers/routes";
import {
  attachClassNameToTag,
  getStatusName,
} from "../../../helpers/attachClassNameToTag";
import { DisputeType } from "./DisputeInterface";
import { truncateString } from "../../../helpers/formatter";
import { formatDistance, isAfter } from "date-fns";
import NoDataIcon from "../../../assets/images/svg/empty.svg";

export type SearchType = {
  startDate?: Date | null;
  endDate?: Date | null;
  category?: string;
  status?: string;
  search?: string;
  page: number;
  size?: number;
};

export default function Disputes() {
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(15);
  const [filterModal, setFilterModal] = useState(false);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams({});
  const [searchState, setSearchState] = useState<SearchType>({
    startDate: null,
    endDate: null,
    category: "",
    status: "",
    search: "",
    page: currentPage,
    size: postsPerPage,
  });
  const [preSearchState, setPreSearchState] = useState<SearchType>({
    page: currentPage,
    size: postsPerPage,
  });
  const { data: allPosDisputes, isLoading: allPosDisputesLoading } =
    useAllPosDisputes(preSearchState);

  useEffect(() => {
    if (currentPage) {
      setPreSearchState({ ...preSearchState, page: currentPage });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  //update the react-query with the url params
  useEffect(() => {
    const currentParams: any = Object.fromEntries([...searchParams]);

    if (
      currentParams?.startDate ||
      currentParams?.endDate ||
      currentParams?.category ||
      currentParams?.status ||
      currentParams?.search
    ) {
      setSearchState({
        startDate: currentParams?.startDate
          ? new Date(currentParams?.startDate)
          : null,
        endDate: currentParams?.endDate
          ? new Date(currentParams?.endDate)
          : null,
        category: currentParams?.category,
        status: currentParams?.status,
        search: currentParams?.search,
        page: Number(currentPage),
        size: Number(postsPerPage),
      });
    }
    setPreSearchState({
      startDate: currentParams?.startDate
        ? new Date(currentParams?.startDate)
        : null,
      endDate: currentParams?.endDate ? new Date(currentParams?.endDate) : null,
      category: currentParams?.category,
      status: currentParams?.status,
      search: currentParams?.search,
      page: Number(currentPage),
      size: Number(postsPerPage),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, currentPage]);

  //update the url params with the initial query
  useEffect(() => {
    const currentParams: any = Object.fromEntries([...searchParams]);

    if (!currentParams?.page || !currentParams?.size) {
      setSearchParams({
        page: JSON.stringify(currentPage),
        size: JSON.stringify(postsPerPage),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchState.startDate, searchState.endDate, searchParams]);

  const handleOnClick = (data: any) => {
    return navigate(ROUTES.POS_DISPUTE_DETAILS, {
      state: { data: data },
    });
  };

  return (
    <>
      <div className=" flex justify-end bg-white pr-10 py-[13.5px] sm:border-t border-t-0">
        {/* <div className="relative flex w-full lg:w-[190px] h-10">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <img className="w-4" src={searchIcon} alt="" />
          </div>
          <input
            type="text"
            placeholder="Search"
            className="placeholder:font-thin border border-[#EDEFF5] text-[#5B5B5B] text-sm rounded pl-10 py-3 focus:outline-none"
            onChange={(e) =>
              setPreSearchState({ ...searchState, search: e.target.value })
            }
          />
        </div> */}
        <label
          className="min-w-[114px] h-10 border bg-[#F5F5F5] px-2 lg:px-5 py-2 cursor-pointer rounded xxs:justify-center md:justify-around active:shadow-md transition duration-150 ease-in-out"
          htmlFor="filterModal"
          onClick={() => setFilterModal(true)}
        >
          <div className="xxs:px-1 md:px-2 py-1 h-[24px] flex items-center">
            <img
              className="object-contain w-[18px] h-[18px]"
              src={filterIcon}
              alt=""
            />
            <span className="ml-[12px] font-thin  text-gray-400  text-sm focus:outline-none focus:ring-0">
              Filter By
            </span>
          </div>
        </label>
      </div>
      <div className="bg-aellaDimGray w-full px-4 lg:px-10 py-[32px]">
        <div className="py-6 border border-aellaBorderGray bg-white px-6 lg:px-10 ">
          <div className="flex justify-between flex-wrap items-center pb-[18px]">
            <h1 className="text-[16px] lg:text-[24px] lg:leading-[29px] font-[350]">
              Disputes Listing
            </h1>
          </div>
          <div className="pt-4 lg:pt-10 border-t border-[#F1F1F1] overflow-x-auto w-full">
            {allPosDisputesLoading ? (
              <SecondaryLoader count={10} />
            ) : allPosDisputes?.data?.data?.data?.length === 0 ? (
              <NoListState
                icon={NoDataIcon}
                textHeader="No Dispute Yet"
                firstParaText="There will be information on this page when you have a dispute."
                // secondParaText="Click the button below to request for a POS Machine."
              />
            ) : (
              <div className="bg-white md:w-full max-h-screen h-auto overflow-y-auto overflow-x-auto md:overflow-x-hidden hide_scrollbar">
                <div className=" mt-4 ">
                  <div className="flex items-center bg-[#F7F9FC] w-[400%] gap-x-4 overflow-x-auto md:overflow-x-hidden md:gap-x-0 md:w-full px-4 py-4 text-aellaBlack text-sm border-b">
                    <p className="w-[16%] capitalize">Transaction Ref</p>
                    <p className="w-[20%] capitalize">Amount</p>
                    <p className="w-[15%] capitalize">Dispute Category</p>
                    <p className="w-[20%] text-center capitalize">Status</p>
                    <p className="w-[20%] text-center capitalize">
                      Date Created
                    </p>
                    <p className="flex-1 text-right capitalize ">Due In</p>
                  </div>
                  {allPosDisputes?.data?.data?.data?.map(
                    (dispute: DisputeType) => {
                      return (
                        <div
                          key={dispute?.id}
                          onClick={() => handleOnClick(dispute)}
                          className="flex items-center bg-white w-[400%] gap-x-4 overflow-x-auto md:overflow-x-hidden md:gap-x-0 md:w-full p-4 text-[#5B5B5B] text-sm font-light border-b cursor-pointer"
                        >
                          <p className="w-[16%]">
                            {truncateString(dispute?.transaction_reference, 10)}
                          </p>

                          <p className="w-[20%]">
                            &#x20A6;{formatMoney(dispute?.amount)}
                          </p>
                          <p className="w-[15%]">{dispute?.category}</p>
                          <p className={`w-[20%] text-center`}>
                            <span
                              className={`w-full text-center ${attachClassNameToTag(
                                dispute?.status
                              )} `}
                            >
                              {getStatusName(dispute?.status)}
                            </span>
                          </p>
                          <p className="w-[20%] text-center">
                            {dispute?.created_at
                              ? moment(dispute?.created_at).format(
                                  "DD MMM YYYY, LT"
                                )
                              : "-"}
                          </p>
                          <p
                            className={`flex-1 text-right ${
                              dispute?.status?.toLowerCase() === "decline" ||
                              dispute?.status?.toLowerCase() ===
                                "accept dispute"
                                ? ""
                                : isAfter(
                                    new Date(),
                                    new Date(dispute?.due_date)
                                  )
                                ? "text-[#EB5757]"
                                : ""
                            }`}
                          >
                            {dispute?.due_date
                              ? dispute?.status?.toLowerCase() === "decline" ||
                                dispute?.status?.toLowerCase() ===
                                  "accept dispute"
                                ? "Closed"
                                : formatDistance(
                                    new Date(dispute?.due_date),
                                    new Date()
                                    // {
                                    //   addSuffix: true,
                                    // }
                                  )
                              : "-"}
                          </p>
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
            )}
          </div>
          {!!allPosDisputes?.data?.data?.data?.length && (
            <Pagination
              postsPerPage={postsPerPage}
              totalPosts={allPosDisputes?.data?.data?.totalItems}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalPages={allPosDisputes?.data?.data?.totalPages}
            />
          )}
        </div>
      </div>

      {filterModal && (
        <DisputeFilterModal
          setModalType={setFilterModal}
          setSearchState={setSearchState}
          searchState={searchState}
          setPreSearchState={setPreSearchState}
          preSearchState={preSearchState}
          setCurrentPage={setCurrentPage}
        />
      )}
    </>
  );
}
