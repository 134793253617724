import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useQueries } from "react-query";

import { InnerTab } from "../../component/Tab/Tab.component";
import { ReactComponent as BackIcon } from "../../assets/images/svg/back-icon.svg";
import { ReactComponent as Info } from "../../assets/images/svg/employee-info.svg";
import { useLoansContext } from "./EmployeeLoans";
import { getEmployeeLoansFn } from "./queries";
import { getMultiWordFirstLetters } from "../../helpers/others";
import BlueButton from "../../component/Button/BlueButton";
import {
  attachClassToBoolean,
  attachClassToLoanStatus,
  attachNameToLoanStatus,
} from "../../helpers/attachClassNameToTag";
import { truncateString } from "../../helpers/formatter";
import { EmployeeRepaymentHistory } from "./Tabs/EmployeeRepaymentHistory";
import { EmployeeRepaymentDetailsModal } from "./Modal/LoanRepaymentDetailsModal";
import { formatMoney } from "../../helpers/formatMoney";
import { EmployerLoan, Loan } from "../../interfaces/employee-loans";
import { RequestLogs } from "./Tabs/RequestLogs";
import { LoanBookAction } from "./Modal/Action";
import { ApproveOrDeclineLoanRequestModal } from "./Modal/ApproveOrDeclineLoanRequestModal";

const ListItem = ({
  name,
  value,
  hasPadding,
  valueAsId,
}: {
  name: string;
  value: string | number;
  hasPadding?: boolean;
  valueAsId?: number;
}) => {
  return (
    <div
      className={`flex justify-between full font-light ${
        hasPadding && "py-4 border-b border-b-[#ECEDF0]"
      }`}
    >
      <p className="text-aellaGray max-w-[35%]">{name}</p>
      <p
        className={`p-1 rounded-[4px] max-w-[65%] text-right ${attachClassToLoanStatus(
          valueAsId
        )}`}
      >
        {value}
      </p>
    </div>
  );
};

const LoanDetails = () => {
  const perPage = 50;
  const { updateContext } = useLoansContext();
  const { state: locationState } = useLocation();
  const navigate = useNavigate();
  const { data } = Object(locationState) as {
    data: EmployerLoan;
  };

  const [activeTab, setActiveTab] = useState("Repayment History");
  const [selected, setSelected] = useState(null);
  const [selectedList, setSelectedList] = useState([]);
  const [invalidLoanList, setInvalidLoanList] = useState(null);
  const [state, setState] = useState({
    currentPage: 1,
    loanSearchParam: "",
    searchTerm: "",
    loanDetailsModal: false,
    actionModal: false,
    statusModal: false,
    status: "",
  });

  const updateState = (payload) => {
    setState((prev) => ({ ...prev, ...payload }));
  };

  const [loanDetailsData] = useQueries([
    {
      queryKey: ["employer loan details", data?.staff?.staff_id, data?.id],
      queryFn: () =>
        getEmployeeLoansFn({
          page: 1,
          perPage,
          staffId: data?.staff?.staff_id,
          loanId: data?.id,
        }),
      enabled: !!(data?.id && data?.staff?.staff_id),
    },
  ]);

  const wallet = loanDetailsData?.data?.data?.walletProfile;
  const loan: Loan = loanDetailsData?.data?.data?.loanSummary;
  const repaymentSchedule = loanDetailsData?.data?.data?.repaymentSchedule;

  useEffect(() => {
    updateContext({
      showHeader: false,
    });
  }, []);

  if (loanDetailsData?.isFetching) {
    return (
      <div className="bg-aellaDimGray h-screen w-full flex items-center justify-center">
        <div className="w-10 h-10 border-aellaBlue loader__container" />
      </div>
    );
  }

  if (loanDetailsData?.isError) {
    return (
      <div className="bg-aellaDimGray h-screen w-full flex flex-col text-center font-light gap-y-[10px] items-center justify-center text-aellaBlack">
        <p className="uppercase font-normal">Oops..</p>
        <p>
          An error occurred while <br /> getting loan details
        </p>
        <BlueButton title="Try Again" onClick={loanDetailsData?.refetch} />
      </div>
    );
  }

  return (
    <div className="bg-aellaDimGray">
      <div className="flex items-center py-[12px] lg:py-[14px]  justify-between px-10 bg-white border-y sticky top-0 right-0 border-[#EDEFF5]">
        <div
          onKeyDown={() => {}}
          className="flex items-center cursor-pointer"
          onClick={() => navigate(-1)}
        >
          <BackIcon />
          <span className="pl-[10px] text-[18px] font-[100] lg:leading-[30px] tracking-[0.3px]">
            Back
          </span>
        </div>
        {!!(loan?.status_text?.toLowerCase() === "pending") && (
          <div className="flex gap-x-2 items-center">
            <BlueButton
              title="Approve"
              onClick={() => {
                setSelectedList([loan]);
                updateState({
                  status: "Approve",
                  statusModal: true,
                });
              }}
            />
            <button
              onClick={() => {
                setSelectedList([loan]);
                updateState({
                  status: "Decline",
                  statusModal: true,
                });
              }}
              type="button"
              className={`bg-[#FE4149] text-white text-base font-light h-12 px-[25px] rounded-[4px]`}
            >
              Decline
            </button>
          </div>
        )}
      </div>

      <div className="flex flex-col lg:flex-row items-start justify-between my-10 px-4 lg:px-10 w-full">
        <div className="w-full lg:w-[35%] ">
          <div className="py-4 px-4 w-full bg-white rounded-lg border border-[#EDEFF5] mr-6 flex items-center">
            <div className="rounded-full inline-flex shrink-0 bg-[#E5B7A0] w-[56px] h-[56px] items-center justify-center top-6  cursor-pointer mr-1 text-aellaBlue text-[18px]">
              {getMultiWordFirstLetters(
                wallet?.first_name + " " + wallet?.last_name
              )}
            </div>
            <div className="ml-4">
              <p className="text-[16px] text-aellaBlack leading[19px] font-[300] tracking-[0.2px] capitalize">
                {wallet?.first_name} {wallet?.last_name}
              </p>
              <p className="text-sm text-aellaBlue font-light">
                Employee ID: {data?.staff?.id}
              </p>
            </div>
          </div>

          <div className="bg-white py-4 px-4 mt-4 rounded-lg border-[#EDEFF5] border w-full ">
            <h1 className="text-[16px] leading[20px] font-[400] py-4 border-b border-[#EDEFF5]">
              LOAN SUMMARY
            </h1>
            <div className="py-4 mt-4 flex flex-col gap-y-4">
              <ListItem
                name="Status"
                value={attachNameToLoanStatus(loan?.status)}
                valueAsId={loan?.status}
              />
              <ListItem
                name="Amount borrowed"
                value={`₦${formatMoney(loan?.amount ?? 0)}`}
              />
              <ListItem name="Purpose" value={loan?.loan_purpose || "N/A"} />
              <ListItem
                name="Interest Rate"
                value={`${loan?.interest_rate ?? 0}%`}
              />
              {(loan?.status_text === "approved" ||
                loan?.status_text === "pending") && (
                <ListItem
                  name="Tenor"
                  value={`${loan?.tenor ?? 0} month${
                    loan?.tenor > 1 ? "s" : ""
                  }`}
                />
              )}
              {(loan?.status_text === "approved" ||
                loan?.status_text === "pending") && (
                <ListItem
                  name="Total Repayment"
                  value={`₦${formatMoney(loan?.total_repayment ?? 0)}`}
                />
              )}
              {loan?.status_text === "approved" && (
                <ListItem
                  name="To repay this month"
                  value={`₦${formatMoney(loan?.to_repay_this_month ?? 0)}`}
                />
              )}
              {loan?.status_text === "approved" && (
                <ListItem
                  name="Still owing"
                  value={`₦${formatMoney(loan?.balance ?? 0)}`}
                />
              )}
            </div>

            {loan?.status_text === "declined" && (
              <div className="pt-8 border-t border-t-[#edeff5]">
                <p className="text-aellaBlack font-light">Reason for decline</p>
                <div className="mt-4 border border-[#D8DBDF] rounded-[4px] p-3 text-[#848F9A] font-light">
                  {loan?.declined_reason}
                </div>
              </div>
            )}
          </div>

          <div className="bg-white py-4 px-4 mt-4 rounded-lg border-[#EDEFF5] border w-full ">
            <h1 className="text-[16px] leading[20px] font-[400] py-4 border-b border-[#EDEFF5] uppercase">
              account status
            </h1>
            <div className="pt-4">
              <div className="flex items-center justify-between">
                <div className="flex gap-x-3 items-center">
                  <p className="text-sm text-[#7B7B7B] font-light capitalize">
                    wallet:
                  </p>
                  <Info />
                </div>
                <p
                  className={`text-[14px] leading[17px] rounded-[4px] font-light text-center px-[10px] tracking-[0.2px] pl-2 capitalize
                  
                  ${attachClassToBoolean(wallet?.status)}
                  
                  `}
                >
                  {wallet?.status ? "Active" : "Frozen"}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="lg:w-[60%] w-full mt-3 lg:mt-0 justify-self-end bg-white overflow-hidden rounded-[8px]">
          <div className="w-full px-6 pt-2 bg-white">
            <div className="border-b border-[#C5D4F6] pb-[1px] w-full">
              <div className="flex flex-wrap">
                <InnerTab
                  text="Repayment History"
                  id="Repayment History"
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                />
                <InnerTab
                  text="Request Log"
                  id="Request Log"
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                />
              </div>
            </div>
          </div>
          <div className="w-full py-[17.5px] px-6 bg-[#EDF2FF]">
            <p className="text-[16px] font-[400] uppercase">
              {activeTab === "Request Log" ? "REQUEST LOG" : "REPAYMENT HISTORY"}
            </p>
          </div>
          <div className="bg-white px-6 py-[24px]">
            {activeTab === "Request Log" ? (
              <RequestLogs loanId={loan?.id} />
            ) : (
              <EmployeeRepaymentHistory
                loans={repaymentSchedule}
                setSelected={setSelected}
                setShowDetails={(e) =>
                  updateState({
                    loanDetailsModal: e,
                  })
                }
                loanStatus={attachNameToLoanStatus(loan?.status)}
              />
            )}
          </div>
        </div>
      </div>

      <EmployeeRepaymentDetailsModal
        showModal={state.loanDetailsModal}
        closeModal={() =>
          updateState({
            loanDetailsModal: false,
          })
        }
        selectedObj={selected}
        loan={loan}
      />

      <ApproveOrDeclineLoanRequestModal
        setInvalidList={setInvalidLoanList}
        showModal={state.statusModal}
        status={state.status}
        closeModal={() => {
          updateState({
            statusModal: false,
          });
        }}
        setSelectedEmployees={setSelectedList}
        selectedEmployees={selectedList}
        isSingle
      />
    </div>
  );
};

export default LoanDetails;
