import { format } from "date-fns";
import { toast } from "react-toastify";

export const getMultiWordFirstLetters = (str: string | undefined) => {
  if (!str || typeof str !== "string") {
    return "";
  }

  return str
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase())
    .join("");
};

export const handleCopy = (text: string) => {
  if (text && text !== "-") {
    navigator.clipboard.writeText(text);
  }
  toast.success("Copied successfully");
};

export const formatDate = (date: string) => {
  if (date) {
    return format(new Date(date), "dd MMM, yyyy");
  }

  return null;
};

export const maskBVN = (bvn: string) => {
  if (!bvn) return "";
  if (bvn?.length < 11) {
    return "Invalid BVN";
  }

  const visibleStart = bvn.slice(0, 3);
  const visibleEnd = bvn.slice(-3);

  const maskedPart = "*".repeat(5);

  return visibleStart + maskedPart + visibleEnd;
};

export const getFirstDateOfCurrentMonth = () => {
  const date = new Date();
  return new Date(date.getFullYear(), date.getMonth(), 1);
};

export const daysOfTheMonth = Array.from({ length: 31 }, (_, i) =>
  (i + 1)?.toString()
);
 export const renderReplacedRequestLogText = (text: string) => {
  if (text?.includes("_")) {
    return text?.replaceAll("_", " ")?.toLowerCase();
  }
  return text?.toLowerCase();
};