import { Fragment, useState } from "react";
import acceptIcon from "../../../assets/images/svg/accept-icon.svg";
import declineIcon from "../../../assets/images/svg/decline-icon.svg";
import updateIcon from "../../../assets/images/svg/edit-black-icon.svg";
import locationIcon from "../../../assets/images/svg/location-icon.svg";
import { InnerTab } from "../../../component/Tab/Tab.component";
import { Menu, Transition } from "@headlessui/react";
import { useLocation } from "react-router-dom";
import {
  attachClassStatusNameAccount,
  getStatusNameAccount,
} from "../../../helpers/attachClassNameToTag";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { ReactComponent as BackIcon } from "../../../assets/images/svg/back-icon.svg";
import SubAccountTransactions from "./SubAccountTransactions";
import SubAccountsModal from "../Modal/SubAccountsModal";
import { useSubAccountDetails } from "../../../hooks/useSubAccountDetails";
import { getFirstLetter } from "../../../helpers/capitalizeFirstLetter";
import { currencyFormat } from "../../../helpers/formatter";
import { SpinLoader } from "../../../component/Loader/Loader.component";
import { ReactComponent as DropDownIcon } from "../../../assets/images/svg/white-dropdown-icon.svg";
import SubAccountActivities from "./SubAccountActivities";
import { useSubAccountActivityLog } from "../../../hooks/useSubAccountActivityLog";
import { LOCAL_STORAGE_KEYS } from "../../../helpers/localStorageKeys";
import { getFromLocalStorage } from "../../../helpers/localStorage";

type OptionsType = { id: string; name: string; icon: any };

const ReActivateOptions: Array<OptionsType> = [
  { id: "update", name: "Update User Details", icon: updateIcon },
  { id: "reactivate", name: "Reactivate Sub-account", icon: acceptIcon },
];

const deActivateOptions: Array<OptionsType> = [
  { id: "update", name: "Update User Details", icon: updateIcon },
  { id: "deactivate", name: "Deactivate Sub-account", icon: declineIcon },
];

export type AcceptDisputeType = {
  customerName?: string;
  customerEmail?: string;
  phoneNumber?: string;
  deliveryDate?: Date;
  deliveryAddress?: string;
  details?: string;
  refundOption?: string;
  amount?: number;
  reason?: string;
};

const SubAccountDetails = () => {
  const [actionModal, setActionModal] = useState("");
  const [activeTab, setActiveTab] = useState("transactions");
  const { state } = useLocation();
  const role = getFromLocalStorage(LOCAL_STORAGE_KEYS.ROLE);

  const { data } = Object(state) as {
    data: any;
  };
  const [filter, setFilter] = useState<any>({
    startDate: "",
    endDate: "",
    query: "",
  });
  const [showModal, setShowModal] = useState(false);
  const handleAction = (id: string) => {
    setActionModal(id);
  };
  const { data: AllSubAccountDetails, isLoading: AllSubAccountDetailsLoading } =
    useSubAccountDetails({ id: data?.id });
  const { data: activityLog, isLoading: activityLogIsLoading } =
    useSubAccountActivityLog({
      id: data?.id,
      to: filter.endDate,
      from: filter.startDate,
      query: filter.query,
    });

  const navigate = useNavigate();

  return (
    <>
      <div className="border-t bg-aellaDimGray">
        <div className="flex items-center py-[12px] lg:py-[14px]  justify-between px-10 bg-white border-b border-[#EDEFF5]">
          <div
            className="flex items-center cursor-pointer"
            onClick={() => navigate(-1)}
          >
            <BackIcon />
            <span className="pl-[10px] text-[18px] font-[100] lg:leading-[30px] tracking-[0.3px]">
              Back
            </span>
          </div>
          {/* {role === "Super Admin" && (
            <Menu as="div" className="relative inline-block">
              <div>
                <Menu.Button className="inline-flex w-full items-center rounded bg-aellaBlue px-3 py-3 text-sm font-[300] text-white tracking-[0.02] hover:bg-opacity-80 focus:outline-none disabled:bg-opacity-30">
                  Manage Sub Account
                  <div className="w-[20px] h-[16px] ml-2">
                    <DropDownIcon />
                  </div>
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right p-2 rounded-md border border-[#EDEFF5] bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  {(AllSubAccountDetails?.status === "active"
                    ? deActivateOptions
                    : ReActivateOptions
                  ).map((option: any) => (
                    <Menu.Item key={option.id} as={Fragment}>
                      {({ active }) => (
                        <p
                          onClick={() => handleAction(option.id)}
                          className={`${
                            active
                              ? "bg-aellaBlue text-white rounded"
                              : "text-aellaGray"
                          } px-[11px] py-2 text-sm flex items-center cursor-pointer `}
                        >
                          <div className="pr-2.5">
                            <img src={option.icon} alt="" className="" />
                          </div>
                          {option.name}
                        </p>
                      )}
                    </Menu.Item>
                  ))}
                </Menu.Items>
              </Transition>
            </Menu>
          )} */}
        </div>

        <div className="flex flex-col lg:flex-row items-start justify-between mt-10 w-full">
          <div className="w-full lg:w-[35%] ">
            <div className="py-4 px-10 w-full bg-white rounded-lg border border-[#EDEFF5] mr-6 flex items-center">
              <div className="rounded-full inline-flex bg-[#E5B7A0] w-[56px] h-[56px] items-center justify-center top-6  cursor-pointer mr-1 text-aellaBlue">
                {getFirstLetter(data?.first_name)}
                {getFirstLetter(data?.last_name)}
              </div>
              <p className="text-[16px] leading[19px] pl-2 font-[300] tracking-[0.2px]">
                {data?.first_name} {data?.last_name}
              </p>
            </div>
            <div className="bg-white py-4 pr-6 pl-10 mt-4 rounded-lg border-[#EDEFF5] border w-full ">
              <h1 className="text-[14px] leading-[16px] font-[400] text-aellaGray">
                Sub Account Balance
              </h1>

              <div className="text-[32px] leading[30px] pt-6 font-[200] -tracking-[0.2px]">
                {data ? currencyFormat(data?.balance) : "-"}
              </div>
            </div>
            <div className="bg-white py-6 pr-6 pl-10 mt-4 rounded-lg border-[#EDEFF5] border w-full ">
              <h1 className="text-[16px] leading[20px] font-[400] py-4 border-b border-[#EDEFF5]">
                USER ADDRESS
              </h1>
              <div className="pt-6">
                <div className="flex items-center">
                  <img src={locationIcon} alt="" />{" "}
                  <p className="text-[14px] leading[17px] font-[200] tracking-[0.2px] text-aellaGray pl-2">
                    {data?.address || "N/A"}
                  </p>
                </div>
              </div>
            </div>
            <div className="bg-white py-6 pr-6 pl-10 mt-4 rounded-lg border-[#EDEFF5] border w-full ">
              <h1 className="text-[16px] leading[20px] font-[400] py-4 border-b border-[#EDEFF5]">
                SUB-ACCOUNT INFO
              </h1>
              <div className="pt-6">
                <div className="flex items-center justify-between">
                  <p className="text-[14px] leading[17px] font-[200] tracking-[0.2px] text-aellaGray">
                    Name:
                  </p>
                  {AllSubAccountDetailsLoading ? (
                    <SpinLoader />
                  ) : (
                    <p className="text-[14px] leading[17px] font-[400] tracking-[0.4px] flex-wrap text-end">
                      {AllSubAccountDetails?.name}
                    </p>
                  )}
                </div>
                <div className="flex items-center justify-between mt-[17px]">
                  <p className="text-[14px] leading[17px] font-[200] tracking-[0.2px] text-aellaGray">
                    ID:
                  </p>
                  {AllSubAccountDetailsLoading ? (
                    <SpinLoader />
                  ) : (
                    <p className="text-[14px] leading[17px] font-[400] tracking-[0.4px]">
                      {AllSubAccountDetails?.id}
                    </p>
                  )}
                </div>
                <div className="flex items-center justify-between mt-[17px]">
                  <p className="text-[14px] leading[17px] font-[200] tracking-[0.2px] text-aellaGray">
                    Email Address:
                  </p>
                  {AllSubAccountDetailsLoading ? (
                    <SpinLoader />
                  ) : (
                    <p className="text-[14px] leading[17px] font-[400] tracking-[0.4px]">
                      {AllSubAccountDetails?.email}
                    </p>
                  )}
                </div>
                <div className="flex items-center justify-between mt-[17px]">
                  <p className="text-[14px] leading[17px] font-[200] tracking-[0.2px] text-aellaGray">
                    Phone Number:
                  </p>
                  {AllSubAccountDetailsLoading ? (
                    <SpinLoader />
                  ) : (
                    <p className="text-[14px] leading[17px] font-[400] tracking-[0.4px]">
                      {AllSubAccountDetails?.phone}
                    </p>
                  )}
                </div>

                <div className="flex items-center justify-between mt-[17px]">
                  <p className="text-[14px] leading[17px] font-[200] tracking-[0.2px] text-aellaGray">
                    Status:{" "}
                  </p>
                  {AllSubAccountDetailsLoading ? (
                    <SpinLoader />
                  ) : (
                    <p
                      className={`text-[14px] leading[17px] font-[350] tracking-[0.4px] ${attachClassStatusNameAccount(
                        AllSubAccountDetails?.status
                      )}`}
                    >
                      {getStatusNameAccount(AllSubAccountDetails?.status)}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="bg-white py-6 pr-6 pl-10 my-4 rounded-lg border-[#EDEFF5] border w-full ">
              <h1 className="text-[16px] leading[20px] font-[400] py-4 border-b border-[#EDEFF5]">
                BANK ACCOUNT
              </h1>
              <div className="pt-6 ">
                <div className="flex items-center justify-between">
                  <p className="text-[14px] leading[17px] font-[200] tracking-[0.2px] text-aellaGray">
                    Bank Name:
                  </p>
                  <p className="text-[14px] leading[17px] font-[400] tracking-[0.4px] flex-wrap text-end">
                    Aella MFB
                  </p>
                </div>
                <div className="flex items-center justify-between mt-[17px]">
                  <p className="text-[14px] leading[17px] font-[200] tracking-[0.2px] text-aellaGray">
                    Account Name:
                  </p>
                  <p className="text-[14px] leading[17px] font-[400] tracking-[0.4px]">
                    {data?.name}
                  </p>
                </div>
                <div className="flex items-center justify-between mt-[17px]">
                  <p className="text-[14px] leading[17px] font-[200] tracking-[0.2px] text-aellaGray">
                    Account Number:
                  </p>
                  <p className="text-[14px] leading[17px] font-[400] tracking-[0.4px]">
                    {AllSubAccountDetailsLoading ? (
                      <SpinLoader />
                    ) : (
                      AllSubAccountDetails?.account_number
                    )}
                  </p>
                </div>

                <div className="flex items-center justify-between mt-[17px]">
                  <p className="text-[14px] leading[17px] font-[200] tracking-[0.2px] text-aellaGray">
                    Date Created:
                  </p>
                  <p className="text-[14px] leading[17px] font-[400] tracking-[0.4px] text-end">
                    {moment(data?.created_at).format("DD MMM YYYY, LT")}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="lg:w-[60%] w-full mt-3 lg:mt-0 justify-self-end mr-10">
            <div className="w-full bg-white rounded-t-lg px-6 pt-2">
              <div className="border-b border-[#C5D4F6] pb-[1px] w-full">
                <div className="flex flex-wrap">
                  <InnerTab
                    text="Transactions"
                    id="transactions"
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                  />
                  <InnerTab
                    text="Activity Log"
                    id="activity-log"
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                  />
                </div>
              </div>
            </div>
            <div className="w-full py-[17.5px] px-6 bg-[#EDF2FF]">
              <p className="text-[16px] leading[16px] font-[400] capitalize">
                {activeTab}
              </p>
            </div>
            <div className="bg-white px-6 py-[24px]">
              {(() => {
                switch (activeTab) {
                  case "transactions":
                    return (
                      <SubAccountTransactions
                        id={data?.wallet_id}
                        setFilter={setFilter}
                      />
                    );
                  case "activity-log":
                    return (
                      <SubAccountActivities
                        activityLog={activityLog}
                        filter={filter}
                        setFilter={setFilter}
                        showModal={showModal}
                        setShowModal={setShowModal}
                        isLoading={activityLogIsLoading}
                      />
                    );

                  default:
                    return;
                }
              })()}
            </div>
          </div>
        </div>
      </div>

      {actionModal && AllSubAccountDetails?.account_number && (
        <SubAccountsModal
          modalType={actionModal}
          setModalType={setActionModal}
          id={data?.id}
          accountNum={AllSubAccountDetails?.account_number}
          details={AllSubAccountDetails}
        />
      )}
    </>
  );
};
export default SubAccountDetails;
