import { currencyFormat } from "../../../helpers/formatter";
import fileIcon from "../../../assets/images/svg/file-blue-icon.svg";
import logIcon from "../../../assets/images/svg/activitylog-icon.svg";
import { getStatusName } from "../../../helpers/attachClassNameToTag";
import filterIcon from "../../../assets/images/svg/filter-icon.svg";

import { format, formatDistance } from "date-fns";
import ReactDatePicker from "react-datepicker";
import { useState } from "react";
import { ModalContainer } from "../../../modal/ModalContainer";
import { ReactComponent as CloseIcon } from "../../../assets/images/svg/white-close-modal.svg";
import calendarIcon from "../../../assets/images/svg/calendar.svg";
import BlueButton from "../../../component/Button/BlueButton";
import { toast } from "react-toastify";
import moment from "moment";
import { SecondaryLoader } from "../../../component/Loader/SecondaryLoader";

export default function DisputeActivityLog({
  posDisputeActivity,
  filter,
  setFilter,
  showModal,
  setShowModal,
  isLoading,
}: {
  posDisputeActivity: any;
  filter: any;
  setFilter: any;
  showModal: any;
  setShowModal: any;
  isLoading: boolean;
}) {
  const [start, setStart] = useState<Date | undefined>();
  const [end, setEnd] = useState<Date | undefined>();

  return (
    <>
      {isLoading ? (
        <SecondaryLoader count={10} />
      ) : (
        <div className="w-full px-6 py-4 bg-white">
          <div
            className="w-[150px] flex justify-center items-center ml-auto h-10 border bg-[#F5F5F5] px-2 py-2 cursor-pointer rounded xxs:justify-center md:justify-around active:shadow-md transition duration-150 ease-in-out"
            onClick={() => {
              if (filter?.startDate) {
                setStart(new Date(filter?.startDate));
              }
              if (filter?.endDate) {
                setEnd(new Date(filter?.endDate));
              }
              setShowModal(true);
            }}
          >
            <div className="xxs:px-1 md:px-2 py-1 h-[24px] flex items-center">
              <img
                className="object-contain w-[18px] h-[18px]"
                src={filterIcon}
                alt=""
              />
              <span className="ml-[12px] font-thin  text-gray-400  text-sm focus:outline-none focus:ring-0">
                Filter By
              </span>
            </div>
          </div>
          <div className="w-full overflow-x-auto mt-6">
            {/* <div className="w-full flex gap-3">
                <div className="flex flex-col items-center">
                  <div className="w-10 h-10 flex justify-center items-center rounded-full bg-gray-100">
                    <img src={logIcon} alt="" width={20} height={20} />
                  </div>
                  <div className="w-[2px] flex-1 bg-black" />
                </div>
                <div className="pt-2 w-full pb-3">
                  <div>
                    <p className="text-[1.1rem]">Dispute</p>
                    <p className="text-[0.95rem] mt-1">
                      <span className="opacity-70">Status:</span>
                      <span className="text-primary-green opacity-80 ml-2">
                        Successful
                      </span>
                      <span className="opacity-60 ml-3">
                        * &nbsp;&nbsp; 2 days ago | 4:00 PM
                      </span>
                    </p>
                  </div>
                </div>
              </div> */}
            {/* <div className="w-full flex gap-3">
                <div className="flex flex-col items-center">
                  <div className="w-10 h-10 flex justify-center items-center rounded-full bg-gray-100">
                    <img src={logIcon} alt="" width={20} height={20} />
                  </div>
                  <div className="w-[2px] flex-1 bg-black" />
                </div>
                <div className="pt-2 w-full pb-3">
                  <div>
                    <p className="text-[1.1rem]">Dispute</p>
                    <p className="text-[0.95rem] mt-1">
                      <span className="opacity-70">Status:</span>
                      <span className="text-primary-green opacity-80 ml-2">
                        Successful
                      </span>
                      <span className="opacity-60 ml-3">
                        * &nbsp;&nbsp; 2 days ago | 4:00 PM
                      </span>
                    </p>
                  </div>
                </div>
              </div> */}
            {posDisputeActivity?.data?.data?.length > 0
              ? posDisputeActivity?.data?.data?.map((activity: any) => {
                  return (
                    <div className="w-full flex gap-3" key={activity?.id}>
                      <div className="flex flex-col items-center">
                        <div className="w-10 h-10 flex justify-center items-center rounded-full bg-gray-100">
                          <img src={logIcon} alt="" width={30} height={30} />
                        </div>
                        <div className="w-[1px] flex-1 bg-black" />
                      </div>
                      <div className="pt-2 w-full pb-3">
                        <div>
                          <p className="tracking-[0.2px] text-[16px] leading-[19px] font-[400]">
                            Dispute
                          </p>
                          <p className="tracking-[0.2px] text-[14px] leading-[17px] font-[350] mt-1 flex items-center">
                            <span className="opacity-70">Status:</span>
                            <span
                              className={`${
                                activity?.status.toLowerCase() === "in_progress"
                                  ? "text-[#45858B]"
                                  : activity?.status.toLowerCase() === "pending"
                                  ? "text-[#AA5B00]"
                                  : activity?.status.toLowerCase() ===
                                    "accept dispute"
                                  ? "text-[#14804A]"
                                  : activity?.status.toLowerCase() ===
                                    "awaiting feedback"
                                  ? "text-[#5E5ADB]"
                                  : activity?.status.toLowerCase() ===
                                    "reopened"
                                  ? "text-[#5E5ADB]"
                                  : "text-[#EB5757]"
                              } ml-2`}
                            >
                              {getStatusName(activity?.status)}
                            </span>
                            <p className="rounded-full w-1 h-1 ml-2 mr-2 bg-aellaGray "></p>

                            <span
                              className={`text-[#232323] tracking-[0.2px] text-[14px] leading-[17px] font-[350]`}
                            >
                              {activity?.authorized_by}
                            </span>
                            <p className="rounded-full w-1 h-1 ml-2 mr-2 bg-aellaGray "></p>
                            <span
                              className={`text-aellaGray tracking-[0.2px] text-[14px] leading-[17px] font-[350]`}
                            >
                              {" "}
                              {formatDistance(
                                new Date(activity?.created_at),
                                new Date(),
                                {
                                  addSuffix: true,
                                }
                              )}{" "}
                              |{" "}
                              {format(
                                new Date(activity?.created_at),
                                "hh:mm 	a"
                              )}
                            </span>
                          </p>
                          {(activity?.metadata?.refundOption ||
                            activity?.metadata?.amount ||
                            activity?.attachments?.length ||
                            activity?.metadata?.reason) && (
                            <div className="w-[90%] bg-[#EDEFF5] p-3 rounded-[5px] mt-4">
                              {activity?.metadata?.refundOption && (
                                <p className="tracking-[0.03em] text-[17px] leading-[19px] font-[400] capitalize">
                                  {activity?.metadata?.refundOption}
                                </p>
                              )}
                              <div className="w-full tracking-[0.03em] text-aellaGray text-[14px] leading-[17px] font-[400] mt-4 flex flex-col gap-y-4">
                                {activity?.metadata?.customer_name && (
                                  <div className="w-full flex flex-wrap items-start gap-3">
                                    <p className="w-[45%]">Customer Name:</p>
                                    <p className="flex-1 ">
                                      {activity?.metadata?.customer_name}
                                    </p>
                                  </div>
                                )}
                                {activity?.metadata?.customer_email && (
                                  <div className="w-full flex flex-wrap items-start gap-3">
                                    <p className="w-[45%]">Customer Email:</p>
                                    <p className="flex-1 ">
                                      {activity?.metadata?.customer_email}
                                    </p>
                                  </div>
                                )}
                                {activity?.metadata?.phone_number && (
                                  <div className="w-full flex flex-wrap items-start gap-3">
                                    <p className="w-[45%]">
                                      Customer Phone Number:
                                    </p>
                                    <p className="flex-1 ">
                                      {activity?.metadata?.phone_number}
                                    </p>
                                  </div>
                                )}
                                {activity?.metadata?.delivery_date && (
                                  <div className="w-full flex flex-wrap items-start gap-3">
                                    <p className="w-[45%]">
                                      Date item was delivered to:
                                    </p>
                                    <p className="flex-1 ">
                                      {format(
                                        new Date(
                                          activity?.metadata?.delivery_date
                                        ),
                                        "dd/MM/yyyy"
                                      )}
                                    </p>
                                  </div>
                                )}
                                {activity?.metadata?.details && (
                                  <div className="w-full flex flex-wrap items-start gap-3">
                                    <p className="w-[45%]">
                                      Details of product/service provided:
                                    </p>
                                    <p className="flex-1 ">
                                      {activity?.metadata?.details}
                                    </p>
                                  </div>
                                )}
                                {activity?.metadata?.delivery_address && (
                                  <div className="w-full flex flex-wrap items-start gap-3">
                                    <p className="w-[45%]">
                                      Address item was delivered to:
                                    </p>
                                    <p className="flex-1 ">
                                      {activity?.metadata?.delivery_address}
                                    </p>
                                  </div>
                                )}
                                {activity?.metadata?.amount && (
                                  <div className="w-full flex flex-wrap items-start gap-3">
                                    <p className="w-[45%]">Amount:</p>
                                    <p className="flex-1 ">
                                      {currencyFormat(
                                        activity?.metadata?.amount
                                      )}
                                    </p>
                                  </div>
                                )}
                                {activity?.attachments ? (
                                  <div className="w-full flex flex-wrap items-start gap-3">
                                    <p className="w-[45%]">Attachments:</p>
                                    <p className="flex-1 ">
                                      <div className="flex items-center gap-x-4">
                                        {(activity?.attachments?.map)(
                                          (each: any, index: number) => (
                                            <a
                                              href={each.location}
                                              target="_blank"
                                              rel="noreferrer"
                                              className="flex items-center gap-x-1 cursor-pointer"
                                              key={index}
                                            >
                                              <img
                                                src={fileIcon}
                                                alt="File Icon"
                                                width={15}
                                                height={17}
                                              />
                                              <p className="text-aellaBlue text-[14px] leading-[16px] font-[200]">
                                                file{" "}
                                                {activity?.attachments
                                                  ?.length === 1
                                                  ? " "
                                                  : index + 1}
                                              </p>
                                            </a>
                                          )
                                        )}
                                      </div>
                                    </p>
                                  </div>
                                ) : (
                                  <div className="w-full flex flex-wrap items-start gap-3">
                                    <p className="w-[45%]">Attachments:</p>
                                    <p className="flex-1 ">No attachment</p>
                                  </div>
                                )}
                                {activity?.metadata?.reason && (
                                  <div className="w-full flex flex-wrap items-start gap-3">
                                    <p className="w-[45%]">Reason:</p>
                                    <p className="flex-1 ">
                                      {activity?.metadata?.reason || "N/A"}
                                    </p>
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })
              : "No Activity Available"}
          </div>
        </div>
      )}

      <ModalContainer
        showModal={showModal}
        closeModal={() => {
          setShowModal(false);
          setFilter({});
        }}
      >
        <div className="w-full lg:w-[40vw] relative h-auto lg:max-h-[85vh] z-50 text-aellaBlack flex flex-col rounded-[8px] bg-white py-8 px-6">
          <div
            onClick={() => {
              setShowModal(false);
            }}
            className="absolute -top-8 -right-0 cursor-pointer"
          >
            <CloseIcon />
          </div>
          <p className="text-aellaBlack text-[20px]">Filter</p>
          <div className="w-full flex mt-6 justify-between items-center">
            <div className="flex-col w-[45%]">
              <p className="font-normal">From</p>
              <div className="flex items-center border border-aellaLightGray mt-1 w-full px-2 h-10 rounded-[4px]">
                <ReactDatePicker
                  dateFormat="yyyy-MM-dd"
                  selected={start}
                  onChange={(date: Date) => {
                    setStart(date);
                  }}
                  className="h-full w-[80%] rounded-lg text-aellaBlack lg:leading-[16px] text-[16px] font-[300] outline-none"
                />
                <img src={calendarIcon} alt="calendar" />
              </div>
            </div>
            <div className="flex-col w-[45%]">
              <p className="font-normal">To</p>
              <div className="flex items-center border border-aellaLightGray mt-1 w-full px-2 h-10 rounded-[4px]">
                <ReactDatePicker
                  dateFormat="yyyy-MM-dd"
                  selected={end}
                  minDate={start}
                  onChange={(date: Date) => {
                    setEnd(date);
                  }}
                  className="h-full w-[80%] rounded-lg text-aellaBlack lg:leading-[16px] text-[16px] font-[300] outline-none"
                />
                <img src={calendarIcon} alt="calendar" />
              </div>
            </div>
          </div>

          <div className="flex gap-x-4 justify-end mt-6">
            <button
              onClick={() => {
                setShowModal(false);
                setFilter({});
                setStart(undefined);
                setEnd(undefined);
              }}
              className="border-aellaBlue border rounded h-10 cursor-pointer text-center text-aellaBlue  w-[121px] "
            >
              Reset
            </button>
            <BlueButton
              // disabled={exporting}
              onClick={() => {
                if (!start) {
                  toast.error("Please select date range");
                  return;
                }

                setFilter({
                  startDate: moment(start).format("YYYY-MM-DD"),
                  endDate: moment(end).format("YYYY-MM-DD"),
                });

                setShowModal(false);
                setStart(undefined);
                setEnd(undefined);
              }}
              // title={exporting ? "Exporting" : "Export CSV"}
              title="Filter"
              className="h-10 font-light self-end w-[121px] flex items-center justify-center"
            />
          </div>
        </div>
      </ModalContainer>
    </>
  );
}
