import moment from "moment";
import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData } from "../apis/apiMethods";
import CONFIG from "../helpers/config";

export const getPosAnalytics = async ({ queryKey }: { queryKey: any }) => {
  const { startDate, endDate } = queryKey[1];

  let queryParams = `?`;

  if (startDate) {
    queryParams = `${queryParams}from=${moment(startDate).format(
      "YYYY-MM-DD"
    )}`;
  }
  if (endDate) {
    queryParams = `${queryParams}&to=${moment(endDate).format("YYYY-MM-DD")}`;
  }
  const { data } = await getData(
    `${CONFIG.BASE_URL2}${apiEndpoints.POS_ANALYTICS}${queryParams}`
  );
  return data;
};

export const usePosAnalytics = ({
  startDate,
  endDate,
}: {
  startDate: any;
  endDate: any;
}) => {
  return useQuery(["posAnalytics", { startDate, endDate }], getPosAnalytics, {
    refetchOnWindowFocus: false, // Refetch on window focus
  });
};
