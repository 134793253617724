import HeaderBackNavigation from "../../../component/Header/HeaderBackNavigation.component";
import { Fragment, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ReactComponent as DropDownIcon } from "../../../assets/images/svg/white-dropdown-icon.svg";
import acceptIcon from "../../../assets/images/svg/accept-icon.svg";
import declineIcon from "../../../assets/images/svg/decline-icon.svg";
import tempIcon from "../../../assets/images/svg/aella-blue-logo.png";
import Tab from "../../../component/Tab/Tab.component";
import DisputeConversation from "./DisputeConversation";
import DisputeActivityLog from "./DisputeActivityLog";
import AcceptDisputeDrawer from "../Drawer/AcceptDisputeDrawer";
import DeclineDisputeDrawer from "../Drawer/DeclineDisputeDrawer";
import DisputeActionModal from "../Modal/DisputeActionModal";
import fileIcon from "../../../assets/images/svg/file-blue-icon.svg";
import { usePosDisputeDetails } from "../../../hooks/usePosDisputeDetails";
import { useLocation } from "react-router-dom";
import { SpinLoader } from "../../../component/Loader/Loader.component";
import {
  attachClassNameToTag,
  getStatusName,
} from "../../../helpers/attachClassNameToTag";
import { currencyFormat } from "../../../helpers/formatter";
import moment from "moment";
import { usePosDisputeConvo } from "../../../hooks/usePosDisputeConvo";
import { DisputeType } from "./DisputeInterface";
import { usePosDisputeActivity } from "../../../hooks/usePosDisputeActivity";
import { apiEndpoints } from "../../../apis/apiEndpoints";
import { postData } from "../../../apis/apiMethods";
import CONFIG from "../../../helpers/config";
import { errorHandler } from "../../../helpers/errorHandler";
import { toast } from "react-toastify";
import { useQueryClient } from "react-query";
import { getFromLocalStorage } from "../../../helpers/localStorage";
import { LOCAL_STORAGE_KEYS } from "../../../helpers/localStorageKeys";

type OptionsType = { id: string; name: string; icon: any };
const options: Array<OptionsType> = [
  { id: "accept", name: "Accept", icon: acceptIcon },
  { id: "decline", name: "Decline", icon: declineIcon },
];

export type AcceptDisputeType = {
  customerName?: string;
  customerEmail?: string;
  phoneNumber?: string;
  deliveryDate?: Date;
  deliveryAddress?: string;
  details?: string;
  refundOption?: string;
  amount?: number | string;
  reason?: string;
};

const DisputesDetails = () => {
  const role = getFromLocalStorage(LOCAL_STORAGE_KEYS.ROLE);
  const [isActivateDrawerOpen, setIsActivateDrawerOpen] = useState(false);
  const [isDeclineDrawerOpen, setIsDeclineDrawerOpen] = useState(false);
  // const [transaction, setTransaction] = useState(false);
  // const [searchValue, setSearchValue] = useState("");
  const [actionModal, setActionModal] = useState("");
  const [activeTab, setActiveTab] = useState("dispute conversation");
  const [attachment, setAttachment] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState<any>({
    startDate: "",
    endDate: "",
    show: false,
  });
  const [showModal, setShowModal] = useState(false);

  const { state } = useLocation();
  const queryClient = useQueryClient();

  const { data } = Object(state) as {
    data: DisputeType;
  };
  const handleAction = (id: string) => {
    if (id === "accept") {
      data?.category?.toLowerCase() === "fraud"
        ? setIsActivateDrawerOpen(true)
        : setActionModal(id);
    }
    if (id === "decline") {
      data?.category?.toLowerCase() === "fraud"
        ? setIsDeclineDrawerOpen(true)
        : setActionModal(id);
    }
  };
  const { data: posDisputeDetail, isLoading: posDisputeLoading } =
    usePosDisputeDetails({ id: data?.id });
  const { data: posDisputeConvo, isLoading: posDisputeConvoLoading } =
    usePosDisputeConvo({ id: data?.id });
  const { data: posDisputeActivity, isLoading: posActivityLoading } =
    usePosDisputeActivity({
      id: posDisputeDetail?.data?.data?.id,
      start_date: filter?.startDate,
      end_date: filter?.endDate,
    });

  const handleSubmit = async (reqBody: any) => {
    setLoading(true);
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const res: any = await postData(
        `${CONFIG.BASE_URL2}${apiEndpoints.POS_DISPUTES}/accept/${data?.id}`,
        reqBody
      );
      queryClient.invalidateQueries("getPosDisputeDetails");
      queryClient.invalidateQueries("getPosDisputeConvo");
      queryClient.invalidateQueries("getPosDisputeActivity");
      setIsActivateDrawerOpen(false);
      setActionModal("");
      setAttachment([]);
    } catch (error) {
      toast.error(errorHandler(error));
    }
    setLoading(false);
  };

  const handleSubmitDecline = async (reqBody: any) => {
    setLoading(true);

    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const res: any = await postData(
        `${CONFIG.BASE_URL2}${apiEndpoints.POS_DISPUTES}/decline/${data?.id}`,
        reqBody
      );
      queryClient.invalidateQueries("getPosDisputeDetails");
      queryClient.invalidateQueries("getPosDisputeConvo");
      queryClient.invalidateQueries("getPosDisputeActivity");
      setIsDeclineDrawerOpen(false);
      setActionModal("");
      setAttachment([]);
    } catch (error) {
      toast.error(errorHandler(error));
    }
    setLoading(false);
  };
  return (
    <>
      <div className="border-t bg-aellaDimGray">
        <div className="bg-white py-[17px]">
          <div className="flex justify-between px-10">
            <p className="text-[22px] leading[29px] font-[200]">
              {posDisputeLoading ? (
                <SpinLoader />
              ) : (
                posDisputeDetail?.data?.data?.customer_name
              )}
            </p>
            {role === "SuperAdmin" ||
              (role === "Admin" && (
                <Menu as="div" className="relative inline-block text-left">
                  <div>
                    <Menu.Button
                      className="inline-flex w-full items-center rounded bg-aellaBlue px-3 py-2 text-sm font-[300] text-white tracking-[0.02] hover:bg-opacity-80 focus:outline-none disabled:bg-opacity-30"
                      disabled={
                        posDisputeDetail?.data?.data?.status ===
                          "Accept Dispute" ||
                        posDisputeDetail?.data?.data?.status === "Decline"
                      }
                    >
                      Action
                      <div className="w-[16px] h-[16px] ml-2">
                        <DropDownIcon />
                      </div>
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right p-2 rounded-md border border-[#EDEFF5] bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {options?.map((option: any) => (
                        /* Use the `active` state to conditionally style the active item. */
                        <Menu.Item key={option.id} as={Fragment}>
                          {({ active }) => (
                            <p
                              onClick={() => handleAction(option.id)}
                              className={`${
                                active
                                  ? "bg-aellaBlue text-white rounded"
                                  : "text-aellaGray"
                              } px-[11px] py-2 text-sm flex items-center cursor-pointer `}
                            >
                              <div className="pr-2.5">
                                <img src={option.icon} alt="" className="" />
                              </div>
                              {option.name}
                            </p>
                          )}
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </Menu>
              ))}
          </div>
        </div>
        <div className="border-b border-[#EDEFF5]">
          <HeaderBackNavigation />
        </div>
        <div className="flex flex-col lg:flex-row items-start justify-between mt-10 w-full">
          <div className="w-full lg:w-[35%] ">
            <div className="py-4 px-10 w-full bg-white rounded-lg border border-[#EDEFF5] mr-6 flex items-center">
              <img
                src={tempIcon}
                alt=""
                className="w-[55px] h-[55px] rounded-[5.5px]"
              />
              <p className="text-[16px] leading[19px] pl-2 font-[300] tracking-[0.2px]">
                {posDisputeDetail?.data?.data?.customer_name}
              </p>
            </div>
            <div className="bg-white py-6 pr-6 pl-10 mt-4 rounded-lg border-[#EDEFF5] border w-full ">
              <h1 className="text-[16px] leading[20px] font-[400] py-4 border-b border-[#EDEFF5]">
                MORE INFORMATION
              </h1>
              <div className="py-6 border-b border-[#EDF2FF]">
                <div className="flex items-center justify-between">
                  <p className="text-[14px] leading[17px] font-[200] tracking-[0.2px] text-aellaGray">
                    Status:{" "}
                  </p>
                  {posDisputeLoading ? (
                    <SpinLoader />
                  ) : (
                    <p
                      className={`text-[14px] leading[17px] font-[350] tracking-[0.4px] 
                      ${attachClassNameToTag(
                        posDisputeDetail?.data?.data?.status
                      )}`}
                    >
                      {getStatusName(posDisputeDetail?.data?.data?.status)}
                    </p>
                  )}
                </div>
                <div className="flex items-center justify-between mt-[17px]">
                  <p className="text-[14px] leading[17px] font-[200] tracking-[0.2px] text-aellaGray">
                    Customer Name
                  </p>
                  <p className="text-[14px] leading[17px] font-[400] tracking-[0.4px] text-[#232323]">
                    {posDisputeDetail?.data?.data?.customer_name}
                  </p>
                </div>
                <div className="flex items-center justify-between mt-[17px]">
                  <p className="text-[14px] leading[17px] font-[200] tracking-[0.2px] text-aellaGray">
                    Phone Number
                  </p>
                  <p className="text-[14px] leading[17px] font-[400] tracking-[0.4px] text-[#232323]">
                    {posDisputeDetail?.data?.data?.phone_number}
                  </p>
                </div>

                <div className="flex items-center justify-between mt-[17px]">
                  <p className="text-[14px] leading[17px] font-[200] tracking-[0.2px] text-aellaGray">
                    Amount:
                  </p>
                  <p className="text-[14px] leading[17px] font-[400] tracking-[0.4px] flex-wrap text-end text-[#232323]">
                    {posDisputeLoading ? (
                      <SpinLoader />
                    ) : (
                      currencyFormat(posDisputeDetail?.data?.data?.amount)
                    )}
                  </p>
                </div>
                <div className="flex items-center justify-between mt-[17px]">
                  <p className="text-[14px] leading[17px] font-[200] tracking-[0.2px] text-aellaGray">
                    Dispute Category:
                  </p>
                  <p className="text-[14px] leading[17px] font-[400] tracking-[0.4px] text-[#232323]">
                    {posDisputeDetail?.data?.data?.category}
                  </p>
                </div>
                <div className="flex items-center justify-between mt-[17px]">
                  <p className="text-[14px] leading[17px] font-[200] tracking-[0.2px] text-aellaGray">
                    Channel:
                  </p>
                  <p className="text-[14px] leading[17px] font-[400] tracking-[0.4px] text-[#232323]">
                    {posDisputeDetail?.data?.data?.channel}
                  </p>
                </div>
                <div className="flex items-center justify-between mt-[17px]">
                  <p className="text-[14px] leading[17px] font-[200] tracking-[0.2px] text-aellaGray">
                    Transaction Reference:
                  </p>
                  <p className="text-[14px] leading[17px] font-[400] tracking-[0.4px] text-end text-[#232323]">
                    {posDisputeDetail?.data?.data?.transaction_reference}
                  </p>
                </div>
                <div className="flex items-center justify-between mt-[17px]">
                  <p className="text-[14px] leading[17px] font-[200] tracking-[0.2px] text-aellaGray">
                    Date Created:
                  </p>
                  <p className="text-[14px] leading[17px] font-[400] tracking-[0.4px] text-end text-[#232323]">
                    {moment(posDisputeDetail?.data?.data?.created_at).format(
                      "DD MMM YYYY, LT"
                    )}
                  </p>
                </div>
                <div className="flex items-center justify-between mt-[17px]">
                  <p className="text-[14px] leading[17px] font-[200] tracking-[0.2px] text-aellaGray text-end">
                    Due Date:
                  </p>
                  <p className="text-[14px] leading[17px] font-[400] tracking-[0.4px] text-[#232323]">
                    {moment(posDisputeDetail?.data?.data?.due_date).format(
                      "DD MMM YYYY, LT"
                    )}
                  </p>
                </div>
              </div>
              <div className="mt-6">
                <p className="text-[16px] leading-[20px] font-[300] tracking-[0.03em] text-aellaBlack">
                  Dispute Description
                </p>
                <p className="mt-4 p-[12px] bg-[#F7F9FC] border border-[#D8DBDF] text-[16px] leading[24px] font-[300] tracking-[0.03em] rounded text-[#848F9A]">
                  {posDisputeDetail?.data?.data?.description || "Not available"}
                </p>
                {data?.attachments?.length > 0 && (
                  <div className="mt-[42px]">
                    <p className=" text-[16px] leading[19px] font-[300] tracking-[0.03em]">
                      Attachments
                    </p>
                    <div className="w-full mt-2 flex flex-wrap gap-x-6 items-center">
                      {data?.attachments?.map((each: any, index: number) => {
                        return (
                          <a
                            href={each.location}
                            target="_blank"
                            rel="noreferrer"
                            className="flex items-center gap-x-1 cursor-pointer"
                            key={index}
                          >
                            <img
                              src={fileIcon}
                              alt="File Icon"
                              width={15}
                              height={17}
                            />
                            <p className="text-aellaBlue text-[14px] leading-[16px] font-[200]">
                              file{" "}
                              {data?.attachments?.length === 1
                                ? " "
                                : index + 1}
                            </p>
                          </a>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="lg:w-[60%] w-full mt-3 lg:mt-0 justify-self-end">
            <div className="w-full bg-white rounded-t-lg px-6 pt-2">
              <div className="border-b border-[#C5D4F6] pb-[1px] w-full">
                <div className="flex flex-wrap gap-x-3 pt-3">
                  {/* <Tab
                    text="Dispute Conversation"
                    id="dispute conversation"
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                  />
                  <Tab
                    text="Activity Log"
                    id="activity log"
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                  /> */}

                  <p
                    onClick={() => setActiveTab("dispute conversation")}
                    className={`border-b-[2px] px-5 pb-2 cursor-pointer ${
                      activeTab === "dispute conversation"
                        ? " border-aellaBlue"
                        : "border-transparent"
                    }`}
                  >
                    Dispute
                  </p>
                  <p
                    onClick={() => setActiveTab("activity log")}
                    className={`border-b-[2px] px-5  pb-2 cursor-pointer ${
                      activeTab === "activity log"
                        ? " border-aellaBlue"
                        : "border-transparent"
                    }`}
                  >
                    Activity Log
                  </p>
                </div>
              </div>
            </div>
            <div className="w-full flex justify-between py-[17.5px] px-6 bg-[#EDF2FF]">
              <p className="text-[16px] leading[16px] font-[400] uppercase">
                {activeTab}
              </p>
              {/* {activeTab === "activity log" && <p>Filter</p>} */}
            </div>
            <div className="bg-white px-6 py-[34px]">
              {(() => {
                switch (activeTab) {
                  case "dispute conversation":
                    return (
                      <DisputeConversation
                        posDisputeConvoLoading={posDisputeConvoLoading}
                        posDisputeConvo={posDisputeConvo}
                        data={posDisputeDetail}
                      />
                    );
                  case "activity log":
                    return (
                      <DisputeActivityLog
                        posDisputeActivity={posDisputeActivity}
                        filter={filter}
                        setFilter={setFilter}
                        showModal={showModal}
                        setShowModal={setShowModal}
                        isLoading={posActivityLoading}
                      />
                    );

                  default:
                    return;
                }
              })()}
            </div>
          </div>
        </div>
      </div>
      {isActivateDrawerOpen && (
        <AcceptDisputeDrawer
          isOpen={isActivateDrawerOpen}
          setIsOpen={setIsActivateDrawerOpen}
          attachment={attachment}
          setAttachment={setAttachment}
          amount={posDisputeDetail?.data?.data?.amount}
          submitHandler={handleSubmit}
          loading={loading}
          merchantName={posDisputeDetail?.data?.data?.name}
          disputeDetail={posDisputeDetail?.data?.data}
        />
      )}
      {isDeclineDrawerOpen && (
        <DeclineDisputeDrawer
          isOpen={isDeclineDrawerOpen}
          setIsOpen={setIsDeclineDrawerOpen}
          attachment={attachment}
          setAttachment={setAttachment}
          amount={posDisputeDetail?.data?.data?.amount}
          submitHandler={handleSubmitDecline}
          loading={loading}
          merchantName={posDisputeDetail?.data?.data?.name}
          disputeDetail={posDisputeDetail?.data?.data}
        />
      )}
      {actionModal && (
        <DisputeActionModal
          modalType={actionModal}
          setModalType={setActionModal}
          attachment={attachment}
          setAttachment={setAttachment}
          amount={posDisputeDetail?.data?.data?.amount}
          submitHandler={handleSubmit}
          submitDeclineHandler={handleSubmitDecline}
          loading={loading}
          merchantName={posDisputeDetail?.data?.data?.name}
        />
      )}
    </>
  );
};
export default DisputesDetails;
