import { useEffect, useRef, useState } from "react";
import moment from "moment";
import { useSearchParams, useLocation } from "react-router-dom";

import Pagination from "../../component/Pagination/Pagination.component";
import { useTransfersData } from "./Transfers";
import { formatMoney } from "../../helpers/formatMoney";
import { useBulkList } from "../../hooks/useTransfersList";
import EmptyTransfer from "./EmptyTransfer";
import { SecondaryLoader } from "../../component/Loader/SecondaryLoader";
import { getFromLocalStorage } from "../../helpers/localStorage";
import { LOCAL_STORAGE_KEYS } from "../../helpers/localStorageKeys";

const BulkTransfer = () => {
  const {
    setShowAction,
    setViewTransfer,
    setSelectedBulkTransfer,
    merchantDetails,
    uploadStage,
    viewTransfer,
    searchState,
    setShowDateModal,
  } = useTransfersData();
  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = "15";
  const [searchParams, setSearchParams] = useSearchParams({});

  const merchantId = getFromLocalStorage(LOCAL_STORAGE_KEYS.MERCHANTID);
  const currentParams: any = Object.fromEntries([...searchParams]);
  const isInitialLoad = useRef(true);
  const location = useLocation();

  const { data: bulkTransferList, isLoading: bulkIsLoading } = useBulkList({
    page: currentParams?.page || currentPage,
    from:
      currentParams?.from && moment(currentParams?.from).format("YYYY-MM-DD"),
    to: currentParams?.to && moment(currentParams?.to).format("YYYY-MM-DD"),
    userId: merchantId,
    limit: Number(currentParams?.size) || Number(postsPerPage),
  });

  useEffect(() => setShowAction(false), []);

  //set params to 1 by default
  useEffect(() => {
    if (location?.search === "") {
      setSearchParams({
        page: "1",
        size: "15",
      });
    }
  }, [location]);

  //set params when you filter
  useEffect(() => {
    if (searchState?.from && searchState?.to) {
      setSearchParams({
        ...searchParams,
        page: currentParams?.page || currentPage?.toString(),
        size: currentParams?.size ? currentParams?.size : postsPerPage,

        from: moment(searchState?.from).format("YYYY-MM-DD"),
        to: moment(searchState?.to).format("YYYY-MM-DD"),
      });
    } else if (searchState?.from) {
      setSearchParams({
        ...searchParams,
        page: currentParams?.page || currentPage?.toString(),
        size: currentParams?.size ? currentParams?.size : postsPerPage,

        from: moment(searchState?.from).format("YYYY-MM-DD"),
      });
    } else if (searchState?.to) {
      setSearchParams({
        ...searchParams,
        page: currentParams?.page || currentPage?.toString(),
        size: currentParams?.size ? currentParams?.size : postsPerPage,

        to: moment(searchState?.to).format("YYYY-MM-DD"),
      });
    }
  }, [searchState]);

  //to not reset onLoad
  useEffect(() => {
    setTimeout(() => {
      isInitialLoad.current = false;
    }, 3000);
  }, []);

  // reset when the filter resets
  useEffect(() => {
    if (isInitialLoad.current && currentParams?.to && currentParams?.from) {
      setSearchParams({
        page: currentParams.page,
        size: currentParams?.size ? currentParams?.size : postsPerPage,

        from: currentParams?.from,
        to: currentParams?.to,
      });
    }
    if (!isInitialLoad?.current && !searchState?.from && !searchState?.to) {
      setSearchParams({
        page: "1",
        size: "15",
      });
      setShowDateModal(false);
    }
  }, [searchState]);

  if (bulkIsLoading) {
    return <SecondaryLoader count={10} />;
  }

  if (!bulkIsLoading && !bulkTransferList?.data?.list?.length) {
    return <EmptyTransfer setShowAction={setShowAction} />;
  }

  if (!bulkIsLoading && !uploadStage && !viewTransfer) {
    return (
      <div
        onClick={() => setShowAction(false)}
        className="bg-[#F6F8FA]  w-auto md:w-full h-full overflow-y-auto overflow-x-auto md:overflow-x-hidden hide_scrollbar mt-3 md:mt-0"
      >
        <div className="mx-4 md:mx-10 mt-4 mb-14 2xl:mb-20">
          <div className="flex items-center bg-[#F7F9FC] w-[140%] gap-x-4 overflow-x-auto md:overflow-x-hidden md:gap-x-0 md:w-full px-4 h-10 text-aellaBlack text-base border-b">
            <p className="w-[14rem] md:w-[25%]">Number of Recipients</p>
            <p className="w-[10rem] md:w-[26%]">Total Amount</p>
            <p className="w-[10rem] md:w-[30%]">Created By</p>
            <p className=" w-[14rem] md:flex-1 text-right">Date Created</p>
          </div>
          {bulkTransferList?.data?.list?.map((list: any) => {
            const { group_id, no_recipient, total_amount, transaction_date } =
              list;
            return (
              <div
                key={group_id}
                onClick={() => {
                  setSelectedBulkTransfer(list);
                  setViewTransfer(true);
                }}
                className="flex items-center bg-white w-[140%] gap-x-4 overflow-x-auto md:overflow-x-hidden md:gap-x-0 md:w-full p-4 text-[#5B5B5B] text-sm font-light border-b cursor-pointer"
              >
                <p className="w-[14rem] md:w-[25%]">{no_recipient}</p>
                <p className="w-[10rem] md:w-[26%]">{`₦${formatMoney(
                  total_amount || 0
                )}`}</p>
                <p className={`w-[10rem] md:w-[30%] capitalize`}>
                  {(merchantDetails?.first_name?.toLowerCase() || "") +
                    " " +
                    (merchantDetails?.last_name?.toLowerCase() || "-")}
                </p>
                <p className=" w-[14rem] md:flex-1 text-right">
                  {transaction_date
                    ? moment(transaction_date).format("DD MMM YYYY, LT")
                    : "-"}
                </p>
              </div>
            );
          })}

          <Pagination
            postsPerPage={Number(currentParams?.size) || Number(postsPerPage)}
            totalPosts={bulkTransferList?.data?.meta?.total}
            currentPage={Number(currentParams?.page) || currentPage}
            setCurrentPage={setCurrentPage}
            totalPages={bulkTransferList?.data?.meta?.total_page}
          />
        </div>
      </div>
    );
  }

  return null;
};

export default BulkTransfer;
