import { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useForm } from "react-hook-form";
import { string, object } from "yup";
import { toast } from "react-toastify";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { format, subYears } from "date-fns";

import { ModalContainer } from "../../../modal/ModalContainer";
import { ReactComponent as CloseModalIcon } from "../../../assets/images/svg/close-icon.svg";
import blueInfo from "../../../assets/images/svg/blue-info.svg";
import { ReactComponent as BackIcon } from "../../../assets/images/svg/back-icon.svg";
import TextField from "../../../component/CustomHTMLElements/TextField";
import { phoneRegExp, yupValidators } from "../../../helpers/yupValidators";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomInputField from "../../../component/CustomHTMLElements/CustomInputField";
import CustomSelectDropdown from "../../../component/CustomHTMLElements/CustomSelectDropdown";
import BlueButton from "../../../component/Button/BlueButton";
import { createEmployeeFn, getBanksFn, resolveAccountFn } from "../queries";
import { errorHandler } from "../../../helpers/errorHandler";
import { CreateEmployeePayload } from "../../../interfaces/employee-loans";
import { handleScrollToTop } from "../../../helpers/scrollToTop";
import { SpinLoader } from "../../../component/Loader/Loader.component";
import { daysOfTheMonth } from "../../../helpers/others";

export const CreateEmployeeModal = ({
  showModal,
  closeModal,
}: {
  showModal: boolean;
  closeModal: () => void;
}) => {
  const queryClient = useQueryClient();
  const [stage, setStage] = useState(1);
  const schema = object().shape({
    id: string().required("Employeed ID is required"),
    fullName: string()
      .required("Full Name is required")
      .max(60, "Name cannot be longer than 60 characters"),
    title: string()
      .required("Title is required")
      .max(60, "Title cannot be longer than 60 characters"),
    email: string()
      .trim()
      .email("Please enter a valid email address")
      .required("Please enter email address")
      .min(5, "Email must have at least 5 characters")
      .max(128, "Email cannot have more than 128 characters"),
    phoneNumber: string()
      .trim()
      .required("Please enter phone number")
      .test(
        "len",
        "phone number must be exactly 11 characters",
        (val) => val?.length === 11
      )
      .matches(phoneRegExp, "Invalid Phone Number"),
    // bvn: string().notRequired().length(11),
    status: string().required("Employee Status is required"),
    gender: string().required("Gender is required"),
    employmentDate: string().required("Employment Date is required"),
    dateOfBirth: string().required("Date of birth is required"),
    netPay: yupValidators.onlyNumbersWithDecimals,
    monthlySalary: yupValidators.onlyNumbersWithDecimals,
    payday: yupValidators.onlyNumbers,
  });

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors },
    clearErrors,
  } = useForm<{
    id: string;
    fullName: string;
    title: string;
    email: string;
    phoneNumber: string;
    status: string;
    gender: string;
    bvn: string;
    employmentDate: Date;
    netPay: string;
    payday: string;
    monthlySalary: string;
    dateOfBirth: any;
  }>({
    resolver: yupResolver(schema),
  });

  const values = watch();
  const modalRef = useRef(null);

  const [form, setForm] = useState(null);
  const [error, setError] = useState("");
  const [bankAccounts, setBankAccounts] = useState([
    {
      bankId: "",
      accountNumber: "",
      accountName: "",
    },
  ]);

  const [resolvedAccount, setResolvedAccount] = useState(null);
  const dateOfBirthMaxDate = subYears(new Date(), 18);
  const [salaryError, setSalaryError] = useState(null);

  useEffect(() => {
    handleScrollToTop(modalRef);
  }, [stage, showModal]);

  useEffect(() => {
    if (
      +values?.netPay &&
      +values?.monthlySalary &&
      Number((+values?.netPay / 12).toFixed(2)) !== +values?.monthlySalary
    ) {
      setSalaryError({
        message: "Monthly salary has to be annual pay divided by 12",
      });
    } else if (
      +values?.monthlySalary &&
      +values?.netPay &&
      +values?.monthlySalary > +values?.netPay
    ) {
      setSalaryError({
        message: "Monthly salary cannot exceed annual net pay",
      });
    } else {
      setSalaryError(null);
    }
  }, [values?.netPay, values?.monthlySalary]);

  useEffect(() => {
    if (bankAccounts[0]?.accountNumber?.length < 10) {
      setResolvedAccount(null);
    }
  }, [bankAccounts[0]?.accountNumber]);

  const updateForm = (payload) => {
    setForm((prev) => ({ ...prev, payload }));
  };

  const { data: banksList } = useQuery({
    queryKey: ["all banks"],
    queryFn: getBanksFn,
  });

  const { isLoading: isResolvingAccount, mutateAsync: resolveAccountAsync } =
    useMutation({
      mutationFn: resolveAccountFn,
      onSuccess: (data) => {
        setError("");
        setResolvedAccount(data?.data);
      },
      onError: () => {
        setResolvedAccount(null);
        setError(errorHandler(error));
      },
    });

  const handleAccountResolution = async () => {
    setError("");
    setResolvedAccount(null);
    try {
      await resolveAccountAsync({
        payload: {
          account_number: bankAccounts[0]?.accountNumber,
          bank_id: bankAccounts[0]?.bankId,
        },
      });
    } catch (error) {}
  };

  const { isLoading: isCreatingEmployee, mutateAsync: createEmployeeAsync } =
    useMutation({
      mutationFn: createEmployeeFn,
      onSuccess: (data) => {
        queryClient.invalidateQueries("all employees");
        closeModal();
        toast.success(data?.message);
        reset();
        setBankAccounts([
          {
            bankId: "",
            accountNumber: "",
            accountName: "",
          },
        ]);
        setTimeout(() => {
          setStage(1);
        }, 1000);
      },
      onError: (error) => {
        toast.error(errorHandler(error));
      },
    });

  const onSubmit = () => {
    updateForm(values);
    setStage(2);
  };

  const handleCreation = async (e) => {
    e.preventDefault();

    let payload: CreateEmployeePayload = {
      date_of_birth: format(new Date(values?.dateOfBirth), "yyyy-MM-dd"),
      date_of_employment: format(
        new Date(values?.employmentDate),
        "yyyy-MM-dd"
      ),
      email_address: values?.email,
      full_name: values?.fullName,
      gender: values?.gender,
      job_title: values?.title,
      net_annual_pay: +values?.netPay,
      net_monthly_pay: +values?.monthlySalary,
      payday: +values?.payday,
      phone_number: values?.phoneNumber,
      staff_id: values?.id,
      bvn: values?.bvn,
      account_number: resolvedAccount?.accountNumber,
      bank_code: bankAccounts[0]?.bankId,
    };

    try {
      await createEmployeeAsync({
        payload,
      });
    } catch (error) {}
  };

  const handleBankChange = (index, field, value) => {
    const newBankAccounts = [...bankAccounts];
    newBankAccounts[index][field] = value;
    setBankAccounts(newBankAccounts);
  };

  const formatCurrency = (value: string) => {
    const number = parseFloat(value.replace(/[^0-9.]/g, ""));
    return isNaN(number) ? "" : number.toFixed(2);
  };

  return (
    <ModalContainer
      showModal={showModal}
      closeModal={() => {
        closeModal();
        reset();
      }}
    >
      <div
        ref={modalRef}
        className={`w-full lg:w-[40vw] absolute top-0 h-screen z-50 text-[#232323] flex flex-col rounded-[8px] bg-white py-10 px-4 lg:px-8 overflow-y-auto animation ${
          showModal ? "right-0" : "-right-[40vw]"
        } `}
      >
        <div className="flex justify-between items-center w-full">
          <p className="text-[#7b7b7b] text-sm font-light">Step {stage} of 2</p>
          <div onClick={closeModal} className="cursor-pointer">
            <CloseModalIcon />
          </div>
        </div>

        <div className="w-full min-h-[5px] rounded-[100px] my-4 bg-[#EDEFF5]">
          <div
            className={`min-h-[5px] bg-aellaBlue rounded-[100px] ${
              stage === 1 ? "w-1/2" : "w-full"
            }`}
          />
        </div>

        <hr />

        {stage === 2 && (
          <div className="flex items-center mt-3">
            <div
              className={`flex items-center gap-x-2 cursor-pointer`}
              onClick={() => {
                setStage(stage - 1);
              }}
            >
              <BackIcon />
              <span className="text-[#011C34] text-[18px] font-light">
                Back
              </span>
            </div>
          </div>
        )}

        {stage === 1 ? (
          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <div className="mt-6 p-6 border border-[#EDEFF5] rounded-[8px]">
              <p className="text-[#0B1E4B] text-[20px] md:text-[24px] mb-2">
                Employee Basic Info
              </p>
              <p className="text-aellaGray font-light">
                Feel free to give some bit of personal information about the
                employee you want to add to the system.
              </p>

              <div className="flex flex-wrap mt-8 justify-between">
                <div className="w-full sm:w-[47%]">
                  <CustomInputField
                    type="text"
                    maxLength={128}
                    label="Employee ID"
                    errors={errors.id}
                    {...register("id")}
                    placeholder="Enter ID here"
                    showRequiredIcon
                  />
                </div>
                <div className="w-full sm:w-[47%]">
                  <CustomInputField
                    type="text"
                    maxLength={128}
                    label="Employee Full Name"
                    errors={errors.fullName}
                    {...register("fullName")}
                    placeholder="Enter full name here"
                    showRequiredIcon
                  />
                </div>
                <div className="w-full">
                  <CustomInputField
                    type="text"
                    maxLength={128}
                    label="Employee Job Title"
                    errors={errors.title}
                    {...register("title")}
                    placeholder="Enter job title here"
                    showRequiredIcon
                  />
                </div>

                <div className="w-full sm:w-[47%]">
                  <CustomInputField
                    type="email"
                    maxLength={128}
                    label="Employee Work Email"
                    errors={errors.email}
                    {...register("email")}
                    placeholder="Enter email here"
                    showRequiredIcon
                  />
                </div>

                <div className="w-full sm:w-[47%]">
                  <CustomInputField
                    type="text"
                    maxLength={11}
                    label="Employee Phone Number"
                    errors={errors.phoneNumber}
                    {...register("phoneNumber")}
                    placeholder="Enter phone number here"
                    showRequiredIcon
                  />
                </div>

                <div className="w-full">
                  <CustomSelectDropdown
                    errorMessage="Employee's status is required"
                    errors={errors.status}
                    label="Employee Status"
                    name="status"
                    {...register("status")}
                    showRequiredIcon
                  >
                    <option value="">Select status</option>
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                    {/* <option value="suspended">Suspended</option> */}
                  </CustomSelectDropdown>
                </div>
                <div className="w-full">
                  <CustomSelectDropdown
                    errorMessage="Employee's gender is required"
                    errors={errors.gender}
                    label="Employee Gender"
                    name="gender"
                    {...register("gender")}
                    showRequiredIcon
                  >
                    <option value="">Select Gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </CustomSelectDropdown>
                </div>

                <div className="flex flex-col w-full ">
                  <p className="text-aellaBlack font-light">
                    Date Of Birth <sup className="text-[#DD4F05]">*</sup>{" "}
                  </p>
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    placeholderText="dd/mm/yyyy"
                    {...register("dateOfBirth")}
                    selected={values.dateOfBirth}
                    maxDate={dateOfBirthMaxDate}
                    onChange={(date) => {
                      setValue("dateOfBirth", date);
                    }}
                    showYearDropdown
                    scrollableYearDropdown
                    yearDropdownItemNumber={100}
                    className={`h-12 mt-1 w-full pl-3 border outline-none focus:border-aellaBlue rounded-[4px] text-aellaBlack lg:leading-[16px] text-[16px] font-[300] ${
                      errors.dateOfBirth && !values?.dateOfBirth
                        ? "border-[#dd4f05]"
                        : "border-aellaLightGray"
                    }`}
                  />

                  {!!errors?.dateOfBirth?.message && !values?.dateOfBirth && (
                    <p className="text-[#DD4F05] font-light text-[14px] mt-[2px]">
                      {errors?.dateOfBirth?.message?.toString()}
                    </p>
                  )}
                </div>

                <div className="w-full flex flex-col mt-6">
                  <p className="text-aellaBlack font-light">
                    Employment Date <sup className="text-[#DD4F05]">*</sup>{" "}
                  </p>
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    placeholderText="dd/mm/yyyy"
                    {...register("employmentDate")}
                    selected={values?.employmentDate}
                    maxDate={new Date()}
                    onChange={(date) => {
                      setValue("employmentDate", date);
                    }}
                    showYearDropdown
                    scrollableYearDropdown
                    yearDropdownItemNumber={100}
                    className={`h-12 mt-1 w-full pl-3 border outline-none focus:border-aellaBlue rounded-[4px] text-aellaBlack lg:leading-[16px] text-[16px] font-[300] ${
                      errors.employmentDate && !values?.employmentDate
                        ? "border-[#dd4f05]"
                        : "border-aellaLightGray"
                    }`}
                  />
                  {!!errors?.employmentDate?.message &&
                    !values?.employmentDate && (
                      <p className="text-[#DD4F05] font-light text-[14px] mt-[2px]">
                        {errors?.employmentDate?.message?.toString()}
                      </p>
                    )}
                </div>

                <p className="w-full text-[#0B1E4B] text-[20px] md:text-[24px] mb-2 mt-10">
                  Employee Payment Info
                </p>
                <div className="w-full sm:w-[47%]">
                  <CustomInputField
                    type="text"
                    maxLength={128}
                    label="Employee Annual Net Pay"
                    errors={errors.netPay}
                    {...register("netPay", {
                      onChange: (e) => {
                        setSalaryError(null);
                        clearErrors("monthlySalary");
                        clearErrors("netPay");

                        const formattedAmount = formatCurrency(e.target.value);

                        if (formattedAmount !== "") {
                          const monthlyAmount = (
                            parseFloat(formattedAmount) / 12
                          ).toFixed(2);
                          setValue("monthlySalary", monthlyAmount);
                        } else {
                          setValue("monthlySalary", "");
                        }
                      },
                    })}
                    placeholder="NGN 0.00"
                    showRequiredIcon
                  />
                </div>

                <div className="w-full sm:w-[47%] lg:-mt-[2px] sm:mt-1">
                  {/* <CustomInputField
                    type="text"
                    maxLength={128}
                    label="Pay Day"
                    errors={errors.payday}
                    {...register("payday")}
                    placeholder="Enter payday"
                    max="31"
                    min="1"
                    showRequiredIcon
                  /> */}

                  <CustomSelectDropdown
                    label="Pay Day"
                    errors={errors.payday}
                    {...register("payday")}
                    placeholder="Enter payday"
                    showRequiredIcon
                    errorMessage="Payday is required"
                    selectClassName="-mt-1"
                    extraMargin="mb-[7px]"
                  >
                    <option value="">Select payday</option>
                    {daysOfTheMonth?.map((day) => (
                      <option key={day} value={day}>
                        {day}
                      </option>
                    ))}
                  </CustomSelectDropdown>
                </div>

                <div className="w-full">
                  <CustomInputField
                    type="text"
                    maxLength={128}
                    label="Employee Monthly Salary"
                    errors={errors.monthlySalary || salaryError}
                    {...register("monthlySalary", {
                      onChange: (e) => {
                        setSalaryError(null);
                        clearErrors("monthlySalary");
                        clearErrors("netPay");
                        const formattedAmount = formatCurrency(e.target.value);
                        if (formattedAmount !== "") {
                          const annualAmount = (
                            parseFloat(formattedAmount) * 12
                          ).toFixed(2);
                          setValue("netPay", annualAmount);
                        } else {
                          setValue("netPay", "");
                        }
                      },
                    })}
                    placeholder="NGN 0.00"
                    showRequiredIcon
                  />
                </div>
              </div>
              <div className="mt-8 flex gap-x-2 bg-[#F3F5FC] border border-[#C5D4F6] p-4 rounded-[8px]">
                <img src={blueInfo} className="w-4 h-4" alt="" />

                <div className="text-sm flex flex-col text-aellaBlack">
                  <span> Please Note:</span>
                  <span className="font-light mt-2">
                    This email address cannot be changed later on, as this will
                    be used to send loan receipt and also for employees to sign
                    in to their portal if they are to complete their details
                    themselves
                  </span>
                </div>
              </div>
            </div>
            <BlueButton
              disabled={salaryError?.message}
              type="submit"
              title="Save and Continue"
              className="ml-auto mt-4 mb-12 md:mb-0 md:mt-2"
            />
          </form>
        ) : (
          <form autoComplete="off">
            <div className="mt-6 p-6 border border-[#EDEFF5] rounded-[8px]">
              <p className="text-[#0B1E4B] text-[20px] md:text-[24px] mb-2">
                Bank Account Info
              </p>
              <p className="text-aellaGray font-light">
                Provide employee account details. Ensure the name on the account
                matches employee&apos;s name.
              </p>

              <div className="w-full flex flex-col mt-8 gap-y-4">
                {bankAccounts?.map((account, index) => (
                  <div key={index}>
                    <CustomSelectDropdown
                      label="Select Bank"
                      name={`bankName-${index}`}
                      showRequiredIcon
                      onChange={(e) => {
                        handleBankChange(index, "bankId", e.target.value);
                        setResolvedAccount(null);
                      }}
                    >
                      <option value="">Select Bank</option>
                      {banksList?.data?.map((data) => (
                        <option key={data.institution_code} value={data.id}>
                          {data.name}
                        </option>
                      ))}
                    </CustomSelectDropdown>
                    <TextField
                      errors={!account.accountNumber}
                      label="Account Number"
                      placeholder="Enter account number"
                      className="w-full"
                      inputClassName="border-aellaLightGray"
                      isRequired
                      min="1"
                      maxLength={10}
                      value={account.accountNumber}
                      onChange={(e) =>
                        handleBankChange(index, "accountNumber", e.target.value)
                      }
                    />
                    {isResolvingAccount && <SpinLoader />}
                    {error && !resolvedAccount && (
                      <p className="text-[#cc0000] font-light text-sm">
                        {error}
                      </p>
                    )}
                    {resolvedAccount?.accountName &&
                      !isResolvingAccount &&
                      bankAccounts[0]?.accountNumber?.length === 10 &&
                      !error && (
                        <p className="text-aellaBlack text-sm -mt-2 font-light uppercase truncate">
                          {resolvedAccount?.accountName}
                        </p>
                      )}
                  </div>
                ))}

                <BlueButton
                  disabled={
                    isResolvingAccount ||
                    !bankAccounts[0]?.bankId ||
                    bankAccounts[0]?.accountNumber?.length !== 10
                  }
                  title="Find Account"
                  className="mb-6 self-start"
                  onClick={handleAccountResolution}
                />
              </div>

              <CustomInputField
                type="number"
                maxLength={11}
                label="Employee's BVN"
                errors={errors.bvn}
                {...register("bvn")}
                placeholder="Enter BVN here"
              />
              {values?.bvn && values?.bvn?.length !== 11 && (
                <p className="text-[#DD4F05] font-light text-[14px] mt-[2px]">
                  BVN must be 11 characters
                </p>
              )}
            </div>

            <div className="mt-4">
              <div className="flex gap-x-4 items-center justify-end mt-4">
                <BlueButton
                  disabled={
                    isCreatingEmployee ||
                    !resolvedAccount ||
                    bankAccounts[0]?.accountNumber?.length !== 10 ||
                    (values?.bvn && values?.bvn?.length !== 11)
                  }
                  title={isCreatingEmployee ? "Please wait.." : "Submit"}
                  onClick={handleCreation}
                  type="submit"
                  className="ml-auto mt-4 mb-12 md:mb-0 md:mt-2"
                />
              </div>
            </div>
          </form>
        )}
      </div>
    </ModalContainer>
  );
};
