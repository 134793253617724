import { FC, useState } from "react";
import moment from "moment";

import { ReactComponent as BackIcon } from "../../assets/images/svg/back-icon.svg";
import { formatMoney } from "../../helpers/formatMoney";
import BulkTransferDetails from "./BulkTransferDetails";
import { useGroupedList } from "../../hooks/useTransfersList";
import { truncateString } from "../../helpers/formatter";
import { SecondaryLoader } from "../../component/Loader/SecondaryLoader";
import { getFromLocalStorage } from "../../helpers/localStorage";
import { LOCAL_STORAGE_KEYS } from "../../helpers/localStorageKeys";
import GroupedTransferPagination from "../../component/Pagination/GroupedTransferPagination";

type ViewTransferProps = {
  setViewTransfer: (newState: boolean) => void;
  selectedBulkTransfer: any;
};

const ViewBulkTransfer: FC<ViewTransferProps> = ({
  setViewTransfer,
  selectedBulkTransfer,
}) => {
  const merchantId = getFromLocalStorage(LOCAL_STORAGE_KEYS.MERCHANTID);
  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 15;
  const [selectedTransfer, setSelectedTransfer] = useState({});
  const [showModal, setShowModal] = useState(false);

  const { data: groupedList, isLoading } = useGroupedList({
    selected: selectedBulkTransfer?.group_id,
    userId: merchantId,
    limit: postsPerPage,
    page: currentPage,
  });

  if (isLoading) {
    return <SecondaryLoader count={14} />;
  }

  return (
    <div className="h-screen w-full bg-[#F6F8FA]">
      <BulkTransferDetails
        selectedTransfer={selectedTransfer}
        setSelectedTransfer={setSelectedTransfer}
        showModal={showModal}
        setShowModal={setShowModal}
      />
      <div
        onClick={() => setViewTransfer(false)}
        className="h-20 px-4 md:px-10 bg-white flex items-center gap-x-4 text-[18px] text-[#011C34] font-light border-t cursor-pointer"
      >
        <BackIcon />
        Back
      </div>
      <div className="mx-4 md:mx-10 mt-4 mb-14 2xl:mb-20 overflow-x-auto hide_scrollbar">
        <div className="flex items-center bg-[#F7F9FC] w-[280%] gap-x-4 overflow-x-auto md:overflow-x-hidden md:gap-x-0 md:w-full px-4 h-10 text-aellaBlack text-sm border-b">
          <p className="w-[16%]">Account Name</p>
          <p className="w-[18%]">Account Number</p>
          <p className="w-[15%]">Recipient Bank</p>
          <p className="w-[17%]">Amount</p>
          <p className="w-[%]">Status</p>
          <p className="flex-1 text-right">Time Stamp</p>
        </div>
        {groupedList?.data?.list?.map((list: any) => {
          return (
            <div
              key={list?.user_id + Math.random()}
              onClick={() => {
                setSelectedTransfer(list);
                setShowModal(true);
              }}
              className="flex items-center bg-white w-[280%] gap-x-4 overflow-x-auto md:overflow-x-hidden md:gap-x-0 md:w-full p-4 text-[#5B5B5B] text-sm font-light border-b cursor-pointer"
            >
              <p className="w-[16%] capitalize">
                {" "}
                {truncateString(list?.account?.name?.toLowerCase(), 14) || "-"}
              </p>
              <p className="w-[18%]">{list?.account?.number}</p>
              <div className="w-[15%] capitalize">
                {list?.bank?.name?.toLowerCase()}
              </div>
              <p className="w-[17%]">{`₦${formatMoney(list?.amount)}`}</p>
              <p
                className={`w-[6%] inline-flex justify-center py-[2px] rounded-[4px] text-[0.75rem] ${
                  list?.status?.toLowerCase() === "success"
                    ? "text-[#14804A] bg-[#E1FCEF]"
                    : list?.status?.toLowerCase() === "pending"
                    ? "text-[#AA5B00] bg-[#FCF2E6]"
                    : list?.status?.toLowerCase() === "declined"
                    ? "text-[#BD6242] bg-[#FCF2E6]"
                    : "text-[#D1293D] bg-[#FFEDEF]"
                }`}
              >
                {list?.status}
              </p>
              <p className="flex-1 text-right">
                {moment(list?.transaction_date).format("DD MMM YYYY, LT")}
              </p>
            </div>
          );
        })}

        <GroupedTransferPagination
          postsPerPage={postsPerPage}
          totalPosts={groupedList?.data?.meta?.total}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={groupedList?.data?.meta?.total_page}
        />
      </div>
    </div>
  );
};

export default ViewBulkTransfer;
