import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData } from "../apis/apiMethods";
import CONFIG from "../helpers/config";

export const getPosDisputeConvo = async ({ queryKey }: { queryKey: any }) => {
  const { id } = queryKey[1];

  const { data }: any = await getData(
    `${CONFIG.BASE_URL2}${apiEndpoints.POS_DISPUTES}/conversations/${id}`
  );
  return data;
};

export const usePosDisputeConvo = ({ id }: { id: string }) => {
  return useQuery(["getPosDisputeConvo", { id }], getPosDisputeConvo, {
    retry: 1,
    enabled: id ? true : false,
    refetchOnWindowFocus: false,
  });
};