import { FC, useState, useEffect } from "react";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";

import { ReactComponent as BackIcon } from "../../assets/images/svg/back-icon.svg";
import { ReactComponent as ChooseFileIcon } from "../../assets/images/svg/choose-file-icon.svg";

import PreviewBulkData from "./PreviewBulkData";

type BulkTransferProps = {
  uploadStage: string;
  setUploadStage: (newState: string) => void;
  formInfo: any;
  setFormInfo: any;
  register: any;
  handleSubmit: any;
  setValue: any;
  watch: any;
  errors: any;
  reset: any;
  resetForm: any;
  setViewTransfer: (newState: boolean) => void;
  selectedAccount: string;
  setSelectedAccount: (newState: string) => void;
};

const BulkTransfers: FC<BulkTransferProps> = ({
  uploadStage,
  setUploadStage,
  formInfo,
  setFormInfo,
  register,
  handleSubmit,
  setValue,
  watch,
  errors,
  reset,
  resetForm,
  setViewTransfer,
  selectedAccount,
  setSelectedAccount,
}) => {
  const [bulkFile, setBulkFile] = useState<any>();
  const [showExit, setShowExit] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [showPin, setShowPin] = useState(false);
  const [selectedTransfer, setSelectedTransfer] = useState({});
  const [status, setStatus] = useState("");
  const [showStatus, setShowStatus] = useState(false);
  const [ext, setExt] = useState("");
  const [currentFile, setCurrentFile] = useState<any>();
  const [mockData, setMockData] = useState<any>([]);

  const goBack = () => {
    if (uploadStage === "preview") {
      setShowExit(true);
    } else setUploadStage("");
  };

  useEffect(() => {
    if (currentFile) setUploadStage("preview");
  }, [currentFile]);

  const validFileType = ["xls", "xlsx"];

  const handleUpload = async (e: any) => {
    const file = e.target.files[0];

    if (!validFileType?.includes(file?.name?.split(".")?.pop())) {
      toast.error("File type is not allowed. Kindly use xlsx");
      return;
    }

    if (file > 5000000) {
      toast.error("File size is too large. Max file size allowed is 5mb");
      return;
    }

    const extension = file?.name?.split(".")?.pop();
    setExt(extension);

    const reader: any = new FileReader();

    if (file) {
      reader.readAsArrayBuffer(file);
      reader.onload = (e: any) => {
        setBulkFile(e.target.result);
        const workbook = XLSX.read(e.target.result, { type: "buffer" });
        const workSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[workSheetName];
        const data = XLSX.utils.sheet_to_json(worksheet);
        setCurrentFile(data);
      };
    }
  };

  const renderBody = () => {
    switch (uploadStage) {
      case "initial":
        return (
          <>
            <div className="h-[450px] mx-4 sm:mx-10 p-4 bg-white mt-8 rounded-[8px] shadow-sm relative">
              <div className="h-full w-full  border border-aellaBlue border-dashed rounded-[8px] flex flex-col items-center justify-center gap-y-4 text-center px-4">
                <div className="flex">
                  <div className="rounded-[50%] flex justify-center items-center text-2xl h-14 w-14 bg-[#C5D4F6] text-aellaBlue">
                    KY
                  </div>
                  <div className="rounded-[50%] -ml-3 border border-white flex justify-center items-center text-2xl h-14 w-14 bg-[#F0D5C7] text-aellaBlue">
                    JC
                  </div>
                  <div className="rounded-[50%] -ml-3 border border-white flex justify-center items-center text-2xl h-14 w-14 bg-[#C5D4F6] text-aellaBlue">
                    UF
                  </div>
                  <div className="rounded-[50%] -ml-3 border border-white flex justify-center items-center text-2xl h-14 w-14 bg-[#F0D5C7] text-aellaBlue">
                    CH
                  </div>
                </div>

                <p className="text-[#232323] text-2xl">
                  Drag and drop your data file here or
                </p>
                <div className="flex">
                  <input
                    type="file"
                    onChange={handleUpload}
                    className="bg-slate-500 opacity-0  h-[50px] w-40 lg:w-52 absolute top-[54%] right-[35%] md:right-[42%] z-10 cursor-pointer"
                    //   accept=".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .pdf"

                    // accept=".csv, text/csv, .xlsx, .xls"
                    accept=".xlsx, .xls"
                  />
                  <div className="flex items-center justify-center gap-x-2 h-10 w-32 border border-aellaBlue rounded-[4px] cursor-pointer ">
                    <ChooseFileIcon />
                    <p className="text-aellaBlue">Choose File</p>
                  </div>
                </div>
                <p className="text-[#5B5B5B] font-light opacity-70">
                  Maximum file size: 5MB | Files supported: excel
                </p>
              </div>
            </div>

            <div className="mx-10 mb-32 font-light">
              <p className="text-[#232323] text-2xl my-4 font-normal">
                How to upload
              </p>
              <p className=" text-[#5b5b5b]">
                <span className="mr-3"> 1.</span> Download the{" "}
                {/* <strong
                  onClick={() => {
                    exportFile(
                      "csv",
                      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
                      "Aella Bulk Transfer Template",
                      ".csv"
                    );
                  }}
                  className="text-aellaBlue cursor-pointer"
                >
                  csv template
                </strong>{" "}
                or{" "} */}
                <strong
                  onClick={() => {}}
                  className="text-aellaBlue cursor-pointer"
                >
                  <a
                    // onClick={handleDo}
                    // target="_blank"
                    href="https://static.aellacredit.com/user_image_staging/merchant-bulk-upload-1680522223442-WorIynwGvc.xlsx"
                    rel="noopener noreferrer"
                    download
                  >
                    excel template
                  </a>
                </strong>{" "}
                to structure your transfer data
              </p>
              <div className="text-[#5b5b5b]">
                <span className="mr-3"> 2.</span>{" "}
                <span className="text-[#5b5b5b]"> Get the</span>{" "}
                <strong
                  className="text-aellaBlue cursor-pointer"
                  onClick={() => window.open("/bank-list", "_blank")}
                >
                  Bank Name here
                </strong>
              </div>
              <p className="text-[#5B5B5B]">
                <span className="mr-3"> 3.</span>
                Add your Data to the template file or compare to see if your
                data is ready to be uploaded
              </p>
              <p className="text-[#5B5B5B]">
                <span className="mr-3 "> 4.</span>
                Upload the file to add your data into the system.
              </p>
            </div>
          </>
        );

      case "preview":
        return (
          <PreviewBulkData
            showExit={showExit}
            setShowExit={setShowExit}
            setUploadStage={setUploadStage}
            showRemoveModal={showRemoveModal}
            setShowRemoveModal={setShowRemoveModal}
            confirmation={confirmation}
            setConfirmation={setConfirmation}
            showPin={showPin}
            setShowPin={setShowPin}
            // pin={pin}
            // setPin={setPin}
            setSelectedTransfer={setSelectedTransfer}
            setBulkFile={setBulkFile}
            status={status}
            setStatus={setStatus}
            showStatus={showStatus}
            setShowStatus={setShowStatus}
            setCurrentFile={setCurrentFile}
            setExt={setExt}
            mockData={mockData}
            setMockData={setMockData}
            formInfo={formInfo}
            setFormInfo={setFormInfo}
            register={register}
            handleSubmit={handleSubmit}
            setValue={setValue}
            watch={watch}
            errors={errors}
            reset={reset}
            resetForm={resetForm}
            currentFile={currentFile}
            setViewTransfer={setViewTransfer}
            selectedAccount={selectedAccount}
            setSelectedAccount={setSelectedAccount}
          />
        );

      default:
        break;
    }
  };

  return (
    <div className="h-screen bg-aellaDimGray overflow-y-scroll">
      <div
        onClick={goBack}
        className="h-20 bg-white px-10 flex items-center gap-x-4 text-[18px] text-[#011C34] font-light border-t cursor-pointer"
      >
        <BackIcon />
        Back
      </div>
      {renderBody()}
    </div>
  );
};

export default BulkTransfers;
