import { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useSearchParams } from "react-router-dom";
import { format } from "date-fns";

import { ReactComponent as CloseIcon } from "../../../assets/images/svg/close-icon.svg";
import { ModalContainer } from "../../../modal/ModalContainer";
import CalendarIcon from "../../../assets/images/svg/calendar.svg";
import CustomSelectDropdown from "../../../component/CustomHTMLElements/CustomSelectDropdown";
export const FilterEmployees = ({
  setFilterObj,
  showModal,
  closeModal,
  updateState,
}: {
  setFilterObj: any;
  showModal: boolean;
  closeModal: () => void;
  updateState: any;
}) => {
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);
  const [status, setStatus] = useState("");
  const [params, setParams] = useSearchParams({});
  const [error, setError] = useState(false);

  const handleCloseModal = () => {
    closeModal();
  };

  const handleReset = () => {
    setStart(null);
    setEnd(null);
    setStatus("");
    setParams({});
    setFilterObj(null);
    closeModal();
    updateState({
      currentPage: 1,
    });
  };

  const handleParams = () => {
    const newParams = {
      from: format(new Date(start), "yyyy-MM-dd"),
      to: format(new Date(end), "yyyy-MM-dd"),
      ...(status && {
        status:
          status === "1"
            ? "active"
            : status === "2"
            ? "inactive"
            : "deactivated",
      }),
    };

    setParams(newParams);
  };

  return (
    <ModalContainer showModal={showModal} closeModal={handleCloseModal}>
      <div
        className={`w-full lg:w-[35vw] relative right-0 min-h-[30vh] h-auto max-h-[90vh] z-50 flex flex-col justify-center text-center p-10 rounded-[8px] bg-white fast_animation ${
          showModal
            ? "mt-0 opacity-100 visible"
            : "mt-[6rem] opacity-0 invisible"
        }`}
      >
        <button
          onClick={handleCloseModal}
          className="absolute -top-8 -right-0 cursor-pointer"
        >
          <CloseIcon />
        </button>
        <div className="flex flex-col">
          <p className="pb-4 border-b text-left text-[24px] text-aellaBlack">
            Filter by:
          </p>

          <div className="mt-4 w-full">
            <div>
              <CustomSelectDropdown
                label="Status"
                name="status"
                onChange={(e): any => {
                  setStatus(e.target.value);
                  setError(false);
                }}
                value={status}
                selectClassName="font-light"
              >
                <option value="" className="font-light">
                  Select status
                </option>
                <option value="1" className="font-light">
                  Active
                </option>
                <option value="2" className="font-light">
                  Inactive
                </option>
                {/* <option value="3" className="font-light">
                  Deactivated
                </option> */}
              </CustomSelectDropdown>
              {error && (
                <p className="font-light text-[#cc0000] text-[12px] -mt-4 mb-4 text-left">
                  Status is required
                </p>
              )}
            </div>
            <p className="pb-2 text-aellaBlack text-left lg:leading-[16px] text-[16px] font-[300]">
              Employment Date Range
            </p>
            <div className="flex items-center justify-between">
              <div className="w-[46%] z-50 flex items-center border border-aellaLightGray rounded-[4px] px-3">
                <DatePicker
                  dateFormat="yyyy-MM-dd"
                  placeholderText="Start date"
                  selected={start}
                  maxDate={end ?? new Date()}
                  onChange={(date: Date) => {
                    setEnd(null);
                    setStart(date);
                  }}
                  showYearDropdown
                  scrollableYearDropdown
                  yearDropdownItemNumber={100}
                  className="h-11 w-full text-aellaBlack lg:leading-[16px] text-[16px] font-[300] outline-0"
                />
                <img
                  src={CalendarIcon}
                  className="w-[20px] h-[20px]"
                  alt="calendar icon"
                />
              </div>

              <div className="w-[46%] z-50 flex items-center border border-aellaLightGray rounded-[4px] px-3">
                <DatePicker
                  dateFormat="yyyy-MM-dd"
                  placeholderText="End Date"
                  selected={end}
                  minDate={start}
                  maxDate={new Date()}
                  onChange={(date: Date) => {
                    setEnd(date);
                  }}
                  showYearDropdown
                  scrollableYearDropdown
                  yearDropdownItemNumber={100}
                  className="h-11 w-full text-aellaBlack lg:leading-[16px] text-[16px] font-[300] outline-0"
                />
                <img
                  src={CalendarIcon}
                  className="w-[20px] h-[20px]"
                  alt="calendar icon"
                />
              </div>
            </div>

            <div className="flex justify-end gap-x-8 bg-white pt-10">
              <div></div>
              <button
                className="border-aellaBlue border rounded py-2 cursor-pointer text-center text-aellaBlue w-[121px]"
                onClick={handleReset}
              >
                Reset
              </button>
              <button
                className="border border-aellaBlue rounded bg-aellaBlue text-white cursor-pointer w-[121px]"
                onClick={() => {
                  if (!status) {
                    return setError(true);
                  }
                  setFilterObj({
                    start,
                    end,
                    status,
                  });
                  updateState({
                    searchParams: "",
                    searchCategory: "",
                    currentPage: 1,
                  });
                  closeModal();
                  // setStatus("");
                  // setStart(new Date());
                  // setEnd(new Date());
                  // handleParams();
                }}
              >
                Filter
              </button>
            </div>
          </div>
        </div>
      </div>
    </ModalContainer>
  );
};
