import { useEffect, useState } from "react";
import Tab from "../../component/Tab/Tab.component";
import { ReactComponent as AddIcon } from "../../assets/images/svg/white-add-icon.svg";
import { apiEndpoints } from "../../apis/apiEndpoints";
import { postData } from "../../apis/apiMethods";
import CONFIG from "../../helpers/config";
import { errorHandler } from "../../helpers/errorHandler";
import { useQueryClient } from "react-query";
import SubAccount from "./Tabs/SubAccount";
import CreateSubAccountDrawer, {
  createType,
} from "./Drawer/SubAccounts/CreateSubAccountDrawer";
import SubAccountsModal from "./Modal/SubAccountsModal";
import searchIcon from "../../assets/images/svg/search-icon.svg";
import filterIcon from "../../assets/images/svg/filter-icon.svg";
import SubAccountFilterModal from "../POS/Modal/SubAccountFilterModal";

const SubAccounts = () => {
  const [tab, setTab] = useState<any>(
    localStorage.getItem("activeTab")?.split(",")
  );
  const [activeTab, setActiveTab] = useState(
    tab?.includes("sub accounts") ? tab[0] : "sub accounts"
  );

  const [filterModal, setFilterModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [actionModal, setActionModal] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [searchState, setSearchState] = useState<any>({
    query: "",
    page: 1,
    pageSize: 20,
    to: "",
    from: "",
    status: "",
  });

  const queryClient = useQueryClient();

  useEffect(() => {
    if (localStorage.getItem("activeTab")) {
      const active = localStorage.getItem("activeTab")?.split(",");

      if (active && active[1] === "sub accounts") {
        setTab(active);
      } else {
        setTab("sub accounts");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem("activeTab")]);

  const onSubmit = async (payload: createType) => {
    setLoading(true);
    const {
      merchantName,
      customerEmail,
      phoneNumber,
      customerFirstName,
      customerLastName,
      merchantAddress,
    } = payload;

    const reqBody = {
      name: merchantName,
      email: customerEmail,
      phone: phoneNumber,
      first_name: customerFirstName,
      last_name: customerLastName,
      address: merchantAddress,
    };

    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const res: any = await postData(
        `${CONFIG.BASE_URL2}${apiEndpoints.SUBACCOUNTS}`,
        reqBody
      );
      setMessage(res.message);
      setIsOpen(false);
      setActionModal("success");
      queryClient.invalidateQueries("getAllSubAccounts");
    } catch (error) {
      setMessage(errorHandler(error));
      setActionModal("error");
    }
    setLoading(false);
  };

  return (
    <>
      <div className="bg-aellaDimGray">
        <div className=" bg-white px-10 overflow-x-auto">
          <div className="border-t border-[#F1F1F1] flex justify-between items-center pt-2 pb-[1px] flex-wrap  w-[200%] xs:w-[165%] sm:w-full overflow-x-auto">
            <div className="flex justify-between ">
              <Tab
                text="Sub Accounts"
                id="sub accounts"
                activeTab={activeTab}
                setActiveTab={setActiveTab}
              />
            </div>
            {activeTab !== "withdrawal account" && (
              <div className="flex justify-end items-end bg-white py-[13.5px]">
                <div className="relative w-full lg:w-[190px]">
                  <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <img className="w-4" src={searchIcon} alt="" />
                  </div>
                  <input
                    type="text"
                    placeholder="Search"
                    className="placeholder:font-thin border border-[#EDEFF5] text-[#5B5B5B] text-sm rounded pl-10 py-3 focus:outline-none "
                    onChange={(e) =>
                      setSearchState({ ...searchState, query: e.target.value })
                    }
                  />
                </div>
                <label
                  className="min-w-[114px] h-11 border bg-[#F5F5F5] px-2 lg:px-5 flex items-center justify-center cursor-pointer rounded xxs:justify-center md:justify-around active:shadow-md transition duration-150 ease-in-out ml-3"
                  htmlFor="filterModal"
                  onClick={() => setFilterModal(true)}
                >
                  <div className=" h-[40px] flex items-center">
                    <img
                      className="object-contain w-[18px]"
                      src={filterIcon}
                      alt=""
                    />
                    <span className="font-thin pl-3 text-gray-400  text-sm focus:outline-none focus:ring-0">
                      Filter By
                    </span>
                  </div>
                </label>

                {/* <button
                  className="px-[20px] py-[12px] ml-4 flex rounded-[6px] bg-aellaBlue text-white items-center transition ease-in-out duration-300 font-normal text-[14px]"
                  type="submit"
                  onClick={() => setIsOpen(true)}
                >
                  <span className="mr-2">
                    <AddIcon />
                  </span>
                  <span className="hidden md:block">Create Sub Account</span>
                </button> */}
              </div>
            )}
          </div>
        </div>
        <div className="">
          {(() => {
            switch (activeTab) {
              case "sub accounts":
                localStorage.setItem("activeTab", "sub accounts, sub accounts");
                return (
                  <SubAccount
                    searchState={searchState}
                    setSearchState={setSearchState}
                  />
                );

              default:
                return;
            }
          })()}
        </div>
      </div>

      {isOpen && (
        <CreateSubAccountDrawer
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          onSubmit={onSubmit}
          setLoading={setLoading}
          loading={loading}
        />
      )}
      {actionModal && (
        <SubAccountsModal
          modalType={actionModal}
          setModalType={setActionModal}
          message={message}
        />
      )}
      {filterModal && (
        <SubAccountFilterModal
          setModalType={setFilterModal}
          setSearchState={setSearchState}
          searchState={searchState}
        />
      )}
    </>
  );
};

export default SubAccounts;
