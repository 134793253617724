import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import PinInput from "react-pin-input";
import { useMutation } from "react-query";
import Countdown, { zeroPad } from "react-countdown";

import { getFromLocalStorage } from "../../../helpers/localStorage";
import { LOCAL_STORAGE_KEYS } from "../../../helpers/localStorageKeys";
import { Stage } from "../../../interfaces/settings";
import { initiateResetPinFn } from "../queries";
import { errorHandler } from "../../../helpers/errorHandler";

export const EnterOtp = ({
  setStage,
  stage,
  showModal,
  setOtp,
  counter,
  setCounter,
}: {
  setStage: (state: Stage) => void;
  stage: Stage;
  showModal: boolean;
  setOtp: (state: string) => void;
  counter: number;
  setCounter: (state: number) => void;
}) => {
  const email = getFromLocalStorage(LOCAL_STORAGE_KEYS.EMAIL);
  const OTP_WAIT_TIME = 60000 * 5;

  const { mutateAsync: resendOtpAsync, isLoading: isResendingOtp } =
    useMutation({
      mutationFn: initiateResetPinFn,
      onSuccess: () => {
        setCounter(Date.now() + OTP_WAIT_TIME);
      },
      onError: (error) => {
        toast.error(errorHandler(error));
      },
    });

  const handleResend = async () => {
    if (isResendingOtp) return;
    try {
      await resendOtpAsync();
    } catch (error) {}
  };

  return (
    <div className="flex flex-col">
      <p className="text-[24px] text-aellaBlack">Enter OTP</p>
      <p className="text-aellaBlack mt-1 font-light">
        Please enter the code sent to <strong>{email ?? "your email"}</strong>
      </p>

      <div className="mt-10">
        <p className="text-aellaBlack mb-3 font-light">
          Enter <strong>6 digit</strong> code here
        </p>
        <PinInput
          length={6}
          placeholder=""
          initialValue=""
          secret={true}
          onChange={(value, index) => setOtp(value)}
          type="numeric"
          inputMode="number"
          style={{ display: "flex", justifyContent: "space-between" }}
          inputStyle={{
            border: "1px solid #7B7B7B80",
            width: "23%",
            height: "54px",
            paddingRight: "0px",
            paddingLeft: "0px",
            fontWeight: 100,
            borderRadius: "3px",
          }}
          inputFocusStyle={{ border: "1px solid #2054D2" }}
          onComplete={() => setStage("pin")}
          autoSelect={true}
        />
      </div>

      <Countdown
        date={counter}
        key={counter}
        renderer={({ minutes, seconds, completed }) => {
          if (completed) {
            return (
              <button
                className="text-aellaBlack disabled:opacity-75 text-[16px] lg:leading-[19px] font-[300] cursor-pointer self-end mt-4"
                onClick={handleResend}
                disabled={isResendingOtp}
              >
                {isResendingOtp ? "Resending.." : "Resend Code"}
              </button>
            );
          } else {
            return (
              <span className="text-[16px] lg:leading-[19px] font-[300] text-aellaGray self-end mt-4">
                Resend code in{" "}
                <span className="text-aellaBlack">
                  {zeroPad(minutes)}:{zeroPad(seconds)}
                </span>
              </span>
            );
          }
        }}
      />
    </div>
  );
};
