import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData } from "../apis/apiMethods";
import CONFIG from "../helpers/config";
import { toast } from "react-toastify";
import { errorHandler } from "../helpers/errorHandler";

export const getAllServices = async () => {
  const { data } = await getData(
    `${CONFIG.BASE_URL2}${apiEndpoints.GET_ALL_SERVICES}`
  );
  return data;
};

export const useAllServices = () => {
  return useQuery(
    "AllServices",

    getAllServices,
    {
      refetchOnWindowFocus: false, // Refetch on window focus
      retry: 1,
      onError: (error) => {
        toast.error(errorHandler(error));
      },
    }
  );
};
