import moment from "moment";
import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData } from "../apis/apiMethods";
import CONFIG from "../helpers/config";

export const getWalletBalance = async ({ queryKey }: { queryKey: any }) => {
  const { walletID, startDate, endDate, mode, merchantID } = queryKey[1];
  let queryParams = `?merchant_id=${merchantID}`;

  if (walletID) {
    queryParams = `${queryParams}&wallet_id=${walletID}`;
  }
  if (startDate) {
    queryParams = `${queryParams}&from=${moment(startDate).format(
      "YYYY-MM-DD"
    )}`;
  }

  if (endDate) {
    queryParams = `${queryParams}&to=${moment(endDate).format("YYYY-MM-DD")}`;
  }
  const { data } = await getData(
    `${CONFIG.BASE_URL2}${apiEndpoints.GET_WALLET_BALANCE}${queryParams}`
  );
  return data;
};

export const useWalletBalance = (props: {
  walletID?: string | undefined;
  startDate?: Date | undefined;
  endDate?: Date | undefined;
  mode?: string;
  merchantID?: string;
}) => {
  const { walletID, startDate, endDate, mode, merchantID } = props;

  return useQuery(
    ["getWalletBalance", { walletID, startDate, endDate, mode, merchantID }],

    getWalletBalance,
    {
      refetchOnWindowFocus: false, // Refetch on window focus
      enabled: merchantID ? true : false,
      retry: 1,
    }
  );
};
