import moment from "moment";
import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData } from "../apis/apiMethods";
import CONFIG from "../helpers/config";
import { getFromLocalStorage } from "../helpers/localStorage";
import { LOCAL_STORAGE_KEYS } from "../helpers/localStorageKeys";

export const getPosTransaction = async ({ queryKey }: { queryKey: any }) => {
  const { page, size, trnxType, startDate, endDate, amount_from, amount_to, status } =
    queryKey[1];

  const merchantId = getFromLocalStorage(LOCAL_STORAGE_KEYS.MERCHANTID);

  let queryParams = `?merchant_id=${merchantId}&size=${size}&page=${page}`;

  if (startDate) {
    queryParams = `${queryParams}&from=${moment(startDate).format(
      "YYYY-MM-DD"
    )}`;
  }
  if (endDate) {
    queryParams = `${queryParams}&to=${moment(endDate).format("YYYY-MM-DD")}`;
  }
  if (trnxType) {
    queryParams = `${queryParams}&transaction_type=${trnxType}`;
  }

  if (status) {
    queryParams = `${queryParams}&status=${status}`;
  }
  if (amount_from) {
    queryParams = `${queryParams}&amount_from=${amount_from}`;
  }

  if (amount_to) {
    queryParams = `${queryParams}&amount_to=${amount_to}`;
  }

  const data = await getData(
    `${CONFIG.BASE_URL2}${apiEndpoints.POS_TRANSACTIONS}${queryParams}`
  );
  return data;
};

export const usePosTransaction = ({
  page,
  size,
  trnxType,
  startDate,
  endDate,
  amount_from,
  amount_to,
  status
}: {
  page: number;
  size: number;
  trnxType?: string;
  startDate?: Date | null;
  endDate?: Date | null;
  amount_from?: string;
  amount_to?: string;
  status?: string
}) => {
  return useQuery(
    [
      "PosTransaction",
      { size, trnxType, startDate, endDate, page, amount_from, amount_to, status },
    ],
    getPosTransaction,
    {
      refetchOnWindowFocus: false, // Refetch on window focus
    }
  );
};
