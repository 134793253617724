import { ReactComponent as ShowModalIcon } from "../../assets/images/svg/external-link-icon.svg";
import { ReactComponent as NextIcon } from "../../assets/images/svg/blue-right-arrow-icon.svg";
import { ReactComponent as InfoIcon } from "../../assets/images/svg/info-icon.svg";
import { ReactComponent as CalendarIcon } from "../../assets/images/svg/calendar-icon.svg";
import { ReactComponent as ArrowIcon } from "../../assets/images/svg/black-arrow-down-icon.svg";
import { ROUTES } from "../../helpers/routes";
import { Link } from "react-router-dom";
import { currencyFormat, removerAsterisk } from "../../helpers/formatter";
import moment from "moment";
import {
  attachClassNameToStatus,
  getStatusNameByNumber,
} from "../../helpers/attachClassNameToTag";
import "react-datepicker/dist/react-datepicker.css";
import { getFromLocalStorage } from "../../helpers/localStorage";
import { LOCAL_STORAGE_KEYS } from "../../helpers/localStorageKeys";
import { useEffect, useState } from "react";
import { useAllMerchantServices } from "../../hooks/useAllMerchantServices";
import { postData } from "../../apis/apiMethods";
import { apiEndpoints } from "../../apis/apiEndpoints";
import CONFIG from "../../helpers/config";
import { useAuth } from "../../context/auth";

export default function HomeData({
  totalWalletData,
  walletInFlowSummaryForTdy,
  walletOutFlowSummaryData,
  walletInFlowTransactions,
  isLoading,
  totalTransactionSum,
  totalTransactions,
  totalVolSearch,
  setTotalVolSearch,
  outFlowSummarySearch,
  totalValSearch,
  setTotalValSearch,
  setShowOutFlowModal,
  setShowTransactionModal,
  setShowWithdrawalModal,
  details,
}: {
  totalWalletData: any;
  walletInFlowSummaryForTdy: any;
  walletOutFlowSummaryData: any;
  walletInFlowTransactions: any;
  isLoading: boolean;
  totalTransactionSum: any;
  totalTransactions: any;
  details: any;
  totalVolSearch: any;
  setTotalVolSearch: (newState: any) => void;
  outFlowSummarySearch: any;
  totalValSearch: any;
  setTotalValSearch: (newState: any) => void;
  setShowOutFlowModal: (newState: boolean) => void;
  setShowTransactionModal: (newState: boolean) => void;
  setShowWithdrawalModal: (newState: string) => void;
}) {
  const businessName = getFromLocalStorage(LOCAL_STORAGE_KEYS.BUSINESSNAME);
  const merchantName = getFromLocalStorage(LOCAL_STORAGE_KEYS.FIRSTNAME);
  const { merchantCntxtStatus } = useAuth();
  const [merchantDefaultAccountData, setMerchantDefaultAccountData] =
    useState<any>("");
  const [copiedText, setCopiedText] = useState("");

  const { data: merchantServicesData } = useAllMerchantServices({
    merchantId: details?.merchant?.id,
    status: merchantCntxtStatus,
    main: true,
    services: true,
  });

  const [inflowResponse, setInflowResponse] = useState<any>();
  const handleCopy = (text: string | undefined) => {
    if (text) {
      navigator.clipboard.writeText(text);
      setCopiedText(text);
    }
    setTimeout(() => setCopiedText(""), 3000);
  };

  useEffect(() => {
    const fetchData = async () => {
      const reqBody = {
        user_id: details?.merchant_user_object?.id,
        first_name: details?.merchant_user_object?.first_name,
        last_name: details?.merchant_user_object?.last_name,
        company_name: businessName,
        email: details?.merchant_user_object?.email,
        phone_number: details?.phone_number,
        merchant_id: details?.merchant?.id,
      };
      try {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const res: any = await postData(
          `${CONFIG.BASE_URL2}${apiEndpoints.MERCHANT_ACCOUNT}`,
          reqBody
        );

        setMerchantDefaultAccountData(res?.data?.[0]);
      } catch (error) {}
    };
    if (details) {
      fetchData();
    }
  }, [details]);

  useEffect(() => {
    const newData = [];
    if (walletInFlowTransactions?.data?.length > 0) {
      for (let data of walletInFlowTransactions?.data) {
        if (
          data?.transaction_type === null ||
          data?.transaction_type === undefined
        ) {
          if (data?.description) {
            let transaction_type = {} as any;
            let desObj = JSON.parse(data?.description);
            transaction_type.name = desObj?.mode;
            data.transaction_type = transaction_type;
          } else {
            data.transaction_type = "N/A";
          }
        }
        newData.push(data);
      }
      walletInFlowTransactions.data = newData;
      setInflowResponse(walletInFlowTransactions);
    }
  }, [walletInFlowTransactions]);
  return (
    <>
      <div className="overflow-auto h-screen bg-aellaDimGray w-full">
        <div className="px-10 py-[25px] bg-white">
          <h1 className="text-[25px] lg:leading-[38px] font-[100]">
            Hi <span className="font-[400] capitalize">{merchantName},</span>
          </h1>
          <p className="text-[16px] lg:leading-[22px] font-[50]">
            Here’s what is happening on your merchant today
          </p>
        </div>
        <div className="px-10 py-[25px] mb-5 w-full">
          <div className="flex flex-col lg:flex-row md:justify-between">
            <div className="border border-aellaBorderGray lg:w-[25.5vw] bg-white mb-5 w-full ">
              <h1 className="px-6 py-2 text-[12px] lg:leading-[16px] font-[300] pt-4 h-[50px] bg-[#EDEFF5] ">
                GENERAL WALLET
              </h1>
              <div className="px-6">
                <h1 className="text-[12px] lg:leading-[16px] font-[300] pt-[24px]">
                  BALANCE
                </h1>
                <h1 className="text-[32px] lg:leading-[38px] font-[300] tracking-[-0.2px] pt-[18px]">
                  {totalWalletData?.available_balance
                    ? currencyFormat(totalWalletData?.available_balance)
                    : currencyFormat(0)}
                </h1>
                <p className="text-[13px]  lg:leading-[20px] font-[200] text-aellaGray pt-2 pb-[26px]">
                  This is the total amount available for withdrawal
                </p>
                {/* <button
                  className="text-[16px] lg:leading-[19px] font-[300] text-aellaBlue py-[26px] flex"
                  onClick={() => setShowWithdrawalModal("step1")}
                >
                  <span className="pr-2.5">Withdraw balance</span>
                  <ShowModalIcon />
                </button> */}
              </div>

              <div className="px-6 pb-6  border-aellaBorderGray border-t">
                {merchantDefaultAccountData && (
                  <div className="rounded-[8px] bg-[#F3F5FC] py-4 pl-4 pr-2 mt-[20px]">
                    <div className="flex flex-col">
                      <h1 className="font-light text-[#737373] text-[14px] leading-[17px] tracking-[0.2]">
                        Account Name
                      </h1>
                      <h4 className="font-[300] text-[16px] lg:leading-[19px] tracking-[0.2] mt-1.5">
                        {removerAsterisk(
                          merchantDefaultAccountData?.account_name
                        ) || "N/A"}
                      </h4>
                    </div>
                    <div className="flex flex-wrap justify-between items-center mt-[9px] ">
                      <div className="flex flex-col">
                        <h1 className="font-light text-[#737373] text-[14px] leading-[17px]">
                          Bank Name
                        </h1>
                        <div className="flex  mt-1.5">
                          <h4 className="font-[300] text-[16px] leading-[19px]">
                            {merchantDefaultAccountData?.bank_name || "N/A"}
                          </h4>
                          <span className="pl-[7px]">
                            <img
                              src={merchantDefaultAccountData?.bank_logo}
                              alt=""
                              className="w-5 h-5"
                            />
                          </span>
                        </div>
                      </div>
                      <div className="flex flex-col">
                        <h1 className="font-light text-[#737373] text-[14px] leading-[17px]">
                          Account Number
                        </h1>
                        <h4 className="font-[300] text-[16px] leading-[19px] mt-1.5">
                          {merchantDefaultAccountData?.account_number || "N/A"}
                        </h4>
                      </div>

                      <div
                        onClick={() =>
                          handleCopy(merchantDefaultAccountData?.account_number)
                        }
                        className="p-1.5 cursor-pointer bg-white rounded-2xl capitalize text-[14px] lg:leading-[17px] tracking-[0.2] text-[#828282]"
                      >
                        {copiedText ===
                        merchantDefaultAccountData?.account_number
                          ? "copied"
                          : "copy"}
                      </div>
                    </div>
                  </div>
                )}

                <div className="flex mt-[17px] items-start">
                  <div className="shrink-0">
                    <InfoIcon />
                  </div>
                  <h1 className="ml-2.5 text-[12px] lg:leading-[13px] font-[100]">
                    Use this account details to fund your wallet. Your wallet
                    will be funded within minutes. If it takes longer, reach out
                    to us{" "}
                    <span className="text-aellaBlue">
                      via support@aellacredit.com.
                    </span>
                  </h1>
                </div>
              </div>
            </div>
            <div className="border border-aellaBorderGray lg:w-[25.5vw] bg-white mb-5 w-full">
              <div
                className="px-6 py-2 text-[13px]  font-[300] pt-4 h-[50px] bg-[#EDEFF5] flex items-center justify-between justify-none cursor-pointer"
                onClick={() => setShowOutFlowModal(true)}
              >
                <span className="pr-4  lg:leading-[18px]">Showing:</span>
                <CalendarIcon />
                <span className="pl-1 lg:leading-[20px] tracking-[0.2px] lg:pr-1">
                  {moment(outFlowSummarySearch?.startDate).format(
                    "DD MMM YYYY"
                  )}{" "}
                  -{" "}
                  {moment(outFlowSummarySearch?.endDate).format("DD MMM YYYY")}
                </span>
                <div>
                  <ArrowIcon />
                </div>
              </div>

              <div className="px-6 flex flex-col pt-[40px] justify-center h-[257px]">
                <h1 className="text-[13px] lg:leading-[16px] font-[300]">
                  TOTAL VOLUME OF OUTFLOW TRANSACTIONS
                </h1>
                <h1 className="text-[32px] lg:leading-[38px] font-[300] tracking-[-0.2px] pt-[18px]">
                  {walletOutFlowSummaryData?.count || 0}
                </h1>
                <p className="text-[13px]  lg:leading-[20px] font-[200] text-aellaGray pt-2 pb-[30px]">
                  This is the total number of withdrawals <br></br> that have
                  been recorded on your account.
                </p>
              </div>
              <div className="px-6 pt-[40px] border-aellaBorderGray border-t flex flex-col justify-center h-[257px]">
                <h1 className="text-[13px] lg:leading-[16px] font-[300] ">
                  TOTAL EARNED TODAY
                </h1>
                <h1 className="text-[32px] lg:leading-[30px] font-[300] tracking-[-0.2px] pt-[18px]">
                  {walletInFlowSummaryForTdy?.sum
                    ? currencyFormat(walletInFlowSummaryForTdy?.sum)
                    : currencyFormat(0)}
                </h1>
                <p className="text-[13px]  lg:leading-[20px] font-[200] text-aellaGray pt-2">
                  This is the total amount that you have received on your
                  account today.
                </p>
              </div>
            </div>
            <div className="border border-aellaBorderGray lg:w-[25.5vw] bg-white mb-5 w-full">
              <div
                className="px-6 py-2 text-[13px]  font-[300] pt-4 h-[50px] bg-[#EDEFF5]  flex items-center cursor-pointer"
                onClick={() => setShowTransactionModal(true)}
              >
                <span className="pr-4  lg:leading-[18px]">Showing:</span>
                <CalendarIcon />
                <span className="pl-1 lg:leading-[20px] tracking-[0.2px] pr-1">
                  {moment(totalVolSearch?.startDate).format("DD MMM YYYY")} -{" "}
                  {moment(totalVolSearch?.endDate).format("DD MMM YYYY")}
                </span>
                <div>
                  <ArrowIcon />
                </div>
              </div>

              <div className="pl-6 pr-3 flex flex-col pt-[40px] justify-center h-[257px]">
                <div className="flex flex-row items-center">
                  <h1 className="text-[12px] lg:leading-[16px] font-[300]  pr-2.5">
                    TOTAL VOLUME OF TRANSACTIONS
                  </h1>
                  <select
                    className="text-[12px] lg:leading-[16px] appearance-none border-none focus:outline-none text-aellaBlue pr-6 cursor-pointer"
                    name="walletId"
                    value={totalVolSearch?.walletId}
                    onChange={(e) => {
                      const { value } = e.target;
                      setTotalVolSearch({ ...totalVolSearch, walletId: value });
                      // const allParams: any = {
                      //   ...searchState,
                      //   startDateForOutFlow: moment(
                      //     searchState?.startDateForOutFlow
                      //   ).format("YYYY-MM-DD"),
                      //   endDateForOutFlow: moment(
                      //     searchState?.endDateForOutFlow
                      //   ).format("YYYY-MM-DD"),
                      //   startDateForTrnx: moment(
                      //     searchState?.startDateForTrnx
                      //   ).format("YYYY-MM-DD"),
                      //   endDateForTrnx: moment(
                      //     searchState?.endDateForTrnx
                      //   ).format("YYYY-MM-DD"),
                      //   walletId: value,
                      // };

                      // Object.keys(allParams).forEach(
                      //   (key) =>
                      //     allParams[key] === undefined && delete allParams[key]
                      // );

                      // setSearchParams(allParams);
                    }}
                  >
                    <option value="">ALL WALLETS</option>
                    {merchantServicesData?.map((data: any) => {
                      const { id, label, category } = data;
                      return (
                        <option value={id} key={id}>
                          {category === "main" ? "General Wallet" : label}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <h1 className="text-[32px] lg:leading-[38px] font-[300] tracking-[-0.2px] pt-[18px]">
                  {totalTransactions || 0}
                </h1>
                <p className="text-[13px]  lg:leading-[20px] font-[200] text-aellaGray pt-2 pb-[30px]">
                  This is the total number of transactions that have been
                  recorded on your account.
                </p>
              </div>
              <div className="px-6 pt-[40px] border-aellaBorderGray border-t flex flex-col justify-center h-[257px]">
                <div className="flex flex-row items-center">
                  <h1 className="text-[12px] lg:leading-[16px] font-[300] pr-2">
                    TOTAL VALUE OF TRANSACTIONS
                  </h1>
                  <select
                    className="text-[12px] lg:leading-[16px] appearance-none border-none focus:outline-none text-aellaBlue pr-6 cursor-pointer"
                    name="walletId"
                    value={totalValSearch?.walletId}
                    onChange={(e) => {
                      const { value } = e.target;
                      setTotalValSearch({ ...totalValSearch, walletId: value });
                      // const allParams: any = {
                      //   ...searchState,
                      //   startDateForOutFlow: moment(
                      //     searchState?.startDateForOutFlow
                      //   ).format("YYYY-MM-DD"),
                      //   endDateForOutFlow: moment(
                      //     searchState?.endDateForOutFlow
                      //   ).format("YYYY-MM-DD"),
                      //   startDateForTrnx: moment(
                      //     searchState?.startDateForTrnx
                      //   ).format("YYYY-MM-DD"),
                      //   endDateForTrnx: moment(
                      //     searchState?.endDateForTrnx
                      //   ).format("YYYY-MM-DD"),
                      //   walletId: value,
                      // };

                      // Object.keys(allParams).forEach(
                      //   (key) =>
                      //     allParams[key] === undefined && delete allParams[key]
                      // );

                      // setSearchParams(allParams);
                    }}
                  >
                    <option value="">ALL WALLETS</option>
                    {merchantServicesData?.map((data: any) => {
                      const { id, label, category } = data;
                      return (
                        <option value={id} key={id}>
                          {category === "main" ? "General Wallet" : label}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <h1 className="text-[32px] lg:leading-[30px] font-[300] tracking-[-0.2px] pt-[18px]">
                  {totalTransactionSum
                    ? currencyFormat(totalTransactionSum)
                    : currencyFormat(0)}
                </h1>
                <p className="text-[13px]  lg:leading-[20px] font-[200] text-aellaGray pt-2">
                  This is the total amount of transactions on your account
                </p>
              </div>
            </div>
          </div>

          <div className="py-6 border border-aellaBorderGray  bg-white mt-[24px]">
            <div className="flex justify-between items-center pb-[18px] px-6 lg:px-10 ">
              <h1 className="text-[16px] lg:text-[24px] lg:leading-[29px] font-[350]">
                Inflow Transactions
              </h1>
              {inflowResponse?.data?.length > 0 && (
                <Link to={ROUTES.INFLOW_TRANSACTIONS}>
                  <h2 className="text-[12px] lg:text-[16px] lg:leading-[20px] font-[350] flex items-center text-aellaBlue cursor-pointer">
                    See All
                    <NextIcon />
                  </h2>
                </Link>
              )}
            </div>
            <div className="pt-10 border-t border-[#F1F1F1] px-6 lg:px-10 overflow-x-auto w-full">
              <div className="flex items-center bg-[#F7F9FC] w-[280%] gap-x-4 overflow-x-auto md:overflow-x-hidden md:gap-x-0 md:w-full px-4 h-10 text-aellaBlack text-sm border-b">
                <p className="w-[22%]">Transaction</p>
                <p className="w-[25%]"> Amount</p>
                <p className="w-[18%]">Transaction Type</p>
                <p className="w-[18%]"> Time Stamp</p>
                <p className="w-[20%] text-right"> Status</p>
              </div>

              {isLoading ? (
                <div className="py-20 w-full flex justify-center items-center ">
                  <div className="w-10 h-10 border-aellaBlue loader__container" />
                </div>
              ) : (
                inflowResponse?.data?.map(
                  ({
                    amount,
                    date,
                    status,
                    id,
                    transaction_type,
                    type,
                  }: {
                    amount: number;
                    date: string;
                    status: number;
                    id: string;
                    transaction_type: {
                      name: string;
                      description: string;
                    };
                    type: string;
                  }) => {
                    return (
                      <div
                        key={id}
                        className="flex items-center bg-white w-[280%] gap-x-4 overflow-x-auto md:overflow-x-hidden md:gap-x-0 md:w-full p-4 text-[#5B5B5B] text-sm font-light border-b cursor-pointer"
                      >
                        <p className="w-[22%]">{transaction_type?.name}</p>
                        <p className="w-[25%]"> {currencyFormat(amount)}</p>
                        <p
                          className={`w-[18%] capitalize  text-[13px] lg:text-[16px] lg:leading-[19px] tracking-[0.2px] font-[300] py-[15px] ${
                            type === "cr"
                              ? "text-[#219653]"
                              : type === "dr"
                              ? "text-[#EB5757]"
                              : "text-aellaGray"
                          }`}
                        >
                          {type === "dr"
                            ? "Debit"
                            : type === "cr"
                            ? "Credit"
                            : ""}
                        </p>
                        <p className="w-[18%] ">
                          {date ? moment(date).format("DD MMM YYYY | LT") : "-"}
                        </p>

                        <p className={`w-[20%] text-right`}>
                          <span
                            className={`w-[13%] text-center ${attachClassNameToStatus(
                              status
                            )} `}
                          >
                            {getStatusNameByNumber(status)}
                          </span>
                        </p>
                      </div>
                    );
                  }
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
