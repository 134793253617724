import { Fragment, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import CustomInputField from "../../component/CustomHTMLElements/CustomInputField";
import { ROUTES } from "../../helpers/routes";
import { yupResolver } from "@hookform/resolvers/yup";
import { yupValidators } from "../../helpers/yupValidators";
import * as yup from "yup";
import { errorHandler } from "../../helpers/errorHandler";
import { ReactComponent as NextIcon } from "../../assets/images/svg/right-arrow-icon.svg";
import { ReactComponent as ShowPasswordIcon } from "../../assets/images/svg/eye-open-icon.svg";
import { ReactComponent as HidePasswordIcon } from "../../assets/images/svg/eye-close-icon.svg";
import { RegistrationFormData } from "../../interfaces/login";
import { Loader, SpinLoader } from "../../component/Loader/Loader.component";
import { toast } from "react-toastify";
import { apiEndpoints } from "../../apis/apiEndpoints";
import CONFIG from "../../helpers/config";
import { postData } from "../../apis/apiMethods";
import { postToLocalStorage } from "../../helpers/localStorage";
import { LOCAL_STORAGE_KEYS } from "../../helpers/localStorageKeys";
import { Listbox, Transition } from "@headlessui/react";
import { businesTypes, genderType } from "../../helpers/json";
import DropdownIcon from "../../assets/images/svg/star-icon.svg";
import CustomSelectDropdown from "../../component/CustomHTMLElements/CustomSelectDropdown";
import { useCheckEmailExist } from "../../hooks/useCheckEmailExist";

const schema = yup.object().shape({
  email: yupValidators.email,
  password: yupValidators.password,
  firstName: yupValidators.firstName,
  lastName: yupValidators.lastName,
  phoneNumber: yupValidators.phoneNumber,
  businessName: yupValidators.businessName,
  terms: yup
    .boolean()
    .oneOf([true], "Please read and accept the terms and conditions"),
  businessCategory: yup.string().required(),
  gender: yup.string().required(),
  confirmPassword: yup
    .string()
    .required()
    .oneOf([yup.ref("password")], "Password confirmation does not match"),
});

interface ISignUpInitialStep {
  setStep: (newState: number) => void;
}

const SignUpInitialStep: React.FC<ISignUpInitialStep> = ({ setStep }) => {
  const [passwordType, setPasswordType] = useState("password");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");
  const [loading, setLoading] = useState(false);
  const [emailToCheck, setEmailToCheck] = useState("");
  const [selected, setSelected] = useState("");

  const { data: checkEmailData, isLoading: checkEmailLoading } =
    useCheckEmailExist({ email: emailToCheck });
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<RegistrationFormData>({
    criteriaMode: "all",
    mode: "all",
    resolver: yupResolver(schema),
  });
  const navigate = useNavigate();
  const selectedBusinessCategory = watch("businessCategory");
  const email = watch("email");

  useEffect(() => {
    if (checkEmailData?.exists) {
      toast.success(
        "We noticed you already have an Aella account. Please sign up using your existing credentials.",
        { autoClose: 60000 }
      );
    }
  }, [checkEmailData]);

  const onSubmit = async ({
    email,
    password,
    businessName,
    firstName,
    lastName,
    phoneNumber,
    businessCategory,
    gender,
  }: {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
    phoneNumber: number;
    businessName: string;
    businessCategory: string;
    gender: string;
  }) => {
    setLoading(true);

    const reqBody = {
      first_name: firstName,
      last_name: lastName,
      email,
      phone_number: phoneNumber,
      password,
      confirm_password: password,
      business_type: businessCategory,
      trading_name: businessName,
      gender,
    };

    try {
      const res: any = await postData(
        `${CONFIG.BASE_URL2}${apiEndpoints.CREATE_MERCHANT}`,
        reqBody
      );
      const data = res.data;

      setLoading(false);
      postToLocalStorage(LOCAL_STORAGE_KEYS.EMAIL, data.email);
      postToLocalStorage(LOCAL_STORAGE_KEYS.MERCHANTID, data.id);
      postToLocalStorage(LOCAL_STORAGE_KEYS.FIRSTNAME, data.first_name);
      postToLocalStorage(LOCAL_STORAGE_KEYS.BUSINESSNAME, data.name);
      postToLocalStorage(LOCAL_STORAGE_KEYS.PHONENUMBER, data.phone_number);
      postToLocalStorage(LOCAL_STORAGE_KEYS.TOKEN, data.token);
      postToLocalStorage(LOCAL_STORAGE_KEYS.USERID, data.id);
      switch (true) {
        case data?.stage?.next === "LOGIN":
          return navigate(ROUTES.LOGIN, { state: { reRoute: true } });

        case data?.stage?.next === "ADD_SERVICE":
          return setStep(3);

        default:
          return setStep(2);
      }
    } catch (error) {
      setLoading(false);
      toast.error(errorHandler(error));
    }
  };

  // check if users already exist
  const handleOnBlur = async () => {
    setEmailToCheck(email);
  };

  const togglePasswordField = (type: string) => {
    if (type === "password") {
      if (passwordType === "password") {
        setPasswordType("text");
      } else {
        setPasswordType("password");
      }
    } else if (type === "confirmPassword") {
      if (confirmPasswordType === "password") {
        setConfirmPasswordType("text");
      } else {
        setConfirmPasswordType("password");
      }
    }
  };

  return (
    <>
      <div className="lg:pr-[185px] md:pr-[64px]  mb-20">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-7 lg:pt-[50px]">
            <h3 className="text-[32px] lg:leading-[38px] font-[300] pb-2">
              Input Profile Details
            </h3>
            <p className="text-aellaGray text-[16px] font-[200] lg:leading-[19px]">
              Please provide more information about your business
            </p>
          </div>

          <div className="flex flex-col lg:flex-row">
            <div className=" w-full lg:pr-6">
              <CustomInputField
                type="text"
                maxLength={128}
                placeholder="Enter first name"
                label="First Name"
                errors={errors.firstName}
                showRequiredIcon
                {...register("firstName")}
              />
            </div>
            <div className="w-full">
              <CustomInputField
                type="text"
                maxLength={128}
                placeholder="Enter last name"
                label="Last Name"
                errors={errors.lastName}
                showRequiredIcon
                {...register("lastName")}
              />
            </div>
          </div>
          <div className="flex flex-col lg:flex-row">
            <div className=" w-full lg:pr-6">
              <CustomInputField
                type="email"
                maxLength={128}
                placeholder="User@gmail.com"
                label="Work Email"
                handleOnBlur={() => handleOnBlur()}
                errors={errors.email}
                showRequiredIcon
                removeBottomBorder={checkEmailLoading ? true : false}
                {...register("email")}
              />
              {checkEmailLoading && (
                <p className="flex gap-3 items-center text-aellaGray text-[12px] lg:leading-[12px] font-[300] my-2">
                  <SpinLoader />
                  verifying email
                </p>
              )}
            </div>
            <div className="w-full">
              <CustomInputField
                type="number"
                maxLength={11}
                placeholder="08100000000"
                label="Phone Number"
                hasIcon="+234"
                errors={errors.phoneNumber}
                showRequiredIcon
                {...register("phoneNumber")}
              />
            </div>
          </div>
          <CustomSelectDropdown
            label="Gender"
            errors={errors.gender}
            {...register("gender")}
          >
            <option value="">Select Gender</option>
            {genderType?.map((data: any, id: number) => {
              return (
                <option value={data.value} key={id}>
                  {data.name}
                </option>
              );
            })}
          </CustomSelectDropdown>
          <CustomInputField
            type={passwordType}
            maxLength={128}
            placeholder="********"
            extraLabel={
              checkEmailData?.exists &&
              "We noticed you already have an Aella account. Please sign up using your existing password."
            }
            label="Password"
            showRequiredIcon
            errors={errors.password}
            hasActionButton={true}
            autoComplete="new-password"
            actionButtonText={
              passwordType === "password" ? (
                <ShowPasswordIcon />
              ) : (
                <HidePasswordIcon />
              )
            }
            passwordError
            onClickActionButton={() => togglePasswordField("password")}
            {...register("password")}
          />
          <CustomInputField
            type={confirmPasswordType}
            maxLength={128}
            placeholder="********"
            label="Confirm Password"
            showRequiredIcon
            errors={errors.confirmPassword}
            hasActionButton={true}
            autoComplete="new-password"
            actionButtonText={
              confirmPasswordType === "password" ? (
                <ShowPasswordIcon />
              ) : (
                <HidePasswordIcon />
              )
            }
            onClickActionButton={() => togglePasswordField("confirmPassword")}
            {...register("confirmPassword")}
          />
          <Controller
            control={control}
            defaultValue=""
            // name="businessCategory"
            {...register("businessCategory")}
            render={({ field: { onChange } }) => (
              <Listbox
                value={selected}
                onChange={(e) => {
                  onChange(e);
                  setSelected(e);
                }}
              >
                {({ open }) => (
                  <>
                    <Listbox.Label className="flex items-center text-aellaBlack text-[16px] font-[300] mb-[6px]">
                      Business Category
                      <sup className="ml-1 text-[#EB5757]">*</sup>
                    </Listbox.Label>
                    <div className="relative mt-2 mb-[18px]">
                      <Listbox.Button
                        className={`relative w-full cursor-default rounded-md bg-white text-left text-gray-900 shadow-sm ring-1 ring-inset ${
                          errors?.businessCategory
                            ? "ring-[#EB5757]"
                            : "ring-aellaLightGray"
                        }  focus:outline-none focus:ring-1 focus:ring-aellaBlue sm:text-sm sm:leading-6`}
                      >
                        <span className="flex items-center h-[48px] px-4">
                          {selected ? (
                            <span className="block text-aellaBlack leading-6 text-[16px] font-[300]">
                              {selected || "Select business category"}
                            </span>
                          ) : (
                            <span className="block text-[#DADADA] leading-6 text-[16px] font-[300]">
                              Select business category
                            </span>
                          )}
                        </span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                          <img
                            src="data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12.2917 5.33203C12.711 5.33203 12.9441 5.817 12.6822 6.14438L8.38913 11.5107C8.18897 11.7609 7.80843 11.7609 7.60826 11.5107L3.31524 6.14438C3.05334 5.817 3.28642 5.33203 3.70568 5.33203L12.2917 5.33203Z' fill='%23919AAA'/%3E%3C/svg%3E%0A"
                            className="h-5 w-5 text-gray-400"
                            alt=""
                            aria-hidden="true"
                          />
                        </span>
                      </Listbox.Button>

                      <Transition
                        show={open}
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Listbox.Options className="absolute z-10 mt-2  w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                          {businesTypes.map((business) => (
                            <Listbox.Option
                              key={business.id}
                              className={({ active }) =>
                                ` ${
                                  active
                                    ? "text-white rounded-md"
                                    : "text-gray-900"
                                }
                              relative cursor-default select-none py-3 px-3
                          `
                              }
                              value={business.id}
                            >
                              {({ selected, active }) => (
                                <>
                                  <div
                                    className={`flex items-center cursor-pointer
                    ${
                      active || selected
                        ? "bg-gray-100 text-gray-900 rounded-md "
                        : "text-gray-700"
                    }
                     px-4 py-4 text-sm 
                  `}
                                  >
                                    <img
                                      src={DropdownIcon}
                                      alt=""
                                      width={40}
                                      height={40}
                                    />
                                    <div className="flex flex-col pl-3">
                                      <h2 className="text-[16px] leading-[19px] font-[400]">
                                        {business.name}
                                      </h2>
                                      <p className="text-[14px] leading-[17px] font-[200] mt-1">
                                        {business.description}
                                      </p>
                                    </div>
                                  </div>
                                </>
                              )}
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </Transition>
                    </div>
                  </>
                )}
              </Listbox>
            )}
          />
          <CustomInputField
            type="text"
            maxLength={128}
            placeholder="Enter business name here"
            label={`${
              selectedBusinessCategory === "Unregistered" ? "Proposed" : ""
            } Business Name`}
            errors={errors.businessName}
            showRequiredIcon
            {...register("businessName")}
          />

          <div className="form-check flex flex-col pb-[29px] pt-[27px]">
            <div className="flex ">
              <input
                className="border-aellaGray focus:ring-0 form-check-input h-6 w-6  md:h-4 md:w-4 border rounded-sm bg-white checked:bg-aellaBlue checked:border-aellaBlue focus:outline-none transition duration-200 md:mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                type="checkbox"
                value=""
                id="terms"
                {...register("terms")}
              />
              <label
                className="font-[200] form-check-label inline-block text-aellaGray lg:leading-[24px] text-[16px]"
                htmlFor="flexCheckDefault"
              >
                By proceeding, you agree to our
                <span className="text-aellaBlue">
                  {" "}
                  <Link to={ROUTES.TERMS_OF_USE} target="_blank">
                    Terms of Use
                  </Link>
                </span>{" "}
                and acknowledge that you have read the{" "}
                <span className="text-aellaBlue">
                  <a
                    href="https://aellaapp.com/privacy-policy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    Privacy Policy.
                  </a>
                </span>
              </label>
            </div>
            {errors?.terms && (
              <p className="text-left text-[#EB5757] lg:leading-[16px] text-[13px] font-[300]">
                {errors?.terms.message}
              </p>
            )}
          </div>
          <button
            type="submit"
            className="bg-aellaBlue rounded text-white py-4 px-6 items-center w-full flex justify-center cursor-pointer"
            disabled={loading || checkEmailLoading}
          >
            Continue
            <span className="pl-2">
              <NextIcon />{" "}
            </span>
            {loading && <Loader />}
          </button>

          <p className="flex items-center justify-center my-8 text-[16px] lg:leading-[20px] font-[300]">
            <span className="text-aellaGray pr-2">
              Already have an account?{" "}
            </span>
            <Link className="text-aellaBlue" to={ROUTES.LOGIN}>
              Sign In
            </Link>
          </p>
        </form>
      </div>
    </>
  );
};

export default SignUpInitialStep;
