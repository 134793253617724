import { format } from "date-fns";
import { IGetEmployeeLoanHistory } from "../../../interfaces/employee-loans";
import { ReactComponent as SearchIcon } from "../../../assets/images/svg/search-icon.svg";
import { ReactComponent as FilterIcon } from "../../../assets/images/svg/filter-transaction-icon.svg";
import Pagination from "../../../component/Pagination/Pagination.component";
import { UseQueryResult } from "react-query";
import { LoadingState } from "../LoadingState";
import { EmptyList } from "../EmptyEmployees";
import { ErrorMessage } from "../ErrorMessage";
import { truncateString } from "../../../helpers/formatter";
import { formatAmount } from "../../../helpers/formatMoney";
import {
  attachClassToId,
  attachClassToLoanStatus,
  attachNameToLoanStatus,
} from "../../../helpers/attachClassNameToTag";
import NoParamPagination from "../../../component/Pagination/NoParamPagination";

export const LoanHistory = ({
  loanHistoryData,
  updateState,
  state,
  setSelectedLoan,
  postsPerPage,
  setFilterObj,
}: {
  loanHistoryData?: UseQueryResult<IGetEmployeeLoanHistory>;
  updateState: any;
  state: any;
  setSelectedLoan: (state: any) => void;
  postsPerPage: number;
  setFilterObj: (state: any) => void;
}) => {
  return (
    <div className="py-4 lg:py-6 flex flex-col">
      <div className="flex items-center flex-wrap gap-4 ml-auto">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            setFilterObj(null);
            updateState({
              searchParam: state.searchTerm,
              currentPage: 1,
            });
          }}
          className={`w-[200px] lg:w-[400px] flex items-center gap-x-3 px-3 h-10 rounded-[4px] border border-[#EDEFF5]`}
        >
          <SearchIcon />
          <input
            value={state.searchTerm}
            onChange={(e: any) =>
              updateState({
                searchTerm: e.target.value,
              })
            }
            className="bg-inherit h-full placeholder:text-[#5B5B5B] text-aellaBlack flex-1 text-sm placeholder:text-[12px] font-light placeholder:font-light outline-none"
            placeholder="Search loan ID, loan amount"
          />
        </form>
        <button
          type="button"
          onClick={() =>
            updateState({
              filterModal: true,
            })
          }
          className={`flex  items-center justify-center h-10 px-4 text-[#5b5b5b] text-sm font-light bg-[#F5F5F5] border border-[#EDEFF5] rounded-[4px] cursor-pointer gap-x-2`}
        >
          <FilterIcon />
          Filter
        </button>
      </div>

      <div className="mt-5 hide_scrollbar overflow-x-scroll">
        <div className="flex justify-between w-[280%] sm:w-[180%] md:w-full items-center bg-[#F8FAFC] border-b h-10 gap-x-1 px-5 text-sm text-[#000810] uppercase">
          <p className="w-[15%] mr-4 md:mr-0 uppercase">loan id</p>
          <p className="w-[20%]">loan amount</p>
          <p className="w-[15%] mr-4 md:mr-0">still owing</p>
          <p className="w-[16%] text-center">status</p>
          <p className="w-[15%] text-right">due date</p>
        </div>
        {loanHistoryData?.isFetching ? (
          <LoadingState height="h-[45vh]" />
        ) : loanHistoryData?.isError ? (
          <div className="h-[50vh] w-full flex justify-center items-center">
            <ErrorMessage onClick={() => loanHistoryData?.refetch()} />
          </div>
        ) : loanHistoryData?.data?.data?.loans?.length == 0 ? (
          <EmptyList message="This employee has no loan history" />
        ) : (
          loanHistoryData?.data?.data?.loans?.map((loan) => {
            return (
              <div
                onClick={() => {
                  updateState({
                    loanDetailsModal: true,
                  });
                  setSelectedLoan(loan);
                }}
                className="flex justify-between w-[280%] sm:w-[180%] md:w-full items-center bg-white border-b h-12 gap-x-1 px-5 text-sm text-[#7B7B7B] font-light cursor-pointer"
              >
                <p className="w-[15%] mr-4 md:mr-0 truncate">
                  {truncateString(loan?.id, 10)}
                </p>
                <p className="w-[20%]"> ₦{formatAmount(loan?.amount ?? 0)}</p>
                <p className="w-[15%] mr-4 md:mr-0">
                  ₦{formatAmount(loan?.balance ?? 0)}
                </p>
                <p
                  className={`w-[16%] text-center truncate ${attachClassToLoanStatus(
                    loan?.status
                  )}`}
                >
                  {attachNameToLoanStatus(loan?.status)}
                </p>
                <p className="w-[15%] text-right">
                  {loan?.next_due_date
                    ? format(new Date(loan?.next_due_date), "dd MMM, yyyy")
                    : "N/A"}
                </p>
              </div>
            );
          })
        )}
        {loanHistoryData?.data?.data?.loans?.length > 0 &&
        !loanHistoryData?.isFetching ? (
          <NoParamPagination
            currentPage={state?.currentPage}
            postsPerPage={postsPerPage}
            setCurrentPage={(e) =>
              updateState({
                currentPage: e,
              })
            }
            totalPages={loanHistoryData?.data?.data?.meta?.totalPages}
            totalPosts={loanHistoryData?.data?.data?.meta?.totalCount}
          />
        ) : null}
      </div>
    </div>
  );
};
