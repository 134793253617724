import { useQuery } from "react-query";
import moment from "moment";

import { apiEndpoints } from "../apis/apiEndpoints";
import { getData } from "../apis/apiMethods";
import CONFIG from "../helpers/config";

export const getWalletTransactions = async ({
  queryKey,
}: {
  queryKey: any;
}) => {
  const { type, walletID, page, size, merchantID } = queryKey[1];
  let queryParams = `?merchant_id=${merchantID}&page=${page}&per_page=${size}&include=user`;
  if (type) {
    queryParams = `${queryParams}&type=${type}`;
  }
  if (walletID) {
    queryParams = `${queryParams}&wallet_id=${walletID}`;
  }

  const data = await getData(
    `${CONFIG.BASE_URL2}${apiEndpoints.GET_WALLET_TRANSACTIONS}${queryParams}`
  );
  return data;
};

export const useWalletTransactions = (props: {
  type?: string | undefined;
  mode: string;
  walletID?: string | undefined;
  page: number;
  size: number;
  merchantID?: string;
}) => {
  const { walletID, type, mode, page, size, merchantID } = props;
  return useQuery(
    ["getWalletTransactions", { type, walletID, mode, page, size, merchantID }],

    getWalletTransactions,
    {
      refetchOnWindowFocus: false, // Refetch on window focus
      enabled: props.hasOwnProperty("walletID")
        ? walletID
          ? true
          : false
        : mode
        ? merchantID
          ? true
          : false
        : false,
      retry: 1,
    }
  );
};

export const getWalletTransactionsList = async ({
  queryKey,
}: {
  queryKey: any;
}) => {
  const { merchant_id, size, page, wallet_id } = queryKey[1];

  let queryParams = `?merchant_id=${merchant_id}&wallet_id=${wallet_id}&size=${size}&page=${page}`;

  const data = await getData(
    `${CONFIG.BASE_URL2}${apiEndpoints.GET_POS_WALLET_TRANSACTIONS}${queryParams}`
  );

  return data;
};

export const useWalletTransactionsList = (props: {
  page: number;
  size: number;
  merchant_id: string | undefined;
  wallet_id?: string 
}) => {
  const { merchant_id, size, page, wallet_id } = props;
  return useQuery(
    [
      "getWalletTransactionsList",
      {
        merchant_id,
        size,
        page,
        wallet_id
      },
    ],

    getWalletTransactionsList,
    {
      refetchOnWindowFocus: false, // Refetch on window focus
      retry: 1,
    }
  );
};
