import { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { ModalContainer } from "../../../modal/ModalContainer";
import CalendarIcon from "../../../assets/images/svg/calendar.svg";
import { ReactComponent as CloseIcon } from "../../../assets/images/svg/white-close-modal.svg";
import NairaIcon from "../../../assets/images/svg/input-naira.svg";
import CustomInputField from "../../../component/CustomHTMLElements/CustomInputField";

export const FilterEmployeeLoanModal = ({
  setFilterObj,
  showModal,
  closeModal,
  updateState,
}: {
  setFilterObj: any;
  showModal: boolean;
  closeModal: () => void;
  updateState: any;
}) => {
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);
  const [startAmount, setStartAmount] = useState("");
  const [endAmount, setEndAmount] = useState("");

  const handleReset = () => {
    setFilterObj(null);
    closeModal();
    setStart(null);
    setEnd(null);
    setStartAmount("");
    setEndAmount("");
    updateState({
      currentPage:1
    })
  };

  return (
    <ModalContainer showModal={showModal} closeModal={closeModal}>
      <form
        className={`w-full lg:w-[35vw] relative right-0 min-h-[30vh] h-auto max-h-[90vh] z-50 flex flex-col justify-center text-center p-5 md:py-10 md:px-6 rounded-[8px] bg-white fast_animation ${
          showModal
            ? "mt-0 opacity-100 visible"
            : "mt-[6rem] opacity-0 invisible"
        }`}
      >
        <button
          type="button"
          onClick={closeModal}
          className="absolute -top-8 -right-0 cursor-pointer"
        >
          <CloseIcon />
        </button>
        <div className="flex flex-col">
          <p className="pb-4 border-b text-left text-[24px] text-aellaBlack">
            Filter by:
          </p>

          <div className="mt-8 w-full text-left">
            <div className="flex flex-col gap-y-1 my-3 text-left">
              <p className="pb-2 text-aellaBlack lg:leading-[16px] text-[16px] font-[300] -mb-2">
                Amount Range
              </p>
              <div className="flex flex-col sm:flex-row gap-x-3 items-start sm:items-center justify-between w-full">
                <div className="relative w-full sm:w-[47%]">
                  <img
                    src={NairaIcon}
                    alt="input icon"
                    className="absolute top-2 lg:top-[8px] -left-[2px] z-10 h-12 w-10"
                  />
                  <CustomInputField
                    type="number"
                    addPadding={true}
                    min={1}
                    placeholder="0.00"
                    errorMessage="Amount is required"
                    showRequiredIcon={false}
                    value={startAmount}
                    onChange={(e) => setStartAmount(e.target.value)}
                  />
                </div>
                <p className="text-aellaLightGray mb-3 font-light">to</p>
                <div className="relative w-full sm:w-[47%]">
                  <img
                    src={NairaIcon}
                    alt="input icon"
                    className="absolute top-2 lg:top-[8px] -left-[2px] z-10 h-12 w-10"
                  />
                  <CustomInputField
                    type="number"
                    addPadding={true}
                    min={1}
                    placeholder="0.00"
                    errorMessage="Amount is required"
                    showRequiredIcon={false}
                    value={endAmount}
                    onChange={(e) => setEndAmount(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <p className="pb-2 text-aellaBlack text-left lg:leading-[16px] text-[16px] font-[300]">
              Date Requested
            </p>
            <div className="flex flex-col gap-y-8 sm:flex-row items-center justify-between">
              <div className="w-full sm:w-[46%] z-50 flex items-center border border-aellaLightGray rounded-[4px] px-3">
                <DatePicker
                  dateFormat="yyyy-MM-dd"
                  placeholderText="Start Date"
                  selected={start}
                  maxDate={end ?? new Date()}
                  onChange={(date: Date) => {
                    setEnd(null)
                    setStart(date);
                  }}
                  className="h-12 w-full text-aellaBlack lg:leading-[16px] text-[16px] font-[300] outline-0"
                />
                <img
                  src={CalendarIcon}
                  className="w-[20px] h-[20px]"
                  alt="calendar icon"
                />
              </div>

              <div className="w-full sm:w-[46%] z-50 flex items-center border border-aellaLightGray rounded-[4px] px-3">
                <DatePicker
                  dateFormat="yyyy-MM-dd"
                  placeholderText="End Date"
                  selected={end}
                  minDate={start}
                  maxDate={new Date()}
                  onChange={(date: Date) => {
                    setEnd(date);
                  }}
                  className="h-12 w-full text-aellaBlack lg:leading-[16px] text-[16px] font-[300] outline-0"
                />
                <img
                  src={CalendarIcon}
                  className="w-[20px] h-[20px]"
                  alt="calendar icon"
                />
              </div>
            </div>

            <div className="flex justify-end gap-x-8 bg-white pt-10">
              <button
                type="reset"
                className="border-aellaBlue border rounded py-2 cursor-pointer text-center text-aellaBlue w-[121px]"
                onClick={handleReset}
              >
                Reset
              </button>
              <button
                type="button"
                className="border border-aellaBlue rounded bg-aellaBlue text-white cursor-pointer w-[121px]"
                onClick={() => {
                  updateState({
                    currentPage: 1,
                    searchParam: "",
                  });
                  setFilterObj({
                    start,
                    end,
                    startAmount,
                    endAmount,
                  });
                  closeModal();
                }}
              >
                Filter
              </button>
            </div>
          </div>
        </div>
      </form>
    </ModalContainer>
  );
};
