export const LoanBookAction = ({
  showAction,
  onDecline,
  onApprove,
  isDeclining,
  isApproving,
  canApprove = true,
}: {
  showAction: boolean;
  onDecline: () => void;
  onApprove: () => void;
  isDeclining: boolean;
  isApproving: boolean;
  canApprove?: boolean;
}) => {
  return (
    <div className="relative">
      <div
        className={`bg-white absolute top-[2rem] -left-[8rem] z-[9999] p-[6px] rounded-[4px] transition-all duration-200 ease-in-out shadow-md
         ${
           showAction
             ? "mt-0 opacity-100 visible"
             : "-mt-[1rem] opacity-0 invisible"
         }`}
      >
        <div className="w-[100px] flex flex-col py-1 px-1 gap-x-3 gap-y-2 text-[#232323] font-light hover:text-[#232323] rounded-[4px] cursor-pointer">
          {canApprove && (
            <button
              disabled={isApproving}
              onClick={onApprove}
              className="py-2 px-4 hover:bg-[#F3F5FC] rounded-[4px] text-center"
            >
              Approve
            </button>
          )}
          <button
            disabled={isDeclining}
            onClick={onDecline}
            className="py-2 px-4 hover:bg-[#F3F5FC] rounded-[4px] text-center"
          >
            Decline
          </button>
        </div>
      </div>
    </div>
  );
};

export const EmployeeAction = ({ showAction, onDelete, isDeleting }) => {
  return (
    <div className="relative">
      <div
        className={`bg-white absolute top-[2rem] -left-[8rem] z-[999] p-[6px] rounded-[4px] transition-all duration-200 ease-in-out shadow-md
       ${
         showAction
           ? "mt-0 opacity-100 visible"
           : "-mt-[1rem] opacity-0 invisible"
       }`}
      >
        <div className="w-[100px] flex flex-col py-1 px-1 gap-x-3 gap-y-2 text-[#232323] font-light hover:text-[#232323]   rounded-[4px] cursor-pointer">
          <button
            disabled={isDeleting}
            onClick={onDelete}
            className="py-2 px-4 hover:bg-[#F3F5FC] rounded-[4px] text-center"
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};
