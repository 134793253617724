import { ReactComponent as Drop } from "../../../assets/images/svg/my-drop.svg";
import { ReactComponent as More } from "../../../assets/images/svg/more.svg";
import { useState } from "react";
import DeleteAdminModal from "../Modal/DeleteAdminModal";
import { useFilterTeams } from "../../../hooks/useFilterTeams";
import CardSkeletonLoader from "../../../component/Skeleton/CardSkeletonLoader.component";
import { getFromLocalStorage } from "../../../helpers/localStorage";
import { LOCAL_STORAGE_KEYS } from "../../../helpers/localStorageKeys";
import { Link } from "react-router-dom";
import { useTeamRole } from "../../../hooks/useTeamRole";
import ChangeUserAccess from "../Modal/ChangeUserAccess";

const AdminList = () => {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openChangeModal, setOpenChangeModal] = useState(false);
  const [userId, setUserId] = useState("");
  const [userRole, setUserRole] = useState("");
  const { data, isFetching, refetch } = useFilterTeams();
  const { data: roleData, isFetching: roleFetching } = useTeamRole();
  const user = getFromLocalStorage(LOCAL_STORAGE_KEYS.USERID);
  const role = getFromLocalStorage(LOCAL_STORAGE_KEYS.ROLE);

  return (
    <>
      {isFetching ? (
        <div className="flex flex-col md:flex-row justify-between px-10 pt-[32px] lg:pt-[30px]">
          <CardSkeletonLoader width={320} height={458} />
          <CardSkeletonLoader width={320} height={458} />
          <CardSkeletonLoader width={320} height={458} />
        </div>
      ) : (
        <div className="w-full border border-[#EDEFF5]">
          <div className="bg-[#F3F5FC] flex p-[14px]">
            <p className="w-[25%] text-[#0B1E4B]">Full Name</p>
            <p className="w-[25%] text-[#0B1E4B] ">Email Address</p>
            <p className="w-[25%] text-[#0B1E4B] ">Role</p>
            {role === "Super Admin" && (
              <p className="w-[25%] text-[#0B1E4B] ">Action</p>
            )}
          </div>
          {data?.data?.length === 0 ? (
            <div className="flex flex-col gap-[32px] mt-[77px] w-[40%] mx-auto justify-center items-center">
              <div className="text-center">
                <p className="text-[#232323] mb-[8px]">No User Available</p>
                <p className="font-thin text-[#5B5B5B]"></p>
              </div>
            </div>
          ) : (
            <div className="flex flex-col gap-[16px] my-[16px]">
              {data?.data?.map((each: any) => (
                <div key={each?.id} className="flex p-[14px]">
                  <Link
                    to={`/profile-settings/manage-team/${each?.id}`}
                    className="w-[25%] text-[#3C4249] font-thin"
                  >
                    {each?.user?.first_name} {each?.user?.last_name}
                    {each?.user?.id === user ? "(You)" : null}
                  </Link>
                  <div className="w-[25%] text-[#3C4249] font-thin">
                    <p className="w-[95%] truncate"> {each?.user?.email}</p>
                  </div>
                  <div className="w-[25%] text-[#3C4249] font-thin flex gap-[14px] items-center">
                    <p>{each?.merchant_role?.name}</p>
                    {each?.user?.id === user ||
                    role !== "Super Admin" ? null : (
                      <div className="relative myHovered">
                        <Drop />

                        <div className="myHovered2 invisible transition-all py-[10px] cursor-pointer absolute top-[105%] left-[-200%] z-20 w-[200px] p-[8px] bg-white shadow-md rounded-[3px]">
                          {roleData?.data?.map(
                            (info: any) =>
                              info.name !== each?.merchant_role?.name && (
                                <div
                                  key={info?.id}
                                  onClick={() => setOpenChangeModal(true)}
                                  onMouseOver={() => {
                                    setUserRole(info?.id);
                                    setUserId(each?.id);
                                  }}
                                  onFocus={() => {
                                    setUserRole(info?.id);
                                    setUserId(each?.id);
                                  }}
                                  className="text-[14px] px-[11px] py-[8px] transition-all hover:bg-[#F3F5FC]"
                                >
                                  {info?.name}
                                </div>
                              )
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                  {each?.user?.id === user || role !== "Super Admin" ? null : (
                    <button className="w-[25%] relative myHovered cursor-pointer text-[#3C4249] font-thin flex gap-[14px] items-center">
                      <More />
                      <div
                        onMouseOver={() => {
                          setUserId(each?.id);
                          setUserRole(each?.merchant_role_id);
                        }}
                        onClick={() => setOpenDeleteModal(true)}
                        className="myHovered2 invisible transition-all py-[10px] cursor-pointer absolute text-left left-0 top-[100%] z-20 w-[200px] p-[8px] bg-white shadow-md rounded-[3px]"
                      >
                        <div className="text-[14px] text-[#FE4149] px-[11px] py-[8px] transition-all hover:bg-[#F3F5FC]">
                          Revoke Access
                        </div>
                      </div>
                    </button>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      )}

      {openDeleteModal && (
        <DeleteAdminModal
          refetchUsers={refetch}
          id={userId}
          roleId={userRole}
          showModal={openDeleteModal}
          setCloseModal={setOpenDeleteModal}
        />
      )}

      {openChangeModal && (
        <ChangeUserAccess
          userRole={userRole}
          refetchUsers={refetch}
          id={userId}
          showModal={openChangeModal}
          setCloseModal={setOpenChangeModal}
        />
      )}
    </>
  );
};

export default AdminList;
