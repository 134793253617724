import CustomInputField from "../../../../component/CustomHTMLElements/CustomInputField";
import { postData, putData } from "../../../../apis/apiMethods";
import CONFIG from "../../../../helpers/config";
import { apiEndpoints } from "../../../../apis/apiEndpoints";
import { errorHandler } from "../../../../helpers/errorHandler";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import {
  Loader,
  SpinLoader,
} from "../../../../component/Loader/Loader.component";
import { useQueryClient } from "react-query";
import { ReactComponent as AddIcon } from "../../../../assets/images/svg/blue-add-icon.svg";
import { ReactComponent as AttachIcon } from "../../../../assets/images/svg/attach-icon.svg";
import { identificationTypes } from "../../../../helpers/json";
import { convertToBase64 } from "../../../../helpers/convertToBase64";
import { getFromLocalStorage } from "../../../../helpers/localStorage";
import { LOCAL_STORAGE_KEYS } from "../../../../helpers/localStorageKeys";
import { nanoid } from "nanoid";
import closeIcon from "../../../../assets/images/svg/close-icon.svg";

export type OwnerType = {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  bvn: string;
  proof_of_address: {
    file: string;
    type: string;
    fileName?: string;
  };
  means_of_id: {
    file: string;
    type: string;
    fileName?: string;
  };
  identification: string;
  owner: string | boolean;
  share: string;
  edit: boolean;
};

const ProfileStepTwo = ({ merchantDetails }: { merchantDetails: any }) => {
  const [loading, setLoading] = useState(false);
  const [loadingFile, setLoadingFile] = useState<any>({});

  const [owners, setOwners] = useState<Array<OwnerType>>([
    {
      id: nanoid(),
      first_name: "",
      last_name: "",
      email: "",
      phone_number: "",
      bvn: "",
      proof_of_address: {
        file: "",
        type: "",
        fileName: "",
      },
      means_of_id: {
        file: "",
        type: "",
        fileName: "",
      },
      owner: "",
      share: "",
      identification: "",
      edit: true,
    },
  ]);

  const queryClient = useQueryClient();
  const userId = getFromLocalStorage(LOCAL_STORAGE_KEYS.USERID);

  useEffect(() => {
    if (merchantDetails?.merchant?.owners?.length > 0) {
      const resBody = merchantDetails?.merchant?.owners?.map((data: any) => ({
        ...data,
        means_of_id: {
          file: data?.id_file,
          fileName: data?.id_file,
          type: data?.id_type,
        },
        proof_of_address: {
          file: data?.proof_of_address,
          type: "",
          fileName: data?.proof_of_address,
        },
        identification: data?.id_type,
      }));
      setOwners(resBody);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantDetails]);

  const onChange = (e: any, id: string) => {
    const { name, value } = e.target;
    const newDate = owners.map((data: OwnerType) => {
      if (data.id === id) {
        if (e.target.name === "owner") {
          return { ...data, [name]: value, share: "" };
        } else {
          return { ...data, [name]: value };
        }
      } else {
        return data;
      }
    });
    setOwners(newDate);
  };

  const onFileChange = async ({
    e,
    fileType,
    id,
  }: {
    e: any;
    fileType: string;
    id: string;
  }) => {
    setLoadingFile({ ...loadingFile, [fileType + id]: true });
    if (e?.target?.files[0]?.name) {
      const fileAcceptType = e.target.files[0].type;
      const fileName = e.target.files[0].name;
      const isValidFile =
        fileAcceptType.startsWith("image/png") ||
        fileAcceptType.startsWith("image/jpg") ||
        fileAcceptType.startsWith("image/jpeg") ||
        fileAcceptType.startsWith("application/pdf");
      if (!isValidFile) {
        setLoadingFile({ ...loadingFile, [fileType + id]: false });
        toast.error(
          "Invalid file extension. Kindly upload a pdf,word,png,jpeg or jpg file"
        );
        return (e.target.value = null);
      }
      if (e.target.files[0].size > 10000000) {
        setLoadingFile({ ...loadingFile, [fileType + id]: false });
        toast.error(
          "File size is too large. Max file size is 10mb. Kindly reupload another Document"
        );
        return (e.target.value = null);
      }
      const fileBase64 = await convertToBase64(e.target.files[0]);

      const reqBody = {
        user_id: userId,
        photo: fileBase64,
      };

      try {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const res: any = await postData(
          `${CONFIG.BASE_URL1}${apiEndpoints.UPLOAD_IMAGE}`,
          reqBody
        );

        // const otherOwners = owners.filter(
        //   (owner: OwnerType) => owner.id !== id
        // );
        const { name } = e.target;
        const newDate = owners.map((data: OwnerType) => {
          if (data.id === id) {
            return {
              ...data,
              [name]: {
                file: res?.data?.filename,
                type: fileType,
                fileName,
              },
            };
          } else {
            return data;
          }
        });
        setOwners(newDate);
      } catch (error) {
        toast.error(errorHandler(error));
      }
    }
    setLoadingFile({ ...loadingFile, [fileType + id]: false });
  };

  const onAddMore = (e: any) => {
    setOwners([
      ...owners,
      {
        id: nanoid(),
        first_name: "",
        last_name: "",
        email: "",
        phone_number: "",
        bvn: "",
        proof_of_address: {
          file: "",
          type: "",
          fileName: "",
        },
        means_of_id: {
          file: "",
          type: "",
        },
        owner: "",
        share: "",
        identification: "",
        edit: true,
      },
    ]);
  };

  const onSubmit = async () => {
    setLoading(true);
    const newreq = owners?.map((owner) => {
      delete owner.means_of_id.fileName;
      return {
        ...owner,
        proof_of_address: owner.proof_of_address.file,
        share: owner?.share ? Number(owner.share) : "",
        owner:
          owner?.owner === true ? "Yes" : owner?.owner === false ? "No" : "",
      };
    });

    const reqBody = {
      owners: newreq,
    };

    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const res: any = await putData(
        `${CONFIG.BASE_URL2}${apiEndpoints.CREATE_REG_BULK_OWNER}`,
        reqBody
      );
      queryClient.invalidateQueries("getMerchantDetails");
    } catch (error) {
      toast.error(errorHandler(error));
    }
    setLoading(false);
  };

  const deleteBusinessOwner = (id: string) => {
    const remainingRequests = owners.filter((owner: any) => owner.id !== id);
    setOwners([...remainingRequests]);
  };
  return (
    <>
      <form>
        <div className="flex items-center">
          <h3 className="text-[20px] lg:leading-[24px] font-[300] tracking-[0.03px]">
            Business Owner
          </h3>

          <h1 className="ml-1 text-[12px] leading-[24px] text-[#EB5757]">*</h1>
        </div>
        <p className="text-[14px] lg:leading-[19px] font-[100] tracking-[0.03px] text-aellaGray pt-2.5 ">
          This is the details of the owner of this business
        </p>
        {owners?.map((owner: OwnerType) => {
          return (
            <div className="bg-[#F6F8FA] mt-[26px] p-4 rounded" key={owner.id}>
              {owners?.length > 1 && (
                <div className="flex mb-2 justify-between">
                  <div></div>
                  <img
                    src={closeIcon}
                    alt=""
                    className="cursor-pointer"
                    onClick={() => deleteBusinessOwner(owner.id)}
                  />
                </div>
              )}
              <div className="flex flex-col lg:flex-row">
                <div className=" w-full lg:pr-6">
                  <CustomInputField
                    type="text"
                    maxLength={128}
                    label="First Name"
                    name="first_name"
                    onChange={(e: any) => onChange(e, owner.id)}
                    placeholder="First Name"
                    readOnly={merchantDetails?.merchant?.owners?.length > 0}
                    defaultValue={owner.first_name}
                  />
                </div>
                <div className="w-full">
                  <CustomInputField
                    type="text"
                    maxLength={128}
                    label="Last Name"
                    name="last_name"
                    placeholder="Last Name"
                    readOnly={merchantDetails?.merchant?.owners?.length > 0}
                    onChange={(e: any) => onChange(e, owner.id)}
                    defaultValue={owner?.last_name}
                  />
                </div>
              </div>
              <div className="flex flex-col lg:flex-row">
                <div className=" w-full lg:pr-6">
                  <CustomInputField
                    type="number"
                    maxLength={11}
                    label="BVN"
                    name="bvn"
                    placeholder="BVN"
                    readOnly={merchantDetails?.merchant?.owners?.length > 0}
                    onChange={(e: any) => onChange(e, owner.id)}
                    defaultValue={owner?.bvn}
                  />
                </div>
                <div className="w-full">
                  <CustomInputField
                    type="number"
                    maxLength={11}
                    placeholder="08100000000"
                    label="Phone Number"
                    hasIcon="+234"
                    name="phone_number"
                    onChange={(e: any) => onChange(e, owner.id)}
                    readOnly={merchantDetails?.merchant?.owners?.length > 0}
                    defaultValue={owner?.phone_number}
                  />
                </div>
              </div>

              <CustomInputField
                type="email"
                maxLength={128}
                label="Personal Email Address"
                name="email"
                placeholder="Email"
                onChange={(e: any) => onChange(e, owner.id)}
                readOnly={merchantDetails?.merchant?.owners?.length > 0}
                defaultValue={owner?.email}
              />

              <h2 className="text-[16px] lg:leading-[16px] font-[300] tracking-[0.48px] mt-10 text-aellaGray">
                Required Documents:
              </h2>
              <div className="pt-[33px] ">
                <li className="text-[16px] lg:leading-[19px] font-[300] tracking-[0.03px]">
                  Proof of Address (Utility Bill, Bank Statement)
                </li>

                <div className="flex flex-col w-full pt-4">
                  <label className="text-[14px] lg:leading-[16px] font-[300] tracking-[0.03px] text-aellaGray mb-2.5">
                    Upload document
                  </label>

                  <label
                    htmlFor={`proof_of_address${owner.id}`}
                    className={` border-aellaLightGray
                inline-flex items-center justify-between bg-white appearance-none border px-4 rounded w-full h-[48px] py-2 cursor-pointer`}
                  >
                    <p className="text-[14px] font-[300] tracking-[0.42px] lg:leading-[16px] text-[#848F9A]">
                      {owner?.proof_of_address?.fileName
                        ? owner?.proof_of_address?.fileName ||
                          owner?.proof_of_address?.type
                        : "Attach File"}
                    </p>

                    <input
                      type="file"
                      id={`proof_of_address${owner.id}`}
                      className="hidden"
                      accept="application/pdf, image/*"
                      disabled={merchantDetails?.merchant?.owners?.length > 0}
                      name="proof_of_address"
                      onChange={(e: any) => {
                        onFileChange({
                          e,
                          fileType: "proof_of_address",
                          id: owner.id,
                        });
                      }}
                    />
                    {loadingFile[`proof_of_address${owner.id}`] ? (
                      <SpinLoader />
                    ) : (
                      <AttachIcon />
                    )}
                  </label>
                </div>
              </div>
              <div className="pt-8">
                <li className="text-[16px] lg:leading-[19px]  font-[300] tracking-[0.03px] ">
                  Valid means of Identification
                </li>
                <div className="flex flex-col lg:flex-row pt-4 ">
                  <div className=" flex flex-col lg:flex-row items-center lg:pr-6 w-full">
                    <div className="w-full">
                      <label className="text-aellaGray text-[14px] lg:leading-[16px] tracking-[0.03px] font-[300] mb-2">
                        Select means of identification{" "}
                      </label>
                      <select
                        className={`border-aellaLightGray bg-white appearance-none border px-4 rounded w-full h-[48px] py-2 leading-6 text-[16px] font-[300] focus:outline-none focus:bg-white `}
                        name="identification"
                        disabled={merchantDetails?.merchant?.owners?.length > 0}
                        onChange={(e: any) => onChange(e, owner.id)}
                        value={owner?.identification}
                      >
                        <option
                          value=""
                          className="text-[#DADADA] text-[16px] lg:leading-[24px] font-[100]"
                        >
                          {/* Select Identification */}
                        </option>
                        {identificationTypes?.map((data: any, _id: any) => {
                          const { value, name } = data;
                          return (
                            <option value={value} key={_id}>
                              {name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>

                  <div className="flex flex-col w-full mt-4 lg:mt-0">
                    <label className="text-[14px] lg:leading-[16px] font-[300] tracking-[0.03px] text-aellaGray mb-2.5">
                      Upload document
                    </label>
                    <label
                      htmlFor={`means_of_id${owner.id}`}
                      className={`border-aellaLightGray inline-flex items-center justify-between bg-white appearance-none border px-4 rounded w-full h-[48px] py-2 cursor-pointer`}
                    >
                      <p className="text-[14px] font-[300] tracking-[0.42px] lg:leading-[16px] text-[#848F9A]">
                        {owner?.means_of_id?.type
                          ? owner?.means_of_id?.fileName ||
                            owner?.means_of_id?.file
                          : "Attach File"}
                      </p>
                      <input
                        type="file"
                        id={`means_of_id${owner.id}`}
                        accept="application/pdf, image/*"
                        className="hidden"
                        name="means_of_id"
                        disabled={
                          !owner?.identification ||
                          merchantDetails?.merchant?.owners?.length > 0
                        }
                        onChange={(e: any) => {
                          onFileChange({
                            e,
                            fileType: owner?.identification,
                            id: owner.id,
                          });
                        }}
                      />
                      {loadingFile[`${owner?.identification}${owner.id}`] ? (
                        <SpinLoader />
                      ) : (
                        <AttachIcon />
                      )}
                    </label>
                  </div>
                </div>
              </div>
              <div className="pt-8 pb-8">
                <label className="lg:leading-[18px] text-[16px] font-[300]">
                  Does this business belong to you?
                </label>
                <div className="mt-6">
                  <input
                    type="radio"
                    className={`mt-1 shrink-0 cursor-pointer`}
                    onChange={() => {
                      const e = { target: { name: "owner", value: true } };
                      onChange(e, owner.id);
                    }}
                    name={"owner"}
                    checked={owner?.owner === true ? true : false}
                  />

                  <label className="ml-4 text-[16px] lg:leading-[16px] font-[300] text-[#011C34] tracking-[0.03px]">
                    Yes, this business belongs to me
                  </label>
                </div>
                <div className="mt-2">
                  <input
                    type="radio"
                    className={` mt-1 shrink-0 cursor-pointer`}
                    checked={owner?.owner === false ? true : false}
                    onChange={() => {
                      const e = { target: { name: "owner", value: false } };
                      onChange(e, owner.id);
                    }}
                    name={"owner"}
                  />

                  <label className="ml-4 text-[16px] lg:leading-[16px] font-[300] text-[#011C34] tracking-[0.03px]">
                    No, this business doesn&apos;t belong to me
                  </label>
                </div>
              </div>
              {owner?.owner === true && (
                <CustomInputField
                  type="number"
                  maxLength={5}
                  placeholder="00"
                  min={1}
                  label="Percentage owned"
                  checkNegative
                  hasIcon="%"
                  name="share"
                  onChange={(e: any) => {
                    onChange(e, owner.id);
                  }}
                  readOnly={merchantDetails?.merchant?.owners?.length > 0}
                  defaultValue={owner.share}
                />
              )}
            </div>
          );
        })}
        {merchantDetails?.merchant?.owners?.length === 0 && (
          <div className="flex justify-between mt-6">
            <div
              className="flex items-center cursor-pointer"
              onClick={(e) => onAddMore(e)}
            >
              <AddIcon />
              <p className="text-aellaBlue text-[16px] font-[300] pl-2.5">
                Add More Business Owner
              </p>
            </div>
            <div></div>
            {!merchantDetails?.merchantDetails?.attributes?.profile?.hasOwnProperty(
              "upgrade_stage"
            ) && (
              <button
                type="submit"
                className="bg-aellaBlue disabled:opacity-75 rounded text-white p-[15.5px] items-center flex xxl:mt-7 mb-28 lg:mb-0 justify-center cursor-pointer"
                disabled={loading}
                onClick={onSubmit}
              >
                Save and Continue
                {loading && <Loader />}
              </button>
            )}
          </div>
        )}
      </form>
    </>
  );
};
export default ProfileStepTwo;
