import { useState } from "react";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import CustomInputField from "../../component/CustomHTMLElements/CustomInputField";
import { ReactComponent as ShowPasswordIcon } from "../../assets/images/svg/eye-open-icon.svg";
import { ReactComponent as HidePasswordIcon } from "../../assets/images/svg/eye-close-icon.svg";
import { ReactComponent as NextIcon } from "../../assets/images/svg/right-arrow-icon.svg";
import { ReactComponent as CheckIcon } from "../../assets/images/svg/green-check-icon.svg";
import { Loader } from "../../component/Loader/Loader.component";
import { acceptInviteFn } from "./queries";
import { errorHandler } from "../../helpers/errorHandler";
import { validatePassword } from "../../helpers/yupValidators";

const InviteStepOne = ({ updateState }: { updateState: any }) => {
  const [passwordType, setPasswordType] = useState("password");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const queryParams = new URLSearchParams(window.location.search);
  const navigate = useNavigate();

  const passwordIsValid =
    validatePassword(password) && password === confirmPassword;

  const { isLoading, mutateAsync } = useMutation({
    mutationFn: acceptInviteFn,
    onSuccess: ({message}) => {
      toast.success(message);
      updateState({
        invite: false,
        hasSelectedAccount: false,
      });
      navigate("/");
    },
    onError: (error) => {
      toast.error(errorHandler(error));
    },
  });

  const handlePasswordSetting = async (e) => {
    e?.preventDefault();
    try {
      await mutateAsync({
        payload: {
          email_address: queryParams.get("email"),
          password,
          token: queryParams.get("token"),
        },
      });
    } catch (error) {
      toast.error(errorHandler(error));
    }
  };

  const togglePasswordField = () => {
    if (passwordType === "password") {
      setPasswordType("text");
    } else {
      setPasswordType("password");
    }
  };

  const toggleConfirmPassword = () => {
    if (confirmPasswordType === "password") {
      setConfirmPasswordType("text");
    } else {
      setConfirmPasswordType("password");
    }
  };

  return (
    <form
      autoComplete="off"
      className="h-full w-full md:w-[571px] flex flex-col justify-center"
    >
      <p className="text-[32px] text-aellaBlack">Set Password</p>
      <p className="text-[#7b7b7b] text-base font-light">
        Please input your password
      </p>
      <div className="mt-8">
        <CustomInputField
          autoComplete="new-password"
          type={passwordType}
          maxLength={128}
          placeholder="********"
          label="Password"
          showRequiredIcon
          //   errors={errors.password}
          hasActionButton={true}
          actionButtonText={
            passwordType === "password" ? (
              <ShowPasswordIcon />
            ) : (
              <HidePasswordIcon />
            )
          }
          onClickActionButton={togglePasswordField}
          onChange={(e) => setPassword(e.target.value)}
        />

        <div className="flex flex-col gap-y-2 mb-6 md:flex-row md:gap-x-[100px] lg:gap-x-[80px] -mt-2 lg:-mt-4">
          <div className="flex flex-col gap-y-2">
            <p className="flex gap-x-2 items-center text-[13px] font-[200] leading-[16px]">
              {password?.match(/[a-z]/) ? (
                <CheckIcon />
              ) : (
                <span className="inline-flex border-[#2054D2] rounded-full h-[8px] w-[8px] bg-[#2054D2]"></span>
              )}
              <span className="flex-1">One lowercase character</span>
            </p>
            <p className="flex gap-x-2 items-center lg:pr-[136px] text-[13px] font-[200] leading-[16px]">
              {password?.match(/[A-Z]/) ? (
                <CheckIcon />
              ) : (
                <span className="inline-flex border-[#2054D2] rounded-full h-[8px] w-[8px] bg-[#2054D2]"></span>
              )}
              <span className="">One uppercase character</span>
            </p>
          </div>
          <div className="flex flex-col gap-y-2">
            <p className="flex gap-x-2 items-center text-[13px] font-[200] leading-[16px]">
              {password?.match(/[0-9]/) ? (
                <CheckIcon />
              ) : (
                <span className="inline-flex border-[#2054D2] rounded-full h-[8px] w-[8px] bg-[#2054D2]"></span>
              )}
              <span className="">One number</span>
            </p>
            <p className="flex gap-x-2 items-center  text-[13px] font-[200] leading-[16px]">
              {password?.length >= 6 ? (
                <CheckIcon />
              ) : (
                <span className="inline-flex border-[#2054D2] rounded-full h-[8px] w-[8px] bg-[#2054D2]"></span>
              )}
              <span className="">6 characters minimum</span>
            </p>
          </div>
        </div>

        <CustomInputField
          autoComplete="confirm-password"
          type={confirmPasswordType}
          maxLength={128}
          placeholder="********"
          label="Confirm Password"
          showRequiredIcon
          hasActionButton={true}
          actionButtonText={
            confirmPasswordType === "password" ? (
              <ShowPasswordIcon />
            ) : (
              <HidePasswordIcon />
            )
          }
          onClickActionButton={toggleConfirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />

        <button
          onClick={handlePasswordSetting}
          type="button"
          className={`mt-[30px] bg-aellaBlue rounded text-white py-4 px-6 items-center w-full flex justify-center ${
            (isLoading || !passwordIsValid) && "disabled:opacity-75"
          }`}
          disabled={isLoading || !passwordIsValid}
        >
          Continue
          <span className="pl-2">
            <NextIcon />
          </span>
          {isLoading && (
            <div className="">
              <Loader />
            </div>
          )}
        </button>

        <div className="flex items-center justify-center my-8 text-[16px] lg:leading-[20px] font-[300]">
          <span className="text-aellaGray pr-2">Already have an account? </span>
          <p
            onClick={() => {
              updateState({
                invite: false,
              });
              navigate("/");
            }}
            className="text-aellaBlue cursor-pointer"
          >
            Sign In
          </p>
        </div>
      </div>
    </form>
  );
};

export default InviteStepOne;
