import { apiEndpoints } from "../../apis/apiEndpoints";
import { postData } from "../../apis/apiMethods";
import CONFIG from "../../helpers/config";
import { ResetResponse } from "../../interfaces/settings";

export const initiateResetPinFn = async () => {
  const { data } = await postData(
    `${CONFIG.BASE_URL2}${apiEndpoints.INITIATE_PIN_RESET}`
  );
  return data as ResetResponse;
};

export const resetPinFn = async ({
  payload,
}: {
  payload: { pin: string; otp: string };
}) => {
  const { data } = await postData(
    `${CONFIG.BASE_URL2}${apiEndpoints.RESET_PIN}`,
    payload
  );
  return data as ResetResponse;
};
