import { useState } from "react";
import CloseIcon from "../../../assets/images/svg/close-icon.svg";
import ConfirmDelete from "../Components/ConfirmDelete";
import DeleteAccount from "../Components/DeleteAccount";
import UnableToDeleteAccount from "../Components/UnableToDeleteAccount";
import CustomInputField from "../../../component/CustomHTMLElements/CustomInputField";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useQueries } from "react-query";
import { toast } from "react-toastify";
import { MerchantEndpoint } from "../Components/MerchantEndpoint";

const schemaPassword = yup.object().shape({
  pin: yup.string().required("Input Must not be empty"),
});

interface IPin {
  pin: string;
}

const DeleteAccountModal = ({
  setCloseModal,
  showModal,
  accountNumber,
  refetch,
}: {
  setCloseModal: (newState: boolean) => void;
  showModal: boolean;
  accountNumber: string;
  refetch: any;
}) => {
  const [state, setState] = useState("delete");
  const [isVisible, setVisible] = useState(false);
  const [myPin, setMyPin] = useState("");
  const [sending, setSending] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IPin>({
    criteriaMode: "all",
    mode: "all",
    resolver: yupResolver(schemaPassword),
  });

  const [{ isLoading, isError }] = useQueries([
    {
      queryKey: ["GET MERCHANT DEACTIVATED", sending],
      queryFn: () => MerchantEndpoint({ accountNumber, pin: myPin }),
      onSuccess: (data) => {
        setSending(false);
        toast.success("Deactivated Account Successfully");
      },
      onError: (err: any) => {
        setSending(false);
        toast.error("Error Deactivating Account");
      },
      enabled: sending,
    },
  ]);

  const onSubmit = async ({ pin }: { pin: string }) => {
    // console.log(pin, accountNumber);
    setMyPin(pin);
    setSending(true);
    refetch();
  };

  const renderContent = () => {
    switch (state) {
      case "unable":
        return <UnableToDeleteAccount />;
      case "delete":
        return <DeleteAccount navigate={() => setState("confirm")} />;
      case "confirm":
        return <ConfirmDelete />;
      default:
        return;
    }
  };

  return (
    <>
      <div className="absolute justify-center items-center flex inset-0 z-50 outline-none focus:outline-none  overflow-y-scroll">
        <div className="translate-50 mx-auto max-w-[501px]">
          <div className="flex mb-2 justify-between w-[501px]">
            <div
              onClick={() => {
                setCloseModal(!showModal);
              }}
            >
              <img
                src={CloseIcon}
                alt="close modal"
                className="cursor-pointer"
              />
            </div>
          </div>

          <div className="border-0 rounded-lg flex flex-col w-screen md:w-full bg-colors-white outline-none focus:outline-none">
            <div className="bg-white flex flex-col justify-center rounded-[8px]">
              {/* <div className="">{renderContent()}</div> */}
              <div>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="p-[45px] text-center">
                    <p className="text-[24px] mb-[16px] ">
                      Deactivate Bank Acount
                    </p>
                    <p className="text-[#5B5B5B] font-thin mb-[20px] w-[80%] mx-auto">
                      For security reasons, Enter your pin below to deactivate
                      this account.
                    </p>
                    <div className="w-[256px] mx-auto">
                      <CustomInputField
                        type={!isVisible ? "password" : "text"}
                        label="Enter Pin"
                        {...register("pin")}
                        errors={errors.pin}
                      />
                    </div>
                    <button className="flex justify-center gap-[10px] mx-auto items-center cursor-pointer"></button>
                    <button
                      disabled={isLoading}
                      // onClick={navigate}
                      className="px-[50px] py-[16px] mt-[50px] rounded-[4px] font-thin text-white bg-[#EB5757]"
                    >
                      {isLoading ? "Loading..." : " Continue"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-opacity-25 fixed inset-0 z-40 bg-[#0B1E4B]"></div>
    </>
  );
};

export default DeleteAccountModal;
