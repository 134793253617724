import moment from "moment";
import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData } from "../apis/apiMethods";
import CONFIG from "../helpers/config";

export const getAllPosDisputes = async ({ queryKey }: { queryKey: any }) => {
  const { page, size, startDate, endDate, status, category } =
    queryKey[1];

  let queryParams = `?page=${page}`;

  if (startDate) {
    queryParams = `${queryParams}&start_date=${moment(startDate).format(
      "YYYY-MM-DD"
    )}`;
  }

  if (endDate) {
    queryParams = `${queryParams}&end_date=${moment(endDate).format(
      "YYYY-MM-DD"
    )}`;
  }
  if (status) {
    queryParams = `${queryParams}&status=${status}`;
  }
  if (category) {
    queryParams = `${queryParams}&category=${category}`;
  }

  const res: any = await getData(
    `${CONFIG.BASE_URL2}${apiEndpoints.POS_DISPUTES}${queryParams}`
  );
  return res;
};

export const useAllPosDisputes = ({
  page,
  size,
  endDate,
  startDate,
  status,
  category,
}: {
  page: string | number | undefined;
  size?: string | number | undefined;
  endDate?: Date | null;
  startDate?: Date | null;
  status?: string;
  category?: string;
}) => {
  return useQuery(
    [
      "getAllPosDisputes",
      { page, size, startDate, endDate, category, status },
    ],
    getAllPosDisputes,
    {
      retry: 1,
      refetchOnWindowFocus: false,
    }
  );
};
