import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData } from "../apis/apiMethods";
import CONFIG from "../helpers/config";

export const getMerchantDetails = async () => {
  const { data } = await getData(
    `${CONFIG.BASE_URL2}${apiEndpoints.GET_MERCHANT_DETAILS}`
  );
  return data;
};

export const useMerchantDetails = (props: {
  userId?: string;
  enabled?: boolean;
}) => {
  const { userId, enabled } = props;
  return useQuery(
    ["getMerchantDetails", { userId }],

    getMerchantDetails,
    {
      refetchOnWindowFocus: false, // Refetch on window focus
      enabled: props?.hasOwnProperty("enabled")
        ? enabled
          ? true
          : false
        : true,
      retry: 1,
    }
  );
};
