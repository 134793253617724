import Drawer from "../../../component/Drawer/Drawer";
import { ReactComponent as CloseIcon } from "../../../assets/images/svg/close-icon.svg";
import { ReactComponent as Direction } from "../../../assets/images/svg/direction-m.svg";
import { ReactComponent as Checked } from "../../../assets/images/svg/checked.svg";
import CustomInputField from "../../../component/CustomHTMLElements/CustomInputField";
import { postData } from "../../../apis/apiMethods";
import CONFIG from "../../../helpers/config";
import { apiEndpoints } from "../../../apis/apiEndpoints";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Loader } from "../../../component/Loader/Loader.component";
import { useState } from "react";
import InviteFailedModal from "../Modal/InviteFailedModal";
import InviteFeedBackModal from "../Modal/InviteFeedBackModal";
import { errorHandler } from "../../../helpers/errorHandler";
import CustomSelectDropdown from "../../../component/CustomHTMLElements/CustomSelectDropdown";
import { useTeamRole } from "../../../hooks/useTeamRole";

interface ICreateMember {
  email: string;
  role: string;
  first_name: string;
  last_name: string;
  gender: string;
  phone_number: string;
}

const InviteUserDrawer = ({
  isOpen,
  setIsOpen,
  refetch,
}: {
  isOpen: boolean;
  setIsOpen: (newState: boolean) => void;
  refetch: any;
}) => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [memberError, setMemberError] = useState("");
  const [superAdmin, setSuperAdmin] = useState(false);
  const [admin, setAdmin] = useState(false);
  const [manager, setManager] = useState(false);
  const [developer, setDeveloper] = useState(false);

  const { data } = useTeamRole();

  const regexNospace = /^\S+$/;

  const regexPhoneNumber =
    /(?:\234|0)(?:(?:(?:70|80|81|90|91|92|93|94|95|96|97|98|99)\d{1}|(?:1(?:[0-689]|7[0-8]))\d{1}|(?:2(?:[0-579]|8[01]))\d{1}|(?:3(?:[0-7]|8[0-4]))\d{1}|(?:4(?:[0-79]|8[015]))\d{1}|(?:5(?:[0-69]|7[0-6]))\d{1}|(?:6(?:[0-9]|7[0-69]))\d{1}|(?:9(?:[0-689]|7[09]))\d{1})\d{6})/;

  const schema = yup.object().shape({
    email: yup.string().required("Email Address is Required").email(),
    role: yup.string().required("Select Role"),
    first_name: yup
      .string()
      .required("First Name is Required")
      .matches(regexNospace, { message: "No Space allowed" }),
    phone_number: yup
      .string()
      .required("Phone Number is Required")
      .matches(regexPhoneNumber, { message: "Enter a valid phone Number" }),
    last_name: yup
      .string()
      .required("Last Name is Required")
      .matches(regexNospace, { message: "No Space allowed" }),
    gender: yup.string().required("Gender is Required"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<ICreateMember>({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: ICreateMember) => {
    setLoading(true);
    setEmail(data?.email);

    const reqBody = {
      email_address: data?.email,
      role_id: data?.role,
      first_name: data?.first_name,
      last_name: data?.last_name,
      phone_number: data?.phone_number,
      gender: data?.gender,
    };

    try {
      const res = await postData(
        `${CONFIG.BASE_URL2}${apiEndpoints.INVITE_ACTION}`,
        reqBody
      );
      setLoading(false);
      toast.success(res.message);
      setOpenModal(true);
      setIsOpen(false);
      refetch();
      reset();
    } catch (error) {
      toast.error(errorHandler(error));
      reset();
      setMemberError(errorHandler(error));
      setLoading(false);
      setIsOpen(false);
      setErrorModal(true);
      reset();
    }
  };

  return (
    <>
      <Drawer isOpen={isOpen} setIsOpen={setIsOpen}>
        <div className="flex flex-col">
          <div className="flex border-b pb-[12px] justify-between ">
            <p className="text-[24px] text-[#0B1E4B]">Add Team Member</p>
            <button
              type="button"
              onClick={() => {
                setIsOpen(false);
                refetch();
              }}
            >
              <CloseIcon className="w-[32px] h-[32px] cursor-pointer" />
            </button>
          </div>
          <div className="py-[32px] flex-1 overflow-y-auto">
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="mt-[10px] flex flex-col gap-[30px]"
            >
              <div className="border rounded-[10px] p-[16px]">
                <div className="flex justify-between">
                  <div className="w-[48%]">
                    <CustomInputField
                      {...register("first_name")}
                      type="text"
                      label="First Name"
                      name="first_name"
                      errors={errors.first_name}
                      errorMessage={errors?.first_name?.message}
                    />
                  </div>

                  <div className="w-[48%]">
                    <CustomInputField
                      type="text"
                      label="Last Name"
                      {...register("last_name")}
                      name="last_name"
                      errors={errors.last_name}
                      errorMessage={errors?.last_name?.message}
                    />
                  </div>
                </div>

                <CustomInputField
                  type="phone_number"
                  label="Phone Number"
                  {...register("phone_number")}
                  name="phone_number"
                  errorMessage={errors?.phone_number?.message}
                  errors={errors.phone_number}
                />

                <CustomInputField
                  type="email"
                  label="Email Address"
                  {...register("email")}
                  name="email"
                  errorMessage={errors?.email?.message}
                  errors={errors.email}
                />

                <CustomSelectDropdown
                  errorMessage={errors?.role?.message}
                  errors={errors.role}
                  name="role"
                  label="Role"
                  {...register("role")}
                >
                  <option value="">Select role</option>
                  {data?.data?.map((each) => (
                    <option key={each?.id} value={each?.id}>
                      {each?.name}
                    </option>
                  ))}
                </CustomSelectDropdown>

                <CustomSelectDropdown
                  errorMessage={errors?.gender?.message}
                  errors={errors.gender}
                  name="gender"
                  label="Gender"
                  {...register("gender")}
                >
                  <option value="">Select Gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </CustomSelectDropdown>
              </div>

              <div>
                <div className="flex border-b pb-[12px] justify-between ">
                  <p className="text-[24px] text-[#0B1E4B]">Role Permissions</p>
                </div>

                <div className="border rounded-[10px] py-[16px] px-[3px] mt-[30px]">
                  <div className="p-[22px] border-b border-[#EDF2FF] flex flex-wrap justify-between items-center text-[#232323]">
                    <p className="text-[14px] font-extralight">Super Admin</p>
                    <Direction
                      onClick={() => setSuperAdmin(!superAdmin)}
                      className={`cursor-pointer transition-all ${
                        superAdmin ? "rotate-90" : ""
                      }`}
                    />

                    {superAdmin && (
                      <div className="w-full text-[#232323] font-extralight text-[14px] mt-[20px]">
                        <div className="mb-[20px]">
                          <p>Balance Overview</p>

                          <div className="bg-[#F5F7F9] mt-[14px] px-[29px] py-[13px] rounded-[7px]">
                            <div className="flex items-center gap-[8px] mb-[8px]">
                              <Checked />
                              <p>View Balance Overview</p>
                            </div>
                            <div className="flex items-center gap-[8px] mb-[8px]">
                              <Checked />
                              <p>View Transactions</p>
                            </div>

                            <div className="flex items-center gap-[8px] mb-[8px]">
                              <Checked />
                              <p>Transfer Funds</p>
                            </div>
                          </div>
                        </div>

                        <div className="mb-[20px]">
                          <p>Connect Services</p>

                          <div className="bg-[#F5F7F9] mt-[14px] px-[29px] py-[13px] rounded-[7px]">
                            <div className="flex items-center gap-[8px] mb-[8px]">
                              <Checked />
                              <p>Add more Services</p>
                            </div>
                          </div>
                        </div>

                        <div className="mb-[20px]">
                          <p>Settings Module</p>

                          <div className="bg-[#F5F7F9] mt-[14px] px-[29px] py-[13px] rounded-[7px]">
                            <div className="flex items-center gap-[8px] mb-[8px]">
                              <Checked />
                              <p>View Profile</p>
                            </div>

                            <div className="flex items-center gap-[8px] mb-[8px]">
                              <Checked />
                              <p>Manage Profile Details</p>
                            </div>

                            <div className="flex items-center gap-[8px] mb-[8px]">
                              <Checked />
                              <p>View Security</p>
                            </div>

                            <div className="flex items-center gap-[8px] mb-[8px]">
                              <Checked />
                              <p>Update Security (Password)</p>
                            </div>

                            <div className="flex items-center gap-[8px] mb-[8px]">
                              <Checked />
                              <p>Update Security (Pin)</p>
                            </div>

                            <div className="flex items-center gap-[8px] mb-[8px]">
                              <Checked />
                              <p>View notification</p>
                            </div>

                            <div className="flex items-center gap-[8px] mb-[8px]">
                              <Checked />
                              <p>Update Notification</p>
                            </div>

                            <div className="flex items-center gap-[8px] mb-[8px]">
                              <Checked />
                              <p>View Team members</p>
                            </div>

                            <div className="flex items-center gap-[8px] mb-[8px]">
                              <Checked />
                              <p>Invite Team members</p>
                            </div>

                            <div className="flex items-center gap-[8px] mb-[8px]">
                              <Checked />
                              <p>Manage Team member</p>
                            </div>

                            <div className="flex items-center gap-[8px] mb-[8px]">
                              <Checked />
                              <p>Revoke Team members access</p>
                            </div>

                            <div className="flex items-center gap-[8px] mb-[8px]">
                              <Checked />
                              <p>View API Keys</p>
                            </div>

                            <div className="flex items-center gap-[8px] mb-[8px]">
                              <Checked />
                              <p>Regenerate API Keys</p>
                            </div>
                          </div>
                        </div>

                        <div className="mb-[20px]">
                          <p>POS Module</p>

                          <div className="bg-[#F5F7F9] mt-[14px] px-[29px] py-[13px] rounded-[7px]">
                            <div className="flex items-center gap-[8px] mb-[8px]">
                              <Checked />
                              <p>View POS Transactions</p>
                            </div>
                            <div className="flex items-center gap-[8px] mb-[8px]">
                              <Checked />
                              <p>View POS request</p>
                            </div>

                            <div className="flex items-center gap-[8px] mb-[8px]">
                              <Checked />
                              <p>Request POS</p>
                            </div>

                            <div className="flex items-center gap-[8px] mb-[8px]">
                              <Checked />
                              <p>View Dispute</p>
                            </div>

                            <div className="flex items-center gap-[8px] mb-[8px]">
                              <Checked />
                              <p>Update Dispute</p>
                            </div>
                          </div>
                        </div>

                        <div className="mb-[20px]">
                          <p>Sub-Accounts Module</p>

                          <div className="bg-[#F5F7F9] mt-[14px] px-[29px] py-[13px] rounded-[7px]">
                            <div className="flex items-center gap-[8px] mb-[8px]">
                              <Checked />
                              <p>Create subaccounts</p>
                            </div>
                            <div className="flex items-center gap-[8px] mb-[8px]">
                              <Checked />
                              <p>View Subaccounts</p>
                            </div>

                            <div className="flex items-center gap-[8px] mb-[8px]">
                              <Checked />
                              <p>View Subaccount Details</p>
                            </div>

                            <div className="flex items-center gap-[8px] mb-[8px]">
                              <Checked />
                              <p>View Subaccount Transactions</p>
                            </div>

                            <div className="flex items-center gap-[8px] mb-[8px]">
                              <Checked />
                              <p>Update Subaccounts</p>
                            </div>
                            <div className="flex items-center gap-[8px] mb-[8px]">
                              <Checked />
                              <p>Delete Subaccounts</p>
                            </div>
                          </div>
                        </div>

                        <div className="mb-[20px]">
                          <p>Employee Loans Module</p>

                          <div className="bg-[#F5F7F9] mt-[14px] px-[29px] py-[13px] rounded-[7px]">
                            <div className="flex items-center gap-[8px] mb-[8px]">
                              <Checked />
                              <p>View Loan Overview</p>
                            </div>
                          
                            <div className="flex items-center gap-[8px] mb-[8px]">
                              <Checked />
                              <p>Create Employees</p>
                            </div>
                            <div className="flex items-center gap-[8px] mb-[8px]">
                              <Checked />
                              <p>View Employees</p>
                            </div>

                            <div className="flex items-center gap-[8px] mb-[8px]">
                              <Checked />
                              <p>View Employee Details</p>
                            </div>

                            <div className="flex items-center gap-[8px] mb-[8px]">
                              <Checked />
                              <p>View Employee Loan History</p>
                            </div>

                            <div className="flex items-center gap-[8px] mb-[8px]">
                              <Checked />
                              <p>Update Employee Details</p>
                            </div>
                            <div className="flex items-center gap-[8px] mb-[8px]">
                              <Checked />
                              <p>Delete Employees</p>
                            </div>
                            <div className="flex items-center gap-[8px] mb-[8px]">
                              <Checked />
                              <p>View Loan Book</p>
                            </div>
                            <div className="flex items-center gap-[8px] mb-[8px]">
                              <Checked />
                              <p>View Loan Details</p>
                            </div>
                            <div className="flex items-center gap-[8px] mb-[8px]">
                              <Checked />
                              <p>Approve or decline loan requests</p>
                            </div>
                            {/* <div className="flex items-center gap-[8px] mb-[8px]">
                              <Checked />
                              <p>View Loan Configuration</p>
                            </div>
                            <div className="flex items-center gap-[8px] mb-[8px]">
                              <Checked />
                              <p>Update Loan Configuration</p>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="p-[22px] border-b border-[#EDF2FF] flex flex-wrap justify-between items-center text-[#232323]">
                    <p className="text-[14px] font-extralight">Admin</p>
                    <Direction
                      onClick={() => setAdmin(!admin)}
                      className={`cursor-pointer transition-all ${
                        admin ? "rotate-90" : ""
                      }`}
                    />

                    {admin && (
                      <div className="w-full text-[#232323] font-extralight text-[14px] mt-[20px]">
                        <div className="mb-[20px]">
                          <p>Balance Overview</p>

                          <div className="bg-[#F5F7F9] mt-[14px] px-[29px] py-[13px] rounded-[7px]">
                            <div className="flex items-center gap-[8px] mb-[8px]">
                              <Checked />
                              <p>View Balance Overview</p>
                            </div>
                            <div className="flex items-center gap-[8px] mb-[8px]">
                              <Checked />
                              <p>View Transactions</p>
                            </div>
                          </div>
                        </div>

                        <div className="mb-[20px]">
                          <p>Connect Services</p>

                          <div className="bg-[#F5F7F9] mt-[14px] px-[29px] py-[13px] rounded-[7px]">
                            <div className="flex items-center gap-[8px] mb-[8px]">
                              <Checked />
                              <p>Add more Services</p>
                            </div>
                          </div>
                        </div>

                        <div className="mb-[20px]">
                          <p>Settings Module</p>

                          <div className="bg-[#F5F7F9] mt-[14px] px-[29px] py-[13px] rounded-[7px]">
                            <div className="flex items-center gap-[8px] mb-[8px]">
                              <Checked />
                              <p>View Profile</p>
                            </div>

                            <div className="flex items-center gap-[8px] mb-[8px]">
                              <Checked />
                              <p>View Security</p>
                            </div>

                            <div className="flex items-center gap-[8px] mb-[8px]">
                              <Checked />
                              <p>Update Security (Password)</p>
                            </div>

                            <div className="flex items-center gap-[8px] mb-[8px]">
                              <Checked />
                              <p>View notification</p>
                            </div>

                            <div className="flex items-center gap-[8px] mb-[8px]">
                              <Checked />
                              <p>View Team members</p>
                            </div>

                            <div className="flex items-center gap-[8px] mb-[8px]">
                              <Checked />
                              <p>Invite Team members</p>
                            </div>
                          </div>
                        </div>

                        <div className="mb-[20px]">
                          <p>POS Module</p>

                          <div className="bg-[#F5F7F9] mt-[14px] px-[29px] py-[13px] rounded-[7px]">
                            <div className="flex items-center gap-[8px] mb-[8px]">
                              <Checked />
                              <p>View POS Transactions</p>
                            </div>
                            <div className="flex items-center gap-[8px] mb-[8px]">
                              <Checked />
                              <p>View POS request</p>
                            </div>

                            <div className="flex items-center gap-[8px] mb-[8px]">
                              <Checked />
                              <p>Request POS</p>
                            </div>

                            <div className="flex items-center gap-[8px] mb-[8px]">
                              <Checked />
                              <p>View Dispute</p>
                            </div>

                            <div className="flex items-center gap-[8px] mb-[8px]">
                              <Checked />
                              <p>Update Dispute</p>
                            </div>
                          </div>
                        </div>
                        <div className="mb-[20px]">
                          <p>Sub-Accounts Module</p>

                          <div className="bg-[#F5F7F9] mt-[14px] px-[29px] py-[13px] rounded-[7px]">
                            <div className="flex items-center gap-[8px] mb-[8px]">
                              <Checked />
                              <p>Create subaccounts</p>
                            </div>
                            <div className="flex items-center gap-[8px] mb-[8px]">
                              <Checked />
                              <p>View Subaccounts</p>
                            </div>

                            <div className="flex items-center gap-[8px] mb-[8px]">
                              <Checked />
                              <p>View Subaccount Details</p>
                            </div>

                            <div className="flex items-center gap-[8px] mb-[8px]">
                              <Checked />
                              <p>View Subaccount Transactions</p>
                            </div>
                          </div>
                        </div>
                        <div className="mb-[20px]">
                          <p>Employee Loans Module</p>

                          <div className="bg-[#F5F7F9] mt-[14px] px-[29px] py-[13px] rounded-[7px]">
                            <div className="flex items-center gap-[8px] mb-[8px]">
                              <Checked />
                              <p>View Loan Overview</p>
                            </div>
                          
                            <div className="flex items-center gap-[8px] mb-[8px]">
                              <Checked />
                              <p>Create Employees</p>
                            </div>
                            <div className="flex items-center gap-[8px] mb-[8px]">
                              <Checked />
                              <p>View Employees</p>
                            </div>

                            <div className="flex items-center gap-[8px] mb-[8px]">
                              <Checked />
                              <p>View Employee Details</p>
                            </div>

                            <div className="flex items-center gap-[8px] mb-[8px]">
                              <Checked />
                              <p>View Employee Loan History</p>
                            </div>

                            <div className="flex items-center gap-[8px] mb-[8px]">
                              <Checked />
                              <p>Update Employee Details</p>
                            </div>
                            <div className="flex items-center gap-[8px] mb-[8px]">
                              <Checked />
                              <p>Delete Employees</p>
                            </div>
                            <div className="flex items-center gap-[8px] mb-[8px]">
                              <Checked />
                              <p>View Loan Book</p>
                            </div>
                           
                            <div className="flex items-center gap-[8px] mb-[8px]">
                              <Checked />
                              <p>View Loan Details</p>
                            </div>
                            <div className="flex items-center gap-[8px] mb-[8px]">
                              <Checked />
                              <p>Approve or decline loan requests</p>
                            </div>
                            {/* <div className="flex items-center gap-[8px] mb-[8px]">
                              <Checked />
                              <p>View Loan Configuration</p>
                            </div>
                            <div className="flex items-center gap-[8px] mb-[8px]">
                              <Checked />
                              <p>Update Loan Configuration</p>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="p-[22px] border-b border-[#EDF2FF] flex flex-wrap justify-between items-center text-[#232323]">
                    <p className="text-[14px] font-extralight">Manager</p>
                    <Direction
                      onClick={() => setManager(!manager)}
                      className={`cursor-pointer transition-all ${
                        manager ? "rotate-90" : ""
                      }`}
                    />

                    {manager && (
                      <div className="w-full text-[#232323] font-extralight text-[14px] mt-[20px]">
                        <div className="mb-[20px]">
                          <p>Balance Overview</p>

                          <div className="bg-[#F5F7F9] mt-[14px] px-[29px] py-[13px] rounded-[7px]">
                            <div className="flex items-center gap-[8px] mb-[8px]">
                              <Checked />
                              <p>View Balance Overview</p>
                            </div>
                            <div className="flex items-center gap-[8px] mb-[8px]">
                              <Checked />
                              <p>View Transactions</p>
                            </div>
                          </div>
                        </div>

                        <div className="mb-[20px]">
                          <p>Settings Module</p>

                          <div className="bg-[#F5F7F9] mt-[14px] px-[29px] py-[13px] rounded-[7px]">
                            <div className="flex items-center gap-[8px] mb-[8px]">
                              <Checked />
                              <p>View Profile</p>
                            </div>

                            <div className="flex items-center gap-[8px] mb-[8px]">
                              <Checked />
                              <p>View Security</p>
                            </div>

                            <div className="flex items-center gap-[8px] mb-[8px]">
                              <Checked />
                              <p>Update Security (Password)</p>
                            </div>
                          </div>
                        </div>

                        <div className="mb-[20px]">
                          <p>POS Module</p>

                          <div className="bg-[#F5F7F9] mt-[14px] px-[29px] py-[13px] rounded-[7px]">
                            <div className="flex items-center gap-[8px] mb-[8px]">
                              <Checked />
                              <p>View POS Transactions</p>
                            </div>
                            <div className="flex items-center gap-[8px] mb-[8px]">
                              <Checked />
                              <p>View POS request</p>
                            </div>

                            <div className="flex items-center gap-[8px] mb-[8px]">
                              <Checked />
                              <p>View Dispute</p>
                            </div>
                          </div>
                        </div>

                        <div className="mb-[20px]">
                          <p>Employee Loans Module</p>

                          <div className="bg-[#F5F7F9] mt-[14px] px-[29px] py-[13px] rounded-[7px]">
                            <div className="flex items-center gap-[8px] mb-[8px]">
                              <Checked />
                              <p>View Loan Overview</p>
                            </div>
                          
                            <div className="flex items-center gap-[8px] mb-[8px]">
                              <Checked />
                              <p>Create Employees</p>
                            </div>
                            <div className="flex items-center gap-[8px] mb-[8px]">
                              <Checked />
                              <p>View Employees</p>
                            </div>

                            <div className="flex items-center gap-[8px] mb-[8px]">
                              <Checked />
                              <p>View Employee Details</p>
                            </div>

                            <div className="flex items-center gap-[8px] mb-[8px]">
                              <Checked />
                              <p>View Employee Loan History</p>
                            </div>

                            <div className="flex items-center gap-[8px] mb-[8px]">
                              <Checked />
                              <p>Update Employee Details</p>
                            </div>
                            <div className="flex items-center gap-[8px] mb-[8px]">
                              <Checked />
                              <p>Delete Employees</p>
                            </div>
                            <div className="flex items-center gap-[8px] mb-[8px]">
                              <Checked />
                              <p>View Loan Book</p>
                            </div>
                            <div className="flex items-center gap-[8px] mb-[8px]">
                              <Checked />
                              <p>View Loan Details</p>
                            </div>
                          
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="p-[22px] border-b border-[#EDF2FF] flex flex-wrap justify-between items-center text-[#232323]">
                    <p className="text-[14px] font-extralight">Developer</p>
                    <Direction
                      onClick={() => setDeveloper(!developer)}
                      className={`cursor-pointer transition-all ${
                        developer ? "rotate-90" : ""
                      }`}
                    />

                    {developer && (
                      <div className="w-full text-[#232323] font-extralight text-[14px] mt-[20px]">
                        <div className="mb-[20px]">
                          <p>Balance Overview</p>

                          <div className="bg-[#F5F7F9] mt-[14px] px-[29px] py-[13px] rounded-[7px]">
                            <div className="flex items-center gap-[8px] mb-[8px]">
                              <Checked />
                              <p>View Balance Overview</p>
                            </div>
                            <div className="flex items-center gap-[8px] mb-[8px]">
                              <Checked />
                              <p>View Transactions</p>
                            </div>
                          </div>
                        </div>

                        <div className="mb-[20px]">
                          <p>Settings Module</p>

                          <div className="bg-[#F5F7F9] mt-[14px] px-[29px] py-[13px] rounded-[7px]">
                            <div className="flex items-center gap-[8px] mb-[8px]">
                              <Checked />
                              <p>View Profile</p>
                            </div>

                            <div className="flex items-center gap-[8px] mb-[8px]">
                              <Checked />
                              <p>View API Keys</p>
                            </div>

                            <div className="flex items-center gap-[8px] mb-[8px]">
                              <Checked />
                              <p>Update Security (Password)</p>
                            </div>
                          </div>
                        </div>

                        <div className="mb-[20px]">
                          <p>Employee Loans Module</p>

                          <div className="bg-[#F5F7F9] mt-[14px] px-[29px] py-[13px] rounded-[7px]">
                            <div className="flex items-center gap-[8px] mb-[8px]">
                              <Checked />
                              <p>View Loan Overview</p>
                            </div>
                            <div className="flex items-center gap-[8px] mb-[8px]">
                              <Checked />
                              <p>View Employees</p>
                            </div>
                            <div className="flex items-center gap-[8px] mb-[8px]">
                              <Checked />
                              <p>View Employee Details</p>
                            </div>
                            <div className="flex items-center gap-[8px] mb-[8px]">
                              <Checked />
                              <p>View Employee Loan History</p>
                            </div> 
                            <div className="flex items-center gap-[8px] mb-[8px]">
                              <Checked />
                              <p>View Loan Book</p>
                            </div>
                            <div className="flex items-center gap-[8px] mb-[8px]">
                              <Checked />
                              <p>View Loan Details</p>
                            </div>
                          
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="mt-[30px] flex justify-end gap-[8px]">
                <button
                  type="button"
                  onClick={() => {
                    setIsOpen(false);
                  }}
                  className="w-[156px] rounded-[4px] text-center bg-[#ECEDF0] p-[14px] text-[#232323]"
                >
                  Cancel
                </button>

                <button className="w-[156px] rounded-[4px] flex items-center justify-center gap-[5px] bg-[#2054D2] p-[14px] text-white">
                  <span>Send Invite</span> <span>{loading && <Loader />}</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </Drawer>

      {errorModal && (
        <InviteFailedModal
          showModal={errorModal}
          message={memberError}
          setCloseModal={setErrorModal}
        />
      )}

      {openModal && (
        <InviteFeedBackModal
          showModal={openModal}
          setCloseModal={setOpenModal}
          mail={email}
        />
      )}
    </>
  );
};

export default InviteUserDrawer;
