import { FC } from "react";

import { ReactComponent as PatternIcon } from "../../assets/images/svg/transfer-summary-pattern.svg";
import { ReactComponent as CloseIcon } from "../../assets/images/svg/hide-modal.svg";
import { useMerchantTransferFee } from "../../hooks/useMerchantTransferFee";
import { ModalContainer } from "../../modal/ModalContainer";
import { truncateString } from "../../helpers/formatter";
import { formatAmount } from "../../helpers/formatMoney";
import { SpinLoader } from "../../component/Loader/Loader.component";

type stateType = {
  showModal: boolean;
  setShowModal: (newState: boolean) => void;
  formInfo: any;
  onSubmit: any;
  loading: boolean;
  bank: any;
  type: string;
  source: string;
  loadFee: boolean;
  receiverDetails: any;
  resetMyForm: any;
  amount: any;
  amountToSend: string;
  setShowPinModal: (newState: boolean) => void;
  summaryAmount: number;
};

type listType = {
  name: string;
  value: any;
  className?: string;
  valueClassName?: string;
};

const TransferSummary: FC<stateType> = ({
  showModal,
  setShowModal,
  formInfo,
  onSubmit,
  loading,
  bank,
  type,
  source,
  loadFee,
  receiverDetails,
  resetMyForm,
  amount,
  amountToSend,
  setShowPinModal,
  summaryAmount,
}) => {
  const { data: transferFee, isLoading } = useMerchantTransferFee({
    loadFee,
    amount: amountToSend,
  });
  const ListItem: FC<listType> = ({
    name,
    value,
    className,
    valueClassName,
  }) => {
    return (
      <div className="flex gap-x-12 justify-between items-center border border-[#d9d9d9] px-4 py-3 rounded-[5px]">
        <p
          className={`text-[#5b5b5b] font-light text-sm capitalize ${className}`}
        >
          {name}
        </p>
        <div className={` ${valueClassName}`}>{value}</div>
      </div>
    );
  };
  return (
    <ModalContainer
      showModal={showModal}
      closeModal={() => {
        resetMyForm();
        setShowModal(false);
      }}
    >
      <div
        className={`w-full lg:w-[35vw] relative h-auto lg:max-h-[85vh] z-50 text-[#232323] flex flex-col rounded-[8px] bg-white pt-8 animation ${
          showModal
            ? "mt-0 opacity-100 visible"
            : "mt-[5rem] opacity-0 invisible"
        }`}
      >
        <div className="overflow-y-auto">
          <div
            onClick={() => {
              resetMyForm();
              setShowModal(false);
            }}
            className="absolute -top-8 -right-0 cursor-pointer"
          >
            <CloseIcon />
          </div>
          <p className="text-2xl font-medium pl-4 lg:pl-10">Transfer Summary</p>
          <p className="font-light text-aellaGray mt-1 px-4 lg:px-10">
            Kindly review the details below before proceeding to authorize the
            payment.
          </p>

          {type === "Bank" ? (
            <div className="rounded-[5px] bg-[#F4E3DB] text-[#232323] px-4 mx-4 lg:mx-10 py-4 mt-6 flex items-center justify-between gap-x-2 2xl:gap-x-6 2xl:px-8">
              <div className="flex items-center gap-x-3">
                <div className="rounded-[50%] h-12 w-12 shrink-0">
                  <img alt="bank logo" src={bank?.bank_logo} />
                </div>
                <div className="flex flex-col gap-y-1 truncate">
                  <p className="text-sm md:text-[1.2rem] capitalize">
                    {bank?.bank_name?.toLowerCase()}
                  </p>
                  <p className="font-light text-[0.75rem] md:text-base capitalize">
                    {truncateString(bank?.account_name?.toLowerCase(), 13)} (
                    {bank?.account_number})
                  </p>
                </div>
              </div>
              <div className="2xl:ml-8">
                <PatternIcon />
              </div>
            </div>
          ) : (
            <div className="rounded-[5px] bg-[#F4E3DB] text-[#232323] px-4 mx-4 lg:mx-10 py-4 mt-6 flex items-center justify-between gap-x-2 2xl:gap-x-6 2xl:px-8">
              <div className="flex items-center gap-x-2">
                <div className="rounded-[50%] shrink-0 h-14 w-14 flex justify-center items-center bg-[#D8A28F] text-aellaBlue text-2xl uppercase">
                  {(receiverDetails?.first_name?.charAt(0) || "") +
                    (receiverDetails?.last_name?.charAt(0) || "")}
                </div>

                <div className="flex flex-col gap-y-1">
                  <div className="font-normal text-sm ml-2 flex flex-col">
                    <p className="text-sm md:text-[1.2rem] capitalize">
                      {(receiverDetails?.first_name || "") +
                        " " +
                        (receiverDetails?.last_name || "")}
                    </p>
                    <p className="text-[0.7rem] md:text-base font-light">
                      {receiverDetails?.phone_number}
                    </p>
                  </div>
                </div>
              </div>
              <div className="ml-0 2xl:ml-8">
                <PatternIcon />
              </div>
            </div>
          )}

          <div className="mt-6 flex flex-col gap-y-2 px-4 lg:px-10">
            <ListItem
              name="Amount"
              value={`N${formatAmount(
                summaryAmount || parseFloat(amountToSend?.replace(/,/g, ""))
              )}`}
            />

            <ListItem
              name="Fee"
              value={
                isLoading ? (
                  <SpinLoader />
                ) : type === "Bank" ? (
                  `N${transferFee?.amount}`
                ) : (
                  "N/A"
                )
              }
            />

            <ListItem
              name="Source"
              value={source?.toLowerCase()}
              valueClassName="capitalize"
            />

            <ListItem
              name="Narration"
              value={formInfo.narration || "-"}
              className="w-[40%]"
            />
          </div>

          <div className="bg-[#ECEDF0] flex justify-end py-4 items-center gap-x-8 mt-10 px-4 lg:px-10">
            <p
              onClick={() => {
                resetMyForm();
                setShowModal(false);
              }}
              className="text-[#5b5b5b] cursor-pointer"
            >
              Cancel
            </p>
            <button
              onClick={() => {
                setShowModal(false);
                setShowPinModal(true);
              }}
              type="button"
              className="py-3 px-4 bg-aellaBlue text-white rounded-[4px] cursor-pointer"
            >
              Proceed
            </button>
          </div>
        </div>
      </div>
    </ModalContainer>
  );
};

export default TransferSummary;
