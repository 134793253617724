import { FC, useState, useEffect } from "react";
import { useQueryClient } from "react-query";
import { v4 as uuidv4 } from "uuid";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";

import { ReactComponent as FailedIcon } from "../../assets/images/svg/faint-error-icon.svg";
import { ReactComponent as ErrorIcon } from "../../assets/images/svg/deep-error-icon.svg";
import { ReactComponent as CheckIcon } from "../../assets/images/svg/check-success-icon.svg";
import { ReactComponent as BlackInfoIcon } from "../../assets/images/svg/black-info-icon.svg";
import { ReactComponent as RemoveIcon } from "../../assets/images/svg/remove-icon.svg";
import { ReactComponent as SearchIcon } from "../../assets/images/svg/search-icon.svg";
import { ReactComponent as RefreshIcon } from "../../assets/images/svg/gray-refresh-icon.svg";
import { ReactComponent as EditIcon } from "../../assets/images/svg/gray-edit-icon.svg";
import { ReactComponent as CloseModalIcon } from "../../assets/images/svg/close-icon.svg";
import NairaIcon from "../../assets/images/svg/input-naira.svg";
import BankIcon from "../../assets/images/svg/input-bank-icon.svg";
import InputUserIcon from "../../assets/images/svg/input-user-icon.svg";
import InputDotsIcon from "../../assets/images/svg/input-dots-icon.svg";
import { ReactComponent as NoteEdit } from "../../assets/images/svg/note-edit.svg";
import { ReactComponent as NoteInfo } from "../../assets/images/svg/note-info.svg";
import { ReactComponent as CloseIcon } from "../../assets/images/svg/close-icon.svg";

import BlueButton from "../../component/Button/BlueButton";
import { ModalContainer } from "../../modal/ModalContainer";
import Pin from "./Pin";
import BulkTransferStatus from "./BulkTransferStatus";
import CustomInputField from "../../component/CustomHTMLElements/CustomInputField";
import CustomSelectDropdown from "../../component/CustomHTMLElements/CustomSelectDropdown";
import { useAllWithdrawalBanks } from "../../hooks/useAllWithdrawalBanks";
import { apiEndpoints } from "../../apis/apiEndpoints";
import CONFIG from "../../helpers/config";
import { getData, postData } from "../../apis/apiMethods";
import { errorHandler } from "../../helpers/errorHandler";
import { toast } from "react-toastify";
import { SecondaryLoader } from "../../component/Loader/SecondaryLoader";
import { SpinLoader } from "../../component/Loader/Loader.component";
import { truncateString } from "../../helpers/formatter";
import { formatMoney } from "../../helpers/formatMoney";
import { useWalletBalance } from "../../hooks/useWalletBalance";
import { useMerchantDetails } from "../../hooks/useMerchantDetails";
import { getFromLocalStorage } from "../../helpers/localStorage";
import { LOCAL_STORAGE_KEYS } from "../../helpers/localStorageKeys";
import { useAgentList } from "../../hooks/useAgentList";

type BulkDataProps = {
  showExit: boolean;
  setShowExit: (newState: boolean) => void;
  setUploadStage: (newState: string) => void;
  showRemoveModal: boolean;
  setShowRemoveModal: (newState: boolean) => void;
  confirmation: boolean;
  setConfirmation: (newState: boolean) => void;
  showPin: boolean;
  setShowPin: (newState: boolean) => void;
  setSelectedTransfer: (newState: object) => void;
  setBulkFile: (newState: object) => void;
  status: string;
  setStatus: (newState: string) => void;
  showStatus: boolean;
  setShowStatus: (newState: boolean) => void;
  setCurrentFile: (newState: string) => void;
  setExt: (newState: string) => void;
  mockData?: any;
  setMockData?: any;
  formInfo: any;
  setFormInfo: (newState: object) => void;
  register: any;
  handleSubmit: any;
  setValue: any;
  watch: any;
  errors: any;
  reset: any;
  resetForm: any;
  currentFile: any;
  setViewTransfer: (newState: boolean) => void;
  selectedAccount: string;
  setSelectedAccount: (newState: string) => void;
};

const PreviewBulkData: FC<BulkDataProps> = ({
  showExit,
  setShowExit,
  setUploadStage,
  showRemoveModal,
  setShowRemoveModal,
  confirmation,
  setConfirmation,
  showPin,
  setShowPin,
  setSelectedTransfer,
  setBulkFile,
  status,
  setStatus,
  showStatus,
  setShowStatus,
  setCurrentFile,
  setExt,
  formInfo,
  setFormInfo,
  register,
  handleSubmit,
  setValue,
  watch,
  errors,
  reset,
  resetForm,
  currentFile,
  setViewTransfer,
  selectedAccount,
  setSelectedAccount,
}) => {
  const [toUpdate, setToUpdate] = useState<any>({});
  const [mockData, setMockData] = useState(
    currentFile?.map((mock: any, index: any) => {
      return {
        id: uuidv4(),
        mId: index + 1,
        accountName: mock.AccountName,
        bank: mock.BankName,
        amount: mock.Amount,
        accountNumber: mock.AccountNumber,
        narration: mock.Narration,
      };
    })
  );

  const [searchTerm, setSearchTerm] = useState("");
  const [transactionData, setTransactionData] = useState({});
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [bankName, setBankName] = useState<any>("");
  const [selectedItems, setSelectedItems] = useState<any>([]);
  const [allChecked, setAllChecked] = useState(false);
  const [accountName, setAccountName] = useState("");
  const [bank, setBank] = useState("");
  const [codes, setCodes] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [submittingBulkTransfer, setSubmittingBulkTransfer] = useState(false);
  const [bankLogo, setBankLogo] = useState("");
  const [pin, setPin] = useState("");
  const [bankNameLoading, setBankNameLoading] = useState(false);
  const [invalidMessage, setInvalidMessage] = useState(false);
  const [invalidBank, setInvalidBank] = useState(false);
  const [invalidAmount, setInvalidAmount] = useState(false);
  const [invalidNumber, setInvalidNumber] = useState(false);
  const [hovered, setHovered] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showSelectModal, setShowSelectModal] = useState(false);
  const [error, setError] = useState(false);

  const queryClient = useQueryClient();
  const userId = getFromLocalStorage(LOCAL_STORAGE_KEYS.USERID);

  const { data: merchantDetails } = useMerchantDetails({ userId });
  const { data: bankData } = useAllWithdrawalBanks();
  const { data: agentList, isLoading: subAccountDetailsLoading } =
    useAgentList();

  const updateObject = (id: any, newData: any) => {
    const updatedArray = accountList?.map((obj: any) => {
      if (obj.id === id) {
        return { ...obj, ...newData };
      } else {
        return obj;
      }
    });
    setAccountList(updatedArray);
  };
  const bk = watch("bankName");
  const accName = watch("accountName");
  const accNo = watch("accountNumber");

  const handleUpdate = () => {
    const { accountNumber, amount } = formInfo;
    const updatedData = {
      id: toUpdate?.id,
      account_name: accountName || accName,
      account_number: accNo || accountNumber,
      bank_name: bk,
      bank_logo:
        bankLogo ||
        `https://static.aellacredit.com/files/banks/${
          bank?.toLowerCase()?.split(" ")[0]
        }.png`,
      amount: amount || toUpdate?.amount,
      status: "Success",
    };

    updateObject(toUpdate?.id, updatedData);
    setShowUpdateModal(false);
    setToUpdate({});
    setValue("amount", "");
    setValue("bankName", "");
    setValue("accountNumber", "");
    setValue("accountName", "");
    setBank("");
    resetForm();
  };
  const validateBulkDetails = async () => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars

      const sortCodes = await getData(
        `${CONFIG.BASE_URL2}${apiEndpoints.GET_BANK_SORT_CODES}`
      );
      setCodes(sortCodes);

      const res: any = await postData(
        `${CONFIG.BASE_URL2}${apiEndpoints.RESOLVE_BULK_ACCOUNTS}`,
        currentFile
          ?.map((mock: any) => {
            return {
              id: uuidv4(),
              accountName: mock.AccountName,
              bank: mock.BankName,
              amount: mock.Amount,
              accountNumber: mock.AccountNumber,
            };
          })
          ?.map((mock: any) => {
            return {
              id: mock?.id,
              accountName: mock.accountName,
              bank: mock.bank,
              amount: mock.amount,
              accountNumber: mock.accountNumber?.toString(),
              sortCode: sortCodes?.data?.find(
                (elem: any) =>
                  elem.name?.toLowerCase() === mock.bank?.toLowerCase()
              )?.sort_code,
            };
          })
          ?.map((data: any) => {
            return {
              sort_code: data?.sortCode || "0",
              account_number: data?.accountNumber?.toString(),
            };
          })
      );

      setAccountList(
        res?.data?.map((account: any, index: any) => {
          if (account?.status === true) {
            return {
              id: uuidv4(),
              mId: index + 1,
              account_name: account?.data?.account_name,
              account_number: account?.data?.account_number,
              bank_id: account?.data?.bank_id,
              bank_logo: account?.data?.bank_logo,
              bank_name: account?.data?.bank_name,
              status:
                account?.data?.account_name &&
                account?.data?.account_number &&
                account?.data?.bank_name &&
                mockData
                  ?.find((i: any) => i?.mId === index + 1)
                  ?.amount?.toString()
                  .replace(/[^0-9\.]/g, "")
                  ? "Success"
                  : "Failed",
              amount: mockData
                ?.find((i: any) => i?.mId === index + 1)
                ?.amount?.toString()
                .replace(/[^0-9\.]/g, ""),
              narration: mockData?.find((i: any) => i?.mId === index + 1)
                ?.narration,
            };
          } else
            return {
              id: uuidv4(),
              mId: index + 1,
              account_name: "",
              account_number: account?.account_number || "",
              bank_id: "",
              bank_logo: "",
              bank_name: "",
              status: "Failed",
              amount: mockData
                ?.find((i: any) => i?.mId === index + 1)
                ?.amount?.toString()
                .replace(/[^0-9\.]/g, ""),
              narration: mockData?.find((i: any) => i?.mId === index + 1)
                ?.narration,
            };
        })
      );
    } catch (error) {
      // toast.error(errorHandler(error));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const loadDetails = async () => {
      await validateBulkDetails();
    };
    loadDetails();
  }, []);

  //validate banks
  const validateBankDetails = async () => {
    setBankNameLoading(true);
    try {
      const reqBody = {
        account_number: accNo,
        bank_id: bankData?.find((i: any) => i?.name === bk)?.id,
      };
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const res: any = await postData(
        `${CONFIG.BASE_URL2}${apiEndpoints.VALIDATE_BANKS}`,
        reqBody
      );
      setBank(res?.data?.bank_name);
      setAccountName(res?.data?.account_name);
      setBankLogo(res?.data?.bank_logo);
    } catch (error) {
      setAccountName("");
      setBank("");
      setBankLogo("");
    } finally {
      setBankNameLoading(false);
    }
  };

  useEffect(() => {
    validateBankDetails();
  }, [bankName, accNo, bk]);

  useEffect(() => {
    setValue("accountName", accountName);
  }, [accountName, bankName]);

  const handleCheckboxClick = (itemId: any) => {
    if (selectedItems.includes(itemId)) {
      setSelectedItems(selectedItems.filter((id: any) => id !== itemId));
    } else {
      setSelectedItems([...selectedItems, itemId]);
    }
  };

  const handleSelectAll = () => {
    if (selectedItems?.length === accountList?.length) {
      setSelectedItems([]);
    } else setSelectedItems(accountList?.map((i: any) => i?.id));
  };

  const deselectAll = () => {
    setSelectedItems([]);
  };
  const [accountList, setAccountList] = useState<any>([]);

  const searchToReturn = accountList?.filter(
    (item: any) =>
      item?.account_name?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
      item?.account_number?.toString()?.includes(searchTerm?.toLowerCase()) ||
      item?.bank_name?.toLowerCase()?.includes(searchTerm?.toLowerCase())
  );

  const [searchList, setSearchList] = useState(searchToReturn);
  const deleteUser = () => {
    const newList = searchToReturn?.filter(
      (i: any) => !selectedItems?.includes(i?.id)
    );
    setSearchList(newList);
    setMockData(newList);
    setAccountList(newList);
  };

  const bulkData = accountList?.map((data: any) => {
    return {
      amount: Number(data?.amount),
      user_id: merchantDetails?.merchant?.id,
      narration: data?.narration,
      account_number: data?.account_number,
      account_name: data?.account_name,
      source_wallet_id: selectedAccount
        ? selectedAccount
        : merchantDetails?.merchant?.wallet_id,
      save_detail: true,
      sort_code: codes?.data?.find(
        (elem: any) =>
          elem.name?.toLowerCase() === data.bank_name?.toLowerCase()
      )?.sort_code,
      pin,
    };
  });

  const totalAmount = bulkData?.reduce(
    (accumulator: any, currentValue: any) => accumulator + currentValue?.amount,
    0
  );

  const initiateTransfer = async () => {
    if (pin.length !== 4) {
      toast.error("Transaction pin is required");
      return;
    }

    try {
      setTransactionData({});
      setSubmittingBulkTransfer(true);
      const res: any = await postData(
        `${CONFIG.BASE_URL2}${apiEndpoints.INITIATE_BULK_TRANSFER}`,
        bulkData
      );
      setSubmittingBulkTransfer(false);
      setTransactionData(res);
      setShowPin(false);
      setShowStatus(true);
      // setPin("");
    } catch (error: any) {
      setTransactionData(error?.res);
      if (
        error?.response?.data?.message?.toLowerCase() ===
        "transaction pin validation failed"
      ) {
        toast.error("Incorrect Pin");
      } else toast.error(errorHandler(error));
      setSubmittingBulkTransfer(false);
    } finally {
      queryClient.invalidateQueries("getBulkTransferList");
      queryClient.invalidateQueries("getGroupedTransferList");
    }
  };

  useEffect(() => {
    if (!showPin && !showStatus) {
      setPin("");
    }
  }, [showPin, showStatus]);

  const totalSuccessful = accountList?.filter(
    (x: any) => x?.status?.toLowerCase() === "success"
  )?.length;

  const failedResolves = accountList?.filter(
    (x: any) => x?.status?.toLowerCase() === "failed"
  );

  const reportToDownload = failedResolves?.map((x: any) => {
    return {
      Amount: mockData?.find((i: any) => i?.mId === x?.mId)?.amount || "",
      AccountName:
        mockData?.find((i: any) => i?.mId === x?.mId)?.accountName || "",
      AccountNumber:
        mockData?.find((i: any) => i?.mId === x?.mId)?.accountNumber || "",
      BankName: mockData?.find((i: any) => i?.mId === x?.mId)?.bank || "",
      Narration: x?.narration || "",
    };
  });

  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(reportToDownload);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    FileSaver.saveAs(data, `Aella Report-${new Date().toLocaleString()}.xlsx`);
  };

  const { data: totalWalletData, isLoading: totalWalletDataLoading } =
    useWalletBalance({ merchantID: merchantDetails?.merchant?.id });

  const selectedAccountDetails = agentList?.data?.find((agent: any) => {
    return agent.wallet_id === selectedAccount;
  });

  const GoBackModal = () => {
    return (
      <ModalContainer
        showModal={showExit}
        closeModal={() => {
          setShowExit(false);
        }}
      >
        <div className="w-full lg:w-[35vw] relative right-0 h-auto min-h-[30vh] max-h-[90vh] z-50 flex flex-col items-center justify-center text-center p-10 rounded-[8px] bg-white">
          <p className="text-aellaBlack text-2xl">Confirmation Request</p>
          <p className="text-[#5B5B5B] font-light mt-4">
            Are you sure you want to go back? <br />
            The data provided will be lost and cannot be recovered.
          </p>
          <BlueButton
            onClick={() => {
              setShowExit(false);
              setUploadStage("initial");
              setBulkFile({});
              setCurrentFile("");
              setExt("");
              setSelectedAccount("");
            }}
            title="Go Back"
            className="h-10 mt-10 font-light"
          />
          <div
            className="absolute -top-8 -right-0 cursor-pointer"
            onClick={() => setShowExit(false)}
          >
            <CloseModalIcon />
          </div>
        </div>
      </ModalContainer>
    );
  };

  const RemoveItemModal = () => {
    return (
      <ModalContainer
        showModal={showRemoveModal}
        closeModal={() => {
          setShowRemoveModal(false);
        }}
      >
        <div
          className={`w-full lg:w-[35vw] relative right-0 min-h-[30vh] h-auto max-h-[90vh] z-50 flex flex-col items-center justify-center text-center p-10 rounded-[8px] bg-white animation ${
            showRemoveModal
              ? "mt-0 opacity-100 visible"
              : "mt-[5rem] opacity-0 invisible"
          }`}
        >
          <p className="text-aellaBlack text-2xl">Remove Transfer From List</p>
          <p className="text-[#5B5B5B] font-light mt-4">
            Are you sure you want to remove this transfer off the list? <br />{" "}
            Once done, this cannot be reversed.
          </p>

          <button
            onClick={() => {
              //action here
              deleteUser();
              setShowRemoveModal(false);
              setSelectedItems([]);
            }}
            type="button"
            className="bg-[#EB5757] text-white text-sm font-light mt-10 h-10 w-[90px] rounded-[4px]"
          >
            Remove
          </button>
          <div
            className="absolute -top-8 -right-0 cursor-pointer"
            onClick={() => setShowRemoveModal(false)}
          >
            <CloseModalIcon />
          </div>
        </div>
      </ModalContainer>
    );
  };

  const ConfirmationModal = () => {
    return (
      <ModalContainer
        showModal={confirmation}
        closeModal={() => {
          setConfirmation(false);
        }}
      >
        <div
          className={`w-full lg:w-[35vw] relative right-0 min-h-[30vh] h-auto max-h-[90vh] z-50 flex flex-col items-center justify-center text-center p-10 rounded-[8px] bg-white  animation ${
            confirmation
              ? "mt-0 ml-0 opacity-100 visible"
              : "mt-[5rem] ml-[5rem] opacity-0 invisible"
          }`}
        >
          <p className="text-aellaBlack text-2xl">Confirmation Request</p>
          <p className="text-[#5B5B5B] font-light mt-4">
            Are you sure you want to proceed with this bulk transfer? <br />{" "}
            Once you do, it cannot be reversed.
          </p>
          <BlueButton
            onClick={() => {
              //action here
              setConfirmation(false);
              setShowPin(true);
            }}
            title="Yes, Proceed With Transfer"
            className="h-10 mt-10 font-light"
          />
          <div
            className="absolute -top-8 -right-0 cursor-pointer"
            onClick={() => setConfirmation(false)}
          >
            <CloseModalIcon />
          </div>
        </div>
      </ModalContainer>
    );
  };

  const selectAccountModal = () => {
    return (
      <ModalContainer
        showModal={showModal}
        closeModal={() => {
          setShowModal(false);
          setSelectedAccount("");
        }}
      >
        <div
          className={`w-full lg:w-[35vw] relative right-0 min-h-[30vh] h-auto max-h-[90vh] z-50 flex flex-col items-center justify-center text-center p-4 lg:p-8 rounded-[8px] bg-white animation ${
            showModal
              ? "mt-0 opacity-100 visible"
              : "mt-[5rem] opacity-0 invisible"
          }`}
        >
          <div
            onClick={() => {
              setShowSelectModal(false);
              setShowModal(false);
              setSelectedAccount("");
            }}
            className="absolute -top-8 right-0 cursor-pointer"
          >
            <CloseIcon />
          </div>
          <div className="relative mb-10 w-full">
            <img
              src={InputDotsIcon}
              alt="input icon"
              className="absolute top-[2.6rem] lg:top-[2.6rem] left-[12px] z-10"
            />
            <div className="w-full flex justify-start">
              <label htmlFor="" className="font-light text-aellaBlack mb-1">
                Source
              </label>
            </div>
            <input
              type="text"
              disabled
              className="h-12 w-full bg-[#F1F1F1] rounded-[4px] pl-10 text-[#232323] font-light border border-aellaLightGray capitalize"
              value={
                selectedAccount
                  ? agentList?.data?.find(
                      (agent: any) => agent?.wallet_id === selectedAccount
                    )?.name ||
                    agentList?.data?.find(
                      (agent: any) => agent?.wallet_id === selectedAccount
                    )?.trading_name
                  : "General Wallet"
              }
              // label="Source"
            />
            <div className="text-base text-[#D8A28F] mt-1 -mb-4 flex justify-between gap-x-1 w-full">
              <div className="flex gap-x-2 items-center flex-1 flex-wrap">
                <p className="font-normal">Wallet Balance:</p>
                <div className="font-light tracking-wide">
                  {totalWalletDataLoading || subAccountDetailsLoading ? (
                    <SpinLoader />
                  ) : (
                    `N${formatMoney(
                      selectedAccount
                        ? selectedAccountDetails?.balance
                        : (totalWalletData?.available_balance &&
                            totalWalletData?.available_balance) ||
                            (totalWalletData?.balance &&
                              totalWalletData?.balance) ||
                            "0"
                    )}`
                  )}
                </div>
              </div>
              <p
                onClick={() => {
                  setShowModal(false);
                  setShowSelectModal(true);
                }}
                className="text-aellaBlue font-normal text-sm cursor-pointer"
              >
                Select Account
              </p>
            </div>
          </div>
          <div className="flex justify-end w-full mt-4">
            <BlueButton
              onClick={() => {
                setShowModal(false);
                setConfirmation(true);
              }}
              title="Proceed"
            />
          </div>
        </div>
      </ModalContainer>
    );
  };

  const accountListModal = () => {
    return (
      <ModalContainer
        showModal={showSelectModal}
        closeModal={() => {
          setShowSelectModal(false);
          setShowModal(true);
          setSelectedAccount("");
        }}
      >
        <div className="w-full lg:w-[35vw] relative h-auto lg:max-h-[85vh] z-50 text-[#232323] flex flex-col rounded-[8px] bg-white pt-8">
          <div
            onClick={() => {
              setShowSelectModal(false);
              setShowModal(true);
              setSelectedAccount("");
            }}
            className="absolute -top-8 right-0 cursor-pointer"
          >
            <CloseIcon />
          </div>
          <div>
            <p className="text-2xl font-medium pl-4 lg:pl-10">Select Account</p>
            <p className="font-light text-aellaGray mt-1 px-4 lg:px-10">
              Kindly select an account to be debited for this transfer
            </p>
            <form className="mt-6">
              <select
                value={selectedAccount}
                onChange={(e) => {
                  setSelectedAccount(e.target.value);
                  setError(false);
                }}
                id=""
                className={`mx-4 lg:mx-10 w-[80%] outline-none bg-white appearance-none border px-4 placeholder:text-[#DADADA] focus:border-aellaBlue  font-light rounded-[3px] py-2 text-aellaBlack ${
                  error ? "border-[#EB5757]" : "border-aellaLightGray"
                }`}
              >
                <option value="">Select a sub-account</option>
                {agentList?.data?.map((agent: any) => {
                  return (
                    <option key={agent?.wallet_id} value={agent?.wallet_id}>
                      {agent?.name}
                    </option>
                  );
                })}
              </select>
              <p
                className={`text-[#DD4F05] text-sm px-4 lg:px-10 font-light mt-1 ${
                  error ? "visible" : "invisible"
                }`}
              >
                Please select an account
              </p>
              <div className="bg-[#ECEDF0] flex justify-end py-4 items-center gap-x-8 mt-6 px-4 lg:px-10 rounded-b-[8px]">
                <p
                  onClick={() => {
                    setSelectedAccount("");
                    setShowSelectModal(false);
                    setShowModal(true);
                  }}
                  className="text-[#5b5b5b] cursor-pointer"
                >
                  Cancel
                </p>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    if (!selectedAccount) {
                      setError(true);
                      return;
                    }
                    setShowSelectModal(false);
                    setShowModal(true);
                  }}
                  type="submit"
                  className="py-2 px-4 bg-aellaBlue text-white rounded-[4px] cursor-pointer"
                >
                  Select
                </button>
              </div>
            </form>
          </div>
        </div>
      </ModalContainer>
    );
  };

  const updateTransferModal = () => {
    return (
      <ModalContainer
        showModal={showUpdateModal}
        closeModal={() => {
          setShowUpdateModal(false);
          resetForm();
          setToUpdate({});
          setValue("amount", "");
          setValue("bankName", "");
          setValue("accountNumber", "");
          setValue("accountName", "");
          setBank("");
        }}
      >
        <div className="w-full lg:w-[35vw] relative right-0 h-auto max-h-[90vh] z-50 flex flex-col  pt-10 pb-6 px-8 rounded-[8px] bg-white">
          <p className="text-[#232323] text-2xl text-left">Update Transfer</p>
          <p className="text-[#7b7b7b] mt-2 font-light">
            Kindly update the transfer details below to ensure a successful
            transaction
          </p>

          <form className="overflow-y-auto hide_scrollbar">
            <div className="flex flex-col gap-y-4 mt-8">
              <div className="relative">
                <img
                  src={NairaIcon}
                  alt="input icon"
                  className="absolute top-8 lg:top-[26px] -left-[2px] z-10 h-12 w-10"
                />
                <CustomInputField
                  type="text"
                  addPadding={true}
                  min={1}
                  placeholder="0.00"
                  label="Amount"
                  defaultValue={toUpdate?.amount}
                  errors={errors.amount}
                  errorMessage="Amount is required"
                  showRequiredIcon={false}
                  {...register("amount", {
                    onChange: (e: any) => {
                      setFormInfo({ ...formInfo, amount: e.target.value });
                      setValue(
                        "amount",
                        e.target.value
                          .replace(/[^0-9\.]/g, "")
                          // ?.replace(/\./g, "")
                          ?.replace("..", ".")
                          ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      );
                    },
                  })}
                />

                <div className="relative">
                  <img
                    src={BankIcon}
                    alt="input icon"
                    className="absolute top-11 left-[12px] z-10"
                  />

                  <CustomSelectDropdown
                    addPadding={true}
                    label="Destination Bank Name"
                    errors={!bankName ? errors.bankName : null}
                    errorMessage={!bankName ? "Bank is required" : ""}
                    {...register("bankName")}
                    onChange={(e: any) => {
                      setValue("bankName", e.target.value);
                      setBankName(e.target.value);
                      setFormInfo({ ...formInfo, bankName: e.target.name });
                    }}
                    selectClassName="relative"
                  >
                    <option value="">Select Bank</option>
                    {bankData?.map((data: any) => {
                      const { id, name } = data;
                      return (
                        <option key={id} value={name}>
                          {name}
                        </option>
                      );
                    })}
                  </CustomSelectDropdown>

                  <div className="relative mb-4">
                    <img
                      src={InputDotsIcon}
                      alt="input icon"
                      className="absolute top-[2.8rem] lg:top-10 left-[12px] z-10"
                    />
                    <CustomInputField
                      // disabled={!bankName}
                      type="text"
                      addPadding={true}
                      min={1}
                      placeholder="Enter account number"
                      label="Destination Account"
                      defaultValue={toUpdate?.accountNumber}
                      errors={errors.accountNumber}
                      showRequiredIcon={false}
                      errorMessage="Please enter account number"
                      {...register("accountNumber", {
                        onChange: (e: any) => {
                          setFormInfo({
                            ...formInfo,
                            accountNumber: e.target.value,
                          });
                        },
                      })}
                    />
                  </div>

                  <div className="w-full flex gap-x-2 items-center">
                    <div className="relative w-full">
                      <img
                        src={InputUserIcon}
                        alt="input icon"
                        className="absolute top-10 left-[12px] z-10"
                      />
                      <CustomInputField
                        type="text"
                        // value={accountName}
                        addPadding={true}
                        placeholder=""
                        readOnly
                        defaultValue={
                          toUpdate?.accountName
                          // ? toUpdate.accountName
                          // : accountName
                        }
                        label="Account Name"
                        errors={errors.accountName}
                        showRequiredIcon={false}
                        {...register("accountName", {
                          onChange: (e: any) => {
                            setFormInfo({
                              ...formInfo,
                              accountName: e.target.value,
                            });
                          },
                        })}
                      />
                    </div>
                    {bankNameLoading && <SpinLoader />}
                  </div>
                  {!bankNameLoading && !accName && (
                    <p className="text-[#DD4F05] -mt-5 font-light">
                      Account not found.
                    </p>
                  )}
                </div>
              </div>
            </div>

            <div className="flex justify-end items-center gap-x-10">
              <p
                onClick={() => {
                  setShowUpdateModal(false);
                  setToUpdate({});
                  setShowUpdateModal(false);
                  resetForm();
                  setValue("amount", "");
                  setValue("bankName", "");
                  setValue("accountNumber", "");
                  setValue("accountName", "");
                  setBank("");
                }}
                className="text-[#5B5B5B] cursor-pointer"
              >
                Cancel
              </p>
              <BlueButton
                onClick={handleSubmit(handleUpdate)}
                title="Update"
                className="h-10 font-light"
              />
            </div>
          </form>

          <div
            className="absolute -top-8 -right-0 cursor-pointer"
            onClick={() => {
              setShowUpdateModal(false);
              setToUpdate({});
              setShowUpdateModal(false);
              resetForm();
              setValue("amount", "");
              setValue("bankName", "");
              setValue("accountNumber", "");
              setValue("accountName", "");
              setBank("");
            }}
          >
            <CloseModalIcon />
          </div>
        </div>
      </ModalContainer>
    );
  };

  useEffect(() => {
    if (selectedItems?.length === accountList?.length) {
      setAllChecked(true);
    }
    setAllChecked(false);
  }, [selectedItems, accountList]);

  return (
    <div className="w-screen md:w-full">
      <Pin
        showModal={showPin}
        setShowModal={setShowPin}
        pin={pin}
        setPin={setPin}
        setSelectedTransfer={setSelectedTransfer}
        loading={submittingBulkTransfer}
        onSubmit={initiateTransfer}
      />
      <BulkTransferStatus
        showModal={showStatus}
        setShowModal={setShowStatus}
        status={status}
        setStatus={setStatus}
        transactionData={transactionData}
        setTransactionData={setTransactionData}
        setUploadStage={setUploadStage}
        setViewTransfer={setViewTransfer}
        onSubmit={initiateTransfer}
        loading={submittingBulkTransfer}
        totalAmount={totalAmount}
        setSelectedAccount={setSelectedAccount}
      />
      {updateTransferModal()}
      {selectAccountModal()}
      {accountListModal()}
      <ConfirmationModal />
      <RemoveItemModal />
      <GoBackModal />
      <div className="mx-4 md:mx-10 mb-32 pt-8 overflow-x-auto md:overflow-x-hidden hide_scrollbar">
        <div className="flex justify-between items-center w-full flex-col gap-x-6 gap-y-4 md:flex-row">
          <p className="text-2xl font-light text-[#232323] self-start">
            Preview Uploaded Data
          </p>

          <div
            className={`flex flex-wrap px-3 md:px-5 gap-x-2 items-center py-2 text-[#232323] bg-[#FDEFF1] rounded-[4px] border border-[#D95263] font-light text-center ${
              reportToDownload?.length ? "visible" : "invisible"
            }`}
          >
            <NoteInfo />
            <strong>Please Note:</strong> To rectify the error/s found, click
            the
            <span className="flex -mx-1 items-center decoration underline underline-offset-1">
              edit icon <NoteEdit className="" />
            </span>
            and provide accurate information
          </div>
        </div>
        <div className="w-[280%] sm:w-[180%] md:w-full overflow-x-auto hide_scrollbar">
          <div className="bg-[#EEF1F5] h-12 mt-5 px-6 flex items-center justify-between ">
            <div className="flex items-center gap-x-4 text-[#232323]">
              <BlackInfoIcon />
              <p className="font-medium border-r-[2px] border-[#BFC7D9] pr-4">
                Uploaded transfers:{" "}
                <span className="font-light">{accountList?.length}</span>
              </p>
              <div className="flex items-center border-r-[2px] border-[#BFC7D9] pr-4">
                <CheckIcon />
                <p className="text-[#41986D] ml-2 font-light text-sm">
                  {!loading ? totalSuccessful : "-"} Successful
                </p>
              </div>
              <div className="flex items-center">
                <FailedIcon />
                <p className="text-[#D1293D] ml-2 font-light text-sm">
                  {!loading ? accountList?.length - totalSuccessful : "-"}{" "}
                  Failed
                </p>
              </div>
            </div>

            <p
              onClick={() => {
                exportToExcel();
              }}
              className={`text-aellaBlue underline decoration-aellaBlue underline-offset-2 font-medium cursor-pointer ${
                reportToDownload?.length ? "visible" : "invisible"
              }`}
            >
              Download Report
            </p>
          </div>
          <div className="bg-white pt-3 pb-6 px-6">
            <div className="h-10 w-full flex items-center justify-between">
              <div
                className={`flex items-center w-auto gap-x-4 ${
                  selectedItems?.length ? "visible" : "invisible"
                }`}
              >
                <p className="text-[#232323] text-sm pr-4 border-r-[2px] border-[#BFC7D9]">
                  {selectedItems?.length} Selected
                </p>
                <button
                  onClick={() => {
                    if (selectedItems?.length) {
                      setShowRemoveModal(true);
                    }
                  }}
                  type="button"
                  className="bg-[#D1293D] text-white text-sm font-light h-8 w-[90px] rounded-[4px]"
                >
                  Remove
                </button>
                <RemoveIcon
                  className="cursor-pointer"
                  onClick={() => deselectAll()}
                />
              </div>
              <div
                className={`w-[400px] flex items-center gap-x-3 px-3 h-10 rounded-[4px] border border-[#EDEFF5]`}
              >
                <SearchIcon />
                <input
                  value={searchTerm}
                  onChange={(e: any) => setSearchTerm(e.target.value)}
                  className="bg-inherit h-full placeholder:text-[#5B5B5B] text-aellaBlack flex-1 text-sm placeholder:text-sm placeholder:font-light outline-none"
                  placeholder="Search by account name, account number, recipient bank..."
                />
              </div>
            </div>

            <div className="flex items-center bg-[#F8FAFC] border-b h-10 gap-x-1 px-5 mt-10 text-sm text-[#0B1E4B]">
              <input
                type="checkbox"
                checked={
                  selectedItems?.length === accountList?.length &&
                  accountList?.length
                    ? true
                    : !accountList?.length
                    ? false
                    : false
                }
                onChange={handleSelectAll}
                className="w-4 h-4 border-red mr-4"
              />
              <p className="min-w-[16%] mr-4 md:mr-0">Account Name</p>
              <p className="min-w-[20%]">Account Number</p>
              <p className="min-w-[23%] mr-4 md:mr-0">Recipient Bank</p>
              <p className="min-w-[18%]">Amount</p>
              <p className="min-w-[8%]">Status</p>
            </div>

            {/* mapped items */}
            {loading ? (
              <SecondaryLoader count={5} />
            ) : searchToReturn?.length ? (
              searchToReturn?.map((list: any) => {
                const { id, amount, status } = list;
                return (
                  <div
                    className={`flex items-center border-b h-12 px-5 gap-x-1 text-sm text-[#5B5B5B] font-light cursor-pointer accent-aellaBlue ${
                      selectedItems?.includes(list?.id)
                        ? "bg-[#EDEFF5]"
                        : "bg-[bg-white]"
                    }`}
                  >
                    <input
                      type="checkbox"
                      value={id}
                      checked={selectedItems?.includes(list?.id)}
                      onChange={(e) => {
                        handleCheckboxClick(list?.id);
                      }}
                      className="w-4 h-4 border-red mr-4 cursor-pointer"
                    />
                    <div className="min-w-[16%] mr-4 md:mr-0 capitalize relative">
                      <>
                        {list?.account_name ? (
                          list?.account_name?.toLowerCase()?.slice(0, 16) +
                          "..."
                        ) : (
                          <ErrorIcon
                            onMouseEnter={() => {
                              setInvalidMessage(true);
                              setHovered(id);
                            }}
                            onMouseLeave={() => {
                              setInvalidMessage(false);
                              setHovered("");
                            }}
                          />
                        )}
                        {!list?.account_name &&
                          invalidMessage &&
                          hovered === id && (
                            <p className="absolute top-[24px] left-0 bg- px-4 py-1 rounded-[8px] bg-[#D1293D] text-white">
                              Invalid account name
                            </p>
                          )}
                      </>
                    </div>
                    <div className="min-w-[20%] flex items-center gap-x-2 relative">
                      {list?.account_number?.length !== 10 && (
                        <ErrorIcon
                          onMouseEnter={() => {
                            setInvalidNumber(true);
                            setHovered(id);
                          }}
                          onMouseLeave={() => {
                            setInvalidNumber(false);
                            setHovered("");
                          }}
                          className=""
                        />
                      )}
                      {list?.account_number}
                      {!list?.bank_name &&
                        invalidNumber &&
                        hovered === id &&
                        list?.account_number?.length !== 10 && (
                          <p className="absolute top-[24px] left-0 bg- px-4 py-1 rounded-[8px] bg-[#D1293D] text-white">
                            Invalid account number
                          </p>
                        )}
                    </div>
                    <div className="min-w-[23%] mr-4 md:mr-0 flex items-center gap-x-2 capitalize relative">
                      {list?.bank_name ? (
                        <>
                          <img
                            alt="bank logo"
                            src={list?.bank_logo}
                            className="rounded-[50%] h-4 w-4"
                          />
                          {list?.bank_name?.toLowerCase() || ""}
                        </>
                      ) : (
                        <ErrorIcon
                          onMouseEnter={() => {
                            setInvalidBank(true);
                            setHovered(id);
                          }}
                          onMouseLeave={() => {
                            setInvalidBank(false);
                            setHovered("");
                          }}
                        />
                      )}
                      {!list?.bank_name && invalidBank && hovered === id && (
                        <p className="absolute top-[24px] left-0 bg- px-4 py-1 rounded-[8px] bg-[#D1293D] text-white">
                          Invalid bank
                        </p>
                      )}
                    </div>
                    <div className="min-w-[18%] relative">
                      {amount ? (
                        formatMoney(amount)
                      ) : (
                        <ErrorIcon
                          onMouseEnter={() => {
                            setInvalidAmount(true);
                            setHovered(id);
                          }}
                          onMouseLeave={() => {
                            setInvalidAmount(false);
                            setHovered("");
                          }}
                        />
                      )}
                      {!list?.amount && invalidAmount && hovered === id && (
                        <p className="absolute top-[24px] left-0 bg- px-4 py-1 rounded-[8px] bg-[#D1293D] text-white">
                          Invalid amount
                        </p>
                      )}
                    </div>
                    <div
                      className={`min-w-[8%] flex justify-center rounded-[4px] ${
                        list?.status?.toLowerCase() === "success"
                          ? "text-[#14804A] bg-[#E1FCEF]"
                          : "text-[#D1293D] bg-[#FFEDEF]"
                      } `}
                    >
                      {status}
                    </div>
                    {list?.status?.toLowerCase() !== "success" && (
                      <div className="flex-1 ml-4 flex justify-center items-center gap-x-7">
                        <EditIcon
                          className="cursor-pointer"
                          onClick={() => {
                            setToUpdate(list);
                            setValue("amount", list?.amount);
                            setValue("accountNumber", list?.account_number);
                            setValue(
                              "bankName",
                              list?.bank_name?.toUpperCase()
                            );
                            // setValue("accountName", list?.account_name);
                            setShowUpdateModal(true);
                          }}
                        />
                        {/* <RefreshIcon className="cursor-pointer" /> */}
                      </div>
                    )}
                  </div>
                );
              })
            ) : (
              <div className="flex h-[150px] bg-white w-full items-center justify-center">
                <p className="text-2xl">No Data Found.</p>
              </div>
            )}
          </div>
        </div>
        <div className="flex justify-end mt-10">
          <BlueButton
            onClick={() => {
              if (!accountList?.length) {
                return;
              }
              if (totalSuccessful === accountList?.length) {
                // setConfirmation(true);
                setShowModal(true);
              } else toast.error("Please validate all Accounts");
            }}
            title="Confirm"
            className="h-12 w-[145px] flex justify-center"
          />
        </div>
      </div>
    </div>
  );
};

export default PreviewBulkData;
