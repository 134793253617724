import { apiEndpoints } from "../../apis/apiEndpoints";
import { getData, postData } from "../../apis/apiMethods";
import CONFIG from "../../helpers/config";

export const setPasswordFn = async ({ payload }) => {
  const { data } = await postData(
    `${CONFIG.BASE_URL2}${apiEndpoints.WITHDRAW_FROM_WALLET}`,
    payload
  );
  return data;
};

export const acceptInviteFn = async ({
  payload,
}: {
  payload: { token: string; email_address: string; password: string };
}) => {
  const { data, message } = await postData(
    `${CONFIG.BASE_URL2}${apiEndpoints.ACCEPT_INVITE}`,
    payload
  );
  return {data, message};
};

export const getOrganizations = async () => {
  const { data } = await getData(
    `${CONFIG.BASE_URL2}${apiEndpoints.GET_ORGANIZATIONS}`,
    false
  );
  return data;
};

export const memberAcceptInviteFn = async ({
  payload,
}: {
  payload: { token: string; email_address: string };
}) => {
  const { data, message } = await postData(
    `${CONFIG.BASE_URL2}${apiEndpoints.MEMBER_ACCEPT_INVITE}`,
    payload
  );
  return { data, message };
};
