import Drawer from "../../../component/Drawer/Drawer";
import CustomInputField from "../../../component/CustomHTMLElements/CustomInputField";
import { yupValidators } from "../../../helpers/yupValidators";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { RequestsType } from "../Pos";
import deleteIcon from "../../../assets/images/svg/delete-icon.svg";
import { ReactComponent as AddIcon } from "../../../assets/images/svg/add-icon.svg";
import { Dispatch, SetStateAction } from "react";
import { Loader } from "../../../component/Loader/Loader.component";

type FormData = {
  fullName: string;
  phoneNumber: string;
};
export default function PosRequestDrawer({
  isOpen,
  setIsOpen,
  onAddMore,
  requests,
  setSingleAddress,
  singleAddress,
  setRequests,
  deleteDetail,
  setModalType,
  setFullName,
  setPhoneNumber,
  fullName,
  phoneNumber,
  loading,
  details,
  setDetails,
}: {
  isOpen: boolean;
  loading: boolean;
  setIsOpen: (newState: boolean) => void;
  onAddMore: (newState: any) => void;
  requests: Array<RequestsType>;
  setSingleAddress: (newState: boolean) => void;
  setModalType: (newState: string) => void;
  singleAddress: boolean;
  setRequests: (newState: Array<RequestsType>) => void;
  deleteDetail: Dispatch<SetStateAction<any>>;
  setFullName: Dispatch<SetStateAction<string>>;
  fullName: string;
  phoneNumber: string;
  setPhoneNumber: Dispatch<SetStateAction<string>>;
  details: any;
  setDetails: any;
}) {
  const schema = yup.object().shape({
    fullName: yupValidators.lastName,
    phoneNumber: yupValidators.phoneNumber,
  });
  const exceptThisSymbols = ["e", "E", "+", "-", "."];
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<FormData>({
    resolver: yupResolver(schema),
  });

  const onChange = (e: any, id?: number) => {
    e.preventDefault();
    const requestToUpdate = requests.find((request) => request.id === id);
    const otherRequests = requests.filter(
      (request: RequestsType) => request.id !== id
    );
    const { name, value } = e.target;
    setRequests([...otherRequests, { ...requestToUpdate, [name]: value }]);
  };
  const disabledValue = (id?: number) => {
    const exactField = requests.find((req) => req.id === id);

    return !exactField?.edit;
  };
  const onSubmit = ({
    fullName,
    phoneNumber,
  }: {
    fullName: string;
    phoneNumber: string;
  }) => {
    setFullName(fullName);
    setPhoneNumber(phoneNumber);
    setModalType("step1");
    setIsOpen(false);
    setValue("fullName", "");
    setValue("phoneNumber", "");
    setRequests(
      requests?.map((req: any) => {
        return { ...req, edit: false };
      })
    );
  };

  return (
    <Drawer isOpen={isOpen} setIsOpen={setIsOpen}>
      <>
        <div className="flex justify-between items-center border-b pb-[17px]">
          <div className="text-[#0B1E4B] font-[200]  text-[24px] leading-[29px] tracking-[0.2px]">
            Request New POS
          </div>
          <div
            onClick={() => {
              setIsOpen(false);
              setRequests([
                {
                  id: 1,
                  delivery_address: "",
                  quantity: 0,
                  edit: true,
                },
              ]);
              setFullName("");
              setPhoneNumber("");
              setValue("fullName", "");
              setValue("phoneNumber", "");
            }}
            className="cursor-pointer"
          >
            <svg
              width="32"
              height="33"
              viewBox="0 0 32 33"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect y="0.5" width="32" height="32" rx="16" fill="white" />
              <rect
                x="11.7305"
                y="18.6338"
                width="2.13343"
                height="2.13343"
                fill="#6D6D6D"
              />
              <rect
                x="9.59766"
                y="20.7671"
                width="2.13343"
                height="2.13343"
                fill="#6D6D6D"
              />
              <rect
                width="2.13343"
                height="2.13343"
                transform="matrix(1 0 0 -1 11.7305 14.3672)"
                fill="#6D6D6D"
              />
              <rect
                width="2.13343"
                height="2.13343"
                transform="matrix(1 0 0 -1 9.59766 12.2334)"
                fill="#6D6D6D"
              />
              <rect
                width="2.13343"
                height="2.13343"
                transform="matrix(1 0 0 -1 13.8633 16.5005)"
                fill="#6D6D6D"
              />
              <rect
                width="2.13343"
                height="2.13343"
                transform="matrix(1 0 0 -1 15.9961 18.6338)"
                fill="#6D6D6D"
              />
              <rect
                x="18.1328"
                y="12.2334"
                width="2.13343"
                height="2.13343"
                fill="#6D6D6D"
              />
              <rect
                x="20.2617"
                y="10.1001"
                width="2.13343"
                height="2.13343"
                fill="#6D6D6D"
              />
              <rect
                width="2.13343"
                height="2.13343"
                transform="matrix(1 0 0 -1 18.1328 20.7671)"
                fill="#6D6D6D"
              />
              <rect
                width="2.13343"
                height="2.13343"
                transform="matrix(1 0 0 -1 20.2617 22.9009)"
                fill="#6D6D6D"
              />
            </svg>
          </div>
        </div>
        <div className="mt-8">
          <h1 className="text-[24px] lg:leading-[29px] tracking-[0.2px] font-[200] ">
            New POS Machine Terminal
          </h1>
          <p className="text-[16px] lg:leading-[24px] text-aellaGray font-[100] ">
            We&apos;ll provide your POS Terminal in no time!<br></br> Just
            provide the following details
          </p>
        </div>
        <div className="flex flex-col  border rounded-[6px] border-[#EDF2FF] p-6 mt-4">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className=" w-full">
              <CustomInputField
                type="text"
                maxLength={128}
                placeholder="Enter Name"
                label="Enter Name"
                errors={errors.fullName}
                defaultValue={fullName}
                showRequiredIcon
                {...register("fullName")}
                onChange={(e: any) =>
                  setDetails({ ...details, fullName: e.target.value })
                }
              />
            </div>
            <div className="w-full">
              <CustomInputField
                type="number"
                maxLength={11}
                placeholder="08100000000"
                label="Phone Number"
                hasIcon="+234"
                errors={errors.phoneNumber}
                defaultValue={phoneNumber}
                showRequiredIcon
                {...register("phoneNumber")}
                onChange={(e: any) =>
                  setDetails({ ...details, phoneNumber: e.target.value })
                }
              />
            </div>
            <label className="block text-aellaGray lg:leading-[18px] text-[16px] font-[300] mb-2">
              Do you want all POS Terminals delivered to a single address?
            </label>
            <div className="flex ">
              <div>
                <input
                  type="radio"
                  className="mt-1 shrink-0"
                  checked={singleAddress}
                  onChange={() => {
                    setSingleAddress(true);
                    setRequests([
                      {
                        id: 1,
                        delivery_address: "",
                        quantity: 0,
                        edit: true,
                      },
                    ]);
                  }}
                />

                <label className="ml-2.5 text-[16px] lg:leading-[16px] font-[300] text-[#011C34] tracking-[0.03px]">
                  Yes
                </label>
              </div>
              <div className="pl-40">
                <input
                  type="radio"
                  className="mt-1 shrink-0"
                  checked={!singleAddress}
                  onChange={() => {
                    setSingleAddress(false);
                    setRequests([
                      {
                        id: 1,
                        delivery_address: "",
                        quantity: 0,
                        edit: true,
                      },
                    ]);
                  }}
                />

                <label className="ml-2.5 text-[16px] lg:leading-[16px] font-[300] text-[#011C34] tracking-[0.03px]">
                  No
                </label>
              </div>
            </div>
            <div></div>

            <div className="bg-[#F6F8FA] p-[16px] mt-[23px]">
              {requests?.map((request: RequestsType) => (
                <div
                  className="rounded flex items-center mt-2.5 first:mt-0"
                  key={request.id}
                >
                  <div className="flex flex-col">
                    <label className=" text-[#0B1E4B] lg:leading-[18px] text-[16px] font-[300] mb-2 ">
                      Delivery Address
                    </label>
                    <textarea
                      className="bg-white appearance-none border px-4 py-2 h-[53px] font-[100] rounded placeholder:text-[#DADADA] placeholder:text-[16px] placeholder:lg:leading-[24px] placeholder:font-[100] text-aellaGray"
                      name="delivery_address"
                      disabled={disabledValue(request.id)}
                      value={request.delivery_address}
                      onChange={(e: any) => {
                        onChange(e, request.id);
                      }}
                    />
                  </div>
                  <div className="flex flex-col pr-2.5 pl-2.5">
                    <label className=" text-[#0B1E4B] lg:leading-[18px] text-[16px] font-[300] mb-2  ">
                      Quantity
                    </label>
                    <input
                      type="number"
                      className="bg-white appearance-none border px-4 py-2 h-[53px] w-[93px] font-[100] rounded placeholder:text-[#DADADA] placeholder:text-[16px] placeholder:lg:leading-[24px] placeholder:font-[100] text-aellaGray request"
                      placeholder="e.g 5"
                      name="quantity"
                      min={1}
                      onKeyDown={(e) =>
                        exceptThisSymbols.includes(e.key) && e.preventDefault()
                      }
                      disabled={disabledValue(request.id)}
                      value={request.quantity}
                      onChange={(e: any) => {
                        onChange(e, request.id);
                      }}
                    />
                  </div>

                  {!singleAddress && (
                    <img
                      src={deleteIcon}
                      alt=""
                      className="mt-6 cursor-pointer"
                      onClick={() => deleteDetail(request.id)}
                    />
                  )}
                </div>
              ))}

              {!singleAddress && (
                <button
                  className=" px-[16px] py-[14px] flex rounded-[6px] mt-2.5 text-aellaBlue disabled:bg-colors-blue5 items-center transition ease-in-out duration-300  font-normal text-[14px]"
                  type="submit"
                  onClick={(e: any) => onAddMore(e)}
                >
                  <span className="mr-2">
                    <AddIcon />
                  </span>
                  Add more
                </button>
              )}
            </div>

            <div className="flex justify-end">
              <button
                type="submit"
                className="bg-aellaBlue rounded text-white py-4 px-10 items-center flex justify-center cursor-pointer mt-8"
              >
                Continue
                {loading && <Loader />}
              </button>
            </div>
          </form>
        </div>
      </>
    </Drawer>
  );
}
