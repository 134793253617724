import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useLoansContext } from "../EmployeeLoans";
import { ApprovedLoans } from "./ApprovedLoans";
import { DeclinedRequests } from "./DeclinedRequests";
import { ROUTES } from "../../../helpers/routes";
import { RunningLoans } from "./RunningLoans";
import { PendingLoans } from "./PendingLoans";
import { DefaultLoans } from "./DefaultLoans";
import { CompletedLoans } from "./CompletedLoans";

const LoanBook = () => {
  const { state: locationState } = useLocation();
  const navigate = useNavigate();
  const { updateContext } = useLoansContext();
  const { data: paramsData } = Object(locationState) as {
    data: any;
  };
  const [state, setState] = useState({
    activeTab: paramsData?.type ?? "Loan Requests",
    tabs: [
      "Loan Requests",
      "Approved Loans",
      "Declined Requests",
      "Running Loans",
      "Default Loans",
      "Completed Loans",
    ],
  });

  const updateState = (payload) => {
    setState((prev) => ({ ...prev, ...payload }));
  };

  useEffect(() => {
    updateContext({
      showHeader: true,
    });
  }, []);

  const renderBody = () => {
    // return <AwaitingApproval />;

    switch (state?.activeTab) {
      case "Approved Loans":
        return <ApprovedLoans />;
      case "Declined Requests":
        return <DeclinedRequests />;

      case "Running Loans":
        return <RunningLoans />;

      case "Loan Requests":
        return <PendingLoans />;

      case "Default Loans":
        return <DefaultLoans />;

      case "Completed Loans":
        return <CompletedLoans />;
      // default:
      //   return <AwaitingApproval />;
    }
  };

  return (
    <div className="bg-aellaDimGray w-full">
      <div className="px-4 lg:px-10 py-[32px] mb-10 w-full">
        <div className="flex justify-start items-center">
          <div className="rounded-[24px] p-2 sm:h-12 flex gap-x-4 text-[#232323] bg-[#EDEFF5] justify-start overflow-x-auto hide_scrollbar font-light">
            {state?.tabs?.map((tab) => (
              <button
                key={tab}
                onClick={() => {
                  updateState({
                    activeTab: tab,
                  });
                  navigate(`${ROUTES.EMPLOYEE_LOANS}/${ROUTES.LOAN_BOOK}`, {
                    state: { data: { type: tab } },
                  });
                }}
                className={`px-4 py-2 min-w-[10rem] sm:w-auto rounded-[24px] flex items-center justify-center cursor-pointer ${
                  state?.activeTab === tab ? "bg-white" : "bg-transparent"
                }`}
              >
                {tab}
              </button>
            ))}
          </div>
        </div>

        <div className="mt-4">{renderBody()}</div>
      </div>
    </div>
  );
};

export default LoanBook;
