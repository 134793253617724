import DatePicker from "react-datepicker";
import closeIcon from "../../../assets/images/svg/close-icon.svg";
import calendar from "../../../assets/images/svg/calendar-icon.svg";
import CustomSelectDropdown from "../../../component/CustomHTMLElements/CustomSelectDropdown";
import { Dispatch, SetStateAction, useState } from "react";
const subaccountStatus = [
  { id: "active", name: "Active" },
  { id: "inactive", name: "Inactive" },
];

export default function SubAccountFilterModal({
  setModalType,
  setSearchState,
  searchState,
}: {
  setModalType: Dispatch<SetStateAction<boolean>>;
  setSearchState: Dispatch<SetStateAction<any>>;
  searchState: any;
}) {
  const [status, setStatus] = useState("");
  const [startDate, setStartDate] = useState(searchState.from);
  const [endDate, setEndDate] = useState(searchState.to);
  return (
    <>
      <div className="fixed justify-center items-center flex overflow-x-hidden inset-0 z-50 outline-none focus:outline-none">
        <div className="relative my-auto mx-auto max-w-[501px]">
          <div
            className="flex mb-2 justify-between max-w-[501px]"
            onClick={() => setModalType(false)}
          >
            <div></div>
            <img src={closeIcon} alt="" className="cursor-pointer" />
          </div>
          {/*content*/}
          <div className="border-0 rounded-lg flex flex-col w-screen md:w-full bg-colors-white outline-none focus:outline-none  overflow-y-auto">
            <div className="bg-white p-6 flex flex-col justify-center">
              <p className="pb-4 border-b">Filter By:</p>

              <div className="mt-6 w-full">
                <CustomSelectDropdown
                  label="Status"
                  name="status"
                  onChange={(e): any => setStatus(e.target.value)}
                  value={status || searchState.status}
                >
                  <option value="">Select status</option>;
                  {subaccountStatus?.map((data: any) => {
                    const { id, name } = data;
                    return <option value={id}>{name}</option>;
                  })}
                </CustomSelectDropdown>
              </div>

              <div className="w-full">
                <p className="pb-2 text-aellaBlack lg:leading-[16px] text-[16px] font-[300]">
                  Date Range:
                </p>
                <div className="flex justify-between items-center">
                  <div className="relative w-[45%]">
                    <DatePicker
                      dateFormat="yyyy-MM-dd"
                      placeholderText={"Start date"}
                      selected={startDate || searchState.from}
                      maxDate={new Date()}
                      onChange={(date: Date) => {
                        setStartDate(date);
                      }}
                      locale="en-GB"
                      className="h-11 w-full placeholder:text-gray-400 border border-gray-300 rounded-lg pl-2 dark:border-gray-600/30 text-aellaBlack lg:leading-[16px] text-[16px] font-[300]"
                    />
                    <div className="absolute h-11 inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                      <img className="w-3  z-20" src={calendar} alt="" />
                    </div>
                  </div>

                  <div className="relative w-[45%]">
                    <DatePicker
                      dateFormat="yyyy-MM-dd"
                      placeholderText={"End date"}
                      selected={endDate || searchState.to}
                      minDate={startDate}
                      maxDate={new Date()}
                      onChange={(date: Date) => {
                        setEndDate(date);
                      }}
                      className="h-11 w-full placeholder:text-gray-400 border border-gray-300 rounded-lg pl-2 dark:border-gray-600/30 text-aellaBlack lg:leading-[16px] text-[16px] font-[300]"
                    />
                    <div className="absolute h-11 inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                      <img className="w-3  z-20" src={calendar} alt="" />
                    </div>
                  </div>
                </div>

                <div className="flex justify-between bg-white pt-10">
                  <div></div>
                  <button
                    type="button"
                    className="border-aellaBlue border rounded py-4 cursor-pointer flex items-center text-aellaBlue px-10"
                    onClick={() => {
                      setSearchState({
                        ...searchState,
                        to: null,
                        from: null,
                        status: "",
                      });
                      setModalType(false);
                    }}
                  >
                    Reset
                  </button>
                  <button
                    className="rounded bg-aellaBlue text-white px-10 py-3 cursor-pointer"
                    type="button"
                    onClick={() => {
                      setModalType(false);
                      setSearchState({
                        ...searchState,
                        to: endDate,
                        from: startDate,
                        status: status,
                      });
                    }}
                  >
                    Filter
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="bg-opacity-25 fixed inset-0 z-40 bg-[#0B1E4B]"
        onClick={() => setModalType(false)}
      ></div>
    </>
  );
}
