import { usePosRequests } from "../../../hooks/usePosRequests";
import { usePosAnalytics } from "../../../hooks/usePosAnalytics";
import { ReactComponent as CalendarIcon } from "../../../assets/images/svg/calendar-icon.svg";
import { ReactComponent as ArrowIcon } from "../../../assets/images/svg/black-arrow-down-icon.svg";
import moment from "moment";
import {
  attachClassNameAccount,
  attachClassNameToTag,
  getStatusName,
} from "../../../helpers/attachClassNameToTag";
import PosRequestModal from "../Modal/PosRequestModal";
import { useEffect, useState } from "react";
import DateRangeModal from "../../../modal/DateRangeModal";
import { SearchStateType } from "../../../interfaces/home";
import Pagination from "../../../component/Pagination/Pagination.component";
import { truncateString } from "../../../helpers/formatter";
import CardSkeletonLoader from "../../../component/Skeleton/CardSkeletonLoader.component";
import NoDataState, {
  NoListState,
} from "../../../component/NoDataState/NoDataState";
import transactionIcon from "../../../assets/images/svg/transaction-icon.svg";
import { useSearchParams } from "react-router-dom";
import EnterOTP from "../Modal/EnterOTP";
import EnterNewPassword from "../Modal/EnterNewPassword";
import PasswordChangeStatus from "../Modal/PasswordChangeStatus";
import { SecondaryLoader } from "../../../component/Loader/SecondaryLoader";
import { ReactComponent as ChevronDown } from "../../../assets/images/svg/blue-chevron-down.svg";
import filterIcon from "../../../assets/images/svg/filter-icon.svg";
import { ReactComponent as SearchIcon } from "../../../assets/images/svg/search-icon.svg";
import NoDataIcon from "../../../assets/images/svg/empty.svg";
import { usePosContext } from "../Pos";
import { getData, postData } from "../../../apis/apiMethods";
import CONFIG from "../../../helpers/config";
import { apiEndpoints } from "../../../apis/apiEndpoints";
import { errorHandler } from "../../../helpers/errorHandler";
import { toast } from "react-toastify";

export default function Requests() {
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth();

  const [modalType, setModalType] = useState("");
  const [showDateRangeModal, setShowDateRangeModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(15);
  const [request, setRequest] = useState<any>();
  const [password, setPassword] = useState("");
  const [showOtp, setShowOtp] = useState(false);
  const [otp, setOtp] = useState("");
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [showStatus, setShowStatus] = useState(false);
  const [viewAll, setViewAll] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [error, setError] = useState(false);
  const [passwordState, setPasswordState] = useState({
    password: "",
    confirm_password: "",
  });

  // const { setShowExportModal, setIsOpen } = usePosContext();

  const [searchParams, setSearchParams] = useSearchParams({});

  const [searchState, setSearchState] = useState<any>({
    startDateForTrnx: new Date(year, month, 1),
    endDateForTrnx: new Date(),
  });

  const { data: posRequests, isLoading: posRequestsLoading } = usePosRequests({
    page: currentPage,
    size: postsPerPage,
    start_date: searchState?.startDateForTrnx,
    end_date: searchState?.endDateForTrnx,
    status: searchState?.status,
  });
  const { data: posAnalytics } = usePosAnalytics({
    startDate: searchState.startDateForTrnx,
    endDate: searchState.endDateForTrnx,
  });

  //update the  react-query with the url params
  useEffect(() => {
    const currentParams: any = Object.fromEntries([...searchParams]);

    // if (currentParams?.startDateForTrnx || currentParams?.endDateForTrnx) {
    //   setSearchState({
    //     startDateForTrnx: new Date(currentParams?.startDateForTrnx),
    //     endDateForTrnx: new Date(currentParams?.endDateForTrnx),
    //   });
    // }
    if (currentParams?.page || currentParams?.size) {
      setCurrentPage(JSON.parse(currentParams?.page));
      setPostsPerPage(JSON.parse(currentParams?.size));
    }
  }, [searchParams, currentPage]);

  //update the url params with the initial query
  useEffect(() => {
    const currentParams: any = Object.fromEntries([...searchParams]);

    if (
      !currentParams?.startDateForTrnx ||
      !currentParams?.endDateForTrnx ||
      !currentParams?.page ||
      !currentParams?.size
    ) {
      setSearchParams({
        startDateForTrnx: moment(searchState.startDateForTrnx).format(
          "YYYY-MM-DD"
        ),
        endDateForTrnx: moment(searchState.endDateForTrnx).format("YYYY-MM-DD"),
        page: JSON.stringify(currentPage),
        size: JSON.stringify(postsPerPage),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchState.startDateForTrnx, searchState.endDateForTrnx, searchParams]);
  // if (posAnalyticsLoading) {
  //   return (
  //     <div className="flex flex-col md:flex-row justify-between px-4 lg:px-10 pt-[32px] lg:pt-[120px]">
  //       <CardSkeletonLoader width={370} height={458} />
  //       <CardSkeletonLoader width={370} height={458} />
  //       <CardSkeletonLoader width={370} height={458} />
  //     </div>
  //   );
  // }

  // const searchToReturn = posRequests?.data?.filter((item: any) =>
  //   item?.terminal_id?.toLowerCase()?.includes(searchTerm?.toLowerCase())
  // );

  const searchToReturn = posRequests?.data?.requests;
  const initiatePasswordReset = async () => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const res: any = await postData(
        `${CONFIG.BASE_URL1}/user/reset-password/initiate`,
        { email_or_phone_number: "" }
      );
      if (res?.status?.toLowerCase() === "success") {
        setModalType("");
        setShowOtp(true);
      }
    } catch (err) {
      // console.log(err);
    }
  };

  const changePassword = async ({
    password,
    confirmPassword,
  }: {
    password: string;
    confirmPassword: string;
  }) => {
    const reqBody = {
      email_or_phone_number: "",
      new_password: "",
      confirm_new_password: "",
      otp: "",
    };
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const res: any = await postData(
        `${CONFIG.BASE_URL2}${apiEndpoints.UPDATE_PASSWORD}`,
        reqBody
      );
      setShowStatus(true);
    } catch (error) {
      toast.error(errorHandler(error));
    }
  };
  return (
    <>
      <div
        className={`bg-aellaDimGray w-full px-4 lg:px-10 ${
          viewAll ? "pb-[32px]" : "py-[32px]"
        }`}
      >
        {/* <div className="flex flex-col bg-white px-0 lg:px-10 pt-[24px] pb-10">
            <div className="flex flex-col lg:flex-row items-center justify-between pb-[13.5px] w-full border-b border-[#EDEFF5] mb-6">
              <h1 className="text-[24px] lg:leading-[29px] font-[300]  items-center">
                Analytics
              </h1>
              <div
                className="text-[13px] font-[300] pt-4 lg:pl-6 flex items-center cursor-pointer"
                onClick={() => setShowDateRangeModal(true)}
              >
                <span className="py-2 pr-4 lg:leading-[18px] text-aellaGray">
                  Showing:
                </span>
                <span className="border rounded py-2 flex flex-wrap px-2.5">
                  <CalendarIcon />
                  <span className="pl-1 lg:leading-[20px] tracking-[0.2px] pr-1">
                    {moment(searchState.startDateForTrnx).format("DD MMM YYYY")}{" "}
                    - {moment(searchState.endDateForTrnx).format("DD MMM YYYY")}
                  </span>
                  <div>
                    <ArrowIcon />
                  </div>
                </span>
              </div>
            </div>
            <div className="flex flex-col lg:flex-row">
              <div className="flex flex-col py-4 px-6 lg:border-r border-[#EDEFF5] w-full shadow-[0_20px_60px_20px_rgba(197, 212, 246, 0.15)]">
                <h1 className="text-[13px] lg:leading-[16px] font-[300] tracking-[0.03em]">
                  TOTAL OF NUMBER POS Terminal
                </h1>

                <h1 className="text-[32px] lg:leading-[38px] font-[300] tracking-[-0.2px] pt-[18px]">
                  0
                </h1>
                <p className="text-[13px]  lg:leading-[20px] font-[200] text-aellaGray pt-2 pb-[30px]">
                  This is the total number of POS requests
                </p>
              </div>
              <div className="flex flex-col py-4 px-6 lg:border-r border-[#EDEFF5] w-full">
                <h1 className="text-[13px] lg:leading-[16px] font-[300] tracking-[0.03em]">
                  TOTAL NUMBER OF APPROVED
                </h1>

                <h1 className="text-[32px] lg:leading-[38px] font-[300] tracking-[-0.2px] pt-[18px]">
                  0
                </h1>
                <p className="text-[13px]  lg:leading-[20px] font-[200] text-aellaGray pt-2 pb-[30px]">
                  This is the total number of approved POS Requests
                </p>
              </div>
              <div className="flex flex-col py-4 px-6 w-full">
                <h1 className="text-[13px] lg:leading-[16px] font-[300] tracking-[0.03em]">
                  TOTAL NUMBER OF DECLINED
                </h1>

                <h1 className="text-[32px] lg:leading-[38px] font-[300] tracking-[-0.2px] pt-[18px]">
                  0
                </h1>
                <p className="text-[13px]  lg:leading-[20px] font-[200] text-aellaGray pt-2 pb-[30px]">
                  This is the total number of declined POS Requests
                </p>
              </div>
            </div>
          </div> */}
        {viewAll && (
          <div className="flex px-4 -mx-10 lg:px-[44px] py-4 justify-between items-center bg-[#ffffff]">
            {/* <div
              onClick={() => setShowExportModal(true)}
              className="flex items-center gap-x-2 border border-aellaBlue rounded-[4px] px-2 py-1 text-aellaBlue cursor-pointer z-20"
            >
              <p>Export</p>
              <ChevronDown />
            </div> */}
            <div className="flex flex-row-reverse gap-x-3 ml-auto">
              <div className="flex flex-row-reverse">
                <label
                  className="min-w-[114px] h-10 border bg-[#F5F5F5] px-2 lg:px-5 py-2 cursor-pointer rounded xxs:justify-center md:justify-around active:shadow-md transition duration-150 ease-in-out"
                  htmlFor="filterModal"
                  onClick={() => setShowDateRangeModal(true)}
                >
                  <div className="xxs:px-1 md:px-2 py-1 h-[24px] flex items-center">
                    <img
                      className="object-contain w-[18px] h-[18px]"
                      src={filterIcon}
                      alt=""
                    />
                    <span className="ml-[12px] font-thin  text-gray-400  text-sm focus:outline-none focus:ring-0">
                      Filter By
                    </span>
                  </div>
                </label>
              </div>
              <div
                className={`w-[10rem] flex items-center gap-x-2 px-2 h-10 rounded-[4px] border border-[#EDEFF5]`}
              >
                <div className="shrink-0">
                  <SearchIcon />
                </div>
                <input
                  value={searchTerm}
                  onChange={(e: any) => setSearchTerm(e.target.value)}
                  className="bg-inherit h-full placeholder:text-[#5B5B5B] text-aellaBlack flex-1 text-sm placeholder:text-sm placeholder:font-light outline-none"
                  placeholder="Search by Terminal ID"
                />
              </div>
            </div>
          </div>
        )}
        <div className="py-6 border border-aellaBorderGray  bg-white mb-5">
          <div className="flex justify-between items-center pb-[18px] px-6 lg:px-10 ">
            <h1 className="text-[16px] lg:text-[24px] lg:leading-[29px] font-[350]">
              POS Request
            </h1>
            {!!posRequests?.data?.requests?.length && (
              <p
                onClick={() => {
                  if (postsPerPage !== posRequests?.data?.totalItems) {
                    setViewAll(true);
                    setPostsPerPage(posRequests?.data?.totalItems);
                  } else {
                    setPostsPerPage(15);
                    setViewAll(false);
                  }
                  setSearchTerm("");
                }}
                className="underline decoration-aellaBlue underline-offset-2 text-aellaBlue cursor-pointer"
              >
                {postsPerPage !== posRequests?.data?.totalItems
                  ? "View All"
                  : "View Less"}
              </p>
            )}
          </div>
          <div className="pt-10 border-t border-[#F1F1F1] px-6 lg:px-10">
            <div className="overflow-x-auto hide_scrollbar">
              {posRequests?.data?.requests?.length === 0 ? (
                <NoListState
                  icon={NoDataIcon}
                  textHeader="No Request Found"
                  firstParaText="There will be information on this page when you make requests"
                  // secondParaText="Click the button below to request for a POS Machine."
                  // title="Request POS"
                  // onClick={() => setIsOpen(true)}
                />
              ) : searchToReturn?.length === 0 ? (
                <NoDataState
                  icon={transactionIcon}
                  textHeader="Request not found"
                  firstParaText="There is no Pos Request with this terminal ID"
                />
              ) : (
                <div className="">
                  <div className="flex items-center bg-[#F7F9FC] w-[380%] gap-x-4 overflow-x-auto md:overflow-x-hidden md:gap-x-0 md:w-full px-4 h-10 text-aellaBlack text-sm border-b hide_scrollbar">
                    <p className="w-[15%]">Terminal Id</p>
                    <p className="w-[20%]"> Customer Name</p>
                    <p className="w-[10%]"> Request Date</p>
                    <p className="w-[15%] mr-5 text-center"> Status</p>
                    <p className="w-[10%]"> Quantity</p>
                    {/* <p className="w-[20%] text-center">Delivery Date</p> */}
                    <p className="w-[25%] text-right">Address</p>
                  </div>

                  {posRequestsLoading ? (
                    <SecondaryLoader count={10} />
                  ) : (
                    searchToReturn?.map((data: any) => {
                      return (
                        <div
                          key={data?.id}
                          onClick={() => {
                            setRequest(data);
                            setModalType("request");
                          }}
                          className="flex items-center bg-white w-[380%] gap-x-4 overflow-x-auto md:overflow-x-hidden md:gap-x-0 md:w-full p-4 text-[#5B5B5B] text-sm font-light border-b cursor-pointer hide_scrollbar"
                        >
                          <p className="w-[15%]">
                            {data?.terminal_id
                              ? truncateString(data?.terminal_id, 10)
                              : "-"}
                          </p>
                          <p className="w-[20%] capitalize">
                            {truncateString(data?.customer_name, 10)}
                          </p>
                          <p className="w-[10%] ">
                            {moment(data?.request_date).format("DD MMM YYYY")}
                          </p>

                          <p className={`w-[15%] mr-5 text-center`}>
                            <span
                              className={`w-full text-center                  
                            ${attachClassNameToTag(data?.status)}
                              `}
                            >
                              {/* {data?.status === 0 ? "Declined" : "Approved"} */}

                              {data?.status}
                            </span>
                          </p>

                          <p className="w-[10%]">{data?.quantity}</p>
                          {/* <p className="w-[20%] text-center">
                            {data?.delivery_date
                              ? moment(data?.delivery_date).format(
                                  "DD MMM YYYY"
                                )
                              : "-"}
                          </p> */}
                          <p className="w-[25%] text-right capitalize">
                            {truncateString(data?.delivery_address, 40)}
                          </p>
                        </div>
                      );
                    })
                  )}
                </div>
              )}
            </div>

            {!!searchToReturn?.length && (
              <Pagination
                postsPerPage={postsPerPage}
                totalPosts={posRequests?.data?.totalItems}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalPages={posRequests?.data?.totalPages}
              />
            )}
          </div>
        </div>
      </div>
      {modalType && (
        <PosRequestModal
          modalType={modalType}
          setModalType={setModalType}
          request={request}
          password={password}
          setPassword={setPassword}
          setShowOtp={setShowOtp}
          handleOTP={initiatePasswordReset}
        />
      )}
      {showDateRangeModal && (
        <DateRangeModal
          startDate={searchState.startDateForTrnx}
          setSearchState={setSearchState}
          endDate={searchState.endDateForTrnx}
          searchState={searchState}
          setCloseModal={setShowDateRangeModal}
          startDateName="startDateForTrnx"
          endDateName="endDateForTrnx"
          showModal={showDateRangeModal}
          hasStatus={true}
        />
      )}
      <EnterOTP
        showModal={showOtp}
        setShowModal={setShowOtp}
        setOtp={setOtp}
        setShowPasswordModal={setShowPasswordModal}
        handleSubmit={initiatePasswordReset}
        error={error}
      />
      <EnterNewPassword
        showModal={showPasswordModal}
        setShowModal={setShowPasswordModal}
        setShowStatus={setShowStatus}
        onSubmit={changePassword}
        passwordState={passwordState}
      />
      <PasswordChangeStatus
        showModal={showStatus}
        setShowModal={setShowStatus}
      />
    </>
  );
}
