import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData } from "../apis/apiMethods";
import CONFIG from "../helpers/config";

export const getAllStates = async ({ queryKey }: { queryKey: any }) => {
  const { country } = queryKey[1];
  const { data } = await getData(
    `${CONFIG.BASE_URL1}${apiEndpoints.GET_ALL_STATES}?country=${country}`
  );
  return data;
};

export const useAllStates = ({ country }: { country: string }) => {
  return useQuery(
    ["getAllStates", { country }],

    getAllStates,
    {
      refetchOnWindowFocus: false, // Refetch on window focus
      enabled: country ? true : false,
      retry: 1,
    }
  );
};
