import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useState } from "react";
import moment from "moment";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import closeIcon from "../../../assets/images/svg/close-icon.svg";
import { SearchStateType } from "../../../interfaces/home";
import CustomInputField from "../../../component/CustomHTMLElements/CustomInputField";
import NairaIcon from "../../../assets/images/svg/input-naira.svg";
import CalendarIcon from "../../../assets/images/svg/calendar.svg";
import { yupValidators } from "../../../helpers/yupValidators";
import { yupResolver } from "@hookform/resolvers/yup";

export default function POSFilterModal({
  startDate,
  setSearchState,
  endDate,
  searchState,
  setCloseModal,
  startDateName,
  endDateName,
  showModal,
  transaction_status,
  setSearchParams,
  setFilter,
}: {
  startDate?: Date;
  setSearchState: (newState: SearchStateType) => void;
  endDate?: Date;
  searchState: any;
  setCloseModal: (newState: boolean) => void;
  startDateName: string;
  endDateName: string;
  showModal: boolean;
  transaction_status: string;
  setSearchParams: any;
  setFilter: any;
}) {
  const date = new Date();
  const [start, setStart] = useState<any>(startDate);
  const [end, setEnd] = useState<any>(endDate);
  const [trxStatus, setTrxStatus] = useState("");
  const [startAmount, setStartAmount] = useState(searchState?.amountFrom);
  const [endAmount, setEndAmount] = useState(searchState?.amountTo);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const schema = yup.object().shape({
    amount: yupValidators.amount,
  });

  const {
    register,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const setFilterUrlParams = () => {
    let params = {
      page: "1",
      size: "15",
    } as any;
    if (start) {
      params = {
        ...params,
        startDate: moment(start).format("YYYY-MM-DD"),
      };
    }
    if (end) {
      params = {
        ...params,
        endDate: moment(end).format("YYYY-MM-DD"),
      };
    }
    if (trxStatus) {
      params = {
        ...params,
        transaction_status: trxStatus,
      };
    }

    setSearchParams({ ...params });
  };

  return (
    <>
      <div className="fixed justify-center items-center flex overflow-x-hidden inset-0 z-50 outline-none focus:outline-none  overflow-y-scroll">
        <div className="translate-50 mx-auto max-w-[501px]">
          <div className="flex mb-2 justify-between max-w-[501px]">
            <div></div>
            <div onClick={() => setCloseModal(!showModal)}>
              <img src={closeIcon} alt="" className="cursor-pointer" />
            </div>
          </div>
          {/*content*/}
          <div className="border-0 rounded-lg flex flex-col w-screen md:w-full bg-colors-white outline-none focus:outline-none">
            <div className="bg-white p-6 flex flex-col justify-center">
              <p className="pb-4 border-b">Filter by:</p>

              <div className="flex flex-col gap-y-1 mt-6">
                <p className="pb-2 text-aellaBlack lg:leading-[16px] text-[16px] font-[300]">
                  Transaction Status
                </p>
                <select
                  onChange={(e) => setTrxStatus(e.target.value)}
                  name=""
                  id=""
                  value={searchState?.transaction_status}
                  className="bg-inherit py-[10px] rounded-[4px] px-2 appearance-none placeholder:text-[#5B5B5B] text-aellaBlack flex-1 text-sm font-light placeholder:text-sm placeholder:font-light outline-none border border-aellaLightGray"
                >
                  <option value="">Select status</option>
                  <option value="Resolved">Resolved</option>
                  <option value="Pending">Pending</option>
                  <option value="Failed">Failed</option>
                </select>
              </div>

              <div className="flex flex-col gap-y-1 mt-5">
                <p className="pb-2 text-aellaBlack lg:leading-[16px] text-[16px] font-[300] -mb-2">
                  Amount Range
                </p>
                <div className="flex gap-x-3 items-center justify-between w-full">
                  <div className="relative w-[47%]">
                    <img
                      src={NairaIcon}
                      alt="input icon"
                      className="absolute top-2 lg:top-[8px] -left-[2px] z-10 h-12 w-10"
                    />
                    <CustomInputField
                      type="text"
                      addPadding={true}
                      min={1}
                      placeholder="0.00"
                      errors={errors.amount}
                      errorMessage="Amount is required"
                      showRequiredIcon={false}
                      value={startAmount}
                      onChange={(e) => setStartAmount(e.target.value)}
                    />
                  </div>
                  <p className="text-aellaLightGray mb-3">to</p>
                  <div className="relative w-[47%]">
                    <img
                      src={NairaIcon}
                      alt="input icon"
                      className="absolute top-2 lg:top-[8px] -left-[2px] z-10 h-12 w-10"
                    />
                    <CustomInputField
                      type="text"
                      addPadding={true}
                      min={1}
                      placeholder="0.00"
                      errors={errors.amount}
                      errorMessage="Amount is required"
                      showRequiredIcon={false}
                      value={endAmount}
                      onChange={(e) => setEndAmount(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              <div className="mt-2 w-full">
                <p className="pb-2 text-aellaBlack lg:leading-[16px] text-[16px] font-[300]">
                  Transaction Date Range:
                </p>
                <div className="flex items-center justify-between w-full gap-x-3">
                  <div className="w-[46%] z-50 flex items-center border border-aellaLightGray rounded-[4px] px-3">
                    <DatePicker
                      dateFormat="yyyy-MM-dd"
                      placeholderText="Start date"
                      selected={start}
                      maxDate={end || new Date()}
                      onChange={(date: Date) => {
                        setStart(date);
                      }}
                      className="h-11 w-full text-aellaBlack lg:leading-[16px] text-[16px] font-[300] outline-0"
                    />
                    <img
                      src={CalendarIcon}
                      className="w-[20px] h-[20px]"
                      alt="calendar icon"
                    />
                  </div>

                  <div className="w-[46%] z-50 flex items-center border border-aellaLightGray rounded-[4px] px-3">
                    <DatePicker
                      dateFormat="yyyy-MM-dd"
                      placeholderText="End date"
                      selected={end}
                      minDate={start}
                      onChange={(date: Date) => {
                        setEnd(date);
                      }}
                      className="h-11 w-full text-aellaBlack lg:leading-[16px] text-[16px] font-[300] outline-0"
                    />
                    <img
                      src={CalendarIcon}
                      className="w-[20px] h-[20px]"
                      alt="calendar icon"
                    />
                  </div>
                </div>

                <div className="flex justify-between bg-white pt-10">
                  <div></div>
                  <button
                    className="border-aellaBlue border rounded py-2 cursor-pointer text-center text-aellaBlue  w-[121px] "
                    onClick={() => {
                      setFilter(null);
                      setSearchState({
                        ...searchState,
                        [startDateName]: null,
                        [endDateName]: null,
                        [transaction_status]: null,
                      });
                      setSearchParams([]);
                      setStart("");
                      setEnd("");
                      setCloseModal(!showModal);
                      setTrxStatus("");
                    }}
                  >
                    Reset
                  </button>
                  <button
                    // disabled={!(start || end)}
                    className={`border border-aellaBlue rounded bg-aellaBlue text-white cursor-pointer w-[121px] 
        
                    
                    
                    `}
                    onClick={() => {
                      // if (!(start || end)) {
                      //   return;
                      // }
                      setFilterUrlParams();
                      setFilter({
                        status: trxStatus,
                        start: startAmount,
                        end: endAmount,
                      });

                      setCloseModal(!showModal);
                    }}
                  >
                    Filter
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="bg-opacity-25 fixed inset-0 z-40 bg-[#0B1E4B]"
        onClick={() => {
          setCloseModal(!showModal);
        }}
      ></div>
    </>
  );
}
