import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData } from "../apis/apiMethods";
import CONFIG from "../helpers/config";

export const getTeamRole = async () => {
  const res: any = await getData(
    `${CONFIG.BASE_URL2}${apiEndpoints.TEAM_ROLE}`
  );
  return res;
};

export const useTeamRole = () => {
  return useQuery(["getAllRole"], getTeamRole, {
    retry: 1,
    refetchOnWindowFocus: false,
  });
};
