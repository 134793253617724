export const LoadingState = ({ height }: { height?: string }) => {
  return (
    <div
      className={`bg-white w-full flex items-center justify-center ${
        height ?? "h-[90vh]"
      }`}
    >
      <div className="w-10 h-10 border-aellaBlue loader__container" />
    </div>
  );
};
