import { ModalContainer } from "../../../modal/ModalContainer";
import { ReactComponent as CloseIcon } from "../../../assets/images/svg/white-close-modal.svg";
import BlueButton from "../../../component/Button/BlueButton";
import CustomInputField from "../../../component/CustomHTMLElements/CustomInputField";
import { ReactComponent as Eye } from "../../../assets/images/svg/eye-icon.svg";
import { Loader } from "../../../component/Loader/Loader.component";

import * as yup from "yup";
import { yupValidators } from "../../../helpers/yupValidators";
import { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { RegistrationFormData } from "../../../interfaces/login";

const schemaPassword = yup.object().shape({
  password: yupValidators.password,
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

const EnterNewPassword = ({
  showModal,
  setShowModal,
  setShowStatus,
  onSubmit,
  passwordState,
}: {
  showModal: boolean;
  setShowModal: (newState: boolean) => void;
  setShowStatus: (newState: boolean) => void;
  onSubmit: any;
  passwordState: any;
}) => {
  const [isNewVisible, setNewVisible] = useState(false);
  const [isConfirmVisible, setConfirmVisible] = useState(false);

  const toggleNewPassword = () => {
    setNewVisible(!isNewVisible);
  };

  const toggleConfirmPassword = () => {
    setConfirmVisible(!isConfirmVisible);
  };

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<RegistrationFormData>({
    resolver: yupResolver(schemaPassword),
  });

  const watchMe = watch();

  return (
    <ModalContainer
      showModal={showModal}
      closeModal={() => {
        setShowModal(false);
      }}
    >
      <form className="w-full lg:w-[30vw] relative h-auto lg:max-h-[85vh] z-50 text-aellaBlack flex flex-col rounded-[8px] bg-white p-6">
        <div className="flex justify-between items-center">
          <div
            onClick={() => setShowModal(false)}
            className="absolute -top-8 -right-0 cursor-pointer"
          >
            <CloseIcon />
          </div>
        </div>
        <div className="mt-5 text-light">
          <p className="mb-6 text-xl text-[#011C34]">
            Enter and confirm your new password
          </p>
          <form
          // onSubmit={handleSubmit(onSubmit)}
          >
            <div className="mb-[32px]">
              <div className="relative">
                <CustomInputField
                  type={!isNewVisible ? "password" : "text"}
                  maxLength={128}
                  placeholder="********"
                  label="New password"
                  errors={errors.password}
                  hasActionButton={true}
                  {...register("password")}
                />

                <Eye
                  onClick={toggleNewPassword}
                  className="absolute right-[12px] top-[70%] translate-y-[-50%] cursor-pointer"
                />
              </div>

              {errors.password && (
                <div className="flex gap-[9px] items-center">
                  <div className="w-[5px] h-[5px] bg-[#2054D2] rounded-[50%]"></div>

                  <p className="text-[12px] font-thin text-[#6A7581]">
                    {errors?.password?.message}
                  </p>
                </div>
              )}
            </div>

            <div>
              <div className="relative">
                <CustomInputField
                  type={!isConfirmVisible ? "password" : "text"}
                  maxLength={128}
                  placeholder="********"
                  label=" Confirm New password"
                  errors={errors.confirmPassword}
                  hasActionButton={true}
                  {...register("confirmPassword")}
                />

                <Eye
                  onClick={toggleConfirmPassword}
                  className="absolute right-[12px] top-[70%] translate-y-[-50%] cursor-pointer"
                />
              </div>

              {errors.confirmPassword && (
                <div className="flex gap-[9px] items-center">
                  <div className="w-[5px] h-[5px] bg-[#2054D2] rounded-[50%]"></div>

                  <p className="text-[12px] font-thin text-[#6A7581]">
                    {errors.confirmPassword?.message}
                  </p>
                </div>
              )}
            </div>

            <BlueButton
              onClick={handleSubmit(onSubmit)}
              // onClick={() => {
              //   // setShowModal(false);
              //   // setShowStatus(true);
              // }}
              title="Continue"
              // type="submit"
              className="ml-auto mt-8 px-10"
            />
          </form>
        </div>
      </form>
    </ModalContainer>
  );
};

export default EnterNewPassword;
