import { useState } from "react";
import closeIcon from "../assets/images/svg/close-icon.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useLocation, useSearchParams } from "react-router-dom";
import moment from "moment";

import CustomSelectDropdown from "../component/CustomHTMLElements/CustomSelectDropdown";
import CustomInputField from "../component/CustomHTMLElements/CustomInputField";
import NairaIcon from "../assets/images/svg/input-naira.svg";
import CalendarIcon from "../assets/images/svg/calendar.svg";

export default function DateRangeModal({
  startDate,
  setSearchState,
  endDate,
  searchState,
  setCloseModal,
  startDateName,
  endDateName,
  showModal,
  hasStatus = false,
  hasAmountRange = false,
}: {
  startDate?: Date;
  setSearchState: (newState: any) => void;
  endDate?: Date;
  searchState: any;
  setCloseModal: (newState: boolean) => void;
  startDateName: string;
  endDateName: string;
  showModal: boolean;
  hasStatus?: boolean;
  hasAmountRange?: boolean;
}) {
  const location = useLocation();

  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth();

  const [start, setStart] = useState(startDate);
  const [end, setEnd] = useState(endDate);
  const [status, setStatus] = useState(searchState?.status);
  const [startAmount, setStartAmount] = useState(searchState?.amountFrom);
  const [endAmount, setEndAmount] = useState(searchState?.amountTo);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams({});

  return (
    <>
      <div className="fixed justify-center items-center flex overflow-x-hidden inset-0 z-50 outline-none focus:outline-none overflow-y-scroll">
        <div className="translate-50 mx-auto max-w-[501px]">
          <div className="flex mb-2 justify-between max-w-[501px]">
            <div></div>
            <div onClick={() => setCloseModal(!showModal)}>
              <img src={closeIcon} alt="" className="cursor-pointer" />
            </div>
          </div>
          {/*content*/}
          <div className="border-0 rounded-lg flex flex-col w-screen md:w-full bg-colors-white outline-none focus:outline-none max-h-[320px]">
            <div className="bg-white p-6 flex flex-col justify-center">
              <p className="pb-4 border-b">Filter by:</p>

              {hasStatus && (
                <div className="mt-4">
                  <CustomSelectDropdown
                    label="Status"
                    name="status"
                    onChange={(e): any => setStatus(e.target.value)}
                    value={status}
                  >
                    <option value="">Select status</option>;
                    {location?.pathname === "/pos/wallets" ? (
                      <>
                        <option value="Success">Success</option>;
                        <option value="Pending">Pending</option>;
                        <option value="Failed">Failed</option>
                      </>
                    ) : (
                      <>
                        <option value="Resolved">Resolved</option>;
                        <option value="Failed">Failed</option>;
                        <option value="Pending">Pending</option>
                      </>
                    )}
                  </CustomSelectDropdown>
                </div>
              )}

              {hasAmountRange && (
                <div className="flex flex-col gap-y-1 mt-3">
                  <p className="pb-2 text-aellaBlack lg:leading-[16px] text-[16px] font-[300] -mb-2">
                    Amount Range
                  </p>
                  <div className="flex gap-x-3 items-center justify-between w-full">
                    <div className="relative w-[47%]">
                      <img
                        src={NairaIcon}
                        alt="input icon"
                        className="absolute top-2 lg:top-[8px] -left-[2px] z-10 h-12 w-10"
                      />
                      <CustomInputField
                        type="number"
                        addPadding={true}
                        min={1}
                        placeholder="0.00"
                        errorMessage="Amount is required"
                        showRequiredIcon={false}
                        value={startAmount}
                        onChange={(e) => setStartAmount(e.target.value)}
                      />
                    </div>
                    <p className="text-aellaLightGray mb-3">to</p>
                    <div className="relative w-[47%]">
                      <img
                        src={NairaIcon}
                        alt="input icon"
                        className="absolute top-2 lg:top-[8px] -left-[2px] z-10 h-12 w-10"
                      />
                      <CustomInputField
                        type="number"
                        addPadding={true}
                        min={1}
                        placeholder="0.00"
                        errorMessage="Amount is required"
                        showRequiredIcon={false}
                        value={endAmount}
                        onChange={(e) => setEndAmount(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              )}

              <div className="mt-3 w-full">
                <p className="pb-2 text-aellaBlack lg:leading-[16px] text-[16px] font-[300]">
                  Filter Date:
                </p>
                <div className="flex items-center justify-between z-[999999] w-full gap-x-3">
                  <div className="w-[46%] z-50 flex items-center border border-aellaLightGray rounded-[4px] px-3">
                    <DatePicker
                      dateFormat="yyyy-MM-dd"
                      placeholderText="Start date"
                      selected={start}
                      maxDate={end || new Date()}
                      onChange={(date: Date) => {
                        setStart(date);
                      }}
                      className="h-11 w-full text-aellaBlack lg:leading-[16px] text-[16px] font-[300] outline-0"
                    />
                    <img
                      src={CalendarIcon}
                      className="w-[20px] h-[20px]"
                      alt="calendar icon"
                    />
                  </div>

                  <div className="w-[46%] z-50 flex items-center border border-aellaLightGray rounded-[4px] px-3">
                    <DatePicker
                      dateFormat="yyyy-MM-dd"
                      placeholderText="End date"
                      selected={end}
                      minDate={start}
                      onChange={(date: Date) => {
                        setEnd(date);
                      }}
                      className="h-11 w-full text-aellaBlack lg:leading-[16px] text-[16px] font-[300] outline-0"
                    />
                    <img
                      src={CalendarIcon}
                      className="w-[20px] h-[20px]"
                      alt="calendar icon"
                    />
                  </div>
                </div>

                <div className="flex justify-between bg-white pt-10">
                  <div></div>
                  <button
                    className="border-aellaBlue border rounded py-2 cursor-pointer text-center text-aellaBlue  w-[121px] "
                    onClick={() => {
                      setSearchState({
                        ...searchState,
                        status: null,
                        [startDateName]: new Date(year, month, 1),
                        [endDateName]: new Date(),
                      });
                      setStatus("");
                      setStartAmount(null);
                      setEndAmount(null);
                      setSearchParams({
                        [startDateName]: moment(
                          new Date(year, month, 1)
                        ).format("YYYY-MM-DD"),
                        [endDateName]: moment(new Date()).format("YYYY-MM-DD"),
                      });
                      setStart(new Date(year, month, 1));
                      setEnd(new Date());
                      setCloseModal(!showModal);
                    }}
                  >
                    Reset
                  </button>
                  <button
                    className="border border-aellaBlue rounded bg-aellaBlue text-white cursor-pointer w-[121px]"
                    onClick={() => {
                      setSearchState({
                        ...searchState,
                        [startDateName]: start,
                        [endDateName]: end,
                        status,
                        amountFrom: startAmount,
                        amountTo: endAmount,
                      });
                      setSearchParams({
                        [startDateName]: moment(start).format("YYYY-MM-DD"),
                        [endDateName]: moment(end).format("YYYY-MM-DD"),
                      });
                      setCloseModal(!showModal);
                    }}
                  >
                    Filter
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="bg-opacity-25 fixed inset-0 z-40 bg-[#0B1E4B]"
        onClick={() => {
          setCloseModal(!showModal);
        }}
      ></div>
    </>
  );
}
