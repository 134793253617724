import React from "react";
import { NavLink } from "react-router-dom";
import { ROUTES } from "../../helpers/routes";
import { ReactComponent as HomeIcon } from "../../assets/images/svg/home-icon.svg";
import { ReactComponent as WalletIcon } from "../../assets/images/svg/wallet-icon.svg";
import { ReactComponent as PosIcon } from "../../assets/images/svg/pos-icon.svg";
import { ReactComponent as TransferIcon } from "../../assets/images/svg/transfers-icon.svg";
import { ReactComponent as SubAccountIcon } from "../../assets/images/svg/sub-account-icon.svg";
import { ReactComponent as TransactionsIcon } from "../../assets/images/svg/transaction-icon.svg";
import { ReactComponent as LoansIcon } from "../../assets/images/svg/employee-loans.svg";
import { useMerchantServices } from "../../hooks/useMerchantServices";
import { useAuth } from "../../context/auth";
import { getFromLocalStorage } from "../../helpers/localStorage";
import { LOCAL_STORAGE_KEYS } from "../../helpers/localStorageKeys";

const Sidebar = () => {
  const { merchantCntxtStatus } = useAuth();
  const { data } = useMerchantServices({ status: merchantCntxtStatus });

  const role = getFromLocalStorage(LOCAL_STORAGE_KEYS.ROLE);

  return (
    <React.Fragment>
      <ul className={`flex flex-col  min-h-[250px]`}>
        {/* Home */}
        <li>
          <NavLink
            className={({ isActive }) =>
              isActive
                ? `border-l py-2 pl-[19px] pr-5 flex mb-3 mt-2.5 cursor-pointer bg-[#1941A4] shadow-inner-[2px 0px 0px] items-center`
                : `py-2 px-5 flex w-full mb-3 mt-2.5 opacity-75 cursor-pointer items-center`
            }
            to={ROUTES.HOME}
          >
            <div className="w-[20px] h-[20px]">
              <HomeIcon />
            </div>
            <span className="ml-4 text-[16px] lg:leading-[17px] font-[300]">
              Home
            </span>
          </NavLink>
        </li>
        {/* Sub Account*/}
        {(role === "Super Admin" || role === "Admin") &&
          merchantCntxtStatus === "1" && (
            <li>
              <NavLink
                className={({ isActive }) =>
                  isActive
                    ? `border-l py-2 pl-[19px] pr-5 flex mb-3 cursor-pointer bg-[#1941A4] shadow-inner-[2px 0px 0px] items-center`
                    : `py-2 px-5 flex w-full mb-3 opacity-75 cursor-pointer items-center`
                }
                to={ROUTES.SUB_ACCOUNTS}
              >
                <div className="w-[20px] h-[20px]">
                  <SubAccountIcon />
                </div>
                <span className="ml-4 text-[16px] lg:leading-[17px] font-[300]">
                  Sub Accounts
                </span>
              </NavLink>
            </li>
          )}
        {/* Transaction*/}
        {merchantCntxtStatus === "1" && (
          <li>
            <NavLink
              className={({ isActive }) =>
                isActive
                  ? `border-l py-2 pl-[19px] pr-5 flex mb-3 cursor-pointer bg-[#1941A4] shadow-inner-[2px 0px 0px] items-center`
                  : `py-2 px-5 flex w-full mb-3 opacity-75 cursor-pointer items-center`
              }
              to={ROUTES.TRANSACTIONS}
            >
              <div className="w-[20px] h-[20px]">
                <TransactionsIcon />
              </div>
              <span className="ml-4 text-[16px] lg:leading-[17px] font-[300]">
                Transactions
              </span>
            </NavLink>
          </li>
        )}
        {/* Wallet*/}
        {merchantCntxtStatus === "1" && (
          <li>
            <NavLink
              className={({ isActive }) =>
                isActive
                  ? `border-l py-2 pl-[19px] pr-5 flex mb-3 cursor-pointer bg-[#1941A4] shadow-inner-[2px 0px 0px] items-center`
                  : `py-2 px-5 flex w-full mb-3 opacity-75 cursor-pointer items-center`
              }
              to={ROUTES.WALLET}
            >
              <div className="w-[20px] h-[20px]">
                <WalletIcon />
              </div>
              <span className="ml-4 text-[16px] lg:leading-[17px] font-[300]">
                Balance
              </span>
            </NavLink>
          </li>
        )}
        {/* Transfers */}
        {merchantCntxtStatus === "1" && (
          <li>
            <NavLink
              className={({ isActive }) =>
                isActive
                  ? `border-l py-2 pl-[19px] pr-5 flex mb-3 cursor-pointer bg-[#1941A4] shadow-inner-[2px 0px 0px] items-center`
                  : `py-2 px-5 flex w-full mb-3 opacity-75 cursor-pointer items-center`
              }
              to={ROUTES.TRANSFERS}
            >
              <div className="w-[20px] h-[20px]">
                <TransferIcon />
              </div>
              <span className="ml-4 text-[16px] lg:leading-[17px] font-[300]">
                Transfer
              </span>
            </NavLink>
          </li>
        )}
        {/* Event */}
        {/* {merchantDetails?.status === 1 &&
          data?.some((obj: any) => obj.name.toLowerCase() === "event") && (
            <li>
              <NavLink
                className={({ isActive }) =>
                  isActive
                    ? `border-l py-2 px-5 flex mb-3 cursor-pointer bg-[#1941A4] shadow-inner-[2px 0px 0px] items-center`
                    : `py-2 px-5 flex w-full mb-3 opacity-75 cursor-pointer items-center`
                }
                to={ROUTES.EVENT}
              >
                <EventIcon />
                <span className="ml-4 text-[16px] lg:leading-[17px] font-[300]">
                  Event
                </span>
              </NavLink>
            </li>
          )} */}

        {/* employee loans */}
        {merchantCntxtStatus === "1" &&
          data?.some(
            (obj: any) => obj?.type?.name?.toLowerCase() === "employee loans"
          ) && (
            <li>
              <NavLink
                className={({ isActive }) =>
                  isActive
                    ? `border-l py-2 pl-[19px] pr-5 flex mb-3 cursor-pointer bg-[#1941A4] shadow-inner-[2px 0px 0px] items-center`
                    : `py-2 px-5 flex w-full mb-3 opacity-75 cursor-pointer items-center`
                }
                to={ROUTES.EMPLOYEE_LOANS}
              >
                <div className="w-[20px] h-[20px]">
                  <LoansIcon />
                </div>
                <span className="ml-4 text-[16px] lg:leading-[17px] font-[300]">
                  Employee Loans
                </span>
              </NavLink>
            </li>
          )}
        {/* POS */}
        {(role === "Super Admin" || role === "Admin" || role === "Manager") &&
          merchantCntxtStatus === "1" &&
          data?.some(
            (obj: any) => obj?.type?.name?.toLowerCase() === "pos"
          ) && (
            <li>
              <NavLink
                className={({ isActive }) =>
                  isActive
                    ? `border-l py-2 pl-[19px] pr-5 flex mb-3 cursor-pointer bg-[#1941A4] shadow-inner-[2px 0px 0px] items-center`
                    : `py-2 px-5 flex w-full mb-3 opacity-75 cursor-pointer items-center`
                }
                to={ROUTES.POS}
              >
                <div className="w-[20px] h-[20px]">
                  <PosIcon />
                </div>
                <span className="ml-4 text-[16px] lg:leading-[17px] font-[300]">
                  POS
                </span>
              </NavLink>
            </li>
          )}
      </ul>
    </React.Fragment>
  );
};
export default Sidebar;
