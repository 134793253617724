export const identificationTypes = [
  { name: "Driver License", value: "DRIVER_LICENSE" },
  { name: "International Passport", value: "INTERNATIONAL_PASSPORT" },
  { name: "NIN", value: "NIN" },
  { name: "TIN", value: "TIN" },
  { name: "Voter Card", value: "VOTER_CARD" },
];
export const registeredBusinessTypes = [
  { name: "Business", value: "Business" },
  { name: "Company", value: "Company" },
  { name: "Trustee", value: "Trustee" },
];
export const genderType = [
  { name: "Female", value: "Female" },
  { name: "Male", value: "Male" },
];

export const businesTypes = [
  {
    id: "Unregistered",
    name: "Unregistered Business",
    description:
      "An unregistered business is one that has not completed the legal procedures required to operate legally including CAC registration but is testing their ideas with real customers",
  },
  {
    id: "Registered",
    name: "Registered Business",
    description:
      "A registered business is a legally recognised business that has fulfilled all the necessary requirements and has obtained documents such as CAC registration to operate its business.",
  },
];
