import { useState } from "react";
import ReactDatePicker from "react-datepicker";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";

import { ModalContainer } from "../../../modal/ModalContainer";
import calendarIcon from "../../../assets/images/svg/calendar.svg";
import BlueButton from "../../../component/Button/BlueButton";
import { ReactComponent as CloseIcon } from "../../../assets/images/svg/white-close-modal.svg";
import InfoIcon from "../../../assets/images/svg/info-bulk.svg";
import { getData } from "../../../apis/apiMethods";
import { apiEndpoints } from "../../../apis/apiEndpoints";
import CONFIG from "../../../helpers/config";
import moment from "moment";
import { errorHandler } from "../../../helpers/errorHandler";

const ExportModal = ({
  showModal,
  setShowModal,
  exportDetails,
  setExportDetails,
  setShowExportStatus,
  walletId,
  setStatus,
}: {
  showModal: boolean;
  setShowModal: (newState: boolean) => void;
  exportDetails: any;
  setExportDetails: any;
  setShowExportStatus: (newState: boolean) => void;
  walletId: string;
  setStatus: (newState: string) => void;
}) => {
  const [exporting, setExporting] = useState(false);
  const { pathname } = useLocation();
  const currentPage = pathname?.split("/")[2];

  const handleSubmit = async () => {
    setExporting(true);
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const res: any = await getData(
        `${CONFIG.BASE_URL1}${
          apiEndpoints.EXPORT_LIST
        }/wallet?start_date=${moment(exportDetails?.startDate).format(
          "DD-MM-YYYY"
        )}&end_date=${moment(exportDetails?.endDate).format(
          "DD-MM-YYYY"
        )}&format=ALL&walletId=${walletId}`
      );

      if (res?.data?.toLowerCase()?.includes("success")) {
        setShowExportStatus(true);
      }

      setStatus("success");
      setShowModal(false);
      setShowExportStatus(true);
      setExportDetails({});
    } catch (error) {
      setStatus("failed");
      toast.error(errorHandler(error));
      setShowModal(false);
      setShowExportStatus(true);
      setExportDetails({});
    }

    setExporting(false);
  };

  return (
    <ModalContainer
      showModal={showModal}
      closeModal={() => {
        setShowModal(false);
        setExportDetails({});
      }}
    >
      <div className="w-full lg:w-[40vw] relative h-auto lg:max-h-[85vh] z-50 text-aellaBlack flex flex-col rounded-[8px] bg-white py-8 px-6">
        <div
          onClick={() => {
            setExportDetails({});
            setShowModal(false);
          }}
          className="absolute -top-8 -right-0 cursor-pointer"
        >
          <CloseIcon />
        </div>
        <p className="text-aellaBlack text-[20px]">Export Date Range</p>
        <p className="text-[#5b5b5b] font-light">
          Select the time period you would like to be exported
        </p>
        <div className="w-full flex mt-6 justify-between items-center">
          <div className="flex-col w-[30%]">
            <p className="font-normal">From</p>
            <div className="flex items-center border border-aellaLightGray mt-1 w-full px-2 h-10 rounded-[4px]">
              <ReactDatePicker
                dateFormat="yyyy-MM-dd"
                selected={exportDetails?.startDate}
                onChange={(date: Date) => {
                  setExportDetails({ ...exportDetails, startDate: date });
                }}
                className="h-full w-[80%] rounded-lg text-aellaBlack lg:leading-[16px] text-[16px] font-[300] outline-none"
              />
              <img src={calendarIcon} alt="calendar" />
            </div>
          </div>
          <div className="flex-col w-[30%]">
            <p className="font-normal">To</p>
            <div className="flex items-center border border-aellaLightGray mt-1 w-full px-2 h-10 rounded-[4px]">
              <ReactDatePicker
                dateFormat="yyyy-MM-dd"
                selected={exportDetails?.endDate}
                minDate={exportDetails?.startDate}
                onChange={(date: Date) => {
                  setExportDetails({ ...exportDetails, endDate: date });
                }}
                className="h-full w-[80%] rounded-lg text-aellaBlack lg:leading-[16px] text-[16px] font-[300] outline-none"
              />
              <img src={calendarIcon} alt="calendar" />
            </div>
          </div>
          <BlueButton
            disabled={exporting}
            onClick={() => {
              // setShowModal(false);
              // setShowExportStatus(true); {}

              if (!exportDetails?.startDate || !exportDetails?.endDate) {
                toast.error("Please select date range");
                return;
              }
              handleSubmit();
            }}
            title={exporting ? "Exporting" : "Export CSV"}
            className="h-10 font-light self-end"
          />
        </div>

        <div className="text-[14px] mt-8 p-4 rounded-[8px] border border-[#C5D4F6] bg-[#F3F5FC]">
          <div className="flex gap-x-3">
            <img src={InfoIcon} alt="info icon" className="self-start" />
            <div className="flex flex-col gap-y-1">
              <p className="font-medium">Large amount of data included</p>
              <p className="font-light">
                You&apos;re about to export a large amount of data that may take a
                while to export and appear in your email.
              </p>
            </div>
          </div>
        </div>
      </div>
    </ModalContainer>
  );
};

export default ExportModal;
