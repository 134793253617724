import React, { useEffect } from "react";
import CloseIcon from "../../../assets/images/svg/close-icon.svg";
import { ReactComponent as DisputeIcon } from "../../../assets/images/svg/dispute-accept-icon.svg";
import CustomSelectDropdown from "../../../component/CustomHTMLElements/CustomSelectDropdown";
import CustomTextArea from "../../../component/CustomHTMLElements/CustomTextArea";
import attachmentIcon from "../../../assets/images/svg/attachment-blue-icon.svg";
import deleteIcon from "../../../assets/images/svg/delete-icon.svg";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { AcceptDisputeType } from "../Tab/DisputesDetails";
import { Loader } from "../../../component/Loader/Loader.component";
import { toast } from "react-toastify";
import { convertToBase64 } from "../../../helpers/convertToBase64";
import { nanoid } from "nanoid";
import { truncateString } from "../../../helpers/formatter";
import fileIcon from "../../../assets/images/svg/file-blue-icon.svg";

const refundOptions = [
  { id: "Full Payment", name: "Full Payment" },
  { id: "Partial Payment", name: "Partial Payment" },
];
const schema = yup.object().shape({});

interface TDisputeActionModal {
  modalType: string;
  setModalType: (newState: string) => void;
  loading: boolean;
  attachment: string[];
  setAttachment: (newState: any) => void;
  submitHandler: (newState: object) => void;
  submitDeclineHandler: (newState: object) => void;
  merchantName: any;
  amount: string | number;
}
export type AcceptDisputeMModalType = {
  refundOption?: string;
  amount?: number | string;
  reason: string;
};
const DisputeActionModal: React.FC<TDisputeActionModal> = ({
  modalType,
  setModalType,
  attachment,
  setAttachment,
  amount,
  submitHandler,
  submitDeclineHandler,
  loading,
  merchantName,
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm<AcceptDisputeMModalType>({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    setValue("amount", amount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount]);

  const onSubmit = async ({
    refundOption,
    amount,
    reason,
  }: AcceptDisputeType) => {
    const attachments = attachment.map((file: any) => file.photo);
    const req = {
      refundOption,
      amount: Number(amount),
      reason,
      attachments,
      authorized_by: merchantName,
    };
    submitHandler(req);
  };

  const onSubmitDecline = async ({ reason }: AcceptDisputeType) => {
    const attachments = attachment.map((file: any) => file.photo);
    const req = {
      reason,
      authorized_by: merchantName,
      attachments,
    };
    submitDeclineHandler(req);
  };

  const onFileChange = async ({ e, id }: { e: any; id: string }) => {
    const fileName = e.target.files[0].name;
    const fileAcceptType = e.target.files[0].type;
    const isValidFile =
      fileAcceptType.startsWith("image/png") ||
      fileAcceptType.startsWith("image/jpg") ||
      fileAcceptType.startsWith("image/jpeg");
    if (!isValidFile) {
      toast.error(
        "Invalid file extension. Kindly upload a png, jpeg or jpg file"
      );
      return (e.target.value = null);
    }

    const fileBase64 = await convertToBase64(e.target.files[0]);
    const extension = fileName.split(".").pop();

    let newFile = {} as {
      fileName: string;
      extension: string;
      photo: string;
      id: string;
    };
    newFile = {
      fileName,
      extension,
      photo: fileBase64,
      id,
    };

    let newFiles = [...attachment, newFile];
    setAttachment(newFiles);
  };

  const deleteFile = (id: string) => {
    const remainingRequests = attachment.filter((file: any) => file.id !== id);
    setAttachment([...remainingRequests]);
  };
  const refundOptionSelected = watch("refundOption");

  return (
    <>
      <div className="fixed justify-center items-center flex inset-0 z-50 outline-none focus:outline-none">
        <div className="relative  mt-2 mx-auto w-[464px]">
          <div className="flex mb-2 justify-between">
            <div></div>
            <button
              onClick={() => {
                setModalType("");
                setAttachment([]);
              }}
              type="button"
              className="cursor-pointer"
            >
              <img src={CloseIcon} alt="close modal" />
            </button>
          </div>
          {/*content*/}
          <div className="border-0 bg-white rounded-lg py-10 flex flex-col w-screen md:w-full bg-colors-white outline-none focus:outline-none ">
            {(() => {
              switch (modalType) {
                case "accept":
                  return (
                    <>
                      {/*header*/}
                      <div className="flex flex-col overflow-y-auto h-[504px] px-10">
                        <div className="flex flex-col items-center">
                          <DisputeIcon />

                          <h1 className="leading-6 font-[400] text-[20px] tracking[0.4px] text-[#0B1E4B] pt-10">
                            Accept Dispute
                          </h1>
                          <p className="lg:leading-6 font-[200] text-[16px] pt-4 pb-[17px] text-center">
                            By accepting this dispute, the refund amount would
                            be deducted from wallet.
                          </p>
                        </div>
                        <form onSubmit={handleSubmit(onSubmit)}>
                          <div className="mt-3 w-full">
                            <CustomSelectDropdown
                              label="Refund Option"
                              errors={errors.refundOption}
                              {...register("refundOption")}
                            >
                              <option value="">select a refund option</option>
                              {refundOptions?.map((data: any) => {
                                const { id, name } = data;
                                return (
                                  <option value={id} key={id}>
                                    {name}
                                  </option>
                                );
                              })}
                            </CustomSelectDropdown>
                          </div>
                          <div className="w-full mb-[18px] lg:mb-[25px]">
                            <p className="pb-2 text-aellaBlack lg:leading-[16px] text-[16px] font-[300]">
                              Enter Amount
                            </p>

                            <div className="flex">
                              <span className="inline-flex items-center px-3 text-sm bg-[#E5B7A0] rounded-l-md border border-r-0 border-[#D9D9D9]">
                                &#8358;
                              </span>
                              <input
                                type="number"
                                placeholder="0.00"
                                disabled={
                                  refundOptionSelected === "Full Payment" ||
                                  !refundOptionSelected
                                }
                                className=" placeholder:text-eallaGray rounded-none rounded-r bg-white border min-w-0 w-full text-[16px] lg:leading-[20px] font-[300] border-[#D9D9D9] p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-aellaGray dark:text-white dark:focus:ring-aellaBlue dark:focus:border-aellaBlue disabled:text-aellaGray"
                                defaultValue={amount}
                                {...register("amount")}
                              />
                            </div>
                          </div>
                          {refundOptionSelected === "Partial Payment" && (
                            <div className=" w-full">
                              <CustomTextArea
                                maxLength={50}
                                label="Reason for Partial Refund"
                                {...register("reason")}
                                placeholder="Enter reason here"
                              />
                            </div>
                          )}
                          <label
                            htmlFor="file"
                            className="min-w-[50%] h-12 inline-flex items-center gap-4 rounded-[3px] border-aellaBlue border-dashed border-[1px] px-3 py-1 cursor-pointer"
                          >
                            <img
                              src={attachmentIcon}
                              alt="Attachment Icon"
                              width={20}
                              height={20}
                            />
                            <p className="text-[14px] lg:leading-[17px] text-aellaBlue font-[200]">
                              Upload Document (s)
                            </p>
                            <input
                              type="file"
                              name="file"
                              id="file"
                              accept="image/*"
                              className="hidden"
                              onChange={(e) =>
                                onFileChange({
                                  e,
                                  id: nanoid(),
                                })
                              }
                            />
                          </label>
                          {attachment?.length > 0 && (
                            <div className="w-full mt-4">
                              <p className="text-[14px] leading-[16px] font-[200]">
                                Attachments
                              </p>
                              <div className="w-full mt-2 flex flex-wrap  gap-x-4 items-center">
                                {attachment?.map((file: any) => (
                                  <div
                                    className="flex items-center gap-x-1 border p-4 rounded border-aellaBlue mt-2"
                                    key={file.id}
                                  >
                                    <img
                                      src={fileIcon}
                                      alt="File Icon"
                                      width={15}
                                      height={18}
                                    />
                                    <p className="text-aellaBlue text-[14px] leading-[16px] font-[200] pr-1">
                                      {truncateString(file.fileName, 10)}
                                      {file.extension}
                                    </p>
                                    <img
                                      src={deleteIcon}
                                      alt=""
                                      className=" cursor-pointer"
                                      width={15}
                                      height={18}
                                      onClick={() => deleteFile(file.id)}
                                    />
                                  </div>
                                ))}
                              </div>
                            </div>
                          )}
                          <div className="flex justify-end mt-8">
                            <button
                              className="rounded text-[#5B5B5B] py-[15px] px-[19.5px] items-center flex justify-center cursor-pointer mr-2.5"
                              onClick={() => {
                                setModalType("");
                                setAttachment([]);
                              }}
                            >
                              Cancel
                            </button>

                            <button
                              type="submit"
                              disabled={loading}
                              className={`bg-aellaBlue rounded text-white py-[15px] px-[19.5px] items-center flex justify-center cursor-pointer ${
                                loading ? "opacity-30" : ""
                              } `}
                            >
                              Accept Dispute
                              {loading && <Loader />}
                            </button>
                          </div>
                        </form>
                      </div>
                    </>
                  );
                case "decline":
                  return (
                    <>
                      {/*header*/}
                      <div className="flex flex-col overflow-y-auto h-[380px] px-10">
                        <h1 className="leading-6 font-[400] text-[20px] tracking[0.4px]">
                          Decline Dispute
                        </h1>
                        <p className="lg:leading-6 font-[200] text-[16px] pt-4 pb-[17px] text-aellaGray">
                          Please give reasons why you want to decline this
                          dispute
                        </p>

                        <form onSubmit={handleSubmit(onSubmitDecline)}>
                          <div className=" w-full">
                            <CustomTextArea
                              maxLength={50}
                              label="Reason"
                              errors={errors.reason}
                              {...register("reason")}
                              placeholder="Enter reason here"
                            />
                          </div>
                          <label
                            htmlFor="file"
                            className="min-w-[50%] h-12 inline-flex items-center gap-4 rounded-[3px] border-aellaBlue border-dashed border-[1px] px-3 py-1 cursor-pointer"
                          >
                            <img
                              src={attachmentIcon}
                              alt="Attachment Icon"
                              width={20}
                              height={20}
                            />
                            <p className="text-[14px] lg:leading-[17px] text-aellaBlue font-[200]">
                              Upload Document (s)
                            </p>
                            <input
                              type="file"
                              name="file"
                              id="file"
                              accept="image/*"
                              className="hidden"
                              onChange={(e) =>
                                onFileChange({
                                  e,
                                  id: nanoid(),
                                })
                              }
                            />
                          </label>
                          {attachment?.length > 0 && (
                            <div className="w-full mt-4">
                              <p className="text-[14px] leading-[16px] font-[200]">
                                Attachments
                              </p>
                              <div className="w-full mt-2 flex flex-wrap  gap-x-4 items-center">
                                {attachment?.map((file: any) => (
                                  <div
                                    className="flex items-center gap-x-1 border p-4 rounded border-aellaBlue mt-2"
                                    key={file.id}
                                  >
                                    <img
                                      src={fileIcon}
                                      alt="File Icon"
                                      width={15}
                                      height={18}
                                    />
                                    <p className="text-aellaBlue text-[14px] leading-[16px] font-[200] pr-1">
                                      {truncateString(file.fileName, 10)}
                                      {file.extension}
                                    </p>
                                    <img
                                      src={deleteIcon}
                                      alt=""
                                      className=" cursor-pointer"
                                      width={15}
                                      height={18}
                                      onClick={() => deleteFile(file.id)}
                                    />
                                  </div>
                                ))}
                              </div>
                            </div>
                          )}
                          <div className="flex justify-end mt-10">
                            <button
                              className="rounded text-[#5B5B5B] py-[14px] px-[19.5px] items-center flex justify-center cursor-pointer mr-2.5"
                              onClick={() => {
                                setModalType("");
                                setAttachment([]);
                              }}
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              disabled={loading}
                              className={`bg-[#FE4149] rounded text-white py-[14px] px-[19.5px] items-center flex justify-center cursor-pointer ${
                                loading ? "opacity-30" : ""
                              }`}
                            >
                              Decline
                              {loading && <Loader />}
                            </button>
                          </div>
                        </form>
                      </div>
                    </>
                  );
                default:
                  return null;
              }
            })()}
          </div>
        </div>
      </div>
      <div
        className="bg-opacity-25 fixed inset-0 z-40 bg-[#0B1E4B]"
        onClick={() => {
          setModalType("");
        }}
      ></div>
    </>
  );
};
export default DisputeActionModal;
