export const attachClassNameToTag = (element: string) => {
  const item = element ? element?.toLowerCase() : "";
  if (item === "pending" || item === "processing") {
    return "text-[#F2994A] bg-[#F2994A] bg-opacity-25 h-[27px] py-[4px] px-[8px] rounded";
  }
  if (item === "awaiting feedback") {
    return "text-[#5E5ADB] bg-[#5E5ADB] bg-opacity-20 h-[27px] py-[4px] px-[8px] rounded";
  }
  if (
    item === "success" ||
    item === "1" ||
    item === "delivered" ||
    item === "approved" ||
    item === "accept dispute" ||
    item === "resolved" ||
    item?.toLowerCase() === "active"
  ) {
    return "text-[#219653] bg-[#E9F4EE] h-[27px] py-[4px] px-[8px] rounded";
  }
  if (
    item === "failed" ||
    item === "0" ||
    item === "declined" ||
    item === "fail" ||
    item === "decline" ||
    item?.toLowerCase() === "deactivated" ||
    item?.toLowerCase() === "inactive"
  ) {
    return "text-[#EB5757] bg-[#fbdddd] h-[27px] py-[4px] px-[8px] rounded";
  }
};
export const getStatusName = (element: string) => {
  const item = element ? element?.toLowerCase() : "";
  if (item === "pending") {
    return "Pending";
  }
  if (item === "success" || item === "resolved") {
    return "Success";
  }
  if (item === "successful") {
    return "Successful";
  }
  if (item === "reopened") {
    return "Reopened";
  }
  if (item === "accept dispute") {
    return "Accepted";
  }
  if (item === "awaiting feedback") {
    return "Awaiting Feedback";
  }
  if (item === "in-progress") {
    return "In-Progress";
  }
  if (item === "delivered") {
  }
  if (item === "success" || item === "resolved") {
    return "Success";
  }
  if (item === "processing") {
    return "Processing";
  }
  if (item === "delivered") {
    return "Delivered";
  }
  if (item === "approved") {
    return "Approved";
  }
  if (item === "failed" || item === "fail") {
    return "Failed";
  }
  if (item === "declined" || item === "decline") {
    return "Declined";
  }
  if (item === "1") {
    return "Active";
  }
  if (item === "0") {
    return "Not Active";
  }
};

export const attachClassNameToStatus = (element: number) => {
  const item = (element && element === 1) || element === 0 ? element : "";
  if (item === 0) {
    return "text-[#eb5757] bg-[#FBDDDD] h-[27px] py-[4px] px-[8px] rounded";
  }
  if (item === 1) {
    return "text-[#219653] bg-[#E9F4EE] h-[27px] py-[4px] px-[8px] rounded";
  }
};
export const getStatusNameByNumber = (element: Number) => {
  const item = (element && element === 1) || element === 0 ? element : "";
  if (item === 1) {
    return "Successful";
  }
  if (item === 0) {
    return "Failed";
  }
};
export const attachClassNameAccount = (element: number) => {
  const item =
    (element && element === 1) || (element && element === 2) || element === 0
      ? element
      : "";
  if (item === 0) {
    return "text-[#EB5757] bg-[#fbdddd] h-[27px] py-[4px] px-[8px] rounded";
  }
  if (item === 1) {
    return "text-[#219653] bg-[#E9F4EE] h-[27px] py-[4px] px-[8px] rounded";
  }

  if (item === 2) {
    return "text-[#F2994A] bg-[#F2994A] bg-opacity-25 h-[27px] py-[4px] px-[8px] rounded";
  }
};
export const getStatusNameAccount = (element: string) => {
  // const item =
  //   (element && element === 1) || (element && element === 2) || element === 0
  //     ? element
  //     : "";
  if (element?.toLowerCase() === "inactive") {
    return "De-activated";
  }
  if (element?.toLowerCase() === "active") {
    return "Active";
  }
};
export const attachClassStatusNameAccount = (element: string) => {
  if (element?.toLowerCase() === "inactive") {
    return "text-[#EB5757] bg-[#fbdddd] h-[27px] py-[4px] px-[8px] rounded";
  }
  if (element?.toLowerCase() === "active") {
    return "text-[#219653] bg-[#E9F4EE] h-[27px] py-[4px] px-[8px] rounded";
  }
};

export const attachNameToId = (id: number) => {
  if (id === 1) return "Active";
  if (id === 2) return "Inactive";
  if (id === 3) return "Deactivated";
  else return "N/A";
};

export const attachClassToId = (id: number) => {
  if (id === 1) {
    return "text-[#219653] bg-[#E9F4EE] h-[27px] py-[4px] px-[8px] rounded";
  }

  if (id === 2 || id === 3) {
    return "text-[#EB5757] bg-[#fbdddd] h-[27px] py-[4px] px-[8px] rounded";
  }
};

export const attachClassToLoanStatusText = (statusText: string) => {
  if (!statusText) return null;
  if (statusText?.toLowerCase() === "paid") {
    return "text-[#219653] bg-[#E9F4EE] h-[27px] py-[4px] px-[8px] rounded";
  }

  if (
    statusText?.toLowerCase() === "unpaid" ||
    statusText?.toLowerCase() === "late"
  ) {
    return "text-[#EB5757] bg-[#fbdddd] h-[27px] py-[4px] px-[8px] rounded";
  }

  if (
    statusText?.toLowerCase() === "partially paid" ||
    statusText?.toLowerCase() === "partial"
  ) {
    return "text-[#F2994A] bg-[#F2994A1A] h-[27px] py-[4px] px-[8px] rounded";
  }
};

enum LoanStatus {
  PENDING = 4,
  DECLINED = 0,
  APPROVED = 2,
  RUNNING = 1,
  PARTIAL = 7,
  COMPLETED = 3,
  DEFAULTED = 5,
  NPL = 6,
  DISCOUNTED = 8,
  WRITE_OFF = 9,
  ROLLED_OVER = 10,
  WPL = 12,
  DP = 15,
  UNDEFINED = -1,
}

export const attachNameToLoanStatus = (id: number): string => {
  switch (id) {
    case LoanStatus.RUNNING:
      return "Running";
    case LoanStatus.APPROVED:
      return "Approved";
    case LoanStatus.COMPLETED:
      return "Completed";
    case LoanStatus.PENDING:
      return "Pending";
    case LoanStatus.DECLINED:
      return "Declined";
    case LoanStatus.PARTIAL:
      return "Partial";
    case LoanStatus.DEFAULTED:
      return "Defaulted";
    case LoanStatus.NPL:
      return "Non-Performing Loan";
    case LoanStatus.DISCOUNTED:
      return "Discounted";
    case LoanStatus.WRITE_OFF:
      return "Write Off";
    case LoanStatus.ROLLED_OVER:
      return "Rolled Over";
    case LoanStatus.WPL:
      return "WPL";
    case LoanStatus.DP:
      return "DP";
    default:
      return "N/A";
  }
};
export const attachClassToLoanStatus = (id: number): string => {
  switch (id) {
    case LoanStatus.RUNNING:
      return "text-[#F2994A] bg-[#F2994A1A] h-[27px] py-[4px] px-[8px] rounded";
    case LoanStatus.APPROVED:
      return "text-[#219653] bg-[#E9F4EE] h-[27px] py-[4px] px-[8px] rounded";
    case LoanStatus.COMPLETED:
      return "text-[#219653] bg-[#E9F4EE] h-[27px] py-[4px] px-[8px] rounded";
    case LoanStatus.PENDING:
      return "text-[#F2994A] bg-[#F2994A1A] h-[27px] py-[4px] px-[8px] rounded";
    case LoanStatus.DECLINED:
      return "text-[#EB5757] bg-[#fbdddd] h-[27px] py-[4px] px-[8px] rounded";
    case LoanStatus.PARTIAL:
      return "text-[#F2994A] bg-[#F2994A1A] h-[27px] py-[4px] px-[8px] rounded";
    case LoanStatus.DEFAULTED:
      return "text-[#EB5757] bg-[#fbdddd] h-[27px] py-[4px] px-[8px] rounded";
    case LoanStatus.NPL:
      return "text-[#EB5757] bg-[#fbdddd] h-[27px] py-[4px] px-[8px] rounded";
    case LoanStatus.DISCOUNTED:
      return "text-[#F2994A] bg-[#F2994A1A] h-[27px] py-[4px] px-[8px] rounded";
    case LoanStatus.WRITE_OFF:
      return "text-[#EB5757] bg-[#fbdddd] h-[27px] py-[4px] px-[8px] rounded";
    case LoanStatus.ROLLED_OVER:
      return "text-[#F2994A] bg-[#F2994A1A] h-[27px] py-[4px] px-[8px] rounded";
    case LoanStatus.WPL:
      return "text-[#219653] bg-[#E9F4EE] h-[27px] py-[4px] px-[8px] rounded";
    case LoanStatus.DP:
      return "text-[#219653] bg-[#E9F4EE] h-[27px] py-[4px] px-[8px] rounded";
    default:
      return ""; // Return an empty string for unknown statuses
  }
};

export const attachClassToBoolean = (element: boolean) => {
  if (element === false) {
    return "text-[#EB5757] bg-[#FB4F571A] h-[27px] py-[4px] px-[8px] rounded";
  }
  if (element === true) {
    return "text-[#219653] bg-[#E9F4EE] h-[27px] py-[4px] px-[8px] rounded";
  }
};
