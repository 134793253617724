import React from "react";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import { ROUTES } from "./helpers/routes";
// style
import { ToastContainer } from "react-toastify";
import Login from "./pages/Login/Login";
import SignUp from "./pages/SignUp/SignUp";

import "react-toastify/dist/ReactToastify.css";
import "react-loading-skeleton/dist/skeleton.css";
import ScrollToTop from "./component/ScrollToTop/ScrollToTop.component";
import AuthRoutesRoot from "./component/AuthRoutesRoot/AuthRoutesRoot.component";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import { useAuth } from "./context/auth";
import { getFromLocalStorage } from "./helpers/localStorage";
import { LOCAL_STORAGE_KEYS } from "./helpers/localStorageKeys";
import TermsOfUse from "./pages/Terms/TermsOfUse";
import BankList from "./pages/BankList/BankList";
import BankCodes from "./pages/BankCodes/BankCodes";

function App() {
  const { isAuthed } = useAuth();
  const token = getFromLocalStorage(LOCAL_STORAGE_KEYS.TOKEN);
  return (
    <Router>
      <ToastContainer limit={2} />
      <Routes>
        <Route path={ROUTES.LOGIN} element={<Login />} />
        <Route path={ROUTES.SIGNUP} element={<SignUp />} />
        <Route path={ROUTES.RESET_PASSWORD} element={<ResetPassword />} />
        <Route path={ROUTES.TERMS_OF_USE} element={<TermsOfUse />} />
        <Route path={ROUTES.BANK_LIST} element={<BankList />} />
        <Route path={ROUTES.BANK_CODES} element={<BankCodes />} />
        <Route
          path="*"
          element={
            token || isAuthed ? (
              <AuthRoutesRoot />
            ) : (
              <Navigate to={ROUTES.LOGIN} />
            )
          }
        />
      </Routes>
      <ScrollToTop />
    </Router>
  );
}

export default App;
