import React from "react";
import Tab from "../../../component/Tab/Tab.component";
import { ROUTES } from "../../../helpers/routes";
import { getFromLocalStorage } from "../../../helpers/localStorage";
import { LOCAL_STORAGE_KEYS } from "../../../helpers/localStorageKeys";
import { useMerchantDetails } from "../../../hooks/useMerchantDetails";

export const TabMenu = () => {
  const userId = getFromLocalStorage(LOCAL_STORAGE_KEYS.USERID);
  const role = getFromLocalStorage(LOCAL_STORAGE_KEYS.ROLE);

  const {
    data: merchantDetails,
    isLoading: merchantDetailLoading,
    refetch,
  } = useMerchantDetails({ userId });

  if (merchantDetailLoading) {
    <div className="flex"></div>;
  }

  return (
    <div className="flex ">
      <Tab text="Profile" path={ROUTES.SETTINGS_PROFILE} />
      <Tab text="Security" path={ROUTES.SETTINGS_SECURITY} />
      {(role === "Super Admin" || role === "Admin") && (
        <Tab text="Notifications" path={ROUTES.SETTINGS_NOTIFICATIONS} />
      )}
      {(role === "Super Admin" || role === "Admin") &&
        merchantDetails?.merchant?.business_type === "Registered" && (
          <Tab text="Manage Team" path={ROUTES.SETTINGS_MANAGE_TEAM} />
        )}
      {/*  <Tab
    text="Withdrawal Bank Accounts"
    path={ROUTES.SETTINGS_WITHDRAWAL}
  /> */}
      {/* <Tab text="APi Keys & Webhooks" path={ROUTES.SETTINGS_API} /> */}
    </div>
  );
};
