import { ModalContainer } from "../../../modal/ModalContainer";
import { ReactComponent as CloseIcon } from "../../../assets/images/svg/white-close-modal.svg";

export const DeletionConfirmationModal = ({
  showModal,
  closeModal,
  handleDeletion,
  isDeleting,
}: {
  showModal: boolean;
  closeModal: () => void;
  handleDeletion: () => void;
  isDeleting: boolean;
}) => {
  return (
    <ModalContainer showModal={showModal} closeModal={closeModal}>
      <div
        className={`w-full lg:w-[35vw] relative right-0 h-auto max-h-[90vh] z-50 text-center flex flex-col  justify-center items-center py-10 rounded-[8px] bg-white fast_animation ${
          showModal
            ? "mt-0 opacity-100 visible"
            : "mt-[5rem] opacity-0 invisible"
        }`}
      >
        <button
          onClick={closeModal}
          className="absolute -top-8 -right-0 cursor-pointer"
        >
          <CloseIcon />
        </button>

        <div className="px-10">
          <p className="text-[24px] text-[#232323]">Delete Employee(s)</p>

          <p className="text-[#7b7b7b] mt-4 font-light">
            Are you sure you want to delete these employee(s)? Once done, this
            cannot be reversed.
          </p>
        </div>

        <button
          disabled={isDeleting}
          onClick={handleDeletion}
          type="button"
          className={`bg-[#EB5757] px-[20px] mt-10 text-white text-base font-light h-12 rounded-[4px] ${
            isDeleting ? "opacity-50" : "opacity-100"
          } `}
        >
          {isDeleting ? "Deleting.." : "Delete"}
        </button>
      </div>
    </ModalContainer>
  );
};
